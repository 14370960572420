import React from "react";

import useAppContext from "../../../hooks/useAppContext";
import { Subcontractor } from "../../../services/app";
import { updateSubcontractorUserList } from "../../../services/subcontractor";
import notify from "../../../utils/notify";
import SubcontractorUserLayout from "./SubcontractorUserLayout";

interface SubcontractorUserContainerProps {}

const SubcontractorUserContainer: React.FC<SubcontractorUserContainerProps> =
  () => {
    const {
      routeParams,
      subcontractors,
      serviceConfig,
      onAppContextCacheItemUpdate,
    } = useAppContext();
    const { entityId } = routeParams;

    const handleSaveUserList = (
      subcontractor: Subcontractor,
      userIds: string[]
    ) => {
      updateSubcontractorUserList(
        entityId!,
        subcontractor.id,
        userIds,
        serviceConfig
      )
        .then((subcontractor) => {
          const newSubcontractors = [...subcontractors];
          newSubcontractors[
            subcontractors.findIndex((sub) => sub.id === subcontractor.id)
          ] = subcontractor;
          onAppContextCacheItemUpdate("subcontractors", newSubcontractors);
          notify.success(`User List saved for ${subcontractor.name}`);
        })
        .catch((err) =>
          notify.error(
            `Failed saving user list for ${subcontractor.name} : ${err}`
          )
        );
    };

    return <SubcontractorUserLayout onSaveUserList={handleSaveUserList} />;
  };

export default SubcontractorUserContainer;
