import React, { useContext } from "react";
import { LocationDetailsContext } from "../LocationDetailsContainer";

// import useAppContext from "../../../hooks/useAppContext";
import { NxpHeader } from "@nexploretechnology/nxp-ui";
import TabBar from "../TabBar";
import AppLocationOtherInfoContainer from "../../../components/App/AppLocationOtherInfo";

interface Props {
  onActiveKeyChange: (activeKey: string) => void;
}

const LocationOtherInfo: React.FC<Props> = ({ onActiveKeyChange }) => {
  const { location } = useContext(LocationDetailsContext);

  return (
    <>
      <NxpHeader titleContent={location.name} />
      <TabBar activeKey="dates-and-other-info" onChange={onActiveKeyChange} />
      <AppLocationOtherInfoContainer location={location} />
    </>
  );
};

export default LocationOtherInfo;
