import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";
import { useReactToPrint } from "react-to-print";

import { CaretDownFilled } from "@ant-design/icons";
import {
  NxpButton,
  NxpHeader,
  NxpTabs,
  TabPane,
} from "@nexploretechnology/nxp-ui";
import { Dropdown, Menu } from "antd";

import AppDefectDetail from "../../components/App/AppDefectDetail";
import AppDefectAnalysis from "../../components/AppDefectAnalysis";
import useAppContext from "../../hooks/useAppContext";
import { Defect } from "../../services/defect";
import notify from "../../utils/notify";
import { stringToBoolean } from "../../utils/throttle";
import { DefectDetailsContext } from "./DefectDetailsContainer";
import DefectDetailsReport from "./DefectDetailsReport";
import DefectFloorPlan from "./DefectFloorPlan";
import AddInspectionButton from "./Inspection/AddInspection/AddInspectionButton";
import AddResponseButton from "./Inspection/AddResponse/AddResponseButton";
import CancelDefectButton from "./Inspection/CancelDefect/CancelDefectButton";
import CloseOutButton from "./Inspection/CloseOut/CloseOutButton";
import InspectionContainer from "./Inspection/InspectionContainer";

const useStyles = createUseStyles((theme) => ({
  defectDetailsLayout: {
    "& .defect-summary-wrapper": {
      display: "flex",
      flexDirection: "column",

      "&>.ant-collapse": {
        marginBottom: theme.pxToRem(16),
      },
    },
    "& .ant-tabs-tabpane.summary": {
      "& .ant-collapse": {
        marginBottom: theme.spacing(2),
      },
    },
  },
  dropdown: {
    border: `${theme.pxToRem(1)} solid ${theme.palette.borderLight}`,
    "& .ant-dropdown-menu-item": {
      color: theme.palette.primary,
      fontWeight: theme.fontWeight.bold,
      fontSize: theme.pxToRem(12),
    },
  },
}));

interface DefectDetailsLayoutProps {
  defect: Defect;
}

const DefectDetailsLayout: React.FC<DefectDetailsLayoutProps> = ({
  defect,
}) => {
  const classes = useStyles();
  const [showPhoto, setShowPhoto] = useState<boolean>(true);
  const { activeLocationGroup, activeEntity, hasRight } = useAppContext();
  const history = useHistory();

  const { t: translation } = useTranslation();

  const { activeTab, setActiveTab } = useContext(DefectDetailsContext);
  const printDivRef = useRef<HTMLDivElement | null>(null);

  const editable = !["closed", "cancelled"].includes(defect.status);

  const handlePrint = useReactToPrint({
    bodyClass: "defect-report",
    documentTitle: "Defect Report",
    content: () => printDivRef.current,
    onBeforeGetContent: () => {},
    copyStyles: true,
    onAfterPrint: () => {},
    onPrintError: (error) => notify.warning(`print ${error} !`),
    pageStyle: `
      @media print {
          @page {
             size: landscape !important; 
             margin-left: 0.5in;
        margin-right: 0.5in;
        margin-bottom: 0;
            }
      }
    `,
  });
  function handleMenuClick(e: any) {
    setShowPhoto(stringToBoolean(e?.key));
    setTimeout(() => handlePrint && handlePrint(), 500);
  }
  const menu = (
    <Menu onClick={handleMenuClick} className={classes.dropdown}>
      <Menu.Item key="true">
        {translation("app.common.exportIncPhotos")}
      </Menu.Item>
      <Menu.Item key="false">
        {translation("app.common.exportExcPhotos")}
      </Menu.Item>
    </Menu>
  );
  return (
    <div className={classes.defectDetailsLayout}>
      <NxpHeader
        onBackClick={history.goBack}
        titleContent={`${translation("defectDetails.defect.no", {
          count: defect.no,
        })}`}
        actionContent={
          <>
            {/* handlePrint */}
            {hasRight("report-view") && (
              <Dropdown overlay={menu}>
                <NxpButton type="primary">
                  {translation("app.common.report")} <CaretDownFilled />
                </NxpButton>
              </Dropdown>
            )}
            {activeTab === "inspection" && editable && (
              <>
                {hasRight("defect-add") && <AddInspectionButton />}
                {hasRight("defect-respond") && <AddResponseButton />}
                {hasRight("defect-closeout") && <CloseOutButton />}
                {hasRight("defect-closeout") && <CancelDefectButton />}
              </>
            )}
          </>
        }
      />
      <NxpTabs
        defaultActiveKey="summary"
        onChange={(activeKey) => setActiveTab(activeKey)}
      >
        <TabPane
          tab={translation("app.common.summary")}
          key="summary"
          className="summary"
        >
          {activeLocationGroup && activeEntity && (
            <AppDefectDetail defect={defect}></AppDefectDetail>
          )}
          {activeLocationGroup && activeEntity && defect.analysis && (
            <AppDefectAnalysis defect={defect}></AppDefectAnalysis>
          )}
        </TabPane>
        <TabPane
          tab={translation("app.common.inspection.label")}
          key="inspection"
        >
          <InspectionContainer />
        </TabPane>
        <TabPane tab={translation("app.common.floorPlanLabel")} key="floorPlan">
          <DefectFloorPlan />
        </TabPane>
      </NxpTabs>
      <div ref={printDivRef}>
        <DefectDetailsReport defect={defect} showPhoto={showPhoto} />
      </div>
    </div>
  );
};

export default DefectDetailsLayout;
