import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import AddSubcontractorModal from "./AddSubcontractorModal";

interface AddSubcontractorButtonProps {}

const AddSubcontractorButton: React.FC<AddSubcontractorButtonProps> = () => {
  const { t: translation } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const handleClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <NxpButton onClick={handleClick}>
        {translation("subcontractorList.addSubcontractor.add")}
      </NxpButton>
      <AddSubcontractorModal
        modalVisible={modalVisible}
        onModalClose={handleModalClose}
      />
    </>
  );
};

export default AddSubcontractorButton;
