import React, { useContext } from "react";
import InspectionList from "./InspectionList";
import { DefectDetailsContext } from "../DefectDetailsContainer";

interface InspectionLayoutProps {}

const InspectionLayout: React.FC<InspectionLayoutProps> = () => {
  const { defect } = useContext(DefectDetailsContext);

  const editable = !["closed", "cancelled"].includes(defect.status);

  return (
    <>
      <InspectionList editable={editable} />
    </>
  );
};

export default InspectionLayout;
