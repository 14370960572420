import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  NxpFormGridItemProps,
  NxpPanelEditable,
} from "@nexploretechnology/nxp-ui";
import _ from "lodash";
import moment from "moment";
import * as yup from "yup";

import useAppContext from "../../hooks/useAppContext";
import { useValidate } from "../../hooks/useValidate";
import {
  Defect,
  DefectAnalysis,
  DefectAnalysisForm,
  updateOneDefectAnalysis,
} from "../../services/defect";
import CustomApiError from "../../utils/backend/customApiError";
import notify from "../../utils/notify";
import { stringToBoolean } from "../../utils/throttle";
import AppDefectDetailLayout from "../App/AppDefectDetail/AppDefectDetailLayout";

const useStyles = createUseStyles((theme) => ({
  analysisForem: {
    "& .ant-input-affix-wrapper-disabled": {
      color: "inherit",
      "& .ant-input.ant-input-disabled": {
        color: theme.palette.text,
      },
    },
  },
}));

interface AppDefectAnalysisContainerProps {
  defect: Defect;
}

const AppDefectAnalysisContainer: React.FC<AppDefectAnalysisContainerProps> = ({
  defect,
}) => {
  const { t: translation } = useTranslation();

  const classes = useStyles();
  const [defectForm, setDefectForm] = useState<Partial<DefectAnalysisForm>>({});
  const [originState, setOriginState] = useState<Partial<DefectAnalysisForm>>(
    {}
  );
  const appContext = useAppContext();
  const {
    activeEntity,
    activeLocationGroup,
    defectCauses,
    serviceConfig,
    hasRight,
  } = appContext;
  const formSchema = yup.object().shape({});
  const formItems: NxpFormGridItemProps<Partial<DefectAnalysisForm>>[] = [
    {
      controlType: "select",
      label: translation("app.common.cause"),
      itemFieldName: "cause",
      span: 8,
      controlProps: {
        defaultValue: defectForm?.cause,
        options: defectCauses.map((type) => ({
          label: type?.name,
          value: type?.id?.toString(),
        })),
      },
    },
    {
      controlType: "inputNumber",
      label: translation("app.common.actualCost"),
      itemFieldName: "actualCost",
      controlProps: {
        defaultValue: defectForm?.actualCost,
        decimalPlace: 0.01,
        id: "actualCost",
        prefix: "$",
        keyboard: true,
      },
      span: 8,
    },

    {
      controlType: "radioGroup",
      label: translation("app.common.backcharge"),
      itemFieldName: "backcharge",
      span: 8,
      controlProps: {
        defaultValue:
          defectForm?.backcharge === "" ? "false" : defectForm?.backcharge,
        options: [
          { label: translation("app.common.no"), value: "false" },
          { label: translation("app.common.yes"), value: "true" },
        ],
      },
    },
    {
      controlType: "input",
      label: translation("app.common.scheduleImpact"),
      itemFieldName: "scheduleImpact",
      controlProps: {
        defaultValue: defectForm?.scheduleImpact,
        id: "estimatedCost",
        addonAfter: "Days",
      },
      span: 8,
    },
    {
      controlType: "input",
      label: translation("appDefectAnalysis.totalRound"),
      itemFieldName: "totalRound",
      controlProps: {
        suffix: <span>({translation("appDefectAnalysis.times")})</span>,
        defaultValue: defectForm?.totalRound,
        id: "actualCost",
        disabled: true,
        // addonAfter: "(Times)",
      },
      span: 8,
    },
    {
      controlType: "input",
      label: translation("appDefectAnalysis.daysSpent"),
      itemFieldName: "daysSpent",
      controlProps: {
        suffix: <span>{translation("appDefectAnalysis.days")}</span>,
        defaultValue: defectForm?.daysSpent,
        id: "actualCost",
        disabled: true,
        // addonAfter: "(Days)",
      },
      span: 8,
    },
  ];
  function countDaysSpent(defect: Defect): DefectAnalysisForm["totalRound"] {
    const closeOutDate = defect.messages.find(
      (message) => message.type === "closure"
    )?.date;
    if (!closeOutDate) return 0;
    const closeOutOn = moment(closeOutDate);
    const raisedOn = moment(Number(defect?.createdAt));
    return closeOutOn.diff(raisedOn, "day");
  }

  const reformatting = useCallback(
    (analysis: Partial<DefectAnalysis>): Partial<DefectAnalysisForm> => ({
      cause: _.get(
        analysis,
        "cause.id",
        defectCauses.find((cause) => cause?.name === "Unspecified")?.id || ""
      ).toString(),
      actualCost:
        analysis?.actualCost !== undefined ? Number(analysis?.actualCost) : 0,
      backcharge:
        analysis?.backcharge !== undefined
          ? analysis?.backcharge.toString()
          : "false",
      scheduleImpact: analysis?.scheduleImpact ?? 0,
      totalRound:
        defect.messages.filter((message) => message.type === "inspection")
          .length > 0
          ? defect.messages.filter((message) => message.type === "inspection")
              .length - 1
          : 0,
      daysSpent: countDaysSpent(defect),
    }),
    [defect, defectCauses]
  );

  const handleSaveValidated = async () => {
    const { id: defectId } = defect;
    if (activeLocationGroup?.id && activeEntity?.id) {
      try {
        const defect = await updateOneDefectAnalysis(
          activeEntity?.id,
          activeLocationGroup?.id,
          defectId,
          {
            ...defectForm,
            cause: Number(defectForm?.cause),
            scheduleImpact: Number(defectForm?.scheduleImpact),
            backcharge: stringToBoolean(
              _.get(defectForm, "backcharge", "false")
            ),
          },
          serviceConfig
        );
        if (defect.analysis!) {
          notify.success(
            translation("appDefectAnalysis.handleSaveValidated.success")
          );
          setDefectForm(reformatting(defect.analysis));
          setOriginState(reformatting(defect.analysis));
        }
      } catch (e) {
        if (e instanceof CustomApiError) {
          const apiError: CustomApiError = e;
          if (apiError.status === 403) {
            notify.error(translation("app.common.errorOccurred"));
          } else {
            notify.error(apiError);
          }
        } else {
          notify.error(e);
        }
      }
    }
  };

  const [validationError, , , saveWithValidate] = useValidate<
    Partial<DefectAnalysisForm>
  >(defectForm, formSchema, handleSaveValidated);

  const handleSave = () =>
    Object.values(saveWithValidate(undefined)).filter(
      (val) => val !== undefined
    ).length === 0;

  const handleFormGridStateChange = (
    fieldName: keyof typeof defectForm,
    value: unknown
  ) => {
    setDefectForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    if (!defect.analysis) return;

    const analysis = defect.analysis;
    setDefectForm(reformatting(analysis));
    setOriginState(reformatting(analysis));
  }, [defect.analysis, reformatting]);

  const handlePanelSave = (setEditing: (editing: boolean) => void) => {
    if (handleSave()) {
      setEditing(false);
    }
  };
  return (
    <NxpPanelEditable
      className={classes.analysisForem}
      titleContent={translation("app.common.defectAnalysis")}
      editable={hasRight("defect-edit")}
      onPanelSave={handlePanelSave}
      onPanelCancel={() => setDefectForm(originState)}
    >
      {(editing, updateEditing) => (
        <AppDefectDetailLayout
          editing={editing}
          validationError={validationError}
          formItems={formItems.map((item) => ({
            ...item,
            span: item.span > 8 ? 2 : 1,
          }))}
          formState={defectForm}
          onFormStateChange={handleFormGridStateChange}
        />
      )}
    </NxpPanelEditable>
  );
};

export default AppDefectAnalysisContainer;
