import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import { DefectDetailsContext } from "../../DefectDetailsContainer";
import CancelDefectModal from "./CancelDefectModal";

interface CancelDefectButtonProps {}

const CancelDefectButton: React.FC<CancelDefectButtonProps> = () => {
  const { defect } = useContext(DefectDetailsContext);

  const { t: translation } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const handleModalVisible = () => {
    setModalVisible(false);
  };

  return (
    <>
      <NxpButton
        onClick={() => setModalVisible(true)}
        danger
        disabled={["closure", "cancelled"].includes(defect.status)}
      >
        {translation("app.common.inspection.cancelDefect.cancelDefect")}
      </NxpButton>
      <CancelDefectModal
        visible={modalVisible}
        onDismiss={handleModalVisible}
      />
    </>
  );
};

export default CancelDefectButton;
