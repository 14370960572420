import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import moment from "moment";
import * as yup from "yup";

import { useValidate } from "../../../../hooks/useValidate";
import { DefectDetailsContext } from "../../DefectDetailsContainer";

// @ts-ignore - override correct yup type
export const requiredDateSchema: yup.SchemaOf<Date> = yup.date().required();

interface CancelDefectModalProps {
  visible: boolean;
  onDismiss: () => void;
}

const emptyForm = {
  date: new Date(),
  comment: "",
};

export interface CancelDefectGridForm {
  date: Date;
  comment: string;
}

const CancelDefectContainer: React.FC<CancelDefectModalProps> = (props) => {
  const { defect, onCancelSubmit } = useContext(DefectDetailsContext);

  const { t: translation } = useTranslation();

  const [submitting, setSubmitting] = useState<boolean>(false);

  const formItems: NxpFormGridItemProps<CancelDefectGridForm>[] = [
    {
      controlType: "datePicker",
      label: translation("app.common.date"),
      itemFieldName: "date",
      controlProps: {
        disabledDate: (current) => {
          return current < moment(Number(defect.createdAt));
        },
      },
      span: 8,
    },
    {
      controlType: "input",
      label: translation("app.common.comment"),
      itemFieldName: "comment",
      span: 24,
      startOnNewRow: true,
    },
  ];

  const formSchema = yup.object().shape({
    date: yup.date().required(translation("app.common.dateIsRequired")),
    comment: yup
      .string()
      .nullable()
      .required(translation("app.common.commentIsRequired")),
  });

  const [cancelDefectForm, setCancelDefectForm] =
    useState<CancelDefectGridForm>(emptyForm);

  const handleSaveValidated = () => {
    if (!submitting) {
      setSubmitting(true);
      onCancelSubmit(cancelDefectForm)
        .then((success) => {
          if (success) {
            props.onDismiss();
            setCancelDefectForm(emptyForm);
          }
          setSubmitting(true);
        })
        .catch((e) => setSubmitting(false));
    }
  };

  const [validationError, , , saveWithValidate] =
    useValidate<CancelDefectGridForm>(
      cancelDefectForm,
      formSchema,
      handleSaveValidated
    );

  const handleSaveClick = () => {
    saveWithValidate(undefined);
  };

  const handleFormGridStateChange = (
    fieldName: keyof CancelDefectGridForm,
    value: unknown
  ) => {
    setCancelDefectForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  return (
    <NxpModal
      title={translation("app.common.inspection.cancelDefect.cancelDefect")}
      visible={props.visible}
      onCancel={() => {
        if (!submitting) props.onDismiss();
      }}
      footer={
        <NxpButton onClick={handleSaveClick}>
          {translation("app.common.save")}
        </NxpButton>
      }
      width="small"
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={cancelDefectForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default CancelDefectContainer;
