import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import { DefectDetailsContext } from "../../DefectDetailsContainer";
import CloseOutModal from "./CloseOutModal";

interface CloseOutButtonProps {}

const CloseOutButton: React.FC<CloseOutButtonProps> = (props) => {
  const { defect } = useContext(DefectDetailsContext);

  const { t: translation } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const handleModalVisible = () => {
    setModalVisible(false);
  };

  return (
    <>
      <NxpButton
        onClick={() => setModalVisible(true)}
        disabled={["closure", "cancelled"].includes(defect.status)}
      >
        {translation("app.common.inspection.closeOut.closeOut")}
      </NxpButton>
      <CloseOutModal visible={modalVisible} onDismiss={handleModalVisible} />
    </>
  );
};

export default CloseOutButton;
