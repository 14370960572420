import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  formatDate,
  NxpFormGridItemProps,
  NxpPanel,
  NxpPanelEditable,
} from "@nexploretechnology/nxp-ui";
import _ from "lodash";
import moment from "moment";
import * as yup from "yup";

import useAppContext from "../../../hooks/useAppContext";
import { useValidate } from "../../../hooks/useValidate";
import { Location, LocationOtherInfo } from "../../../services/app";
import { updateLocationInfo } from "../../../services/location";
import CustomApiError from "../../../utils/backend/customApiError";
import notify from "../../../utils/notify";
import AppLocationOtherInfoLayout from "./AppLocationOtherInfoLayout";

interface AppLocationOtherInfoContainerProps {
  location: Location;
}

const AppLocationOtherInfoContainer: React.FC<AppLocationOtherInfoContainerProps> =
  ({ location }) => {
    const { t: translation } = useTranslation();

    const [originState, setOriginState] = useState<Partial<Location>>({});
    const [locationInfoForm, setlocationInfoForm] = useState<Partial<Location>>(
      {}
    );
    useEffect(() => {
      function setLocationInfo() {
        if (location) {
          setlocationInfoForm({
            ...location,
            responsiblePerson: _.get(location, "responsiblePerson.id"),
          });
          setOriginState({
            ...location,
            responsiblePerson: _.get(location, "responsiblePerson.id"),
          });
        }
      }
      setLocationInfo();
    }, [location]);

    const appContext = useAppContext();

    const { routeParams, users, serviceConfig, hasRight } = appContext;
    const { entityId, locationGroupId, locationId } = routeParams;
    const formSchema = yup.object().shape({
      plannedAccessDate: yup.date().nullable(),
      actualAccessDate: yup.date().nullable(),
      plannedHandoverDate: yup.date().nullable(),
      actualHandoverDate: yup.date().nullable(),
      constructionLotNo: yup.string().nullable(),
      responsiblePerson: yup.string().nullable(),
      drawingNos: yup.string().nullable(),
    });
    const formItems: NxpFormGridItemProps<Partial<LocationOtherInfo>>[] = [
      {
        controlType: "datePicker",
        label: translation("app.appLocationOtherInfo.plannedAccessDate"),
        itemFieldName: "plannedAccessDate",
        span: 8,
      },
      {
        controlType: "datePicker",
        label: translation("app.appLocationOtherInfo.actualAccessDate"),
        itemFieldName: "actualAccessDate",
        span: 8,
      },
      {
        controlType: "datePicker",
        label: translation("app.appLocationOtherInfo.plannedHandoverDate"),
        itemFieldName: "plannedHandoverDate",
        span: 8,
        startOnNewRow: true,
      },
      {
        controlType: "datePicker",
        label: translation("app.appLocationOtherInfo.actualHandoverDate"),
        itemFieldName: "actualHandoverDate",
        span: 8,
      },
      {
        controlType: "input",
        label: translation("app.common.constructionLotNo"),
        itemFieldName: "constructionLotNo",
        startOnNewRow: true,
        span: 8,
        controlProps: {
          allowClear: true,
        },
      },
      {
        controlType: "select",
        label: translation("app.common.responsiblePerson"),
        itemFieldName: "responsiblePerson",
        controlProps: {
          allowClear: true,
          options: users.map((user) => ({
            label: user?.name,
            value: user?.id?.toString(),
          })),
        },
        span: 8,
      },
      {
        controlType: "input",
        label: translation("app.appLocationOtherInfo.drawingNos"),
        itemFieldName: "drawingNos",
        startOnNewRow: true,
        span: 8,
        controlProps: {
          allowClear: true,
        },
      },
    ];
    const detectDateNullOrEmpty = (field: any) =>
      !_.isNull(field) &&
      !_.isUndefined(field) &&
      _.isDate(moment(field).toDate())
        ? formatDate(field)
        : null;

    const handleSaveValidated = async () => {
      try {
        if (_.isEqual(locationInfoForm, originState)) return;
        const infoRes = await updateLocationInfo(
          entityId,
          locationGroupId,
          locationId,
          {
            // ...locationInfoForm,
            constructionLotNo: locationInfoForm?.constructionLotNo,
            drawingNos: locationInfoForm?.drawingNos,
            plannedAccessDate: detectDateNullOrEmpty(
              locationInfoForm?.plannedAccessDate
            ),
            actualAccessDate: detectDateNullOrEmpty(
              locationInfoForm?.actualAccessDate
            ),
            plannedHandoverDate: detectDateNullOrEmpty(
              locationInfoForm?.plannedHandoverDate
            ),
            actualHandoverDate: detectDateNullOrEmpty(
              locationInfoForm?.actualHandoverDate
            ),
            responsiblePerson: locationInfoForm?.responsiblePerson,
          },
          serviceConfig
        );
        if (infoRes) {
          notify.success(
            translation("app.appLocationOtherInfo.handleSaveValidated.success")
          );
          setOriginState(infoRes);
        }
      } catch (e) {
        if (e instanceof CustomApiError) {
          const apiError: CustomApiError = e;
          if (apiError.status === 403) {
            notify.error(translation("app.common.errorOccurred"));
          } else {
            notify.error(apiError);
          }
        } else {
          notify.error(e);
        }
      }
    };

    const [validationError, , , saveWithValidate] = useValidate<
      Partial<LocationOtherInfo>
    >(locationInfoForm, formSchema, handleSaveValidated);

    const handleSave = () =>
      Object.values(saveWithValidate(undefined)).filter(
        (val) => val !== undefined
      ).length === 0;

    const handleFormGridStateChange = (
      fieldName: keyof typeof locationInfoForm,
      value: unknown
    ) => {
      setlocationInfoForm((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    };
    const handlePanelSave = (setEditing: (editing: boolean) => void) => {
      if (handleSave()) {
        setEditing(false);
      }
    };

    if (hasRight("location-info-edit")) {
      return (
        <NxpPanelEditable
          titleContent={translation(
            "app.appLocationOtherInfo.datesAndOtherInfo"
          )}
          defaultCollapsed={false}
          onPanelSave={handlePanelSave}
          onPanelCancel={() => setlocationInfoForm(originState)}
        >
          {(editing, updateEditing) => (
            <AppLocationOtherInfoLayout
              editing={editing}
              validationError={validationError}
              formItems={formItems.map((item) => ({
                ...item,
                span: item.span > 8 ? 2 : 1,
              }))}
              formState={locationInfoForm}
              onFormStateChange={handleFormGridStateChange}
            />
          )}
        </NxpPanelEditable>
      );
    } else {
      return (
        <NxpPanel
          titleContent={translation(
            "app.appLocationOtherInfo.datesAndOtherInfo"
          )}
          defaultCollapsed={false}
        >
          <AppLocationOtherInfoLayout
            editing={false}
            validationError={validationError}
            formItems={formItems.map((item) => ({
              ...item,
              span: item.span > 8 ? 2 : 1,
            }))}
            formState={locationInfoForm}
            onFormStateChange={handleFormGridStateChange}
          />
        </NxpPanel>
      );
    }
  };

export default AppLocationOtherInfoContainer;
