import FileSaver from "file-saver";
import moment from "moment";

import { AddInspectionGridForm } from "../pages/DefectDetails/Inspection/AddInspection/AddInspectionModal";
import { AddResponseGridForm } from "../pages/DefectDetails/Inspection/AddResponse/AddResponseModal";
import { CancelDefectGridForm } from "../pages/DefectDetails/Inspection/CancelDefect/CancelDefectModal";
import { CloseOutGridForm } from "../pages/DefectDetails/Inspection/CloseOut/CloseOutModal";
import { Inspection } from "../pages/DefectDetails/Inspection/InspectionList/InspectionList";
import { apiRequest, ServiceConfig } from "../utils/backend";
import buildQueryString from "../utils/backend/buildQueryString";
import { getObjectWithOffsetDate } from "../utils/date/objectDateFieldOffset";
import {
  DefectDescription,
  DefectGroup,
  DefectSubject,
  Enum,
  File,
  Location,
  Subcontractor,
  User,
} from "./app";

export interface Defect {
  id: number;
  no: number;
  status: "open" | "mark-fixed" | "mark-closed" | "closed" | "cancelled";
  inspectionType?: Enum;
  defectType?: Enum;
  location: Location;
  raisedBy: User;
  createdAt: string;
  defectGroup?: DefectGroup;
  defectSubject?: DefectSubject;
  defectDescription?: DefectDescription;
  responsibleParty?: Subcontractor;
  responsiblePerson?: User;
  estimatedCost: number;
  nextInspectionDate: string;
  dueDate: string;
  isTopPriority: boolean;
  remarks: string;
  remarksLocal: string;
  jointInspectionId: string;
  ncrNo: string;
  planningId: string;
  incomingRef01: string;
  incomingRef02: string;
  watcher: User;
  pinX: number;
  pinY: number;
  messages: DefectMessage[];
  analysis?: DefectAnalysis;
  raiseDate: string;
  closeDate: string;
  round?: number;
}

export interface DefectMessage {
  id: number;
  type: "inspection" | "response" | "closure" | "cancellation";
  createdBy: User;
  defectStatus: Defect["status"];
  date: string;
  nextInspectionDate: string;
  comment: string;
  attachments: File[];
}

export interface DefectAnalysis {
  id: number;
  cause: Enum;
  actualCost: number;
  backcharge: boolean;
  scheduleImpact: number;
}

export interface DefectStatisticResult {
  count: string;
  averageDuration: string;
  totalBackchargeCost: string;
  totalCost: string;
  totalScheduleImpact: string;
}

export type DefectAnalysisForm = Pick<
  DefectAnalysis,
  "scheduleImpact" | "actualCost"
> & {
  cause: string;
  backcharge: string;
  daysSpent: number;
  totalRound: number;
};

export type DefectCreateForm = Pick<
  Defect,
  | "estimatedCost"
  | "nextInspectionDate"
  | "dueDate"
  | "status"
  | "remarksLocal"
  | "ncrNo"
  | "planningId"
  | "remarks"
  | "incomingRef01"
  | "incomingRef02"
  | "jointInspectionId"
> & {
  watcher: string;
  isTopPriority: string;
  location: string;
  defectType: string;
  inspectionType: string;
  defectGroup: string;
  responsibleParty: string;
  responsiblePerson: string;
  defectSubject: string;
  defectDescription: string;
};
export interface StatisticQuery
  extends Partial<{
    dueDateGte: Date;
    dueDateLte: Date;
    statusNot: string;
    status: string;
    responsiblePartyId: number;
    locationGroupId: number;
    locationId: number;
  }> {}

export const getDefects = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  queryString: string | undefined,
  sortBy: string | undefined,
  order: "asc" | "desc",
  offset: number,
  limit: number,
  serviceConfig: ServiceConfig
): Promise<Defect[]> => {
  if (!sortBy) sortBy = "no";
  if (queryString) {
    queryString += `&sortBy=${sortBy}&order=${order}&offset=${offset}&limit=${limit}`;
  } else {
    queryString = `?sortBy=${sortBy}&order=${order}&offset=${offset}&limit=${limit}`;
  }

  return await apiRequest<Defect[]>(
    `/entities/${entityId}/location-groups/${locationGroupId}/defects${
      queryString || ""
    }`,
    serviceConfig
  );
};

export const getDefectsFullSet = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  queryString: string | undefined,
  sortBy: string | undefined,
  order: "asc" | "desc",
  serviceConfig: ServiceConfig
): Promise<Defect[]> => {
  if (!sortBy) sortBy = "no";
  if (queryString) {
    queryString += `&sortBy=${sortBy}&order=${order}&full=true`;
  } else {
    queryString = `?sortBy=${sortBy}&order=${order}&full=true`;
  }

  return await apiRequest<Defect[]>(
    `/entities/${entityId}/location-groups/${locationGroupId}/defects${
      queryString || ""
    }`,
    serviceConfig
  );
};

export const getDefectsExcel = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  queryString: string | undefined,
  sortBy: string | undefined,
  order: "asc" | "desc",
  serviceConfig: ServiceConfig
): Promise<void> => {
  if (!sortBy) sortBy = "no";
  if (queryString) {
    queryString += `&sortBy=${sortBy}&order=${order}&excel=true`;
  } else {
    queryString = `?sortBy=${sortBy}&order=${order}&excel=true`;
  }

  const url = `${
    serviceConfig.apiBaseUrl
  }/entities/${entityId}/location-groups/${locationGroupId}/defects${
    queryString || ""
  }`;

  let response: Response;
  try {
    response = await fetch(url, {
      credentials: "same-origin",
      headers: {
        accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        Pragma: "no-cache",
        "Cache-Control": "no-cache",
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        Authorization: `Bearer ${serviceConfig.token}`,
      },
    });
  } catch (e: any) {
    throw new Error(`Error occured :${e.message}`);
  }
  if (response?.status === 200) {
    const blob = await response.blob();

    FileSaver.saveAs(
      blob,
      `defects_${moment().format("YYYY-MM-DD_HH:mm:ss")}.xlsx`
    );
  } else {
    throw new Error(`Error ${response?.status} occured`);
  }
};

export const createDefect = async (
  entityId: string,
  locationGroupId: number,
  defect: Partial<
    Omit<
      DefectCreateForm,
      "location" | "defectDescription" | "responsiblePerson" | "isTopPriority"
    >
  > & {
    location: number;
    defectDescription: number;
    responsiblePerson: string;
    isTopPriority: boolean;
  },
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(
    `/entities/${entityId}/location-groups/${locationGroupId}/defects`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(defect),
    }
  );
};
export const findOneDefect = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  defectId: number | undefined,
  serviceConfig: ServiceConfig
): Promise<Defect> => {
  return await apiRequest<Defect>(
    `/entities/${entityId}/location-groups/${locationGroupId}/defects/${defectId}`,
    serviceConfig
  );
};
export const updateOneDefect = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  defectId: number | undefined,
  defect: Partial<
    Omit<
      DefectCreateForm,
      | "defectType"
      | "defectGroup"
      | "defectSubject"
      | "watcher"
      | "inspectionType"
      | "location"
      | "defectDescription"
      | "responsiblePerson"
      | "responsibleParty"
      | "isTopPriority"
    >
  > & {
    defectType: number;
    defectGroup: number;
    defectSubject: number;
    watcher: string;
    inspectionType: number;
    location: number;
    defectDescription: number;
    responsiblePerson: string;
    responsibleParty: number;
    isTopPriority: boolean;
  },
  serviceConfig: ServiceConfig
): Promise<Defect> => {
  return await apiRequest<any>(
    `/entities/${entityId}/location-groups/${locationGroupId}/defects/${defectId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(getObjectWithOffsetDate(defect)),
    }
  );
};

export const updateOneDefectAnalysis = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  defectId: number | undefined,
  defect: Partial<
    Omit<
      DefectAnalysisForm,
      "daysSpent" | "totalRound" | "cause" | "scheduleImpact" | "backcharge"
    >
  > & {
    cause: number;
    scheduleImpact: number;
    backcharge: boolean;
  },
  serviceConfig: ServiceConfig
): Promise<Defect> =>
  await apiRequest<any>(
    `/entities/${entityId}/location-groups/${locationGroupId}/defects/${defectId}/analysis`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(defect),
    }
  );

export const addInspection = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  defectId: number | undefined,
  form: AddInspectionGridForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Defect>(
    `/entities/${entityId}/location-groups/${locationGroupId}/defects/${defectId}/inspections`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        date: moment(form.date).format("YYYY-MM-DD"),
        comment: form.comment,
        defectStatus: form.status,
        nextInspectionDate: moment(form.nextinspectiondate).format(
          "YYYY-MM-DD"
        ),
        attachments: form.attachments,
      }),
    }
  );
};

export const updateInspection = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  defectId: number | undefined,
  messageId: number | undefined,
  inspection: Inspection,
  serviceConfig: ServiceConfig
) =>
  await apiRequest<DefectMessage>(
    `/entities/${entityId}/location-groups/${locationGroupId}/defects/${defectId}/inspections/${messageId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        date: moment(inspection.date).format("YYYY-MM-DD"),
        comment: inspection.comment,
        defectStatus: inspection.defectStatus,
        nextInspectionDate: moment(inspection.nextinspectiondate).format(
          "YYYY-MM-DD"
        ),
      }),
    }
  );

export const addResponse = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  defectId: number | undefined,
  form: AddResponseGridForm,
  serviceConfig: ServiceConfig
) =>
  await apiRequest<Defect>(
    `/entities/${entityId}/location-groups/${locationGroupId}/defects/${defectId}/responses`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        date: moment(form.date).format("YYYY-MM-DD"),
        comment: form.comment,
        attachments: form.attachments,
      }),
    }
  );

export const updateResponse = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  defectId: number | undefined,
  messageId: number | undefined,
  inspection: Inspection,
  serviceConfig: ServiceConfig
) =>
  await apiRequest<DefectMessage>(
    `/entities/${entityId}/location-groups/${locationGroupId}/defects/${defectId}/responses/${messageId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        date: moment(inspection.date).format("YYYY-MM-DD"),
        comment: inspection.comment,
        attachments: [],
      }),
    }
  );

export const attachFileToMessage = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  defectId: number | undefined,
  messageId: number | undefined,
  messageType: DefectMessage["type"],
  fileId: number | undefined,
  serviceConfig: ServiceConfig
) =>
  await apiRequest<DefectMessage>(
    `/entities/${entityId}/location-groups/${locationGroupId}/defects/${defectId}/${messageType}s/${messageId}/attachments/${fileId}`,
    serviceConfig,
    {
      method: "PUT",
    }
  );

export const detechFileFromMessage = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  defectId: number | undefined,
  messageId: number | undefined,
  messageType: DefectMessage["type"],
  fileId: number | undefined,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<DefectMessage>(
    `/entities/${entityId}/location-groups/${locationGroupId}/defects/${defectId}/${messageType}s/${messageId}/attachments/${fileId}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};

export const closeDefect = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  defectId: number | undefined,
  form: CloseOutGridForm,
  serviceConfig: ServiceConfig
) =>
  await apiRequest<Defect>(
    `/entities/${entityId}/location-groups/${locationGroupId}/defects/${defectId}/closures`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        date: moment(form.date).format("YYYY-MM-DD"),
        actualCost: form.actualcost,
        scheduleImpact: form.scheduleimpact,
        backcharge: form.backcharge === "true",
        cause: parseInt(form.cause),
        comment: form.comment,
        attachments: form.attachments,
      }),
    }
  );

export const cancelDefect = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  defectId: number | undefined,
  form: CancelDefectGridForm,
  serviceConfig: ServiceConfig
) =>
  await apiRequest<Defect>(
    `/entities/${entityId}/location-groups/${locationGroupId}/defects/${defectId}/cancellations`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        date: moment(form.date).format("YYYY-MM-DD"),
        comment: form.comment,
      }),
    }
  );

export const getDefectStatistic = async (
  entityId: string,
  statisticQuery: StatisticQuery | string,
  serviceConfig: ServiceConfig
) =>
  await apiRequest<{
    averageDuration: string;
    count: string;
    totalBackchargeCost: string;
    totalCost: string;
    totalScheduleImpact: string;
  }>(
    `/entities/${entityId}/defect-statistic?${
      typeof statisticQuery === "string"
        ? statisticQuery
        : buildQueryString(statisticQuery)
    }`,
    serviceConfig
  );

export const updateFloorPlanPin = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  defectId: number,
  floorPlan: {
    pinX: number;
    pinY: number;
  },
  serviceConfig: ServiceConfig
) =>
  await apiRequest<Location>(
    `/entities/${entityId}/location-groups/${locationGroupId}/defects/${defectId}/pin`,
    serviceConfig,
    {
      method: "PUT",
      body: JSON.stringify(floorPlan),
    }
  );
