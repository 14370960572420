import React from "react";

import { Defect } from "../../../services/defect";

import DefectAnalysisLayout from "./DefectAnalysisLayout";

interface DefectAnalysisContainerProps {
  defects: Defect[];
}

const DefectAnalysisContainer: React.FC<DefectAnalysisContainerProps> = ({
  defects,
}) => {
  return <DefectAnalysisLayout defects={[...defects]} />;
};

export default DefectAnalysisContainer;
