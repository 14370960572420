import React, { useEffect, useState } from "react";

import useAppContext from "../../../../hooks/useAppContext";

import { Defect } from "../../../../services/defect";

import DefectAnalysisLayout from "./DefectAnalysisLayout";

import { useTranslation } from "react-i18next";

let initialDefectAnalysis: {
  key: string;
  title: string;
  value: string;
  percentage?: string;
}[] = [];

interface DefectAnalysisContainerProps {
  defects: Defect[];
}

const DefectAnalysisContainer: React.FC<DefectAnalysisContainerProps> = ({
  defects,
}) => {
  const appContext = useAppContext();

  const { t: translation } = useTranslation();

  const [defectAnalysis, setDefectAnalysis] = useState(initialDefectAnalysis);

  useEffect(() => {
    initialDefectAnalysis = [];

    const defectGroups = new Set(
      defects.map((defect) => defect.defectGroup?.id)
    );

    defectGroups.forEach((value) => {
      initialDefectAnalysis.push({
        key: `Total ${
          appContext.defectGroups.find((item) => item.id === value)?.name
        } Defects`,
        title: translation(
          "dashboard.defectAnalysis.defectAnalysis.totalDefects",
          {
            value: appContext.defectGroups.find((item) => item.id === value)
              ?.name,
          }
        ),
        value: defects
          .filter((defect) => defect.defectGroup?.id === value)
          .length.toString(),
      });

      initialDefectAnalysis.push({
        key: `Total ${
          appContext.defectGroups.find((item) => item.id === value)?.name
        } Outstanding Defects`,
        title: translation(
          "dashboard.defectAnalysis.defectAnalysis.totalOutstandingDefects",
          {
            value: appContext.defectGroups.find((item) => item.id === value)
              ?.name,
          }
        ),
        value: defects
          .filter(
            (defect) =>
              defect.defectGroup?.id === value &&
              (defect?.status === "open" ||
                defect?.status === "mark-fixed" ||
                defect?.status === "mark-closed") &&
              new Date(defect.dueDate) >= new Date()
          )
          .length.toString(),
        percentage: Number(
          Math.round(
            (defects.filter(
              (defect) =>
                defect.defectGroup?.id === value &&
                (defect?.status === "open" ||
                  defect?.status === "mark-fixed" ||
                  defect?.status === "mark-closed") &&
                new Date(defect.dueDate) >= new Date()
            ).length /
              defects.filter((defect) => defect.defectGroup?.id === value)
                .length) *
              100 *
              100
          ) / 100
        ).toString(),
      });

      initialDefectAnalysis.push({
        key: `Total ${
          appContext.defectGroups.find((item) => item?.id === value)?.name
        } Fixed Defects`,
        title: translation(
          "dashboard.defectAnalysis.defectAnalysis.totalFixedDefects",
          {
            value: appContext.defectGroups.find((item) => item?.id === value)
              ?.name,
          }
        ),
        value: defects
          .filter(
            (defect) =>
              defect.defectGroup?.id === value && defect?.status === "closed"
          )
          .length.toString(),
        percentage: Number(
          Math.round(
            (defects.filter(
              (defect) =>
                defect.defectGroup?.id === value && defect?.status === "closed"
            ).length /
              defects.filter((defect) => defect.defectGroup?.id === value)
                .length) *
              100 *
              100
          ) / 100
        )
          .toFixed(2)
          .toString(),
      });
    });

    setDefectAnalysis([...initialDefectAnalysis]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.defectGroups, defects]);

  return <DefectAnalysisLayout defectAnalysis={[...defectAnalysis]} />;
};

export default DefectAnalysisContainer;
