import React, { useState } from "react";
import AddDefectContainer from ".";
import { NxpButton } from "@nexploretechnology/nxp-ui";

import { useTranslation } from "react-i18next";
interface Props {
  onDefectCreated?: () => void;
}

const AddDefectButton: React.FC<Props> = ({ onDefectCreated }) => {
  const { t: translation } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <NxpButton onClick={() => setModalVisible(true)}>
        {translation("app.common.addDefects")}
      </NxpButton>
      <AddDefectContainer
        showModal={modalVisible}
        handleDialogClose={() => setModalVisible(false)}
        onDefectCreated={onDefectCreated}
      />
    </>
  );
};

export default AddDefectButton;
