import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { NxpButton } from "@nexploretechnology/nxp-ui";
import { NxpFormGrid, NxpFormGridItemProps } from "@nexploretechnology/nxp-ui";
import { NxpModal } from "@nexploretechnology/nxp-ui";
import useAppContext from "../../hooks/useAppContext";
import { useValidate } from "../../hooks/useValidate";

import { useTranslation } from "react-i18next";

interface Props {
  show: boolean;
  onCopyRole: (form: CopyRoleForm) => Promise<boolean>;
  onClose: () => void;
}

export interface CopyRoleForm {
  sourceLocationGroup?: number;
}

const emptyForm = {
  sourceLocationGroup: undefined,
};

const CopyRoleModal: React.FC<Props> = ({ show, onCopyRole, onClose }) => {
  const { locationGroups, routeParams } = useAppContext();
  const { locationGroupId } = routeParams;

  const { t: translation } = useTranslation();

  const formSchema = yup.object().shape({
    sourceLocationGroup: yup
      .number()
      .required(translation("roleSetting.roleNameIsRequired")),
  });

  const [form, setForm] = useState<CopyRoleForm>(emptyForm);
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setForm(emptyForm);
      setSaving(false);
    };
  }, [show]);

  const formItems: NxpFormGridItemProps<CopyRoleForm>[] = [
    {
      controlType: "select",
      label: translation("roleSetting.fromLocationGroup"),
      itemFieldName: "sourceLocationGroup",
      span: 24,
      controlProps: {
        placeholder: translation("app.common.pleaseSelect"),
        options: [
          ...locationGroups.map((locationGroup) => ({
            value: locationGroup.id,
            label: locationGroup.name,
            disabled: locationGroupId === locationGroup.id,
          })),
        ],
      },
      required: true,
    },
  ];

  const handleSaveValidated = async () => {
    setSaving(true);
    onCopyRole(form).then((success) => {
      if (success) {
        onClose();
      } else {
        setSaving(false);
      }
    });
  };

  const [validationError, , , saveWithValidate] = useValidate<CopyRoleForm>(
    form,
    formSchema,
    handleSaveValidated
  );

  const handleSaveButton = () => {
    saveWithValidate(undefined);
  };

  const handleCloseButton = () => {
    if (saving) return;
    onClose();
  };

  const handleFormStateChange = (
    fieldName: keyof typeof form,
    value: unknown
  ) => {
    if (fieldName === "sourceLocationGroup") {
      setForm((prevState) => ({
        ...prevState,
        sourceLocationGroup: value as number,
      }));
    } else {
      setForm((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  return (
    <NxpModal
      width="large"
      title={translation("roleSetting.copyAccessFromOtherLocationGroup")}
      visible={show}
      showMandatoryLabel
      onCancel={handleCloseButton}
      footer={
        <>
          <NxpButton onClick={handleCloseButton}>
            {translation("app.common.cancel")}
          </NxpButton>
          <NxpButton onClick={handleSaveButton} loading={saving}>
            {translation("app.common.save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={form}
        onFormStateChange={handleFormStateChange}
      />
    </NxpModal>
  );
};

export default CopyRoleModal;
