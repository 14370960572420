import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { NxpTabs, TabPane } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../../hooks/useAppContext";

export type tabKey =
  | "dashboard"
  | "defects"
  | "heat-map"
  | "floor-plan"
  | "qr-code"
  | "dates-and-other-info"
  | "other-files"
  | "planning";
interface Props {
  activeKey: tabKey;
  onChange: (activeKey: string) => void;
}

const useStyles = createUseStyles((theme) => ({
  locationTabBar: {
    "& .ant-tabs-nav": {
      "& .ant-tabs-nav-wrap": {
        "& .ant-tabs-ink-bar-animated": {
          transition: "width 0.3s",
        },
      },
    },
  },
}));

const TabBar: React.FC<Props> = ({ activeKey, onChange }) => {
  const { hasRight } = useAppContext();
  const classes = useStyles();

  const { t: translation } = useTranslation();

  return (
    <NxpTabs
      className={classes.locationTabBar}
      key="location-details-tabbar"
      defaultActiveKey={activeKey}
      onChange={onChange}
    >
      {hasRight("location-view") && (
        <TabPane tab={translation("app.common.dashboard")} key="dashboard" />
      )}
      {hasRight("defect-view") && (
        <TabPane tab={translation("app.common.defects")} key="defects" />
      )}
      <TabPane
        tab={translation("locationDetails.tabBar.heatMap")}
        key="heat-map"
      ></TabPane>
      {hasRight("location-edit") && (
        <TabPane
          tab={translation("app.common.floorPlanLabel")}
          key="floor-plan"
        />
      )}
      {hasRight("location-view") && (
        <TabPane
          tab={translation("locationDetails.tabBar.qrCode")}
          key="qr-code"
        />
      )}
      {hasRight("location-info-view") && (
        <TabPane
          tab={translation("locationDetails.tabBar.datesAndOtherInfo")}
          key="dates-and-other-info"
        />
      )}
      {hasRight("location-file-view") && (
        <TabPane
          tab={translation("locationDetails.tabBar.otherFiles")}
          key="other-files"
        />
      )}
      {hasRight("plan-view") && (
        <TabPane
          tab={translation("locationDetails.tabBar.planning")}
          key="planning"
        />
      )}
    </NxpTabs>
  );
};

export default TabBar;
