import React from "react";
import { createUseStyles } from "react-jss";
import { Column } from "@ant-design/charts";
import { Card } from "antd";

import { useTranslation } from "react-i18next";

interface DefectSummaryByResponsiblePartyLayoutProps {
  data: {
    party: string;
    Total: number;
  }[];
}

const useStyles = createUseStyles(() => ({
  graphCard: {
    textAlign: "center",
    width: "1010px",
    margin: "10px 10px 0px 0px",
  },
}));

const DefectSummaryByResponsiblePartyLayout: React.FC<DefectSummaryByResponsiblePartyLayoutProps> =
  ({ data }) => {
    const classes = useStyles();
    const { t: translation } = useTranslation();
    return (
      <Card className={classes.graphCard}>
        <h1>
          {translation(
            "dashboard.defectAnalysis.defectSummaryByResponsibleParty.defectSummaryByResponsibleParty"
          )}
        </h1>
        <Column
          data={data}
          xField="party"
          yField="Total"
          yAxis={{ tickCount: 6 }}
          color="#005794"
        />
      </Card>
    );
  };

export default DefectSummaryByResponsiblePartyLayout;
