import React from "react";
import { NxpHeader } from "@nexploretechnology/nxp-ui";
import useAppContext from "../../hooks/useAppContext";
import { NxpButton } from "@nexploretechnology/nxp-ui";
import { Role } from "../../services/role";
import { Col, Row } from "antd";
import { UserRightList } from "../../services/app";
import RightGroupPanel from "./RightGroupPanel";
import AppFakeTableHeader from "../../components/AppFakeTableHeader";

import { useTranslation } from "react-i18next";

interface Props {
  roles: Role[];
  selectedIndex: number;
  checklist: Record<keyof UserRightList, boolean>;
  onRoleSelect: (index: number) => void;
  onItemCheck: (values: string, checked: boolean) => void;
  onSaveRights: () => void;
}

const RightSettingLayout: React.FC<Props> = ({
  roles,
  selectedIndex,
  checklist,
  onRoleSelect,
  onItemCheck,
  onSaveRights,
}) => {
  const { routeParams, hasRight } = useAppContext();
  const { locationGroupId } = routeParams;

  const { t: translation } = useTranslation();

  const rightGroups: {
    title: string;
    hide?: boolean;
    items: { title: string; value: keyof UserRightList }[];
  }[] = [
    {
      title: translation("app.common.locationGroup"),
      items: [
        {
          title: translation("rightSetting.viewLocationGroup"),
          value: "location-group-view",
        },
      ],
    },
    {
      title: translation("rightSetting.manageSubcontractor"),
      hide: !!locationGroupId,
      items: [
        {
          title: translation("app.common.addSubcontractor"),
          value: "subcontractor-add",
        },
        {
          title: translation("rightSetting.viewSubcontractor"),
          value: "subcontractor-view",
        },
        {
          title: translation("rightSetting.editSubcontractor"),
          value: "subcontractor-edit",
        },
        {
          title: translation("rightSetting.deleteSubcontractor"),
          value: "subcontractor-delete",
        },
      ],
    },
    {
      title: translation("rightSetting.manageSubcontractorUser"),
      hide: !!locationGroupId,
      items: [
        {
          title: translation("rightSetting.viewSubcontractorUser"),
          value: "subcontractor-user-view",
        },
        {
          title: translation("rightSetting.editSubcontractorUser"),
          value: "subcontractor-user-edit",
        },
      ],
    },
    {
      title: translation("rightSetting.manageDefect"),
      items: [
        { title: translation("rightSetting.addDefect"), value: "defect-add" },
        { title: translation("rightSetting.viewDefect"), value: "defect-view" },
        {
          title: translation("rightSetting.editDefect"),
          value: "defect-edit",
        },
        {
          title: translation("rightSetting.closeCancelDefect"),
          value: "defect-closeout",
        },
        {
          title: translation("rightSetting.addDefectResponse"),
          value: "defect-respond",
        },
      ],
    },

    {
      title: translation("rightSetting.manageLocation"),
      items: [
        {
          title: translation("rightSetting.locationAdmin"),
          value: "location-admin",
        },
        {
          title: translation("rightSetting.viewLocation"),
          value: "location-view",
        },
        {
          title: translation("rightSetting.manageLocationFloorPlan"),
          value: "location-edit",
        },
        {
          title: translation("rightSetting.otherFilesAdd"),
          value: "location-file-add",
        },
        {
          title: translation("rightSetting.otherFilesView"),
          value: "location-file-view",
        },
        {
          title: translation("rightSetting.otherFilesDelete"),
          value: "location-file-delete",
        },
        {
          title: translation("rightSetting.otherInfoEdit"),
          value: "location-info-edit",
        },
        {
          title: translation("rightSetting.otherInfoView"),
          value: "location-info-view",
        },
      ],
    },
    {
      title: translation("rightSetting.manageInspection"),
      items: [
        {
          title: translation("rightSetting.viewInspection"),
          value: "plan-view",
        },
      ],
    },
    {
      title: translation("app.common.subcontractor"),
      items: [
        {
          title: translation("rightSetting.viewSubcontractor"),
          value: "subcontractor-dashboard-view",
        },
      ],
    },
    {
      title: translation("app.common.dashboard"),
      items: [
        {
          title: translation("rightSetting.viewDashboard"),
          value: "dashboard-view",
        },
      ],
    },
    {
      title: translation("app.common.reports"),
      items: [
        {
          title: translation("rightSetting.viewReports"),
          value: "report-view",
        },
      ],
    },
    {
      title: translation("rightSetting.securityManageRoles"),
      items: [
        { title: translation("rightSetting.viewRole"), value: "role-view" },
        { title: translation("rightSetting.createRole"), value: "role-add" },
        {
          title: translation("app.common.editRole"),
          value: "role-edit",
        },
        { title: translation("rightSetting.assignRole"), value: "role-assign" },
        {
          title: translation("app.common.deleteRole"),
          value: "role-delete",
        },
      ],
    },
    {
      title: translation("rightSetting.securityAccessRights"),
      items: [
        { title: translation("rightSetting.viewAccess"), value: "right-view" },
        {
          title: translation("rightSetting.editAccess"),
          value: "right-edit",
        },
      ],
    },
    {
      title: translation("app.common.setup"),
      hide: !!locationGroupId,
      items: [
        { title: translation("rightSetting.addItem"), value: "setup-add" },
        { title: translation("rightSetting.viewItem"), value: "setup-view" },
        {
          title: translation("rightSetting.editItem"),
          value: "setup-edit",
        },
        {
          title: translation("rightSetting.deleteItem"),
          value: "setup-delete",
        },
      ],
    },
  ];

  return (
    <>
      <NxpHeader
        titleContent={`${translation("rightSetting.accessRights")} (${
          locationGroupId
            ? translation("app.common.locationGroup")
            : translation("app.common.module")
        })`}
        actionContent={
          selectedIndex !== -1 && (
            <Row gutter={10}>
              {hasRight("right-edit") && (
                <Col>
                  <NxpButton color="primary" onClick={onSaveRights}>
                    {translation("app.common.save")}
                  </NxpButton>
                </Col>
              )}
            </Row>
          )
        }
      />
      <Row>
        <Col span={6}>
          {roles.map((role, index) => (
            <div>
              <NxpButton
                type="link"
                style={
                  selectedIndex === index
                    ? {}
                    : { color: "rgba(62, 63, 66, 1)", fontWeight: "normal" }
                }
                onClick={() => onRoleSelect(index)}
              >
                {role.name}
              </NxpButton>
            </div>
          ))}
        </Col>
        <Col span={18}>
          {selectedIndex !== -1 && (
            <>
              <AppFakeTableHeader
                items={[
                  { name: " ", span: 1 },
                  { name: translation("rightSetting.rights"), span: 22 },
                  { name: translation("rightSetting.allow"), span: 1 },
                ]}
                padding={8}
              />
              {rightGroups
                .filter((rightGroup) => !rightGroup.hide)
                .map((rightGroup) => (
                  <RightGroupPanel
                    title={rightGroup.title}
                    items={
                      rightGroup.items as {
                        title: string;
                        value: keyof UserRightList;
                      }[]
                    }
                    checklist={checklist}
                    onItemCheck={onItemCheck}
                  />
                ))}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default RightSettingLayout;
