import React, { useState } from "react";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import { Defect } from "../../../../services/defect";
import InspectionDetailsModal from "./InspectionDetailsModal";

interface InspectionDetailsButtonProps {
  buttonText: string;
  className: string;
  detail: Defect;
}

const InspectionDetailsButton: React.FC<InspectionDetailsButtonProps> = ({
  buttonText,
  className,
  detail,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleModalVisible = () => {
    setModalVisible(false);
  };

  return (
    <>
      <NxpButton onClick={() => setModalVisible(true)} className={className}>
        {buttonText}
      </NxpButton>
      <InspectionDetailsModal
        modalVisible={modalVisible}
        handleModalVisible={handleModalVisible}
        detail={detail}
      />
    </>
  );
};

export default InspectionDetailsButton;
