import React from "react";

import { NxpHeader } from "@nexploretechnology/nxp-ui";

import { Defect } from "../../services/defect";
import Cost from "./Cost";
import DefectAnalysis from "./DefectAnalysis";
import DefectSummary from "./DefectSummary";
import Performance from "./Performance";

interface DashboardLayoutProps {
  locationGroup: string | undefined;
  defects: Defect[];
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  locationGroup,
  defects,
}) => {
  return (
    <>
      {defects && (
        <>
          {locationGroup !== "" && <NxpHeader titleContent={locationGroup} />}
          <DefectSummary defects={defects} />
          <Cost defects={defects} />
          <Performance defects={defects} />
          <DefectAnalysis defects={defects} />
        </>
      )}
    </>
  );
};

export default DashboardLayout;
