import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../../hooks/useAppContext";
import { useValidate } from "../../../../hooks/useValidate";
import { createDictionary } from "../../../../services/dictionary";
import notify from "../../../../utils/notify";

interface AddCauseModalProps {
  modalVisible: boolean;
  handleAddToList: (item: any) => void;
  handleModalVisible: () => void;
}

interface AddCauseGridForm {
  name: string;
}

const AddCauseContainer: React.FC<AddCauseModalProps> = (props) => {
  const appContext = useAppContext();
  const { serviceConfig, routeParams, errorHandler, refetchEnums } = appContext;
  const { entityId } = routeParams;

  const { t: translation } = useTranslation();

  const formItems: NxpFormGridItemProps<AddCauseGridForm>[] = [
    {
      controlType: "input",
      label: translation("app.common.defectCause"),
      itemFieldName: "name",
      span: 12,
    },
  ];

  const formSchema = yup.object().shape({
    name: yup
      .string()
      .nullable()
      .required(translation("app.common.defectCauseIsRequired")),
  });

  const [addCauseForm, setAddCauseForm] = useState<AddCauseGridForm>({
    name: "",
  });

  const handleSaveValidated = async () => {
    try {
      const response = await createDictionary(
        entityId!,
        {
          id: 0,
          type: "defectCause",
          name: addCauseForm.name,
        },
        serviceConfig
      );
      props.handleAddToList({
        id: response.id,
        type: response.type,
        name: response.name,
      });
      props.handleModalVisible();
      notify.actionCompleted();
      refetchEnums(entityId, serviceConfig);
    } catch (ex: any) {
      errorHandler(
        ex,
        translation("settings.cause.addCause.handleSaveValidated.error")
      );
    }
  };

  const [validationError, , , saveWithValidate] = useValidate<AddCauseGridForm>(
    addCauseForm,
    formSchema,
    handleSaveValidated
  );

  const handleSaveClick = () => {
    saveWithValidate(undefined);
  };

  const handleFormGridStateChange = (
    fieldName: keyof AddCauseGridForm,
    value: unknown
  ) => {
    setAddCauseForm((prevState) => ({
      ...prevState,
      [fieldName]: value as string,
    }));
  };

  useEffect(() => {
    if (props.modalVisible) {
      setAddCauseForm({ name: "" });
    }
  }, [props.modalVisible]);

  return (
    <NxpModal
      title={translation("settings.cause.addCause.addDefectCause")}
      visible={props.modalVisible}
      onCancel={props.handleModalVisible}
      footer={
        <NxpButton onClick={handleSaveClick}>
          {translation("app.common.save")}
        </NxpButton>
      }
      width="small"
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={addCauseForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddCauseContainer;
