import React from "react";
import { Row, Col, Checkbox, Radio } from "antd";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";

import { ReminderType } from "../../../../services/email";
import { FieldName } from "../helper";

const useStyles = createUseStyles((theme) => ({
  newDefect: {},
  title: {
    "& span": {
      fontSize: theme.pxToRem(20),
      fontWeight: theme.fontWeight.bolder,
    },
  },
  details: {
    marginBottom: theme.spacing(5),
    "& .detail": {
      paddingTop: theme.spacing(2.5),
    },
  },
}));

interface EmailNotificationSetupNewDefectProps {
  reminderSettingsForm: any;
  handleFormChange: (
    value: boolean,
    reminderType: ReminderType,
    fieldName: FieldName
  ) => void;
}

const EmailNotificationSetupNewDefect: React.FC<EmailNotificationSetupNewDefectProps> =
  ({ reminderSettingsForm, handleFormChange }) => {
    const classes = useStyles();
    const { t: translation } = useTranslation();

    return (
      <div className={classes.newDefect}>
        <div className={classes.title}>
          <Checkbox
            checked={reminderSettingsForm.newDefect.isChecked}
            onChange={(e) =>
              handleFormChange(e.target.checked, "newDefect", "isChecked")
            }
          >
            {translation("settings.emailNotification.newDefectReminderEmail")}
          </Checkbox>
        </div>
        {reminderSettingsForm.newDefect.isChecked ? (
          <div className={classes.details}>
            <Row className={"detail"}>
              <Col flex="400px">
                <div>
                  {translation(
                    "settings.emailNotification.includeSubcontractorUserInReminderEmail"
                  )}
                  :
                </div>
                <div>{`(${translation(
                  "settings.emailNotification.assignInSettings"
                )} > ${translation(
                  "settings.emailNotification.subcontractorUsers"
                )})`}</div>
              </Col>
              <Col flex="auto">
                <Radio.Group
                  onChange={(e) => {
                    handleFormChange(
                      e.target.value === "yes",
                      "newDefect",
                      "isCcSubconUser"
                    );
                  }}
                  value={
                    reminderSettingsForm.newDefect.isCcSubconUser ? "yes" : "no"
                  }
                >
                  <Radio value={"yes"}>{translation("app.common.yes")}</Radio>
                  <Radio value={"no"}>{translation("app.common.no")}</Radio>
                </Radio.Group>
              </Col>
            </Row>
          </div>
        ) : null}
      </div>
    );
  };

export default EmailNotificationSetupNewDefect;
