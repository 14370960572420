import React from "react";
import { createUseStyles } from "react-jss";

import { Defect } from "../../../services/defect";

import ByDefectType from "./ByDefectType";
import CurrentAreaByDefectGroup from "./CurrentAreaByDefectGroup";
import CurrentAreaByDefectType from "./CurrentAreaByDefectType";
import CurrentAreaByInspectionType from "./CurrentAreaByInspectionType";
import CurrentAreaByResponsibleParty from "./CurrentAreaByResponsibleParty";
import DefectAnalysis from "./DefectAnalysis";
import DefectCause from "./DefectCause";
import DefectGroup from "./DefectGroup";
import DefectProgress from "./DefectProgress";
import DefectSummaryByResponsibleParty from "./DefectSummaryByResponsibleParty";
import TotalDefect from "./TotalDefect";

interface DefectAnalysisLayoutProps {
  defects: Defect[];
}

const useStyles = createUseStyles(() => ({
  chartRow: {
    display: "flex",
  },
  tableRow: {
    display: "flex",
  },
  graphRow: {
    display: "flex",
  },
  cloudRow: {
    display: "flex",
  },
}));

const DefectAnalysisLayout: React.FC<DefectAnalysisLayoutProps> = ({
  defects,
}) => {
  const classes = useStyles();
  return (
    <>
      <DefectAnalysis defects={defects} />
      <div className={classes.chartRow}>
        <TotalDefect defects={defects} />
        <ByDefectType defects={defects} />
      </div>
      <div className={classes.tableRow}>
        <CurrentAreaByResponsibleParty defects={defects} />
        <CurrentAreaByInspectionType defects={defects} />
      </div>
      <div className={classes.tableRow}>
        <CurrentAreaByDefectType defects={defects} />
        <CurrentAreaByDefectGroup defects={defects} />
      </div>
      <div className={classes.graphRow}>
        <DefectProgress defects={defects} />
      </div>
      <div className={classes.graphRow}>
        <DefectSummaryByResponsibleParty defects={defects} />
      </div>
      <div className={classes.cloudRow}>
        <DefectGroup defects={defects} />
        <DefectCause defects={defects} />
      </div>
    </>
  );
};

export default DefectAnalysisLayout;
