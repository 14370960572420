import React, { ReactNode, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  DefectDescription,
  DefectGroup,
  DefectSubject,
  Enum,
  getDefectGroups,
  getEnums,
  getSubcontractors,
  getTeams,
  Location,
  UserRightList,
} from "../../services/app";
import { ServiceConfig } from "../../utils/backend";
import notify from "../../utils/notify";
import AppContext, { AppContextCache } from "./AppContext";

interface AppContextProviderProps {
  initAppContextCache?: AppContextCache;
  children: ReactNode;
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  initAppContextCache,
  children,
}) => {
  const [appContextCache, setAppContextCache] = useState<AppContextCache>(
    initAppContextCache || {
      locationGroups: [],
      locationFullPaths: {} as Record<number, string>,
      defectGroups: [],
      defectSubjects: [],
      defectDescriptions: [],
      defectTypes: [],
      defectCauses: [],
      inspectionTypes: [],
      subcontractors: [],
      users: [],
      teams: [],
      routeParams: {},
    }
  );

  const { t: translation } = useTranslation();

  const handleAppContextCacheItemUpdate = useCallback(
    <K extends keyof AppContextCache>(
      cacheItem: K,
      itemValue: AppContextCache[K]
    ) => {
      setAppContextCache((prevState) => ({
        ...prevState,
        [cacheItem]: itemValue,
      }));
    },
    []
  );

  const hasRight = (
    rightType: keyof UserRightList,
    source: "module" | "locationGroup" = "locationGroup",
    objectId?: number
  ) => {
    if (!appContextCache.userRightList) return false;
    if (!appContextCache.userRightList[rightType]?.length) return false;

    const { userRightList, routeParams } = appContextCache;

    for (const right of userRightList[rightType]) {
      if (right.source === "module") {
        return true;
      } else if (right.source === source) {
        if (right.source === "locationGroup") {
          if (!objectId) {
            objectId = routeParams.locationGroupId;
          }
          if (right.locationGroup === objectId) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const getChildLocationIdList = (
    target: Location,
    root?: Location,
    found?: boolean
  ): number[] => {
    let result: number[] = [];

    if (!root) root = appContextCache.activeLocationGroup;

    if (!root) return [target.id];

    if (found || root.id === target.id) {
      result.push(root.id);
      if (root.children) {
        for (const child of root.children) {
          result = result.concat(getChildLocationIdList(target, child, true));
        }
      }
    } else {
      if (root.children) {
        for (const child of root.children) {
          result = result.concat(getChildLocationIdList(target, child, false));
        }
      }
    }
    return result;
  };

  const refetchDefectGroups = (
    entityId?: string,
    serviceConfig?: ServiceConfig
  ): void => {
    if (!entityId) return;
    if (!serviceConfig) return;

    getDefectGroups(entityId, serviceConfig)
      .then((defectGroups: DefectGroup[]) => {
        handleAppContextCacheItemUpdate("defectGroups", defectGroups);

        let defectSubjects: DefectSubject[] = [];
        for (const defectGroup of defectGroups) {
          defectSubjects = defectSubjects.concat(defectGroup.defectSubjects);
        }
        handleAppContextCacheItemUpdate("defectSubjects", defectSubjects);

        let defectDescriptions: DefectDescription[] = [];
        for (const defectSubject of defectSubjects) {
          defectDescriptions = defectDescriptions.concat(
            defectSubject.defectDescriptions
          );
        }
        handleAppContextCacheItemUpdate(
          "defectDescriptions",
          defectDescriptions
        );
      })
      .catch((e) =>
        notify.error(
          `${translation("appRouter.getDefectGroups.error")} ${entityId} : ${
            e.message
          }`
        )
      );
  };
  const refetchTeams = (
    entityId?: string,
    serviceConfig?: ServiceConfig
  ): void => {
    if (!entityId) return;
    if (!serviceConfig) return;
    getTeams(entityId, serviceConfig)
      .then((teams) => {
        handleAppContextCacheItemUpdate("teams", teams);
      })
      .catch((e) =>
        notify.error(
          `${translation("appRouter.getTeams.error")} ${entityId} : ${
            e.message
          }`
        )
      );
  };
  const refetchEnums = (
    entityId?: string,
    serviceConfig?: ServiceConfig
  ): void => {
    if (!entityId) return;
    if (!serviceConfig) return;

    getEnums(entityId, serviceConfig)
      .then((enums: Enum[]) => {
        handleAppContextCacheItemUpdate(
          "defectCauses",
          enums.filter((en) => en.type === "defectCause")
        );
        handleAppContextCacheItemUpdate(
          "defectTypes",
          enums.filter((en) => en.type === "defectType")
        );
        handleAppContextCacheItemUpdate(
          "inspectionTypes",
          enums.filter((en) => en.type === "inspectionType")
        );
      })
      .catch((e) =>
        notify.error(
          `${translation("appRouter.getEnums.error")} ${entityId} : ${
            e.message
          }`
        )
      );
  };

  const refetchSubcontractors = (
    entityId?: string,
    serviceConfig?: ServiceConfig
  ): void => {
    if (!entityId) return;
    if (!serviceConfig) return;

    getSubcontractors(entityId, serviceConfig)
      .then((subcontractors) => {
        handleAppContextCacheItemUpdate("subcontractors", subcontractors);
      })
      .catch((e) =>
        notify.error(
          `${translation("appRouter.getSubcontractors.error")} ${entityId} : ${
            e.message
          }`
        )
      );
  };

  const getLocationFullPath = (location: Location): string => {
    if (location.fullPath) return location.fullPath;
    const fullPath = appContextCache.locationFullPaths[location.id];
    if (!fullPath) return location.name;
    return fullPath;
  };

  return (
    <AppContext.Provider
      value={{
        ...appContextCache,
        getLocationFullPath,
        getChildLocationIdList,
        refetchDefectGroups,
        refetchEnums,
        refetchTeams,
        refetchSubcontractors,
        onAppContextCacheUpdate: setAppContextCache,
        onAppContextCacheItemUpdate: handleAppContextCacheItemUpdate,
        hasRight,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
