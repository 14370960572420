import React from "react";
import { useTranslation } from "react-i18next";

import { NxpFormItem } from "@nexploretechnology/nxp-ui";
import moment from "moment";

import useAppContext from "../../hooks/useAppContext";
import { Filter } from "./AppDefectFilterModalContainer";

interface ValueWidgetProps {
  index: number;
  filter: Filter;
  onFilterValueChange: (index: number, value: any) => void;
}

const ValueWidget: React.FC<ValueWidgetProps> = (props) => {
  const { field, value } = props.filter;

  const appContext = useAppContext();
  const {
    activeLocationGroup,
    defectDescriptions,
    defectGroups,
    defectSubjects,
    defectTypes,
    inspectionTypes,
    subcontractors,
    users,
  } = appContext;

  const { t: translation } = useTranslation();

  if (!field) {
    return null;
  } else if (field === "no") {
    return (
      <NxpFormItem
        controlType="inputNumber"
        controlProps={{
          value: value,
          decimalPlace: 0,
          onChange: (value: any) =>
            props.onFilterValueChange(props.index, value),
        }}
      />
    );
  } else if (field === "status") {
    return (
      <NxpFormItem
        controlType="select"
        controlProps={{
          options: [
            { label: translation("app.common.open"), value: "open" },
            {
              label: translation("app.common.markFixed"),
              value: "mark-fixed",
            },
            {
              label: translation("app.common.markClosed"),
              value: "mark-closed",
            },
            {
              label: translation("app.common.closed"),
              value: "closed",
            },
            {
              label: translation("app.common.cancelled"),
              value: "cancelled",
            },
          ],
          value,
          onChange: (value: any) =>
            props.onFilterValueChange(props.index, value),
        }}
      />
    );
  } else if (field === "inspectionType") {
    return (
      <NxpFormItem
        controlType="select"
        controlProps={{
          options: inspectionTypes.map((item) => ({
            label: item.name,
            value: item.id,
          })),
          value,
          onChange: (value: any) =>
            props.onFilterValueChange(props.index, value),
        }}
      />
    );
  } else if (field === "defectType") {
    return (
      <NxpFormItem
        controlType="select"
        controlProps={{
          options: defectTypes.map((item) => ({
            label: item.name,
            value: item.id,
          })),
          value,
          onChange: (value: any) =>
            props.onFilterValueChange(props.index, value),
        }}
      />
    );
  } else if (field === "createdAt") {
    return (
      <NxpFormItem
        controlType="datePicker"
        controlProps={{
          value: value ? moment(value).toDate() : new Date(),
          onChange: (date) => {
            props.onFilterValueChange(
              props.index,
              moment(date).format("YYYY-MM-DD")
            );
          },
        }}
      />
    );
  } else if (field === "raisedBy") {
    return (
      <NxpFormItem
        controlType="select"
        controlProps={{
          options: users.map((item) => ({
            label: item.name,
            value: item.id,
          })),
          value,
          onChange: (value: any) =>
            props.onFilterValueChange(props.index, value),
        }}
      />
    );
  } else if (field === "defectGroup") {
    return (
      <NxpFormItem
        controlType="select"
        controlProps={{
          options: defectGroups.map((item) => ({
            label: item.name,
            value: item.id,
          })),
          value,
          onChange: (value: any) =>
            props.onFilterValueChange(props.index, value),
        }}
      />
    );
  } else if (field === "defectSubject") {
    return (
      <NxpFormItem
        controlType="select"
        controlProps={{
          options: defectSubjects.map((item) => ({
            label: item.name,
            value: item.id,
          })),
          value,
          onChange: (value: any) =>
            props.onFilterValueChange(props.index, value),
        }}
      />
    );
  } else if (field === "defectDescription") {
    return (
      <NxpFormItem
        controlType="select"
        controlProps={{
          options: defectDescriptions.map((item) => ({
            label: item.name,
            value: item.id,
          })),
          value,
          onChange: (value: any) =>
            props.onFilterValueChange(props.index, value),
        }}
      />
    );
  } else if (field === "responsiblePerson") {
    return (
      <NxpFormItem
        controlType="select"
        controlProps={{
          options: users.map((item) => ({
            label: item.name,
            value: item.id,
          })),
          value,
          onChange: (value: any) =>
            props.onFilterValueChange(props.index, value),
        }}
      />
    );
  } else if (field === "responsibleParty") {
    return (
      <NxpFormItem
        controlType="select"
        controlProps={{
          options: subcontractors.map((item) => ({
            label: item.name,
            value: item.id,
          })),
          value,
          onChange: (value: any) =>
            props.onFilterValueChange(props.index, value),
        }}
      />
    );
  } else if (field === "nextInspectionDate") {
    return (
      <NxpFormItem
        controlType="datePicker"
        controlProps={{
          value: value ? moment(value).toDate() : new Date(),
          onChange: (date) => {
            props.onFilterValueChange(
              props.index,
              moment(date).format("YYYY-MM-DD")
            );
          },
        }}
      />
    );
  } else if (field === "dueDate") {
    return (
      <NxpFormItem
        controlType="datePicker"
        controlProps={{
          value: value ? moment(value).toDate() : new Date(),
          onChange: (date) => {
            props.onFilterValueChange(
              props.index,
              moment(date).format("YYYY-MM-DD")
            );
          },
        }}
      />
    );
  } else if (field === "isTopPriority") {
    return (
      <NxpFormItem
        controlType="checkbox"
        controlProps={{
          checked: value,
          onChange: (ev) => {
            props.onFilterValueChange(props.index, ev.target.checked);
          },
        }}
      />
    );
  } else if (field === "location") {
    return (
      <NxpFormItem
        controlType="treeSelect"
        controlProps={{
          options: {
            ...activeLocationGroup,
            title: activeLocationGroup?.name,
            value: activeLocationGroup?.id.toString(),
          },
          onChange: (value) => props.onFilterValueChange(props.index, value),
        }}
      />
    );
  } else if (field === "remarks") {
    return (
      <NxpFormItem
        controlType="input"
        controlProps={{
          value,
          onChange: (ev) =>
            props.onFilterValueChange(props.index, ev.target.value),
        }}
      />
    );
  } else if (field === "constructionLotNo") {
    return (
      <NxpFormItem
        controlType="input"
        controlProps={{
          value,
          onChange: (ev) =>
            props.onFilterValueChange(props.index, ev.target.value),
        }}
      />
    );
  } else if (field === "ncrNo") {
    return (
      <NxpFormItem
        controlType="input"
        controlProps={{
          value,
          onChange: (ev) =>
            props.onFilterValueChange(props.index, ev.target.value),
        }}
      />
    );
  }
  return <>{"Not yet supported"}</>;
};

export default ValueWidget;
