import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { notify, NxpButton, NxpHeader } from "@nexploretechnology/nxp-ui";
import { cloneDeep } from "lodash";

import useAppContext from "../../../../hooks/useAppContext";
import { Subcontractor } from "../../../../services/app";
import {
  createReminders,
  deleteReminders,
  ReminderType,
  SubcontractorSubscription,
  updateReminders,
} from "../../../../services/email";
import { FieldName, ReminderSetting } from "../helper";
import EmailNotificationSetupDueDate from "./EmailNotificationSetupDueDate";
import EmailNotificationSetupNewDefect from "./EmailNotificationSetupNewDefect";

const useStyles = createUseStyles(() => ({
  saveButtonWrapper: {
    position: "relative",
  },
  saveButton: {
    position: "absolute",
    right: 0,
    top: -60,
  },
}));

interface EmailNotificationSetupProps {
  currentSubcontractor: Subcontractor;
  allReminderSettings: SubcontractorSubscription[];
  fetchAllReminders: any;
  setLoading: (value: boolean) => void;
}

const EmailNotificationSetup: React.FC<EmailNotificationSetupProps> = ({
  currentSubcontractor,
  allReminderSettings,
  fetchAllReminders,
  setLoading,
}) => {
  const classes = useStyles();
  const { t: translation } = useTranslation();
  const { routeParams, serviceConfig } = useAppContext();
  const { entityId } = routeParams;

  const defaultReminderSettings: Record<string, ReminderSetting> = useMemo(
    () => ({
      dueDate: {
        id: undefined,
        subconId: currentSubcontractor?.id,
        isChecked: false,
        isDaily: false,
        isCcSubconUser: false,
        isAttachDefectReport: false,
      },
      newDefect: {
        id: undefined,
        subconId: currentSubcontractor?.id,
        isChecked: false,
        isDaily: false, // non-functional
        isCcSubconUser: false,
        isAttachDefectReport: false, // non-functional
      },
    }),
    [currentSubcontractor?.id]
  );

  const [reminderSettings, setReminderSettings] = useState<
    Record<string, ReminderSetting>
  >(defaultReminderSettings);

  const [reminderSettingsForm, setReminderSettingsForm] = useState<
    Record<string, ReminderSetting>
  >(defaultReminderSettings);

  const handleFormChange = (
    value: boolean,
    reminderType: ReminderType,
    fieldName: FieldName
  ) => {
    const newFeminderSettingsForm = {
      ...reminderSettingsForm,
      [reminderType]: {
        ...reminderSettingsForm[reminderType],
        [fieldName]: value,
      },
    };
    setReminderSettingsForm(newFeminderSettingsForm);
  };

  const handleSave = async () => {
    // compare reminderSettings (ori) with reminderSettingsForm (new)
    let createList: any = [];
    let updateList: any = [];
    let deleteList: any = [];
    for (const reminderType in reminderSettings) {
      const reminder = {
        reminderType: reminderType,
        id: reminderSettingsForm[reminderType].id,
        subconId: currentSubcontractor.id,
        isDaily: reminderSettingsForm[reminderType].isDaily,
        isCcSubconUser: reminderSettingsForm[reminderType].isCcSubconUser,
        isAttachDefectReport:
          reminderSettingsForm[reminderType].isAttachDefectReport,
      };
      if (
        reminderSettings[reminderType].isChecked ===
        reminderSettingsForm[reminderType].isChecked
      ) {
        if (reminderSettingsForm[reminderType].isChecked) {
          // update only on checked
          if (
            reminderSettings[reminderType].isDaily !==
              reminderSettingsForm[reminderType].isDaily ||
            reminderSettings[reminderType].isCcSubconUser !==
              reminderSettingsForm[reminderType].isCcSubconUser ||
            reminderSettings[reminderType].isAttachDefectReport !==
              reminderSettingsForm[reminderType].isAttachDefectReport
          ) {
            updateList.push(reminder);
          }
        }
      } else {
        if (reminderSettingsForm[reminderType].isChecked) {
          createList.push(reminder);
        } else {
          deleteList.push(reminder);
        }
      }
    }
    // api call
    setLoading(true);
    try {
      for (const reminder of createList) {
        await createReminders(
          entityId,
          reminder.subconId,
          reminder.reminderType,
          reminder.isDaily,
          reminder.isCcSubconUser,
          reminder.isAttachDefectReport,
          serviceConfig
        );
      }
      for (const reminder of updateList) {
        await updateReminders(
          entityId,
          reminder.id,
          reminder.reminderType,
          reminder.isDaily,
          reminder.isCcSubconUser,
          reminder.isAttachDefectReport,
          serviceConfig
        );
      }
      for (const reminder of deleteList) {
        await deleteReminders(entityId, reminder.id, serviceConfig);
      }
      notify.actionCompleted();
    } catch (ex) {
      notify.error(`Save ${ex}`);
    } finally {
      setLoading(false);
      fetchAllReminders(); // refresh page
    }
  };

  useEffect(() => {
    const targetResults = allReminderSettings.filter(
      (result) => result.subcon.id === currentSubcontractor?.id
    );
    let newReminderSettings = cloneDeep(defaultReminderSettings);
    for (const targetResult of targetResults) {
      newReminderSettings = {
        ...newReminderSettings,
        [targetResult.reminderType]: {
          id: targetResult.id,
          isChecked: true,
          isDaily: Boolean(targetResult.isDaily),
          isCcSubconUser: targetResult.isCcSubconUser,
          isAttachDefectReport: Boolean(targetResult.isAttachDefectReport),
        },
      } as Record<string, ReminderSetting>;
    }
    setReminderSettings(newReminderSettings); // keep originally settings for comparison when save
    setReminderSettingsForm(newReminderSettings); // form value
  }, [allReminderSettings, currentSubcontractor, defaultReminderSettings]);

  return (
    <>
      <div className={classes.saveButtonWrapper}>
        <div className={classes.saveButton}>
          <NxpHeader
            titleContent=""
            actionContent={
              <NxpButton type="primary" onClick={handleSave}>
                {translation("app.common.save")}
              </NxpButton>
            }
          />
        </div>
      </div>
      <br />
      <EmailNotificationSetupDueDate
        reminderSettingsForm={reminderSettingsForm}
        handleFormChange={handleFormChange}
      />
      <br />
      <EmailNotificationSetupNewDefect
        reminderSettingsForm={reminderSettingsForm}
        handleFormChange={handleFormChange}
      />
    </>
  );
};

export default EmailNotificationSetup;
