import React from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpHeader,
  NxpTable,
  NxpTableEditable,
} from "@nexploretechnology/nxp-ui";

import useAppContext from "../../../../hooks/useAppContext";
import AddDescriptionButton from "./AddDescription/AddDescriptionButton";
import AddSubjectButton from "./AddSubject/AddSubjectButton";
import { RowItem } from "./SubjectFormContainer";

interface SubjectFormLayoutProps {
  currentNode: any;
  editItem: RowItem | undefined;
  saveInProgress: boolean;
  subcategoryColumn: any;
  subcategoryDataSource: any;
  handleAddToList: (item: any, itemType: string) => void;
  handleRowCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleRowClick: (record: any) => void;
  handleRowDelete: (deleteItem: RowItem) => void;
  handleRowEdit: (editItem: RowItem) => void;
  handleRowSave: () => void;
  handleSave: () => void;
}

const SubjectFormLayout: React.FC<SubjectFormLayoutProps> = ({
  currentNode,
  editItem,
  saveInProgress,
  subcategoryColumn,
  subcategoryDataSource,
  handleAddToList,
  handleRowCancel,
  handleRowClick,
  handleRowDelete,
  handleRowEdit,
  handleRowSave,
  handleSave,
}) => {
  const appContext = useAppContext();
  const { hasRight } = appContext;

  const { t: translation } = useTranslation();

  const stringSplitArray = currentNode.key
    ?.split("-")
    .map((item: string) => item.replace(/[A-Za-z]+-/g, ""));
  let titleContent = "";
  switch (stringSplitArray.length) {
    case 1:
      titleContent = translation("app.common.defectGroup");
      break;
    case 3:
      titleContent = translation("app.common.subject");
      break;
    case 5:
      titleContent = translation("app.common.description");
      break;
    case 7:
      titleContent = translation("app.common.responsibleParty");
      break;
  }
  return (
    <>
      <NxpHeader
        titleContent={titleContent}
        actionContent={
          (currentNode.key?.includes("Defect Description") && (
            <NxpButton onClick={handleSave}>
              {translation("app.common.save")}
            </NxpButton>
          )) ||
          (currentNode.key?.includes("Defect Subject") &&
            !currentNode.key?.includes("Defect Description") &&
            hasRight("setup-add") && (
              <AddDescriptionButton
                currentNode={{
                  groupId: stringSplitArray[1],
                  subjectId: stringSplitArray[3],
                }}
                handleAddToList={handleAddToList}
              />
            )) ||
          (currentNode.key?.includes("Defect Group") &&
            currentNode.key !== "Defect Group" &&
            hasRight("setup-add") && (
              <AddSubjectButton
                currentNode={{
                  groupId: stringSplitArray[1],
                }}
                handleAddToList={handleAddToList}
              />
            ))
        }
      />
      {hasRight("setup-view") && hasRight("setup-edit", "module") ? (
        currentNode?.key.includes("Defect Description") ||
        currentNode?.title === "Defect Group" ? (
          <NxpTable
            columns={subcategoryColumn}
            dataSource={subcategoryDataSource}
            onRow={(record) => ({
              onClick: () => {
                handleRowClick(record);
              },
            })}
          />
        ) : (
          <NxpTableEditable
            columns={subcategoryColumn} //any for fixing antd type error
            dataSource={subcategoryDataSource}
            editItem={editItem}
            pagination={false}
            saveInProgress={saveInProgress}
            itemCompareKey="key"
            hideDelete={!hasRight("setup-delete")}
            onRow={(record) => ({
              onClick: () => {
                handleRowClick(record);
              },
            })}
            onRowCancel={handleRowCancel}
            onRowDelete={handleRowDelete}
            onRowEdit={handleRowEdit}
            onRowSave={handleRowSave}
          />
        )
      ) : (
        <NxpTable
          dataSource={subcategoryDataSource}
          rowKey="key"
          columns={subcategoryColumn}
          onRow={(record) => ({
            onClick: () => {
              handleRowClick(record);
            },
          })}
        />
      )}
    </>
  );
};

export default React.memo(SubjectFormLayout);
