import { apiRequest, ServiceConfig } from "../utils/backend";
import { Location, File, LocationOtherInfo } from "./app";

export interface FloorPlan {
  id: number;
  type: "image" | "auto";
  width: number;
  height: number;
  areas: FloorPlanArea[];
  file: File;
  container?: {
    width: number;
    height: number;
  };
}

export interface FloorPlanArea {
  id: number;
  location?: Location;
  polygon: number[][];
}

export const getLocationDetails = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  locationId: number | undefined,
  serviceConfig: ServiceConfig
) =>
  await apiRequest<Location>(
    `/entities/${entityId}/location-groups/${locationGroupId}/locations/${locationId}`,
    serviceConfig
  );

export const attachFileToLocation = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  locationId: number | undefined,
  fileId: number | undefined,
  serviceConfig: ServiceConfig
) =>
  await apiRequest<Location>(
    `/entities/${entityId}/location-groups/${locationGroupId}/locations/${locationId}/files/${fileId}`,
    serviceConfig,
    {
      method: "PUT",
    }
  );
export const detechFileFromLocation = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  locationId: number | undefined,
  fileId: number | undefined,
  serviceConfig: ServiceConfig
) =>
  await apiRequest<Location>(
    `/entities/${entityId}/location-groups/${locationGroupId}/locations/${locationId}/files/${fileId}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );

export const updateLocationInfo = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  locationId: number | undefined,
  info: Partial<LocationOtherInfo>,
  serviceConfig: ServiceConfig
) =>
  await apiRequest<LocationOtherInfo>(
    `/entities/${entityId}/location-groups/${locationGroupId}/locations/${locationId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(info),
    }
  );
