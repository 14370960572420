import { useIdleTimer } from "react-idle-timer";

const useAppTimer = (keycloakInstance: Keycloak.KeycloakInstance) => {
  const handleIdleTimerAction = (event: Event) => {};

  const handleIdleTimerActive = (event: Event) => {};

  const handleIdleTimerIdle = (event: Event) => {
    keycloakInstance.logout();
  };
  useIdleTimer({
    timeout: 1000 * 60 * 30, // 30 mins
    onIdle: handleIdleTimerIdle,
    onActive: handleIdleTimerActive,
    onAction: handleIdleTimerAction,
    debounce: 2000,
  });
};

export default useAppTimer;
