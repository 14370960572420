import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../../hooks/useAppContext";
import { useValidate } from "../../../../hooks/useValidate";
import { createTeam } from "../../../../services/team";
import notify from "../../../../utils/notify";

interface AddRecordModalProps {
  modalVisible: boolean;
  handleAddToList: (item: any) => void;
  handleModalVisible: () => void;
}

interface AddRecordGridForm {
  name: string;
  description: string;
}

const AddRecordContainer: React.FC<AddRecordModalProps> = ({
  modalVisible,
  handleAddToList,
  handleModalVisible,
}) => {
  const appContext = useAppContext();
  const { routeParams, serviceConfig, errorHandler } = appContext;
  const { entityId } = routeParams;

  const { t: translation } = useTranslation();

  const formItems: NxpFormGridItemProps<AddRecordGridForm>[] = [
    {
      controlType: "input",
      label: translation("settings.team.addRecord.teamName"),
      itemFieldName: "name",
      span: 12,
    },
    {
      controlType: "input",
      label: translation("app.common.teamDescription"),
      itemFieldName: "description",
      span: 12,
    },
  ];

  const formSchema = yup.object().shape({
    name: yup
      .string()
      .nullable()
      .required(translation("app.common.teamNameIsRequired")),
    description: yup
      .string()
      .nullable()
      .required(translation("app.common.teamDescriptionIsRequired")),
  });

  const [addRecordForm, setAddRecordForm] = useState<AddRecordGridForm>({
    name: "",
    description: "",
  });

  const handleSaveValidated = async () => {
    try {
      const response = await createTeam(
        entityId!,
        addRecordForm,
        serviceConfig
      );
      handleAddToList({
        id: response.id,
        name: response.name,
        description: response.description,
      });
      handleModalVisible();
      notify.actionCompleted();
    } catch (ex) {
      errorHandler(
        ex,
        translation("settings.team.addRecord.handleSaveValidated.error")
      );
    }
  };

  const [validationError, , , saveWithValidate] =
    useValidate<AddRecordGridForm>(
      addRecordForm,
      formSchema,
      handleSaveValidated
    );

  const handleSaveClick = () => {
    saveWithValidate(undefined);
  };

  const handleFormGridStateChange = (
    fieldName: keyof AddRecordGridForm,
    value: unknown
  ) => {
    setAddRecordForm((prevState) => ({
      ...prevState,
      [fieldName]: value as string,
    }));
  };

  useEffect(() => {
    if (modalVisible) {
      setAddRecordForm({ name: "", description: "" });
    }
  }, [modalVisible]);

  return (
    <NxpModal
      title={translation("settings.team.addRecord.addRecord")}
      visible={modalVisible}
      onCancel={handleModalVisible}
      footer={
        <NxpButton onClick={handleSaveClick}>
          {translation("app.common.save")}
        </NxpButton>
      }
      width="small"
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={addRecordForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddRecordContainer;
