import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  notify,
  NxpFormItem,
  NxpTable,
  sorterForDate,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { ColumnProps } from "antd/lib/table";
import moment from "moment";

import useAppContext from "../../../../hooks/useAppContext";
import { Subcontractor } from "../../../../services/app";
import { EmailLog, getEmailLogBySubcon } from "../../../../services/email";

const useStyles = createUseStyles((theme) => ({
  logs: {},
}));

interface EmailNotificationLogsProps {
  currentSubcontractor: Subcontractor;
  setLoading: (value: boolean) => void;
}

const EmailNotificationLogs: React.FC<EmailNotificationLogsProps> = ({
  currentSubcontractor,
  setLoading,
}) => {
  const classes = useStyles();
  const { t: translation } = useTranslation();
  const { routeParams, serviceConfig } = useAppContext();
  const { entityId } = routeParams;

  const [emailLogs, setEmailLogs] = useState<EmailLog[]>();

  const columns: ColumnProps<EmailLog>[] = [
    {
      title: translation("settings.emailNotification.emailSubjects"),
      dataIndex: "emailTpye",
      width: 200,
      sorter: (a: EmailLog, b: EmailLog) =>
        sorterForString(a?.subject, b?.subject),
      render: (_, record) => {
        return (
          <NxpFormItem
            editing={false}
            controlType="input"
            controlProps={{
              value: record.subject,
            }}
          />
        );
      },
    },
    {
      title: translation("settings.emailNotification.recipients"),
      dataIndex: "toEmail",
      width: 200,
      sorter: (a: EmailLog, b: EmailLog) =>
        sorterForString(a?.toEmail, b?.toEmail),
      render: (_, record) => {
        return (
          <NxpFormItem
            editing={false}
            controlType="input"
            controlProps={{
              value: record?.toEmail,
            }}
          />
        );
      },
    },
    {
      title: translation("settings.emailNotification.ccRecipients"),
      dataIndex: "ccEmails",
      width: 200,
      sorter: (a: EmailLog, b: EmailLog) =>
        sorterForString(
          a?.ccUsers.map((user) => user.name).join(","),
          b?.ccUsers.map((user) => user.name).join(",")
        ),
      render: (_, record) => {
        return (
          <NxpFormItem
            editing={false}
            controlType="input"
            controlProps={{
              value: record?.ccUsers?.length
                ? record?.ccUsers.map((user) => user.name).join(",")
                : "",
            }}
          />
        );
      },
    },
    {
      title: translation("settings.emailNotification.sendDate"),
      dataIndex: "createdOn",
      width: 150,
      render: (_, record) => {
        return (
          <NxpFormItem
            editing={false}
            controlType="input"
            controlProps={{
              value: moment(record?.createdOn).format("YYYY-MM-DD HH:mm"),
            }}
          />
        );
      },
      sorter: (a: EmailLog, b: EmailLog) =>
        sorterForDate(
          moment(a?.createdOn).toDate(),
          moment(b?.createdOn).toDate()
        ),
    },
  ];

  const fetchEmailLogs = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getEmailLogBySubcon(
        entityId,
        currentSubcontractor.id,
        serviceConfig
      );
      setEmailLogs(result);
    } catch (ex) {
      notify.error(`Save ${ex}`);
    } finally {
      setLoading(false);
    }
  }, [currentSubcontractor.id, entityId, serviceConfig, setLoading]);

  useEffect(() => {
    fetchEmailLogs();
  }, [currentSubcontractor, fetchEmailLogs]);

  return (
    <div className={classes.logs}>
      <NxpTable
        dataSource={emailLogs}
        rowKey="id"
        columns={columns}
        useDefaultHeight
      />
    </div>
  );
};

export default EmailNotificationLogs;
