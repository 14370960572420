import React from "react";
import { createUseStyles } from "react-jss";
import { DualAxes } from "@ant-design/charts";
import { Card } from "antd";

import { useTranslation } from "react-i18next";

interface DefectProgressLayoutProps {
  defectRaisedOnThatDayData: any;
  cumulativeDefectData: any;
}

const useStyles = createUseStyles(() => ({
  graphCard: {
    textAlign: "center",
    width: "1010px",
    margin: "10px 10px 0px 0px",
  },
  legend: {
    padding: "20px 0px 0px 0px",
    "& p": {
      textAlign: "left",
      padding: "0px 0px 0px 15px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "16px",
      margin: "0px",
    },
    "& .legend-item": {
      height: "30px",
      margin: "0px 0px 0px 20px",
      padding: "0px 20px 0px 0px",
      float: "left",
      display: "flex",
      alignItems: "center",
    },
    "& .defect-raised-on-that-day": {
      width: "20px",
      height: "20px",
      minWidth: "20px",
      minHeight: "20px",
      background: "#DC3545",
    },
    "& .defect-closed-on-that-day": {
      width: "20px",
      height: "20px",
      minWidth: "20px",
      minHeight: "20px",
      background: "#1C7530",
    },
    "& .cum-defect-raised": {
      width: "20px",
      height: "20px",
      minWidth: "20px",
      minHeight: "20px",
      background: "#DC3545",
    },
    "& .cum-defect-closed": {
      width: "20px",
      height: "20px",
      minWidth: "20px",
      minHeight: "20px",
      background: "#28A745",
    },
  },
}));

const DefectProgressLayout: React.FC<DefectProgressLayoutProps> = ({
  defectRaisedOnThatDayData,
  cumulativeDefectData,
}) => {
  const classes = useStyles();
  const { t: translation } = useTranslation();
  return (
    <Card className={classes.graphCard}>
      <h1>
        {translation(
          "dashboard.defectAnalysis.defectProgress.defectProgressRaisedClosed"
        )}
      </h1>
      <DualAxes
        data={[defectRaisedOnThatDayData, cumulativeDefectData]}
        xField={"date"}
        yField={["value", "count"]}
        yAxis={{
          value: {
            tickCount: 6,
            max: defectRaisedOnThatDayData.length
              ? defectRaisedOnThatDayData.reduce(
                  (prev: { value: number }, current: { value: number }) =>
                    prev.value > current.value ? prev : current
                ).value + 3
              : 10,
          },
          count: {
            max: cumulativeDefectData.length
              ? cumulativeDefectData.reduce(
                  (prev: { count: number }, current: { count: number }) =>
                    prev.count > current.count ? prev : current
                ).count + 3
              : 10,
          },
        }}
        geometryOptions={[
          {
            geometry: "column",
            isGroup: true,
            seriesField: "type",
            columnWidthRatio: 0.4,
            color: ["#DC3545", "#1C7530"],
          },
          {
            geometry: "line",
            seriesField: "type",
            point: {
              shape: "circle",
              size: 5,
              style: {
                opacity: 1,
              },
            },
            color: ["#DC3545", "#28A745"],
          },
        ]}
        legend={false}
      />
      <div className={classes.legend}>
        <div className={"legend-item"}>
          <div className={"defect-raised-on-that-day"}></div>
          <p>
            {translation(
              "dashboard.defectAnalysis.defectProgress.defectRaisedOnThatDay"
            )}
          </p>
        </div>
        <div className={"legend-item"}>
          <div className={"defect-closed-on-that-day"}></div>
          <p>
            {translation(
              "dashboard.defectAnalysis.defectProgress.defectClosedOnThatDay"
            )}
          </p>
        </div>
        <div className={"legend-item"}>
          <div className={"cum-defect-raised"}></div>
          <p>
            {translation(
              "dashboard.defectAnalysis.defectProgress.cumDefectRaised"
            )}
          </p>
        </div>
        <div className={"legend-item"}>
          <div className={"cum-defect-closed"}></div>
          <p>
            {translation(
              "dashboard.defectAnalysis.defectProgress.cumDefectClosed"
            )}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default DefectProgressLayout;
