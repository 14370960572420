import React from "react";
import { createUseStyles } from "react-jss";
import moment from "moment";
import { Badge, Button, Calendar, Popover } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { AppTheme } from "../../../components/App/appTheme";
import { Defect } from "../../../services/defect";
import InspectionDetailsButton from "./InspectionDetails/InspectionDetailsButton";

import { useTranslation } from "react-i18next";

interface CalendarLayoutProps {
  currentValue: Date;
  defects: Defect[];
  nextMonth: Date;
  previousMonth: Date;
  handleChangeMonth: (monthDifference: number, currentValue: Date) => void;
}

const useStyles = createUseStyles<
  "legend" | "calenderHeader" | "calender" | "content" | "popOver",
  { previousMonth: string; currentMonth: string; nextMonth: string },
  AppTheme
>(() => ({
  legend: {
    backgroundColor: "white",
    width: "100%",
    height: "50px",
    margin: "10px 0px 10px 0px",
    background: "#FFFFFF",
    border: "1px solid #EAEDF3",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    padding: "0px 30px",
    "& .ant-badge-status-dot": {
      width: "12px",
      height: "12px",
    },
    "&. ant-badge-status-text": {
      fontWeight: "500pX",
    },
  },
  calenderHeader: {
    backgroundColor: "white",
    width: "100%",
    height: "80px",
    marginTop: "10px",
    background: "#FFFFFF",
    borderTop: "1px solid #EAEDF3",
    borderLeft: "1px solid #EAEDF3",
    borderRight: "1px solid #EAEDF3",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "& > p": {
      margin: "0px 10px 0px 10px",
      fontWeight: "bold",
      fontSize: "20px",
    },
    "& .month": {
      textAlign: "center",
      width: "200px",
    },
    "& > button": {
      margin: "0px",
      boxShadow: "none",
      border: "none",
    },
  },
  calender: (props) => ({
    boxSizing: "border-box",
    height: "845px",
    "&.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date": {
      boxSizing: "border-box",
      margin: "0px",
      borderTop: "0px",
    },
    "&.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th": {
      textAlign: "center",
      fontWeight: "500",
      textTransform: "uppercase",
      fontSize: "17px",
      padding: "5px 0px 10px 0px",
      border: "1px solid #EAEDF3",
    },
    "&.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value":
      {
        fontWeight: "bold",
        fontSize: "20px",
        margin: "10px 10px 10px 0px",
      },
    "&.ant-picker-calendar .ant-picker-panel .ant-picker-body": {
      padding: "0px",
    },
    "&.ant-picker-calendar-full .ant-picker-panel .ant-picker-body .ant-picker-content  tbody":
      {
        "& > tr": {
          [`& td.ant-picker-cell[title^='${props.currentMonth}']`]: {
            visibility: "visible",
            border: "1px solid #EAEDF3",
          },
          [`& td.ant-picker-cell[title^='${props.previousMonth}'],  & td.ant-picker-cell[title^='${props.nextMonth}']`]:
            {
              visibility: "hidden",
              border: "1px solid #EAEDF3",
            },
        },
      },
    "&.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content":
      {
        position: "relative",
      },
    "&.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value":
      {
        color: "#000000",
      },
    "&.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today":
      {
        background: "#FFFFFF",
      },
    "&.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner":
      {
        background: "#FFFFFF",
      },
    "&.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date":
      {
        background: "#F5F5F5",
      },
  }),
  content: {
    margin: "0",
    padding: "0",
    listStyle: "none",
    position: "absolute",
    bottom: "0",
    width: "100%",
    "& > button": {
      width: "100%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      margin: "0px 0px 5px 0px",
      textAlign: "left",
      borderRadius: "5px",
      color: "#005794",
      fontWeight: "500",
      fontSize: "15px",
      display: "flex",
      lineHeight: "16px",
      alignItems: "center",
      "&.ant-btn.next": {
        color: "#FFFFFF",
        background: "#FF7E32",
        textTransform: "none",
        borderColor: "1px solid #EAEDF3",
      },
    },
  },
  popOver: {
    width: "177px",
    alignContent: "left",
    "& .ant-popover-inner .ant-popover-inner-content": {
      padding: "0px",
      "& > button": {
        width: "100%",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        margin: "0px",
        textAlign: "left",
        borderRadius: "5px",
        color: "#005794",
        fontWeight: "500",
        fontSize: "15px",
        display: "flex",
        lineHeight: "16px",
        alignItems: "center",
        "&.ant-btn.next": {
          color: "#FFFFFF",
          background: "#FF7E32",
          textTransform: "none",
          borderColor: "1px solid #EAEDF3",
        },
      },
    },
  },
}));

const CalendarLayout: React.FC<CalendarLayoutProps> = ({
  currentValue,
  defects,
  nextMonth,
  previousMonth,
  handleChangeMonth,
}) => {
  const classes = useStyles({
    previousMonth: previousMonth
      .toISOString()
      .slice(0, 10)
      .toString()
      .substring(0, 7),
    currentMonth: currentValue
      .toISOString()
      .slice(0, 10)
      .toString()
      .substring(0, 7),
    nextMonth: nextMonth.toISOString().slice(0, 10).toString().substring(0, 7),
  });

  const { t: translation } = useTranslation();

  const dateCellRender = (value: any) => {
    const listData = getListData(value);
    return (
      <ul className={classes.content}>
        {listData.length <= 2 &&
          listData.map((item) => (
            <InspectionDetailsButton
              buttonText={item.content}
              className={item.type}
              detail={item.detail}
            />
          ))}
        {listData.length > 2 && (
          <>
            <InspectionDetailsButton
              buttonText={listData[0].content}
              className={listData[0].type}
              detail={listData[0].detail}
            />
            <Popover
              overlayClassName={classes.popOver}
              content={listData.map((item) => (
                <InspectionDetailsButton
                  buttonText={item.content}
                  className={item.type}
                  detail={item.detail}
                />
              ))}
              placement="right"
            >
              <Button>+{listData.length - 1} more items</Button>
            </Popover>
          </>
        )}
      </ul>
    );
  };

  const getListData = (value: any) => {
    let listData: {
      type: string;
      content: string;
      detail: Defect;
    }[] = [];
    if (defects) {
      if (
        defects.some(
          (defect) => defect.nextInspectionDate === value.format("YYYY-MM-DD")
        )
      ) {
        let defectsOnThatDate = defects.filter(
          (defect) => defect.nextInspectionDate === value.format("YYYY-MM-DD")
        );
        defectsOnThatDate.forEach((defect) =>
          listData.push({
            type: "next",
            content: `${translation("app.common.defectNo")} ${defect.no}`,
            detail: defect,
          })
        );
      }
    }
    return listData || [];
  };

  return (
    <>
      <div className={classes.legend}>
        <Badge
          color="orange"
          text={translation("inspectors.calendar.nextInspection")}
          className="badge"
        />
      </div>
      <div className={classes.calenderHeader}>
        <Button onClick={(e) => handleChangeMonth(-1, currentValue)}>
          <LeftOutlined />
        </Button>
        <p className="month">
          {currentValue.toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
        </p>
        <Button onClick={(e) => handleChangeMonth(1, currentValue)}>
          <RightOutlined />
        </Button>
      </div>
      <Calendar
        className={classes.calender}
        headerRender={() => null}
        dateCellRender={dateCellRender}
        value={moment(currentValue)}
      ></Calendar>
    </>
  );
};

export default CalendarLayout;
