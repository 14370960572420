import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { CheckCircleTwoTone } from "@ant-design/icons";
import { Col, Row, Tooltip, Tree } from "antd";
import { groupBy, keyBy } from "lodash";

import useAppContext from "../../../../hooks/useAppContext";
import { Subcontractor } from "../../../../services/app";
import {
  ReminderType,
  SubcontractorSubscription,
} from "../../../../services/email";
import themeConfig from "../../../../theme.config";
import { reminderTypeList } from "../helper";
import { tabKey } from "../TabBar/TabBar";

const useStyles = createUseStyles((theme) => ({
  tree: {
    background: theme.palette.backgroundLight,
    padding: "16px 40px",
    height: "calc(100vh - 85px)",
    "& .ant-tree-node-content-wrapper .ant-tree-iconEle": {
      width: "100%",
    },
  },
}));

interface EmailNotificationMenuProps {
  selectedKey: React.Key[];
  setSelectedKey: (value: React.Key[]) => void;
  setActiveTabKey: (value: tabKey) => void;
  setCurrentSubcontractor: (value: Subcontractor | undefined) => void;
  allReminderSettings: SubcontractorSubscription[];
  allSubscribeList: ReminderType[];
}

const EmailNotificationMenu: React.FC<EmailNotificationMenuProps> = ({
  selectedKey,
  setSelectedKey,
  setActiveTabKey,
  setCurrentSubcontractor,
  allReminderSettings,
  allSubscribeList,
}) => {
  const classes = useStyles();
  const { t: translation } = useTranslation();
  const { subcontractors } = useAppContext();

  const [allSubscribeToolTip, setAllSubscribeToolTip] = useState<string>("");
  const [subscriptions, setSubscriptions] = useState<
    Record<number, SubcontractorSubscription[]>
  >([]);

  const reminderTypeMap = useMemo(() => keyBy(reminderTypeList, "value"), []);

  const handleSelectSubcontractor = async (
    selectedKeys: React.Key[],
    e: any
  ) => {
    const selectedSubcontractor = e.selectedNodes?.[0];
    if (selectedSubcontractor?.children) {
      setCurrentSubcontractor(undefined);
    } else {
      setCurrentSubcontractor(selectedSubcontractor);
    }
    setActiveTabKey("setup");
    if (selectedKeys.length) {
      setSelectedKey(selectedKeys);
    } else {
      setSelectedKey(["0"]);
    }
  };

  useEffect(() => {
    const newSubscriptions = groupBy(allReminderSettings, "subcon.id");
    setSubscriptions(newSubscriptions);
  }, [allReminderSettings]);

  useEffect(() => {
    let newToolip = "";
    allSubscribeList.forEach((reminderType, idx) => {
      newToolip = newToolip + reminderTypeMap[reminderType].label;
      if (idx !== allSubscribeList.length - 1) {
        newToolip = newToolip + ", ";
      }
    });
    setAllSubscribeToolTip(newToolip);
  }, [allSubscribeList, reminderTypeMap]);

  return (
    <div className={classes.tree}>
      <Tree
        showIcon
        defaultSelectedKeys={selectedKey}
        checkedKeys={selectedKey}
        expandedKeys={["0"]}
        autoExpandParent={true}
        defaultExpandAll={true}
        onSelect={(selectedKeys, e) =>
          handleSelectSubcontractor(selectedKeys, e)
        }
        treeData={[
          {
            title: "",
            key: "0",
            icon: (
              <Row align={"middle"} gutter={8}>
                <Col>{translation("app.common.subcontractors")}</Col>
                {allSubscribeToolTip && (
                  <Col>
                    <Tooltip title={allSubscribeToolTip}>
                      <CheckCircleTwoTone
                        twoToneColor={themeConfig.palette.success}
                      />
                    </Tooltip>
                  </Col>
                )}
              </Row>
            ),
            children: subcontractors.map((subcontractor) => {
              let subscriptionTooltip = "";
              const subcontractorSubscription =
                subscriptions?.[subcontractor.id];
              subcontractorSubscription?.forEach((reminder, idx) => {
                subscriptionTooltip =
                  subscriptionTooltip +
                  reminderTypeMap[reminder.reminderType].label;
                if (idx !== subcontractorSubscription.length - 1) {
                  subscriptionTooltip = subscriptionTooltip + ", ";
                }
              });
              return {
                ...subcontractor,
                title: ``,
                icon: (
                  <Row align={"middle"} gutter={8}>
                    <Col>{subcontractor?.name}</Col>
                    {subscriptions[subcontractor.id] ? (
                      <Col>
                        <Tooltip title={subscriptionTooltip}>
                          <CheckCircleTwoTone
                            twoToneColor={themeConfig.palette.success}
                          />
                        </Tooltip>
                      </Col>
                    ) : null}
                  </Row>
                ),
                key: subcontractor?.id.toString(),
              };
            }),
          },
        ]}
      />
    </div>
  );
};

export default EmailNotificationMenu;
