import React, { useEffect, useState } from "react";

import useAppContext from "../../../hooks/useAppContext";
import { Defect, getDefects } from "../../../services/defect";
import { User } from "../../../services/app";
import CalendarLayout from "./CalendarLayout";

interface CalendarContainerProps {
  inspector: User | undefined;
}

const CalendarContainer: React.FC<CalendarContainerProps> = ({ inspector }) => {
  const appContext = useAppContext();
  const { serviceConfig, routeParams } = appContext;
  const { entityId, locationGroupId } = routeParams;

  const today = new Date();
  const fixedDate = new Date(today.getFullYear(), today.getMonth(), 15);
  const [currentValue, setCurrentValue] = useState<Date>(fixedDate);
  const [defects, setDefects] = useState<Defect[]>();
  const [nextMonth, setNextMonth] = useState<Date>(
    new Date(
      fixedDate.getFullYear(),
      fixedDate.getMonth() + 1,
      fixedDate.getDate()
    )
  );
  const [previousMonth, setPreviousMonth] = useState<Date>(
    new Date(
      fixedDate.getFullYear(),
      fixedDate.getMonth() - 1,
      fixedDate.getDate()
    )
  );

  const handleChangeMonth = (monthDifference: number, currentValue: Date) => {
    if (monthDifference === 1) {
      currentValue.setMonth(currentValue.getMonth() + 1);
    } else if (monthDifference === -1) {
      currentValue.setMonth(currentValue.getMonth() - 1);
    }
    previousMonth!.setFullYear(
      currentValue.getFullYear(),
      currentValue.getMonth() - 1,
      currentValue.getDate()
    );
    nextMonth!.setFullYear(
      currentValue.getFullYear(),
      currentValue.getMonth() + 1,
      currentValue.getDate()
    );
    setCurrentValue(new Date(currentValue.getTime()));
    setPreviousMonth(new Date(previousMonth.getTime()));
    setNextMonth(new Date(nextMonth.getTime()));
  };

  useEffect(() => {
    getDefects(
      entityId,
      locationGroupId,
      `?responsiblePerson=${inspector!.id}`,
      "no",
      "asc",
      0,
      0,
      serviceConfig
    ).then((response) => setDefects(response));
  }, [entityId, locationGroupId, serviceConfig, inspector]);

  return (
    <CalendarLayout
      currentValue={currentValue}
      defects={defects!}
      nextMonth={nextMonth!}
      previousMonth={previousMonth!}
      handleChangeMonth={handleChangeMonth}
    />
  );
};

export default CalendarContainer;
