import React from "react";
import { createUseStyles } from "react-jss";
import { Pie } from "@ant-design/charts";
import { Card } from "antd";
import { useTranslation } from "react-i18next";

interface ByDefectTypeLayoutProps {
  byDefectTypeData: {
    type: string;
    value: number;
  }[];
}

const colorLibrary = [
  "#28A745",
  "#1C7530",
  "#9EA0A5",
  "#BAE4EA",
  "#17A2B8",
  "#107281",
  "#EA868F",
  "#DC3545",
  "#9A2530",
  "#ADD3B6",
  "#6699FF",
  "#89A5DC",
  "#CFDC89",
  "#777980",
  "#E6AC00",
];

const useStyles = createUseStyles(() => ({
  chartCard: {
    textAlign: "center",
    width: "500px",
    margin: "10px 10px 0px 0px",
    height: "fit-content",
    "& h1": {
      margin: "0px",
    },
  },
  legend: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    padding: "0px 20px 0px 20px",
    "& p": {
      textAlign: "left",
      padding: "0px 0px 0px 15px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "16px",
      margin: "0px",
    },
    "& .legend-item": {
      margin: "15px 10px 0px 0px",
      display: "inline-flex",
    },
    "& .legend-color": {
      width: "20px",
      height: "20px",
      minWidth: "20px",
      minHeight: "20px",
    },
  },
}));

const ByDefectTypeLayout: React.FC<ByDefectTypeLayoutProps> = ({
  byDefectTypeData,
}) => {
  const { t: translation } = useTranslation();

  const classes = useStyles();

  let colors = colorLibrary.slice(0, byDefectTypeData.length);

  return (
    <Card className={classes.chartCard} key="By Defect Type">
      <h1>
        {translation("dashboard.defectAnalysis.byDefectType.byDefectType")}
      </h1>
      <Pie
        appendPadding={10}
        data={byDefectTypeData}
        angleField="value"
        colorField="type"
        color={colors}
        radius={0.85}
        legend={false}
        label={{
          type: "inner",
          autoRotate: false,
          style: {
            fontSize: 20,
            textAlign: "center",
          },
        }}
        interactions={[{ type: "element-active" }]}
      />
      <div className={classes.legend}>
        {byDefectTypeData.map((item, index) => (
          <div key={index} className="legend-item">
            <div
              className="legend-color"
              style={{ background: colors[index] }}
            ></div>
            <p>{item.type}</p>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default ByDefectTypeLayout;
