import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpFormItem,
  NxpHeader,
  NxpTable,
  NxpTableEditable,
  sorterForNumber,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { ColumnProps } from "antd/lib/table";
import * as yup from "yup";

import useAppContext from "../../hooks/useAppContext";
import { useValidate } from "../../hooks/useValidate";
import { Subcontractor } from "../../services/app";
import {
  deleteSubcontractor,
  updateSubcontractor,
} from "../../services/subcontractor";
import notify from "../../utils/notify";
import AddSubcontractorButton from "./AddSubcontractor/AddSubcontractorModalButton";

interface SubcontractorTableProps {}

const SubcontractorTable: React.FC<SubcontractorTableProps> = () => {
  const appContext = useAppContext();
  const {
    serviceConfig,
    subcontractors,
    routeParams,
    errorHandler,
    hasRight,
    onAppContextCacheItemUpdate,
  } = appContext;
  const { entityId } = routeParams;

  const { t: translation } = useTranslation();

  const [editForm, setEditForm] = useState<Subcontractor>();

  const [saveInProgress, setSaveInProgress] = useState(false);

  const formSchema = yup.object().shape({
    id: yup.number(),
    name: yup.string().required(translation("app.common.nameIsRequired")),
    primaryContactPerson: yup
      .string()
      .required(translation("app.common.primaryContactPersonIsRequired")),
    primaryContactEmail: yup
      .string()
      .email()
      .required(translation("app.common.primaryContactEmailIsRequired")),
  });

  const handleSaveValidated = async () => {
    setSaveInProgress(true);
    const subcon = { ...editForm! };
    try {
      await updateSubcontractor(entityId!, subcon, serviceConfig);
      onAppContextCacheItemUpdate(
        "subcontractors",
        subcontractors.map((item) => (item.id !== subcon.id ? item : subcon))
      );
      setEditForm(undefined);
      notify.actionCompleted();
    } catch (ex) {
      if (
        ex instanceof Error &&
        ex.message.includes("duplicate key value violates unique constraint")
      ) {
        notify.error(
          translation("subcontractorList.handleSaveValidated.error")
        );
      } else {
        errorHandler(
          ex,
          translation("subcontractorList.handleSaveValidated.error")
        );
      }
    } finally {
      setSaveInProgress(false);
    }
  };

  const [validationError, , clearError, saveWithValidate] =
    useValidate<Subcontractor>(editForm, formSchema, handleSaveValidated);

  const handleRowEdit = useCallback(
    (editRowItem: Subcontractor) => setEditForm({ ...editRowItem }),
    []
  );

  const handleRowSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  const handleRowCancel = useCallback(() => {
    setEditForm(undefined);
    clearError();
  }, [clearError]);

  const handleRowDelete = useCallback(
    async (deleteRowItem: Subcontractor) => {
      setSaveInProgress(true);
      try {
        await deleteSubcontractor(entityId!, deleteRowItem.id, serviceConfig);
        onAppContextCacheItemUpdate(
          "subcontractors",
          subcontractors.filter((item) => item.id !== deleteRowItem.id)
        );
        notify.actionCompleted();
        setEditForm(undefined);
      } catch (e) {
        notify.error(e);
      }
      setSaveInProgress(false);
    },
    [entityId, onAppContextCacheItemUpdate, serviceConfig, subcontractors]
  );

  const columns: ColumnProps<Subcontractor>[] = [
    {
      title: translation("subcontractorList.id"),
      dataIndex: "id",
      width: 150,
      sorter: (a: Subcontractor, b: Subcontractor) =>
        sorterForNumber(a.id, b.id),
    },
    {
      title: translation("app.common.name"),
      dataIndex: "name",
      width: 150,
      sorter: (a: Subcontractor, b: Subcontractor) =>
        sorterForString(a.name, b.name),
      render: (_, record) => {
        const editing = editForm?.id === record.id;
        return (
          <NxpFormItem
            editing={editing}
            controlType="input"
            error={validationError.name}
            controlProps={{
              value: editing ? editForm?.name : record.name,
              onChange: (e) =>
                setEditForm((prevState) => ({
                  ...prevState!,
                  name: e.target.value,
                })),
            }}
          />
        );
      },
    },
    {
      title: translation("app.common.primaryContactPerson"),
      dataIndex: "primaryContactPerson",
      width: 150,
      render: (_, record) => {
        const editing = editForm?.id === record.id;
        return (
          <NxpFormItem
            editing={editing}
            controlType="input"
            error={validationError.primaryContactPerson}
            controlProps={{
              value: editing
                ? editForm?.primaryContactPerson
                : record.primaryContactPerson,
              onChange: (e) =>
                setEditForm((prevState) => ({
                  ...prevState!,
                  primaryContactPerson: e.target.value,
                })),
            }}
          />
        );
      },
    },
    {
      title: translation("app.common.primaryContactEmail"),
      dataIndex: "primaryContactEmail",
      width: 150,
      render: (_, record) => {
        const editing = editForm?.id === record.id;
        return (
          <NxpFormItem
            editing={editing}
            controlType="input"
            error={
              validationError.primaryContactEmail
                ? translation("app.common.primaryContactEmailMustBeAValidEmail")
                : ""
            }
            controlProps={{
              value: editing
                ? editForm?.primaryContactEmail
                : record.primaryContactEmail,
              onChange: (e) =>
                setEditForm((prevState) => ({
                  ...prevState!,
                  primaryContactEmail: e.target.value || undefined,
                })),
            }}
          />
        );
      },
    },
    {
      title: translation("app.common.address"),
      dataIndex: "address",
      width: 300,
      render: (_, record) => {
        const editing = editForm?.id === record.id;
        return (
          <NxpFormItem
            editing={editing}
            controlType="input"
            error={validationError.address}
            controlProps={{
              value: editing ? editForm?.address : record.address,
              onChange: (e) =>
                setEditForm((prevState) => ({
                  ...prevState!,
                  address: e.target.value,
                })),
            }}
          />
        );
      },
    },
    {
      title: translation("app.common.smsAreaCode"),
      dataIndex: "smsAreaCode",
      width: 150,
      render: (_, record) => {
        const editing = editForm?.id === record.id;
        return (
          <NxpFormItem
            editing={editing}
            controlType="input"
            controlProps={{
              value: editing ? editForm?.smsAreaCode : record.smsAreaCode,
              onChange: (e) =>
                setEditForm((prevState) => ({
                  ...prevState!,
                  smsAreaCode: e.target.value,
                })),
            }}
          />
        );
      },
    },
    {
      title: translation("app.common.smsNo"),
      dataIndex: "smsNo",
      width: 150,
      render: (_, record) => {
        const editing = editForm?.id === record.id;
        return (
          <NxpFormItem
            editing={editing}
            controlType="input"
            controlProps={{
              value: editing ? editForm?.smsNo : record.smsNo,
              onChange: (e) =>
                setEditForm((prevState) => ({
                  ...prevState!,
                  smsNo: e.target.value,
                })),
            }}
          />
        );
      },
    },
  ];

  return (
    <div>
      <NxpHeader
        titleContent={translation("subcontractorList.subcontractorList")}
        actionContent={
          hasRight("subcontractor-add", "module") && <AddSubcontractorButton />
        }
      />
      {hasRight("subcontractor-view") &&
        (hasRight("subcontractor-edit", "module") ? (
          <NxpTableEditable
            dataSource={subcontractors}
            rowKey="id"
            columns={columns}
            useDefaultHeight
            editItem={editForm}
            saveInProgress={saveInProgress}
            itemCompareKey="id"
            hideDelete={!hasRight("subcontractor-delete", "module")}
            onRowDelete={handleRowDelete}
            onRowEdit={handleRowEdit}
            onRowSave={handleRowSave}
            onRowCancel={handleRowCancel}
          />
        ) : (
          <NxpTable
            dataSource={subcontractors}
            rowKey="id"
            columns={columns}
            useDefaultHeight
          />
        ))}
    </div>
  );
};

export default SubcontractorTable;
