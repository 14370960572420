import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import Dashboard from "../../../pages/Dashboard";
import DefectDetails from "../../../pages/DefectDetails";
import DefectListing from "../../../pages/DefectListing";
import Inspectors from "../../../pages/Inspectors";
import LocationDetails from "../../../pages/LocationDetails";
import LocationGroupSelection from "../../../pages/LocationGroupSelection";
import ReportMenu from "../../../pages/ReportMenu";
import RightSetting from "../../../pages/RightSetting";
import RoleSetting from "../../../pages/RoleSetting";
import Cause from "../../../pages/Settings/Cause";
import Checklist from "../../../pages/Settings/Checklist";
import DefectGroup from "../../../pages/Settings/DefectGroup";
import DefectType from "../../../pages/Settings/DefectType";
import EmailNotification from "../../../pages/Settings/EmailNotification";
import InspectionType from "../../../pages/Settings/InspectionType";
import Parameters from "../../../pages/Settings/Parameters";
import SubcontractorUser from "../../../pages/Settings/SubcontractorUser";
import Subject from "../../../pages/Settings/Subject";
import Team from "../../../pages/Settings/Team";
import TeamMembers from "../../../pages/Settings/TeamMembers";
import SplashScreen from "../../../pages/SplashScreen";
import SubcontractorList from "../../../pages/SubcontractorList";
import SubcontractorView from "../../../pages/SubcontractorView";
import SubcontractorSummaryReport from "../../../pages/SubcontractorView/SubcontractorReportPage";
//import { Nxp404 } from "@nexploretechnology/nxp-ui";
import AppProtectedRoute from "./AppProtectedRoute";

interface AppRouterProps {}

const AppRouter: React.FC<AppRouterProps> = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to="/entities" />
        </Route>
        <Redirect
          exact
          from="/entities"
          to="/entities/c7d71adb-71f0-4e15-bdc7-0f1c0d00922d"
        />
        <Redirect
          exact
          from="/entities/:entityId"
          to="/entities/:entityId/location-groups"
        />
        <Redirect
          exact
          from="/entities/:entityId/location-groups"
          to="/entities/:entityId/location-groups/select"
        />
        <AppProtectedRoute
          exact
          path="/entities/:entityId/location-groups/select"
        >
          <LocationGroupSelection />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path="/entities/:entityId/location-groups/:locationGroupId"
        >
          <LocationGroupSelection />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path="/entities/:entityId/location-groups/:locationGroupId/subcontractors/:subcontractorId"
        >
          <SubcontractorView />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path="/entities/:entityId/location-groups/:locationGroupId/subcontractor/:subcontractorId/report/:reportType"
        >
          <SubcontractorSummaryReport />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path="/entities/:entityId/location-groups/:locationGroupId/dashboard"
        >
          <Dashboard />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path="/entities/:entityId/location-groups/:locationGroupId/subcontractors"
        >
          <SubcontractorView />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path="/entities/:entityId/location-groups/:locationGroupId/home"
        >
          <SplashScreen />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path="/entities/:entityId/location-groups/:locationGroupId/locations"
        >
          <LocationDetails />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path="/entities/:entityId/location-groups/:locationGroupId/locations/:locationId"
        >
          <LocationDetails />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path="/entities/:entityId/location-groups/:locationGroupId/defects/:defectId"
        >
          <DefectDetails />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path="/entities/:entityId/location-groups/:locationGroupId/defects"
        >
          <DefectListing />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path="/entities/:entityId/location-groups/:locationGroupId/inspectors"
        >
          <Inspectors />
        </AppProtectedRoute>
        <AppProtectedRoute exact path="/entities/:entityId/settings/cause">
          <Cause />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path="/entities/:entityId/settings/inspection-type"
        >
          <InspectionType />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path="/entities/:entityId/settings/defect-type"
        >
          <DefectType />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path="/entities/:entityId/settings/defect-group"
        >
          <DefectGroup />
        </AppProtectedRoute>
        <AppProtectedRoute exact path="/entities/:entityId/settings/subject">
          <Subject />
        </AppProtectedRoute>
        <AppProtectedRoute exact path="/entities/:entityId/settings/checklist">
          <Checklist />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path="/entities/:entityId/settings/subcontractor-user"
        >
          <SubcontractorUser />
        </AppProtectedRoute>
        {/* <AppProtectedRoute
          exact
          path="/entities/:entityId/location-groups/:locationGroupId/defects/:defectId"
        >
          <DefectDetails />
        </AppProtectedRoute> */}
        <AppProtectedRoute
          exact
          path="/entities/:entityId/settings/email-notification"
        >
          <EmailNotification />
        </AppProtectedRoute>
        <AppProtectedRoute exact path="/entities/:entityId/settings/parameters">
          <Parameters />
        </AppProtectedRoute>
        <AppProtectedRoute exact path="/entities/:entityId/settings/team">
          <Team />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path="/entities/:entityId/settings/team-members"
        >
          <TeamMembers />
        </AppProtectedRoute>
        <AppProtectedRoute exact path="/entities/:entityId/subcontractors">
          <SubcontractorList />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path="/entities/:entityId/location-groups/:locationGroupId/reports"
        >
          <ReportMenu />
        </AppProtectedRoute>
        <AppProtectedRoute exact path="/entities/:entityId/roles">
          <RoleSetting />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path="/entities/:entityId/location-groups/:locationGroupId/roles"
        >
          <RoleSetting />
        </AppProtectedRoute>
        <AppProtectedRoute exact path="/entities/:entityId/rights">
          <RightSetting />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path="/entities/:entityId/location-groups/:locationGroupId/rights"
        >
          <RightSetting />
        </AppProtectedRoute>
        {/* <AppProtectedRoute>
          <Nxp404 />
        </AppProtectedRoute> */}
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
