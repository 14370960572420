import {
  apiRequest,
  CustomApiErrorServerResponseData,
  ServiceConfig,
} from "../utils/backend";
import CustomApiError from "../utils/backend/customApiError";
import fetchWithTimeout from "../utils/backend/extendFetchApiTime";
import { reviveJsonDateTime } from "../utils/date/jsonDateTime";
import { Location, File, Enum, User } from "./app";

export interface Plan {
  id: number;
  inspectionType: Enum;
  inspector: User;
  locations: Location[];
  description: string;
  startDate: Date;
  endDate: Date;
  status: "open" | "closed" | "planned" | "cancelled";
  planningId: string;
  constructionLotNo: string;
  jointInspectionId: string;
  checkItems: Record<string, any>[];
  attachments: File[];
}

export const getPlans = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Plan[]>(
    `/entities/${entityId}/location-groups/${locationGroupId}/plans`,
    serviceConfig
  );
};
export const uploadFloorPlan = async (
  entityId: string | undefined,
  file: FormData,
  serviceConfig: ServiceConfig
) => {
  const upload_data = {
    headers: {
      Authorization: `Bearer ${serviceConfig.token}` || "",
    },
    method: "POST",
    body: file,
  };
  const url = `/entities/${entityId}/files`;
  const path =
    url.startsWith("http://") || url.startsWith("https://")
      ? url
      : serviceConfig.apiBaseUrl + url;
  const response = await fetchWithTimeout(path, {
    ...upload_data,
    timeout: 3600000, // one hour
  });
  if (!response.ok) {
    let errorJson: CustomApiErrorServerResponseData | undefined = undefined;
    try {
      errorJson = await response.json();
    } catch {
      // nothing to do
    }
    throw new CustomApiError(
      url,
      upload_data,
      response.status,
      response.statusText,
      errorJson,
      undefined,
      0,
      undefined,
      true // stop retry for defined api server error
    );
  } else {
    const respText = await response.text();
    if (respText.trim() === "") {
      return null as any;
    }
    const photos = JSON.parse(respText, reviveJsonDateTime);
    return photos;
  }
};
export const linkUpFloorPlan = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  locationId: number | undefined,
  file: number,
  serviceConfig: ServiceConfig
) =>
  await apiRequest<any>(
    `/entities/${entityId}/location-groups/${locationGroupId}/locations/${locationId}/floor-plans`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        file,
      }),
    }
  );

export const updateFloorPlanArea = async (
  entityId: string | undefined,
  locationId: number | undefined,
  locationGroupId: number | undefined,
  floorPlanId: number,
  areas: any[],
  serviceConfig: ServiceConfig
) =>
  await apiRequest<Location>(
    `/entities/${entityId}/location-groups/${locationGroupId}/locations/${locationId}/floor-plans/${floorPlanId}/floor-plan-areas`,
    serviceConfig,
    {
      method: "PUT",
      body: JSON.stringify({ areas }),
    }
  );

export const deleteFloorPlanArea = async (
  entityId: string | undefined,
  locationId: number | undefined,
  locationGroupId: number | undefined,
  serviceConfig: ServiceConfig
) =>
  await apiRequest(
    `/entities/${entityId}/location-groups/${locationGroupId}/locations/${locationId}/floor-plans`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );

export const deleteFloorPlan = async (
  entityId: string,
  locationId: number,
  locationGroupId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/entities/${entityId}/location-groups/${locationGroupId}/locations/${locationId}/floor-plans`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};

export const generateAutoImage = async (
  entityId: string | undefined,
  locationGroupId: number | undefined,
  locationId: number | undefined,
  serviceConfig: ServiceConfig
) =>
  await apiRequest(
    `/entities/${entityId}/location-groups/${locationGroupId}/locations/${locationId}/floor-plans/auto`,
    serviceConfig,
    {
      method: "POST",
    }
  );

export const generateAutoFloorPlan = async (
  entityId: string,
  locationId: number,
  locationGroupId: number,
  serviceConfig: ServiceConfig
) =>
  await apiRequest(
    `/entities/${entityId}/location-groups/${locationGroupId}/locations/${locationId}/floor-plans/auto`,
    serviceConfig,
    {
      method: "POST",
    }
  );
