import React, { useEffect, useState } from "react";

import { Defect } from "../../../services/defect";

import DefectSummaryLayout from "./DefectSummaryLayout";

import { useTranslation } from "react-i18next";

interface DefectSummaryContainerProps {
  defects: Defect[];
}

const DefectSummaryContainer: React.FC<DefectSummaryContainerProps> = ({
  defects,
}) => {
  const { t: translation } = useTranslation();

  const initialDefectSummary: { key: string; title: string; value: string }[] =
    [
      {
        key: "Total Defects",
        title: translation("app.common.totalDefects"),
        value: "0",
      },
      {
        key: "Fixed",
        title: translation("app.common.fixed"),
        value: "0",
      },
      {
        key: "Outstanding",
        title: translation("app.common.outstanding"),
        value: "0",
      },
      {
        key: "Overdue",
        title: translation("app.common.overdue"),
        value: "0",
      },
    ];

  const [defectSummary, setDefectSummary] = useState(initialDefectSummary);

  useEffect(() => {
    initialDefectSummary[0].value = defects.length.toString();

    initialDefectSummary[1].value = defects
      .filter((defect) => defect?.status === "closed")
      .length.toString();

    initialDefectSummary[2].value = defects
      .filter(
        (defect) =>
          defect?.status !== "closed" &&
          defect?.status !== "cancelled" &&
          new Date(defect.dueDate) >= new Date()
      )
      .length.toString();

    initialDefectSummary[3].value = defects
      .filter(
        (defect) =>
          defect?.status !== "closed" &&
          defect?.status !== "cancelled" &&
          new Date(defect.dueDate) <= new Date()
      )
      .length.toString();

    setDefectSummary([...initialDefectSummary]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defects]);

  return <DefectSummaryLayout defectSummary={[...defectSummary]} />;
};

export default DefectSummaryContainer;
