export interface ReminderSetting {
  id: number | undefined;
  subconId: number;
  isChecked: boolean;
  isDaily: boolean;
  isCcSubconUser: boolean;
  isAttachDefectReport: boolean;
}

export type FieldName =
  | "isChecked"
  | "isDaily"
  | "isCcSubconUser"
  | "isAttachDefectReport";

export const reminderTypeList = [
  { label: "Due Date", value: "dueDate" },
  { label: "New Defect", value: "newDefect" },
];
