import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import AddCauseModal from "./AddCauseModal";

interface AddCauseButtonProps {
  handleAddToList: (item: any) => void;
}

const AddCauseButton: React.FC<AddCauseButtonProps> = ({ handleAddToList }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const handleModalVisible = () => {
    setModalVisible(false);
  };

  const { t: translation } = useTranslation();

  return (
    <>
      <NxpButton onClick={() => setModalVisible(true)}>
        {translation("settings.cause.addCause.addDefectCause")}
      </NxpButton>
      <AddCauseModal
        handleAddToList={handleAddToList}
        modalVisible={modalVisible}
        handleModalVisible={handleModalVisible}
      />
    </>
  );
};

export default AddCauseButton;
