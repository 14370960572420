import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { NxpTabs, TabPane } from "@nexploretechnology/nxp-ui";

export type tabKey = "setup" | "logs";
interface Props {
  activeTabKey: tabKey;
  onChange: (activeTabKey: tabKey) => void;
}

const useStyles = createUseStyles((theme) => ({
  locationTabBar: {
    "& .ant-tabs-nav": {
      "& .ant-tabs-nav-wrap": {
        "& .ant-tabs-ink-bar-animated": {
          transition: "width 0.3s",
        },
      },
    },
  },
}));

const TabBar: React.FC<Props> = ({ activeTabKey, onChange }) => {
  // const { hasRight } = useAppContext();
  const classes = useStyles();

  const { t: translation } = useTranslation();

  return (
    <NxpTabs
      className={classes.locationTabBar}
      key="email-notification"
      activeKey={activeTabKey}
      defaultActiveKey={activeTabKey}
      onChange={onChange as (activeTabKey: string) => void}
    >
      {<TabPane tab={translation("app.common.setup")} key="setup" />}
      {
        <TabPane
          tab={translation("settings.emailNotification.tabBar.logs")}
          key="logs"
        />
      }
    </NxpTabs>
  );
};

export default TabBar;
