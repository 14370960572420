import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpAuthSpinner } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../hooks/useAppContext";
import { File } from "../../services/app";
import {
  addInspection,
  addResponse,
  attachFileToMessage,
  cancelDefect,
  closeDefect,
  Defect,
  DefectMessage,
  detechFileFromMessage,
  findOneDefect,
  updateInspection,
  updateResponse,
} from "../../services/defect";
import notify from "../../utils/notify";
import DefectDetailsLayout from "./DefectDetailsLayout";
import { AddInspectionGridForm } from "./Inspection/AddInspection/AddInspectionModal";
import { AddResponseGridForm } from "./Inspection/AddResponse/AddResponseModal";
import { CancelDefectGridForm } from "./Inspection/CancelDefect/CancelDefectModal";
import { CloseOutGridForm } from "./Inspection/CloseOut/CloseOutModal";
import { Inspection } from "./Inspection/InspectionList/InspectionList";

interface DefectDetailsContainerProps {}

interface DefectDetailsContextType {
  defect: Defect;
  activeTab: string;
  setActiveTab: (activeTab: string) => void;
  onAddInspectionSubmit: (form: AddInspectionGridForm) => Promise<boolean>;
  onAddResponseSubmit: (form: AddResponseGridForm) => Promise<boolean>;
  onCloseOutSubmit: (form: CloseOutGridForm) => Promise<boolean>;
  onCancelSubmit: (form: CancelDefectGridForm) => Promise<boolean>;
  onMessageEditSubmit: (form: Inspection) => Promise<boolean>;
  onFetchingDefect: () => void;
  onMessageAttachmentAdd: (
    message: DefectMessage,
    file: File
  ) => Promise<boolean>;
  onMessageAttachmentRemove: (
    message: DefectMessage,
    file: File
  ) => Promise<boolean>;
  loading: boolean;
  onProgessing: (status: boolean) => void;
}

export const DefectDetailsContext =
  React.createContext<DefectDetailsContextType>({} as DefectDetailsContextType);

const DefectDetailsContainer: React.FC<DefectDetailsContainerProps> = () => {
  const appContext = useAppContext();
  const { routeParams, serviceConfig } = appContext;
  const { entityId, locationGroupId, defectId } = routeParams;

  const { t: translation } = useTranslation();

  const [defect, setDefect] = useState<Defect | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("summary");

  const handleFetchingDefect = useCallback(async () => {
    try {
      const resDefect = await findOneDefect(
        entityId,
        locationGroupId,
        defectId,
        serviceConfig
      );
      setDefect(resDefect);
    } catch (error: any) {
      notify.error(error);
    }
  }, [defectId, entityId, locationGroupId, serviceConfig]);

  useEffect(() => {
    if (defectId) handleFetchingDefect();
  }, [
    entityId,
    locationGroupId,
    defectId,
    serviceConfig,
    handleFetchingDefect,
  ]);

  const handleAddInspectionSubmit = async (
    form: AddInspectionGridForm
  ): Promise<boolean> => {
    try {
      let defect = await addInspection(
        entityId,
        locationGroupId,
        defectId,
        form,
        serviceConfig
      );
      setDefect(defect);
      notify.success(
        translation("defectDetails.handleAddInspectionSubmit.success")
      );
      return true;
    } catch (error: any) {
      notify.error(error);
      return false;
    }
  };

  const handleAddResponseSubmit = async (
    form: AddResponseGridForm
  ): Promise<boolean> => {
    try {
      let defect = await addResponse(
        entityId,
        locationGroupId,
        defectId,
        form,
        serviceConfig
      );
      setDefect(defect);
      notify.success(
        translation("defectDetails.handleAddResponseSubmit.success")
      );
      return true;
    } catch (error: any) {
      notify.error(error);
      return false;
    }
  };

  const handleCloseOutSubmit = async (
    form: CloseOutGridForm
  ): Promise<boolean> => {
    try {
      let defect = await closeDefect(
        entityId,
        locationGroupId,
        defectId,
        form,
        serviceConfig
      );
      setDefect(defect);
      notify.success(translation("app.common.floorPlan.success"));
      return true;
    } catch (error: any) {
      notify.error(error);
      return false;
    }
  };

  const handleCancelSubmit = async (
    form: CancelDefectGridForm
  ): Promise<boolean> => {
    try {
      let defect = await cancelDefect(
        entityId,
        locationGroupId,
        defectId,
        form,
        serviceConfig
      );
      setDefect(defect);
      notify.success(translation("defectDetails.handleCancelSubmit.success"));
      return true;
    } catch (error: any) {
      notify.error(error);
      return false;
    }
  };

  const handleMessageEditSubmit = async (
    form: Inspection
  ): Promise<boolean> => {
    try {
      if (form.type === "inspection") {
        let defectMessage = await updateInspection(
          entityId,
          locationGroupId,
          defectId,
          form.key,
          form,
          serviceConfig
        );
        const updatedDefect: Defect = { ...defect } as Defect;
        const index = updatedDefect.messages.findIndex(
          (message) => message.id === defectMessage.id
        );
        if (index !== -1) {
          updatedDefect.messages[index] = defectMessage;
        }
        setDefect(updatedDefect);
        notify.success(
          translation(
            "defectDetails.handleMessageEditSubmit.success.inspectionUpdated"
          )
        );
      } else if (form.type === "response") {
        let defectMessage = await updateResponse(
          entityId,
          locationGroupId,
          defectId,
          form.key,
          form,
          serviceConfig
        );
        const updatedDefect: Defect = { ...defect } as Defect;
        const index = updatedDefect.messages.findIndex(
          (message) => message.id === defectMessage.id
        );
        if (index !== -1) {
          updatedDefect.messages[index] = defectMessage;
        }
        setDefect(updatedDefect);
        notify.success(
          translation(
            "defectDetails.handleMessageEditSubmit.success.responseUpdated"
          )
        );
      }
      return true;
    } catch (error: any) {
      notify.error(error);
      return false;
    }
  };

  const handleMessageAttachmentAdd = async (
    message: DefectMessage,
    file: File
  ): Promise<boolean> => {
    // setLoading(true);
    try {
      let defectMessage = await attachFileToMessage(
        entityId,
        locationGroupId,
        defectId,
        message.id,
        message.type,
        file.id,
        serviceConfig
      );
      // setLoading(false);
      const updatedDefect: Defect = { ...defect } as Defect;
      const index = updatedDefect.messages.findIndex(
        (message) => message.id === defectMessage.id
      );
      if (index !== -1) {
        updatedDefect.messages[index] = defectMessage;
      }
      setDefect(updatedDefect);
      notify.success(translation("app.common.fileUploaded"));
      return true;
    } catch (error: any) {
      // setLoading(false);
      notify.error(error);
      return false;
    }
  };

  const handleMessageAttachmentRemove = async (
    message: DefectMessage,
    file: File
  ): Promise<boolean> => {
    try {
      let defectMessage = await detechFileFromMessage(
        entityId,
        locationGroupId,
        defectId,
        message.id,
        message.type,
        file.id,
        serviceConfig
      );
      const updatedDefect: Defect = { ...defect } as Defect;
      const index = updatedDefect.messages.findIndex(
        (message) => message.id === defectMessage.id
      );
      if (index !== -1) {
        updatedDefect.messages[index] = defectMessage;
      }
      setDefect(updatedDefect);
      notify.success(
        translation("defectDetails.handleMessageAttachmentRemove.success")
      );
      return true;
    } catch (error: any) {
      notify.error(error);
      return false;
    }
  };

  if (!defect) {
    return <NxpAuthSpinner />;
  }
  return (
    <DefectDetailsContext.Provider
      value={{
        defect,
        activeTab,
        setActiveTab,
        onFetchingDefect: handleFetchingDefect,
        onAddInspectionSubmit: handleAddInspectionSubmit,
        onAddResponseSubmit: handleAddResponseSubmit,
        onCloseOutSubmit: handleCloseOutSubmit,
        onCancelSubmit: handleCancelSubmit,
        onMessageEditSubmit: handleMessageEditSubmit,
        onMessageAttachmentAdd: handleMessageAttachmentAdd,
        onMessageAttachmentRemove: handleMessageAttachmentRemove,
        loading: loading,
        onProgessing: setLoading,
      }}
    >
      <DefectDetailsLayout defect={defect} />
    </DefectDetailsContext.Provider>
  );
};

export default DefectDetailsContainer;
