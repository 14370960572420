import React, { useCallback, useEffect, useRef, useState } from "react";

import { LazyLoadStatus, NxpTableProps } from "@nexploretechnology/nxp-ui";
import _ from "lodash";

import useAppContext from "../../hooks/useAppContext";
import { Defect, getDefects } from "../../services/defect";
import { LAZY_LOAD_PAGE_SIZE } from "../../utils/const";
import notify from "../../utils/notify";
import AppDefectListLayout, { AppDefectListItem } from "./AppDefectListLayout";

interface Props {
  queryString?: string;
  invalidateAt?: number;
  onDefectClick?: (defectId: number) => void;
  onSortingChange?: (sortBy: string, order: "asc" | "desc") => void;
  tableProps?: NxpTableProps<any>;
}

const AppDefectListContainer: React.FC<Props> = ({
  queryString,
  invalidateAt = 0,
  onDefectClick = () => {},
  onSortingChange = () => {},
  tableProps = {},
}) => {
  const appContext = useAppContext();
  const { serviceConfig, getLocationFullPath, routeParams } = appContext;
  const { entityId, locationGroupId } = routeParams;

  const [lazyLoadStatus, setLazyLoadStatus] = useState(LazyLoadStatus.ready);
  const [listItems, setListItems] = useState<null | AppDefectListItem[]>(null);

  const [sortBy, setSortBy] = useState<undefined | string>("no");
  const [order, setOrder] = useState<"asc" | "desc">("desc");

  const queryStates = useRef<{
    queryString?: string;
    listItems: AppDefectListItem[] | null;
    sortBy: undefined | string;
    order: "asc" | "desc";
  }>({
    queryString,
    listItems,
    sortBy,
    order,
  });

  queryStates.current = {
    queryString,
    listItems,
    sortBy,
    order,
  };

  const fetchData = useCallback(
    (limit: number, clear: boolean) => {
      setLazyLoadStatus(LazyLoadStatus.loading);
      getDefects(
        entityId,
        locationGroupId,
        queryString,
        queryStates.current.sortBy,
        queryStates.current.order,
        clear
          ? 0
          : queryStates.current.listItems
          ? queryStates.current.listItems.length
          : 0,
        limit,
        serviceConfig
      )
        .then((defects: Defect[]) => {
          const newItems: AppDefectListItem[] = defects.map(
            (defect: Defect) => {
              return {
                key: defect.id + "",
                id: defect.id,
                no: defect.no,
                status: defect.status,
                inspectionType: defect.inspectionType?.name,
                jointInspectionId: defect.jointInspectionId,
                defectType: defect.defectType?.name,
                createdAt: defect.createdAt,
                raisedBy: defect.raisedBy?.name,
                defectGroup: defect.defectGroup?.name,
                defectSubject: defect.defectSubject?.name,
                defectDescription: defect.defectDescription?.name,
                responsiblePerson: defect.responsiblePerson?.name,
                responsibleParty: defect.responsibleParty?.name,
                nextInspectionDate: defect.nextInspectionDate,
                dueDate: defect.dueDate,
                isTopPriority: defect.isTopPriority ? "Top" : "Normal",
                location: getLocationFullPath(defect.location),
                remarks: defect.remarks,
                constructionLotNo: defect.location.constructionLotNo,
                ncrNo: defect.ncrNo,
              } as AppDefectListItem;
            }
          );

          setLazyLoadStatus(LazyLoadStatus.ready);
          setListItems((listItems) => {
            return clear ? newItems : (listItems || []).concat(newItems);
          });
        })
        .catch((e) => notify.error(e));
    },
    [
      entityId,
      getLocationFullPath,
      locationGroupId,
      serviceConfig,
      queryStates,
      queryString,
    ]
  );

  useEffect(() => {
    fetchData(LAZY_LOAD_PAGE_SIZE, true);
  }, [sortBy, order, fetchData, invalidateAt]);

  const handleSortingChange = (field: any, order: any) => {
    setSortBy(field);
    setOrder(order === "descend" ? "desc" : "asc");
    onSortingChange(field, order === "descend" ? "desc" : "asc");
  };

  const handleLazyLoad = _.debounce(() => {
    if (lazyLoadStatus === LazyLoadStatus.ready) {
      fetchData(LAZY_LOAD_PAGE_SIZE, false);
    }
  }, 500);
  return (
    <AppDefectListLayout
      listItems={listItems}
      lazyLoadStatus={lazyLoadStatus}
      onLazyLoad={handleLazyLoad}
      onSortingChange={handleSortingChange}
      onRowClick={onDefectClick}
      tableProps={tableProps}
    />
  );
};

export default AppDefectListContainer;
