import { omit } from "lodash";

import { apiRequest } from "../utils/backend";
import { ServiceConfig } from "../utils/backend/apiRequest";
import { FloorPlan } from "./location";

export interface Entity {
  id: string;
  name: string;
}

export interface EntityParameter {
  entityId: string;
  dueDate: number;
  projectEntity: string;
  nextInspectionDate: number;
  reinspectionDate: number;
  dueDateReminderForSubcontractor: number;
  dueDateReminderForInspector: number;
  dueDatePriority: number;
  nextInspectionDatePriority: number;
  reinspectionDatePriority: number;
}

export interface User {
  id: string;
  name: string;
  email: string;
}

export interface UserRightList {
  "subcontractor-add": UserRightListItem[];
  "subcontractor-view": UserRightListItem[];
  "subcontractor-edit": UserRightListItem[];
  "subcontractor-delete": UserRightListItem[];

  "subcontractor-user-edit": UserRightListItem[];
  "subcontractor-user-view": UserRightListItem[];

  "defect-add": UserRightListItem[];
  "defect-view": UserRightListItem[];
  "defect-edit": UserRightListItem[];
  "defect-respond": UserRightListItem[];
  "defect-closeout": UserRightListItem[];

  "location-view": UserRightListItem[];
  "location-edit": UserRightListItem[];
  "location-admin": UserRightListItem[];

  "location-file-add": UserRightListItem[];
  "location-file-view": UserRightListItem[];
  "location-file-delete": UserRightListItem[];

  "location-info-edit": UserRightListItem[];
  "location-info-view": UserRightListItem[];

  "plan-view": UserRightListItem[];

  "subcontractor-dashboard-view": UserRightListItem[];
  "dashboard-view": UserRightListItem[];
  "report-view": UserRightListItem[];

  "role-view": UserRightListItem[];
  "role-add": UserRightListItem[];
  "role-edit": UserRightListItem[];
  "role-assign": UserRightListItem[];
  "role-delete": UserRightListItem[];

  "right-view": UserRightListItem[];
  "right-edit": UserRightListItem[];

  "setup-add": UserRightListItem[];
  "setup-view": UserRightListItem[];
  "setup-edit": UserRightListItem[];
  "setup-delete": UserRightListItem[];

  "location-group-view": UserRightListItem[];
}
export interface UserRightListItem {
  source: "envar" | "module" | "locationGroup";
  entity?: string;
  locationGroup?: number;
}
export interface File {
  id: number;
  name: string;
  url: string;
  thumbnailUrl: string;
  originalUrl: string;
  uploadedBy: User;
  createdAt: string;
  type?: string;
}

export interface LocationOtherInfo {
  plannedAccessDate: string | null;
  actualAccessDate: string | null;
  plannedHandoverDate: string | null;
  actualHandoverDate: string | null;
  constructionLotNo: string | null;
  responsiblePerson: User | number | null;
  drawingNos: string | null;
}
export interface Location extends Partial<LocationOtherInfo> {
  id: number;
  entityId: string;
  code: string;
  name: string;
  fullPath: string;
  parent: Location;
  children: Location[];
  floorPlans: FloorPlan[];
  files: File[];
}

export interface DefectGroup {
  id: number;
  defectSubjects: DefectSubject[];
  code: string;
  name: string;
  localName: string;
}

export interface DefectStatistics {
  count: string;
  averageduration: string;
  totalCost: string;
  totalBackchargeCost: string;
  totalScheduleImpact: string;
}

export interface DefectSubject {
  id: number;
  defectDescriptions: DefectDescription[];
  name: string;
  localName: string;
}

export interface DefectDescription {
  id: number;
  subcontractors: Subcontractor[];
  name: string;
  localName: string;
}

export interface Subcontractor {
  id: number;
  entityId: string;
  name: string;
  primaryContactPerson?: string;
  primaryContactEmail?: string;
  address?: string;
  smsNo?: string;
  smsAreaCode?: string;
  users: User[];
  createdOn: Date;
  updatedOn: Date;
  deletedOn: Date;
}

export interface Team {
  id: number;
  name: string;
  description: string;
  members: User[];
}

export interface Enum {
  id: number;
  type: "defectCause" | "defectType" | "inspectionType" | string;
  name: string;
}

export interface UserPreference {
  id: string;
  userId: string;
  application: string;
  parameter: string;
  value: string;
}
export type AddUserPreference = Omit<UserPreference, "id" | "userId">;

export const getEntityInfo = async (
  entityId: string,
  serviceConfig: ServiceConfig
): Promise<Entity> => {
  return await apiRequest<Entity>(`/entities/${entityId}`, serviceConfig);
};

export const getEntityParameter = async (
  entityId: string,
  serviceConfig: ServiceConfig
): Promise<EntityParameter> => {
  return await apiRequest<EntityParameter>(
    `/entities/${entityId}/entity-parameters`,
    serviceConfig
  );
};

export const updateEntityParameter = async (
  entityId: string,
  form: any,
  serviceConfig: ServiceConfig
): Promise<EntityParameter> => {
  return await apiRequest<EntityParameter>(
    `/entities/${entityId}/entity-parameters`,
    serviceConfig,
    { method: "PUT", body: JSON.stringify(omit(form, "entityId")) }
  );
};

export const getSubcontractors = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Subcontractor[]>(
    `/entities/${entityId}/subcontractors`,
    serviceConfig
  );
};

export const getMyself = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<User>(
    `/entities/${entityId}/users/me`,
    serviceConfig
  );
};

export const getUserRightList = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<UserRightList>(
    `/entities/${entityId}/user-right-list`,
    serviceConfig
  );
};

export const getUsers = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<User[]>(`/entities/${entityId}/users`, serviceConfig);
};

export const getLocationGroups = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Location[]>(
    `/entities/${entityId}/location-groups`,
    serviceConfig
  );
};

export const getLocationTree = async (
  entityId: string,
  locationGroupId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Location>(
    `/entities/${entityId}/location-groups/${locationGroupId}`,
    serviceConfig
  );
};

export const getDefectGroups = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<DefectGroup[]>(
    `/entities/${entityId}/defect-groups/trees`,
    serviceConfig
  );
};

export const getDefectStatistics = async (
  entityId: string,
  serviceConfig: ServiceConfig,
  queryString: string
) => {
  return await apiRequest<DefectStatistics>(
    `/entities/${entityId}/defect-statistic` + queryString,
    serviceConfig
  );
};

export const getEnums = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Enum[]>(`/entities/${entityId}/enums`, serviceConfig);
};

export const getTeams = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Team[]>(`/entities/${entityId}/teams`, serviceConfig);
};

export const getMyUserPreference = async (serviceConfig: ServiceConfig) => {
  return apiRequest<UserPreference[]>(
    `${serviceConfig.qcApiServiceApiBaseUrl}/user-preference/me`,
    serviceConfig
  );
};
export const createMyUserPreference = async (
  serviceConfig: ServiceConfig,
  userPreference: AddUserPreference
) => {
  return apiRequest<UserPreference>(
    `${serviceConfig.qcApiServiceApiBaseUrl}/user-preference/me`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(userPreference),
    }
  );
};
export const patchMyUserPreference = async (
  serviceConfig: ServiceConfig,
  userPreference: AddUserPreference
) => {
  return apiRequest<any>(
    `${serviceConfig.qcApiServiceApiBaseUrl}/user-preference/me`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(userPreference),
    }
  );
};
