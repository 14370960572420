import { DefectGroup } from "./app";
import { apiRequest, ServiceConfig } from "../utils/backend";

export interface DefectGroupForm
  extends Pick<DefectGroup, "name" | "localName" | "code"> {}

export const getDefectGroup = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<DefectGroup[]>(
    `/entities/${entityId}/defect-groups/`,
    serviceConfig
  );
};

export const createDefectGroup = async (
  entityId: string,
  defectGroupForm: DefectGroupForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<DefectGroup>(
    `/entities/${entityId}/defect-groups/`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(defectGroupForm),
    }
  );
};

export const updateDefectGroup = async (
  entityId: string,
  defectGroup: DefectGroup,
  serviceConfig: ServiceConfig
) => {
  let defectGroupObject = {
    code: defectGroup.code,
    name: defectGroup.name,
    localName: defectGroup.localName,
  };

  return await apiRequest<DefectGroup>(
    `/entities/${entityId}/defect-groups/${defectGroup.id}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(defectGroupObject),
    }
  );
};

export const deleteDefectGroup = async (
  entityId: string,
  defectGroupId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/entities/${entityId}/defect-groups/${defectGroupId}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};
