import { useKeycloak } from "@react-keycloak/web";
import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { ServiceConfig } from "../utils/backend";

const useServiceConfig = (): ServiceConfig => {
  const { keycloak } = useKeycloak();
  const entityRoute = useRouteMatch<{ entityId: string }>(
    "/entities/:entityId"
  );
  const serviceConfig = useMemo(
    () => ({
      entityId: entityRoute?.params.entityId,
      token: keycloak?.token,
      apiBaseUrl: process.env.REACT_APP_API_BASE_URL || "",
      qcApiServiceApiBaseUrl: process.env.REACT_APP_QC_API_BASE_URL || "",
    }),
    [entityRoute?.params.entityId, keycloak?.token]
  );

  return serviceConfig;
};

export default useServiceConfig;
