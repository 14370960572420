import Keycloak from "keycloak-js";

const getKeycloakInstance = (): Keycloak.KeycloakInstance => {
  let config: Keycloak.KeycloakConfig | string;
  if (!process.env.REACT_APP_KEYCLOAK_AUTHSERVERURL) config = "/keycloak.json";
  else {
    config = {
      url: process.env.REACT_APP_KEYCLOAK_AUTHSERVERURL,
      realm: process.env.REACT_APP_KEYCLOAK_REALM || "",
      clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID || "",
    };
  }

  return Keycloak(config);
};

export default getKeycloakInstance;
