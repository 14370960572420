import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { NxpFormItem } from "@nexploretechnology/nxp-ui";
import { Checkbox } from "antd";
import { ColumnProps } from "antd/lib/table";
import * as yup from "yup";

import useAppContext from "../../../../hooks/useAppContext";
import { useValidate, ValidationResult } from "../../../../hooks/useValidate";
import {
  deleteDefectDescription,
  deleteDefectSubject,
  deleteDescriptionSubcontractor,
  updateDefectDescription,
  updateDefectSubject,
  updateDescriptionSubcontractor,
} from "../../../../services/dictionary";
import notify from "../../../../utils/notify";
import { ActionType, TreeNode } from "../SubjectContainer";
import SubjectFormLayout from "./SubjectFormLayout";

export interface RowItem {
  key: string;
  name: string;
}

interface SubjectFormContainerProps {
  currentNode: TreeNode;
  descriptionSubcontractors: any[] | undefined;
  handleModifiedSubcontractors: (subcontractors: any[]) => void;
  handleModifyTree: (
    item: any,
    itemType: string,
    actionType: ActionType
  ) => void;
}

const SubjectFormContainer: React.FC<SubjectFormContainerProps> = ({
  currentNode,
  descriptionSubcontractors,
  handleModifiedSubcontractors,
  handleModifyTree,
}) => {
  const appContext = useAppContext();
  const {
    routeParams,
    serviceConfig,
    subcontractors,
    activeLocationGroup,
    errorHandler,
    refetchDefectGroups,
  } = appContext;
  const { entityId } = routeParams;

  const { t: translation } = useTranslation();

  const formSchema = yup.object().shape({
    name: yup
      .string()
      .nullable()
      .required(translation("app.common.nameIsRequired")),
  });

  const getColumns = (
    editRecord: RowItem | undefined,
    selectedSubcontractorList: any[] | undefined,
    validationError: ValidationResult<RowItem>,
    handleToggle: (record: any) => void,
    setEditRecord: (callback: SetStateAction<RowItem | undefined>) => void
  ): ColumnProps<RowItem>[] => [
    { dataIndex: "name", fixed: "left" },
    {
      dataIndex: "name",
      fixed: "left",
      render: (_: unknown, record: any) => {
        const editing = editRecord?.key === record.key;
        return (
          <NxpFormItem
            editing={editing}
            controlType="input"
            error={validationError.name}
            controlProps={{
              value: editing ? editRecord?.name : record.name,
              onChange: (e) =>
                setEditRecord(
                  (prevState: any) =>
                    prevState && { ...prevState, name: e.target.value }
                ),
            }}
          />
        );
      },
    },
    {
      title: translation("app.common.select"),
      dataIndex: "select",
      fixed: "right",
      align: "right",
      render: (_: unknown, record: any) => (
        <Checkbox
          onClick={() => {
            handleToggle(record);
          }}
          checked={
            !!selectedSubcontractorList?.find(
              (subcontractor) => subcontractor.key === record.id
            )
          }
        />
      ),
    },
  ];

  const [editItem, setEditItem] = useState<RowItem>();
  const [saveInProgress, setSaveInProgress] = useState(false);
  /*
   *      Subcontractors List is kept separate from the rest of the Defects List object.
   *      This is so we don't have the same duplicate of that list at every leaf of Defect Group List.
   */
  const [subcategoryDataSource, setSubcategoryDataSource] = useState<
    any[] | undefined
  >(undefined);

  const [selectedSubcontractorList, setSelectedSubcontractorList] =
    useState<any[]>();

  const handleSaveValidated = async () => {
    setSaveInProgress(true);

    const stringSplitArray = editItem!.key
      ?.split("-")
      .map((item: string) => item.replace(/[A-Za-z]+-/g, ""));

    if (stringSplitArray.length === 5) {
      try {
        await updateDefectSubject(
          entityId!,
          {
            name: editItem?.name!,
          },
          Number(stringSplitArray[1]),
          Number(stringSplitArray[3]),
          serviceConfig
        );
        const rec = subcategoryDataSource?.find(
          (data) => data.key === editItem?.key
        );
        Object.assign(rec, { ...editItem });
        handleModifyTree(editItem, "subject", ActionType.edit);
        setEditItem(undefined);
        notify.actionCompleted();
        refetchDefectGroups(entityId!, serviceConfig);
      } catch (ex: any) {
        errorHandler(ex, "updating defect subject");
      } finally {
        setSaveInProgress(false);
      }
    } else if (stringSplitArray.length === 7) {
      try {
        await updateDefectDescription(
          entityId!,
          {
            name: editItem?.name!,
          },
          Number(stringSplitArray[1]),
          Number(stringSplitArray[3]),
          Number(stringSplitArray[5]),
          serviceConfig
        );
        const rec = subcategoryDataSource?.find(
          (data) => data.key === editItem?.key
        );
        Object.assign(rec, { ...editItem });
        handleModifyTree(editItem, "description", ActionType.edit);
        setEditItem(undefined);
        notify.actionCompleted();
        refetchDefectGroups(entityId!, serviceConfig);
      } catch (ex: any) {
        errorHandler(ex, "updating defect description");
      } finally {
        setSaveInProgress(false);
      }
    }
  };

  const [validationError, , clearError, saveWithValidate] = useValidate<
    Pick<RowItem, "name">
  >(editItem, formSchema, handleSaveValidated);
  const handleRowSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  const handleAddToList = useCallback(
    (item: TreeNode, itemType: string) => {
      handleModifyTree(item, itemType, ActionType.add);

      setSubcategoryDataSource([
        ...subcategoryDataSource!,
        {
          key: item.key,
          name: item.title,
        },
      ]);
    },
    [handleModifyTree, subcategoryDataSource]
  );

  const handleToggle = useCallback(
    (record: any) => {
      /** If the item is in the list, remove from list. **/
      if (
        selectedSubcontractorList?.find(
          (subcontractor) => subcontractor.key === record.id
        )
      ) {
        setSelectedSubcontractorList(
          selectedSubcontractorList?.filter(
            (subcontractor) => subcontractor.key !== record.id
          )
        );
      } /** If the item is not in the list or if list is empty, add to list. **/ else {
        setSelectedSubcontractorList([
          ...(selectedSubcontractorList ?? []),
          { ...record, key: record.id },
        ]);
      }
    },
    [selectedSubcontractorList]
  );

  const subcategoryColumn = useMemo(
    () =>
      getColumns(
        editItem,
        selectedSubcontractorList,
        validationError,
        handleToggle,
        setEditItem
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editItem, handleToggle, selectedSubcontractorList, validationError]
  );

  const [subcontractorsList, setSubcontractorsList] = useState<
    any[] | undefined
  >(undefined);

  const handleRowCancel = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      setEditItem(undefined);
      clearError();
    },
    [clearError]
  );

  const handleRowEdit = useCallback(
    (editItem: RowItem) => setEditItem({ ...editItem }),
    []
  );

  const handleRowDelete = useCallback(
    async (deleteItem: RowItem) => {
      setSaveInProgress(true);

      const stringSplitArray = deleteItem.key
        ?.split("-")
        .map((item: string) => item.replace(/[A-Za-z]+-/g, ""));

      if (stringSplitArray.length === 5) {
        try {
          await deleteDefectSubject(
            entityId!,
            {
              name: deleteItem?.name,
            },
            Number(stringSplitArray[1]),
            Number(stringSplitArray[3]),
            serviceConfig
          );
          handleModifyTree(deleteItem, "subject", ActionType.delete);
          setSubcategoryDataSource(
            subcategoryDataSource?.filter((data) => data !== deleteItem)
          );
          notify.actionCompleted();
        } catch (e: any) {
          notify.error(e);
        }
      }
      if (stringSplitArray.length === 7) {
        try {
          await deleteDefectDescription(
            entityId!,
            {
              name: deleteItem?.name,
            },
            Number(stringSplitArray[1]),
            Number(stringSplitArray[3]),
            Number(stringSplitArray[5]),
            serviceConfig
          );

          handleModifyTree(deleteItem, "description", ActionType.delete);
          setSubcategoryDataSource(
            subcategoryDataSource?.filter((data) => data !== deleteItem)
          );
          notify.actionCompleted();
        } catch (e: any) {
          notify.error(e);
        }
      }
      setEditItem(undefined);
      setTimeout(() => setSaveInProgress(false)); // need setTimeout only because this demo use synchronous procedure for deleting
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      entityId,
      activeLocationGroup?.id,
      serviceConfig,
      handleModifyTree,
      subcategoryDataSource,
    ]
  );

  const handleRowClick = (record: any) => {
    handleToggle(record);
  };

  const handleSave = () => {
    const stringSplitArray = currentNode.key
      ?.split("-")
      .map((item: string) => item.replace(/[A-Za-z]+-/g, ""));

    /** Loop through the subcontractor list. **/
    try {
      subcontractorsList?.forEach(async (item) => {
        if (
          selectedSubcontractorList?.find(
            (subcontractor) => subcontractor.key === item.id
          )
        )
          await updateDescriptionSubcontractor(
            entityId!,
            Number(stringSplitArray[1]),
            Number(stringSplitArray[3]),
            Number(stringSplitArray[5]),
            item.id.toString(),
            serviceConfig
          );
        else {
          await deleteDescriptionSubcontractor(
            entityId!,
            Number(stringSplitArray[1]),
            Number(stringSplitArray[3]),
            Number(stringSplitArray[5]),
            item.id.toString(),
            serviceConfig
          );
        }
      });
      notify.actionCompleted();
      refetchDefectGroups(entityId, serviceConfig);
    } catch (ex: any) {
      errorHandler(ex, translation("app.common.subjectForm.handleSave.error"));
    }

    /** The current list has to be updated in the parent component's state object. **/
    handleModifiedSubcontractors(selectedSubcontractorList!);
  };

  useEffect(() => {
    setEditItem(undefined);
  }, [currentNode]);

  useEffect(() => {
    setSelectedSubcontractorList(descriptionSubcontractors);
  }, [descriptionSubcontractors]);

  useEffect(() => {
    let dataSource: any[] = [];
    currentNode?.children?.forEach((children) =>
      dataSource.push({
        key: children.key,
        name: children.title,
      })
    );
    setSubcategoryDataSource(dataSource);
    if (currentNode?.key.includes("Defect Description")) {
      setSubcontractorsList(subcontractors);
    }
  }, [
    subcontractors,
    currentNode?.children,
    currentNode?.key,
    descriptionSubcontractors,
  ]);

  return currentNode?.key ? (
    <SubjectFormLayout
      currentNode={currentNode}
      editItem={editItem}
      saveInProgress={saveInProgress}
      subcategoryColumn={
        currentNode?.key.includes("Defect Description")
          ? [subcategoryColumn[0], subcategoryColumn[2]]
          : currentNode?.title === "Defect Group"
          ? [subcategoryColumn[0]]
          : [subcategoryColumn[1]]
      }
      subcategoryDataSource={
        currentNode?.key.includes("Defect Description")
          ? subcontractorsList?.filter((item: { name: any }) => item.name)
          : subcategoryDataSource
      }
      handleAddToList={handleAddToList}
      handleRowCancel={handleRowCancel}
      handleRowClick={handleRowClick}
      handleRowDelete={handleRowDelete}
      handleRowEdit={handleRowEdit}
      handleRowSave={handleRowSave}
      handleSave={handleSave}
    />
  ) : null;
};

export default React.memo(SubjectFormContainer);
