import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";

import { NxpHeader } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../hooks/useAppContext";
import { Location } from "../../services/app";
import LocationGroupCard from "./LocationGroupCard";

interface Props {}

const useStyles = createUseStyles((theme) => ({
  instruction: {
    fontSize: theme.fontSize.main,
    fontWeight: theme.fontWeight.bold,
    marginBottom: 20,
  },
}));

const LocationGroupSelection: React.FC<Props> = () => {
  const appContext = useAppContext();
  const { activeEntity, locationGroups, hasRight } = appContext;
  const classes = useStyles();

  const { t: translation } = useTranslation();

  const history = useHistory();

  return (
    <>
      <NxpHeader titleContent={translation("app.common.locationGroup")} />
      <div className={classes.instruction}>
        {translation(
          "locationGroupSelection.pleaseSelectALocationGroupToContinue"
        )}
      </div>
      {locationGroups
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((locationGroup: Location, index) =>
          hasRight("location-group-view", "locationGroup", locationGroup.id) ? (
            <LocationGroupCard
              key={index}
              locationGroup={locationGroup}
              onSelect={() =>
                history.push(
                  `/entities/${activeEntity?.id}/location-groups/${locationGroup.id}/home`
                )
              }
            />
          ) : null
        )}
    </>
  );
};

export default LocationGroupSelection;
