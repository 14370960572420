import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import AddDefectTypeModal from "./AddDefectTypeModal";

interface AddDefectTypeButtonProps {
  handleAddToList: (item: any) => void;
}

const AddDefectTypeButton: React.FC<AddDefectTypeButtonProps> = ({
  handleAddToList,
}) => {
  const { t: translation } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const handleModalVisible = () => {
    setModalVisible(false);
  };

  return (
    <>
      <NxpButton onClick={() => setModalVisible(true)}>
        {translation("settings.defectType.addDefectType.addDefectType")}
      </NxpButton>
      <AddDefectTypeModal
        modalVisible={modalVisible}
        handleAddToList={handleAddToList}
        handleModalVisible={handleModalVisible}
      />
    </>
  );
};

export default AddDefectTypeButton;
