import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import AddRecordModal from "./AddRecordModal";

interface AddRecordButtonProps {
  handleAddToList: (item: any) => void;
}

const AddRecordButton: React.FC<AddRecordButtonProps> = ({
  handleAddToList,
}) => {
  const { t: translation } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const handleModalVisible = () => {
    setModalVisible(false);
  };

  return (
    <>
      <NxpButton onClick={() => setModalVisible(true)}>
        {translation("settings.team.addRecord.addRecord")}
      </NxpButton>
      <AddRecordModal
        modalVisible={modalVisible}
        handleAddToList={handleAddToList}
        handleModalVisible={handleModalVisible}
      />
    </>
  );
};

export default AddRecordButton;
