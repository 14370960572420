export function throttle(func: Function, timeFrame = 500) {
  let lastTime = 0;
  return function () {
    const now = Date.now();
    if (now - lastTime >= timeFrame) {
      func();
      lastTime = now;
    }
  };
}
export const stringToBoolean = (string: string) =>
  string === "false" ? false : !!string;
