import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import moment from "moment";

import useAppContext from "../../../hooks/useAppContext";
import { getDefectStatistics, User } from "../../../services/app";
import SummaryLayout from "./SummaryLayout";
import { Defect, getDefects } from "../../../services/defect";

import { useTranslation } from "react-i18next";

function checkValid(value: number) {
  return Number.isFinite(value) ? value.toFixed(1) : 0;
}

interface SummaryContainerProps {
  inspector: User | undefined;
}

export interface InspectorStatistic {
  key: string;
  title: string;
  value: string | undefined;
  get: string;
  query: string;
}

const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

const formatNumber = (value: number) => {
  if (value > 1000) {
    return Number(value / 1000).toLocaleString("en", options) + "k";
  } else return value;
};

const valueFilter = (get: string, result: any) => {
  let value: number = 0;
  if (get === "count") value = result.count;
  else if (get === "totalCost") value = result.totalCost;
  else if (get === "averageDuration") value = result.averageDuration;
  else if (get === "totalBackchargeCost") value = result.totalBackchargeCost;
  else if (get === "totalScheduleImpact") value = result.totalScheduleImpact;
  return formatNumber(value).toString();
};

const SummaryContainer: React.FC<SummaryContainerProps> = ({ inspector }) => {
  const appContext = useAppContext();
  const { routeParams, serviceConfig } = appContext;
  const { entityId, locationGroupId } = routeParams;
  const { t: translation } = useTranslation();

  const defaultInspectorStatistics = [
    {
      key: "Related Defects",
      title: translation("app.common.relatedDefects"),
      value: "0",
      get: "count",
      query: `?responsiblePersonId={inspectorId}&locationGroupId={locationGroupId}&statusNot=cancelled`,
    },
    {
      key: "Fixed count",
      title: translation("app.common.fixed"),
      value: "0",
      get: "count",
      query: `?responsiblePersonId={inspectorId}&locationGroupId={locationGroupId}&status=closed`,
    },
    {
      key: "Outstanding",
      title: translation("app.common.outstanding"),
      value: "0",
      get: "count",
      query: `?responsiblePersonId={inspectorId}&locationGroupId={locationGroupId}&statusNot=closed,cancelled&dueDateGte={tomorrow}`,
    },
    {
      key: "Overdue",
      title: translation("app.common.overdue"),
      value: "0",
      get: "count",
      query: `?responsiblePersonId={inspectorId}&locationGroupId={locationGroupId}&statusNot=closed,cancelled&dueDateLte={today}`,
    },
    {
      key: "Average days",
      title: translation("app.common.averageDays"),
      value: "0.0",
      get: "averageDuration",
      query: `?responsiblePersonId={inspectorId}&locationGroupId={locationGroupId}&status=closed`,
    },
    {
      key: "Total $ Backcharge",
      title: translation("app.common.totalBackcharge"),
      value: "0",
      get: "totalBackchargeCost",
      query: `?responsiblePersonId={inspectorId}&locationGroupId={locationGroupId}&status=closed`,
    },
    {
      key: "Days impact",
      title: translation("app.common.daysImpact"),
      value: "0",
      get: "totalScheduleImpact",
      query: `?responsiblePersonId={inspectorId}&locationGroupId={locationGroupId}&status=closed`,
    },
  ];

  const [inspectorStatistics, setInspectorStatistics] = useState<
    InspectorStatistic[]
  >(defaultInspectorStatistics);

  const [isLoading, setIsLoading] = useState<boolean>();

  useEffect(() => {
    if (inspector !== undefined) {
      let temp = inspectorStatistics;
      temp.forEach((element, index) => {
        getDefectStatistics(
          entityId ?? "",
          serviceConfig,
          element.query
            .replace("{inspectorId}", "" + inspector?.id?.toString())
            .replace("{locationGroupId}", locationGroupId!.toString())
            .replace("{today}", moment().format("YYYY-MM-DD"))
            .replace("{tomorrow}", moment().add(1, "d").format("YYYY-MM-DD"))
        ).then((result) => {
          element.value = valueFilter(element.get, result);
          var rec = inspectorStatistics?.find(
            (item) => item.key === element.key
          );
          rec!.value = valueFilter(element.get, result);
          setInspectorStatistics(
            inspectorStatistics.map((stat) =>
              stat.key === rec?.key ? rec : stat
            )
          );
        });
        if (index === 4) {
          setIsLoading(true);
          getDefects(
            entityId,
            locationGroupId,
            "?full=true&statusNot=cancelled",
            undefined,
            "asc",
            0,
            0,
            serviceConfig
          ).then((defects: Defect[]) => {
            element.value = checkValid(
              defects
                .filter(
                  (defect) =>
                    defect?.status === "closed" &&
                    defect.responsiblePerson?.id === inspector.id
                )
                .map((defect) =>
                  Math.ceil(
                    Math.abs(
                      (new Date(Date.parse(defect.closeDate)).getTime() -
                        new Date(Date.parse(defect.raiseDate)).getTime()) /
                        (1000 * 60 * 60 * 24)
                    )
                  )
                )
                .reduce(
                  (previousValue, currentValue) => previousValue + currentValue,
                  0
                ) /
                defects.filter(
                  (defect) =>
                    defect?.status === "closed" &&
                    defect.responsiblePerson?.id === inspector.id
                ).length
            ).toString();
            setIsLoading(false);
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspector]);

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <SummaryLayout inspectorStatistics={[...inspectorStatistics]} />
      )}
    </>
  );
};

export default SummaryContainer;
