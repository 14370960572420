import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";

import { DownloadOutlined, FilterFilled } from "@ant-design/icons";
import {
  NxpButton,
  NxpHeader,
  useDefaultTableScrollHeight,
} from "@nexploretechnology/nxp-ui";

import AppDefectFilterModal from "../../../components/AppDefectFilterModal";
import AppDefectList from "../../../components/AppDefectList";
import useAppContext from "../../../hooks/useAppContext";
import { getDefectsExcel } from "../../../services/defect";
import { pxToRem } from "../../../theme.config";
import notify from "../../../utils/notify";
import AddDefectButton from "../../DefectListing/AddDefect/AddDefectButton";
import { LocationDetailsContext } from "../LocationDetailsContainer";
import TabBar from "../TabBar";

interface Props {
  onActiveKeyChange: (activeKey: string) => void;
}
const useStyles = createUseStyles((theme) => ({
  root: { height: "100%" },
  filterCounter: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.backgroundPrimaryLight,
    color: theme.palette.primary,
    borderRadius: theme.pxToRem(5),
    width: theme.pxToRem(20),
    height: pxToRem(20),
    margin: theme.spacing(0, 1),
  },
  dropdown: {
    border: `${theme.pxToRem(1)} solid ${theme.palette.borderLight}`,
    "& .ant-dropdown-menu-item": {
      color: theme.palette.primary,
      fontWeight: theme.fontWeight.bold,
      fontSize: theme.pxToRem(12),
    },
  },
}));

const LocationTreeDefect: React.FC<Props> = ({ onActiveKeyChange }) => {
  const { location } = useContext(LocationDetailsContext);
  const appContext = useAppContext();
  const {
    activeLocationGroup,
    serviceConfig,
    routeParams,
    hasRight,
    getChildLocationIdList,
  } = appContext;
  const { entityId, locationGroupId } = routeParams;

  const { t: translation } = useTranslation();

  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [invalidateAt, setInvalidateAt] = useState<number>(0);
  const [extraQueries, setExtraQueries] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<undefined | string>("no"); // for reference only, AppDefectList handle sorting on it's own
  const [order, setOrder] = useState<"asc" | "desc">("desc"); // for reference only, AppDefectList handle sorting on it's own
  const history = useHistory();

  const classes = useStyles();

  const tableHeight = useDefaultTableScrollHeight(false) - 80;

  useEffect(() => {
    setExtraQueries([]);
  }, [location.id]);

  const handleExportExcel = async () => {
    try {
      await getDefectsExcel(
        entityId,
        locationGroupId,
        queryString,
        sortBy,
        order,
        serviceConfig
      );
    } catch (e) {
      notify.error(e);
    }
  };

  const handleFilterApply = (queryStrings: string[]) => {
    setExtraQueries(queryStrings);
    setFilterModalVisible(false);
  };

  const handleSortingChange = (sortBy: string, order: "asc" | "desc") => {
    setSortBy(sortBy);
    setOrder(order);
  };

  const handleDefectCreated = () => {
    setInvalidateAt(new Date().getTime());
  };

  // function handleMenuClick(e: any) {
  //   notify.info(
  //     translation("locationDetails.locationTreeDefect.handleMenuClick.info")
  //   );
  // }
  if (!activeLocationGroup) return null;
  if (!location) return null;
  if (!location.floorPlans) return null;

  const searchPhase = `location=${getChildLocationIdList(location).join(",")}`;
  let queryString = [searchPhase, ...extraQueries].join("&");
  if (!queryString.startsWith("?")) queryString = `?${queryString}`;

  // const menu = (
  //   <Menu onClick={handleMenuClick} className={classes.dropdown}>
  //     <Menu.Item key="1">
  //       {translation("app.common.report")}
  //     </Menu.Item>
  //     <Menu.Item key="2">
  //       {translation("app.common.reportClient")}
  //     </Menu.Item>
  //   </Menu>
  // );
  return (
    <div className={classes.root}>
      <NxpHeader
        titleContent={location.name}
        actionContent={
          <>
            <NxpButton
              type="default"
              icon={<FilterFilled />}
              onClick={() => setFilterModalVisible(true)}
            >
              {extraQueries?.length > 0 && (
                <div className={classes.filterCounter}>
                  <div>{extraQueries?.length}</div>
                </div>
              )}
            </NxpButton>

            <NxpButton
              type="primary"
              icon={<DownloadOutlined />}
              onClick={handleExportExcel}
            >
              {translation("app.common.exportToExcel")}
            </NxpButton>

            {/* {hasRight("report-view") && (
              <Dropdown overlay={menu}>
                <NxpButton type="primary">
                  {translation("locationDetails.locationTreeDefect.actions")}{" "}
                  <CaretDownFilled />
                </NxpButton>
              </Dropdown>
            )} */}

            {hasRight("defect-add") && (
              <AddDefectButton onDefectCreated={handleDefectCreated} />
            )}
          </>
        }
      />
      <TabBar activeKey="defects" onChange={onActiveKeyChange} />

      <AppDefectList
        queryString={queryString}
        invalidateAt={invalidateAt}
        onDefectClick={(defectId) =>
          history.push(
            `/entities/${entityId}/location-groups/${locationGroupId}/defects/${defectId}`
          )
        }
        onSortingChange={handleSortingChange}
        tableProps={{
          useDefaultHeight: false,
          scroll: { y: tableHeight },
        }}
      />
      <AppDefectFilterModal
        key={`AppDefectFilterModal_${location.id}`} // force reset when location id change
        show={filterModalVisible}
        blockedFields={["location"]}
        onApply={handleFilterApply}
        onDismiss={() => {
          setFilterModalVisible(false);
        }}
      />
    </div>
  );
};

export default LocationTreeDefect;
