import React from "react";
import { createUseStyles } from "react-jss";

import { LoadingOutlined } from "@ant-design/icons";
import { NxpButton } from "@nexploretechnology/nxp-ui";
import { Card, Col, Progress, Row, Spin } from "antd";

interface Props {
  name: string;
  percent: number;
}

const useStyles = createUseStyles((theme) => ({
  card: {
    marginBottom: 10,
    "& .ant-card-body": {
      padding: 8,
      verticalAlign: "bottom",
    },
    "& .ant-col": {
      lineHeight: 2,
      verticalAlign: "bottom",
      whiteSpace: "nowrap",
      overflow: "hidden",
      paddingRight: 16,
    },
  },
  nameButton: {
    verticalAlign: "bottom",
    maxWidth: "100%",
    "& span": {
      verticalAlign: "bottom",
      maxWidth: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

const FileListItem: React.FC<Props> = ({ name, percent }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Row>
        <Col span={10}>
          <NxpButton className={classes.nameButton} type="link">
            {name}
          </NxpButton>
        </Col>
        <Col span={14}>
          {percent < 100 ? (
            <Progress percent={Math.round(percent)} status="active" />
          ) : (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, marginRight: 10 }}
                  spin
                />
              }
            />
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default FileListItem;
