import React, {
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { PaperClipOutlined } from "@ant-design/icons";
import {
  NxpFormItem,
  NxpTable,
  NxpTableEditable,
} from "@nexploretechnology/nxp-ui";
import moment from "moment";
import * as yup from "yup";

import { useValidate, ValidationResult } from "../../../../hooks/useValidate";
import cancelIcon from "../../../../images/defect-message/cancel-icon.svg";
import closureIcon from "../../../../images/defect-message/closure-icon.svg";
import reponseIcon from "../../../../images/defect-message/response-icon.svg";
import { DefectMessage } from "../../../../services/defect";
import { DefectDetailsContext } from "../../DefectDetailsContainer";
import AttachmentDrawer from "../AttachmentDrawer";

interface Props {
  editable?: boolean;
}
export interface Inspection {
  key: number;
  type: DefectMessage["type"];
  sequence: number;
  date: Date;
  inspector: string;
  comment: string;
  defectStatus: DefectMessage["defectStatus"];
  nextinspectiondate: Date;
  defectMessage: DefectMessage;
}

const useStyles = createUseStyles((theme) => ({
  highlightedRow: {
    background: "rgba(2, 74, 148, 0.1)",
    "& td.ant-table-cell": {
      background: "rgba(2, 74, 148, 0.1)",
      overflow: "hidden",
    },
  },
}));

const InspectionListLayout: React.FC<Props> = ({ editable }) => {
  const classes = useStyles();
  const { defect, onMessageEditSubmit, loading, activeTab } =
    useContext(DefectDetailsContext);

  const { t: translation } = useTranslation();

  const getColumns = (
    editRecord: Inspection | undefined,
    setEditRecord: (callback: SetStateAction<Inspection | undefined>) => void,
    validationError: ValidationResult<Inspection>
  ) => [
    {
      title: translation("app.common.inspection.inspectionList.sequence"),
      dataIndex: "sequence",
      width: 100,
      render: (value: unknown, record: Inspection) => {
        if (record.type === "inspection") {
          return value;
        } else if (record.type === "response") {
          return <img src={reponseIcon} alt="Response" />;
        } else if (record.type === "closure") {
          return <img src={closureIcon} alt="Closure" />;
        } else if (record.type === "cancellation") {
          return <img src={cancelIcon} alt="Cancellation" />;
        }
      },
    },
    {
      title: translation("app.common.inspectionDate"),
      dataIndex: "date",
      width: 120,
      render: (_: unknown, record: Inspection) => {
        const editing = editRecord?.key === record.key;
        return (
          <NxpFormItem
            editing={editing}
            controlType="datePicker"
            error={validationError.date}
            controlProps={{
              value: editing ? editRecord?.date : record.date,
              onChange: (value: Date | undefined) =>
                setEditRecord(
                  (prevState) =>
                    prevState && { ...prevState, date: value as Date }
                ),
            }}
          />
        );
      },
    },
    {
      title: translation("app.common.inspector"),
      dataIndex: "inspector",
      width: 100,
    },
    {
      title: translation("app.common.comments"),
      dataIndex: "comment",
      width: 450,
      editable: true,
      render: (_: unknown, record: Inspection) => {
        const editing = editRecord?.key === record.key;
        return (
          <NxpFormItem
            editing={editing}
            controlType="input"
            error={validationError.comment}
            controlProps={{
              value: editing ? editRecord?.comment : record.comment,
              onChange: (e) =>
                setEditRecord(
                  (prevState) =>
                    prevState && { ...prevState, comment: e.target.value }
                ),
            }}
          />
        );
      },
    },
    {
      title: translation("app.common.status"),
      dataIndex: "defectStatus",
      width: 100,
      editable: true,
      render: (value: string, record: Inspection, index: number) => {
        const editing =
          record.type === "inspection" && editRecord?.key === record.key;

        let options: any[] = [];

        if (record.type === "inspection") {
          options =
            index > 0
              ? [
                  {
                    label: translation("app.common.open"),
                    value: "open",
                  },
                  {
                    label: translation("app.common.markClosed"),
                    value: "mark-closed",
                  },
                ]
              : [
                  {
                    label: translation("app.common.open"),
                    value: "open",
                  },
                ];
        } else if (record.type === "response") {
          options = [
            {
              label: translation("app.common.markFixed"),
              value: "mark-fixed",
            },
          ];
        } else if (record.type === "closure") {
          options = [
            {
              label: translation("app.common.closed"),
              value: "closed",
            },
          ];
        } else if (record.type === "cancellation") {
          options = [
            {
              label: translation("app.common.cancelled"),
              value: "cancelled",
            },
          ];
        }

        return (
          <NxpFormItem
            editing={editing}
            controlType="select"
            controlProps={{
              value: editing ? editRecord?.defectStatus : value,
              options: options,
              onChange: (value) =>
                setEditRecord(
                  (prevState) =>
                    prevState && {
                      ...prevState,
                      defectStatus: value as Inspection["defectStatus"],
                    }
                ),
            }}
          />
        );
      },
    },

    {
      title: translation("app.common.nextInspectionDate"),
      dataIndex: "nextinspectiondate",
      width: 200,
      editable: true,
      render: (value: unknown, record: Inspection) => {
        if (record.type !== "inspection") return null;
        const editing = editRecord?.key === record.key;
        return (
          <NxpFormItem
            editing={editing}
            controlType="datePicker"
            error={validationError.nextinspectiondate}
            controlProps={{
              value: editing
                ? editRecord?.nextinspectiondate
                : record.nextinspectiondate,
              onChange: (value: Date | undefined) =>
                setEditRecord(
                  (prevState) =>
                    prevState && {
                      ...prevState,
                      nextinspectiondate: value as Date,
                    }
                ),
            }}
          />
        );
      },
    },
    {
      title: translation("app.common.attachments"),
      dataIndex: "nextinspectionacctactment",
      width: 100,
      editable: true,
      render: (value: unknown, record: Inspection) => {
        return (
          <>
            <PaperClipOutlined /> {record?.defectMessage.attachments.length}
          </>
        );
      },
    },
  ];

  const formSchema = yup.object().shape({
    date: yup.date().required(translation("app.common.dateIsRequired")),
    comment: yup
      .string()
      .nullable()
      .required(translation("app.common.commentIsRequired")),
    defectStatus: yup
      .string()
      .nullable()
      .required(translation("app.common.statusIsRequired")),
    nextinspectiondate: yup
      .date()
      .required(translation("app.common.nextInspectionDateIsRequired")),
  });

  const [editItem, setEditItem] = useState<Inspection>();
  const [saveInProgress, setSaveInProgress] = useState(false);

  const [drawerItemIndex, setDrawerItemIndex] = useState<number | undefined>();

  useEffect(() => {
    if (activeTab !== "inspection") setDrawerItemIndex(undefined);
  }, [activeTab]);

  const handleSaveValidated = () => {
    if (!editItem) return;
    setSaveInProgress(true);
    onMessageEditSubmit(editItem).then((success) => {
      if (success) {
        setEditItem(undefined);
        setSaveInProgress(false);
      }
    });
  };

  const [validationError, , clearError, saveWithValidate] = useValidate<
    Pick<Inspection, "date" | "comment" | "defectStatus" | "nextinspectiondate">
  >(editItem, formSchema, handleSaveValidated);

  const columns = useMemo(
    () => getColumns(editItem, setEditItem, validationError),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editItem, validationError]
  );

  const handleRowEdit = useCallback(
    (editItem: Inspection) => setEditItem({ ...editItem }),
    []
  );

  const handleRowSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  const handleRowCancel = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      setEditItem(undefined);
      clearError();
    },
    [clearError]
  );

  const inspectionData: Inspection[] = [];
  {
    let inspectionCounter = 0;
    for (const message of defect.messages) {
      inspectionData.push({
        key: message.id,
        type: message.type,
        sequence: message.type === "inspection" ? ++inspectionCounter : 0,
        date: moment(`${message.date} 00:00:00`).toDate(),
        inspector: message.createdBy.name,
        comment: message.comment,
        defectStatus: message.defectStatus,
        nextinspectiondate:
          message.type === "inspection"
            ? moment(`${message.nextInspectionDate} 00:00:00`).toDate()
            : new Date(0),
        defectMessage: message,
      });
    }
  }
  return (
    <>
      {editable ? (
        <NxpTableEditable
          editItem={editItem}
          saveInProgress={saveInProgress}
          // validationError={validationError as ValidationResult<Inspection>}
          itemCompareKey="key"
          onRow={(record: Inspection, rowIndex: number | undefined) => {
            return {
              onClick: (ev) => {
                if (
                  ev.target instanceof HTMLTableCellElement ||
                  ev.target instanceof HTMLParagraphElement ||
                  ev.target instanceof SVGPathElement
                ) {
                  if (
                    ["inspection", "response", "closure"].includes(record.type)
                  ) {
                    setDrawerItemIndex(rowIndex);
                  } else {
                    setDrawerItemIndex(undefined);
                  }
                }
              },
            };
          }}
          onRowEdit={handleRowEdit}
          onRowSave={handleRowSave}
          onRowCancel={handleRowCancel}
          rowClassName={(record, index) => {
            return index === drawerItemIndex ? classes.highlightedRow : "";
          }}
          columns={columns as any} //any for fixing antd type error
          dataSource={inspectionData as any}
          pagination={false}
          hideDelete
          loading={loading}
        />
      ) : (
        <NxpTable
          loading={loading}
          onRow={(record: Inspection, rowIndex: number | undefined) => {
            return {
              onClick: (ev) => {
                if (
                  ev.target instanceof HTMLTableCellElement ||
                  ev.target instanceof HTMLParagraphElement ||
                  ev.target instanceof SVGPathElement
                ) {
                  if (
                    ["inspection", "response", "closure"].includes(record.type)
                  ) {
                    setDrawerItemIndex(rowIndex);
                  } else {
                    setDrawerItemIndex(undefined);
                  }
                }
              },
            };
          }}
          rowClassName={(record, index) => {
            return index === drawerItemIndex ? classes.highlightedRow : "";
          }}
          columns={columns as any} //any for fixing antd type error
          dataSource={inspectionData as any}
          pagination={false}
        />
      )}

      <AttachmentDrawer
        editable={editable}
        message={
          drawerItemIndex === undefined
            ? undefined
            : inspectionData[drawerItemIndex].defectMessage
        }
        onClose={() => {
          setDrawerItemIndex(undefined);
        }}
      />
    </>
  );
};

export default InspectionListLayout;
