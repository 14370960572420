import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../hooks/useAppContext";
import { useValidate } from "../../../hooks/useValidate";
import {
  createSubcontractor,
  SubcontractorCreateForm,
} from "../../../services/subcontractor";
import notify from "../../../utils/notify";

interface AddSubcontractorModalProps {
  modalVisible: boolean;
  onModalClose: () => void;
}

const initialFormValues = {
  name: "",
};

const AddSubcontractorModal: React.FC<AddSubcontractorModalProps> = ({
  modalVisible,
  onModalClose,
}) => {
  const appContext = useAppContext();
  const {
    serviceConfig,
    subcontractors,
    routeParams,
    errorHandler,
    onAppContextCacheItemUpdate,
  } = appContext;
  const { entityId } = routeParams;

  const { t: translation } = useTranslation();

  const formSchema = yup.object({
    name: yup
      .string()
      .nullable()
      .required(translation("app.common.nameIsRequired")),
    primaryContactPerson: yup
      .string()
      .required(translation("app.common.primaryContactPersonIsRequired")),
    primaryContactEmail: yup
      .string()
      .email(translation("app.common.primaryContactEmailMustBeAValidEmail"))
      .required(translation("app.common.primaryContactEmailIsRequired")),
  });

  const [editForm, setEditForm] =
    useState<SubcontractorCreateForm>(initialFormValues);

  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleSaveValidated = async () => {
    setSaveInProgress(true);
    try {
      const subcontractor = await createSubcontractor(
        entityId!,
        editForm,
        serviceConfig
      );
      onAppContextCacheItemUpdate("subcontractors", [
        subcontractor,
        ...subcontractors,
      ]);
      setEditForm(initialFormValues);
      notify.actionCompleted();
      onModalClose();
    } catch (ex: any) {
      if (
        ex.message.includes("duplicate key value violates unique constraint")
      ) {
        notify.error(
          translation(
            "subcontractorList.addSubcontractor.handleSaveValidated.error.duplicate"
          )
        );
      } else {
        errorHandler(
          ex,
          translation(
            "subcontractorList.addSubcontractor.handleSaveValidated.error.label"
          )
        );
      }
    } finally {
      setSaveInProgress(false);
    }
  };

  const [validationError, , , saveWithValidate] =
    useValidate<SubcontractorCreateForm>(
      editForm,
      formSchema,
      handleSaveValidated
    );

  const handleFormGridStateChange = (
    fieldName: keyof SubcontractorCreateForm,
    value: unknown
  ) => {
    setEditForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleModalSave = () => {
    saveWithValidate(undefined);
  };

  const formItems: NxpFormGridItemProps<SubcontractorCreateForm>[] = [
    {
      controlType: "input",
      label: translation("app.common.name"),
      itemFieldName: "name",
      required: true,
      span: 24,
    },
    {
      controlType: "input",
      label: translation("app.common.primaryContactPerson"),
      itemFieldName: "primaryContactPerson",
      required: true,
      span: 12,
    },
    {
      controlType: "input",
      label: translation("app.common.primaryContactEmail"),
      itemFieldName: "primaryContactEmail",
      required: true,
      span: 12,
    },
    {
      controlType: "input",
      label: translation("app.common.address"),
      itemFieldName: "address",
      span: 24,
    },
    {
      controlType: "input",
      label: translation("app.common.smsAreaCode"),
      itemFieldName: "smsAreaCode",
      span: 12,
    },
    {
      controlType: "input",
      label: translation("app.common.smsNo"),
      itemFieldName: "smsNo",
      span: 12,
    },
  ];

  useEffect(() => {
    if (modalVisible) {
      setEditForm({
        name: "",
        primaryContactPerson: "",
        primaryContactEmail: "",
        address: "",
        smsNo: "",
        smsAreaCode: "",
      });
    }
  }, [modalVisible]);

  return (
    <NxpModal
      title={translation("app.common.addSubcontractor")}
      visible={modalVisible}
      onCancel={onModalClose}
      footer={
        <NxpButton disabled={saveInProgress} onClick={handleModalSave}>
          {translation("app.common.save")}
        </NxpButton>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={editForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddSubcontractorModal;
