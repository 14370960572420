import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { NxpHeader } from "@nexploretechnology/nxp-ui";
import Switch from "antd/lib/switch";

import useAppContext from "../../../hooks/useAppContext";

import Dashboard from "../../Dashboard";
import { LocationDetailsContext } from "../LocationDetailsContainer";
import TabBar from "../TabBar";

import { useTranslation } from "react-i18next";

interface Props {
  onActiveKeyChange: (activeKey: string) => void;
}

const useStyles = createUseStyles(() => ({
  switch: {
    display: "flex",
    color: "#8A9999",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "uppercase",
    position: "relative",
    left: "650px",
    top: "45px",
    "& p": {
      padding: "0px 5px 0px 5px",
    },
  },
}));

const LocationDashboardTab: React.FC<Props> = ({ onActiveKeyChange }) => {
  const classes = useStyles();

  const appContext = useAppContext();
  const { getChildLocationIdList } = appContext;

  const { location } = useContext(LocationDetailsContext);

  const { t: translation } = useTranslation();

  const [toggleChecked, setToggleChecked] = useState<boolean>(false);
  const [locations, setLocations] = useState<number[]>([]);

  const handleToggle = (checked: boolean) => {
    setToggleChecked(checked);
    let locations_ = [];
    if (checked) {
      locations_ = getChildLocationIdList(location!);
    } else {
      locations_.push(location?.id);
    }
    setLocations(locations_);
  };

  useEffect(() => {
    setToggleChecked(false);

    if (location) {
      let locations_ = [];
      locations_.push(location.id);
      setLocations(locations_);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <NxpHeader titleContent={location?.name} />
      <TabBar activeKey="dashboard" onChange={onActiveKeyChange} />
      <div className={classes.switch}>
        <Switch checked={toggleChecked} onChange={handleToggle} />
        <p>
          {translation(
            "locationDetails.locationDashboardTab.includeSubLocation"
          )}
        </p>
      </div>
      <Dashboard locations={locations} />
    </>
  );
};

export default LocationDashboardTab;
