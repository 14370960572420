import React, { useEffect, useState } from "react";

import { AppDefectListItem } from "../../components/AppDefectList/AppDefectListLayout";
import AppDefectSummaryReportGenerationModal from "../../components/AppDefectSummaryReportGenerationModal";
import useAppContext from "../../hooks/useAppContext";
import { User } from "../../services/app";
import { Defect, getDefectsExcel } from "../../services/defect";
import { getInspectors } from "../../services/inspector";
import notify from "../../utils/notify";
import { stringToBoolean } from "../../utils/throttle";
import InspectorsLayout from "./InspectorsLayout";

import { useTranslation } from "react-i18next";

interface InspectorsLayoutContainerProps {}

const InspectorsLayoutContainer: React.FC<InspectorsLayoutContainerProps> =
  () => {
    const appContext = useAppContext();
    const { serviceConfig, routeParams, users } = appContext;
    const { entityId, locationGroupId } = routeParams;

    const { t: translation } = useTranslation();

    const [activeTab, setActiveTab] = useState<string>();
    const [extraQueries, setExtraQueries] = useState<string[]>([]);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [inspector, setInspector] = useState<User | undefined>(undefined);
    const [inspectors, setInspectors] = useState<User[] | undefined>(undefined);
    const [invalidateAt, setInvalidateAt] = useState<number>(0);
    const [order, setOrder] = useState<"asc" | "desc">("desc"); // for reference only, AppDefectList handle sorting on it's own
    const [sortBy, setSortBy] = useState<undefined | string>("no"); // for reference only, AppDefectList handle sorting on it's own
    const [showPhoto, setShowPhoto] = useState<boolean>(true);
    const [shouldGenerateListingReport, setShouldGenerateListingReport] =
      useState<boolean>(false);

    const searchPhase = `&responsiblePerson=${inspector?.id}`;
    let queryString = [searchPhase, ...extraQueries].join("&");
    if (!queryString.startsWith("?")) queryString = `?${queryString}`;

    let blockedFilterFields: (keyof AppDefectListItem)[] =
      [] as (keyof AppDefectListItem)[];
    if (searchPhase.startsWith("?responsiblePerson")) {
      blockedFilterFields = ["responsiblePerson"];
    }

    const handleDefectCreated = () => {
      setInvalidateAt(new Date().getTime());
    };

    const handleExportExcel = async () => {
      try {
        await getDefectsExcel(
          entityId,
          locationGroupId,
          queryString,
          sortBy,
          order,
          serviceConfig
        );
      } catch (e) {
        notify.error(e);
      }
    };

    const handleFilterApply = (queryStrings: string[]) => {
      setExtraQueries(queryStrings);
      setFilterModalVisible(false);
    };

    const handleItemSelect = async (selectedKeys: React.Key[], info: any) => {
      setInspector(
        inspectors?.filter((inspector) => inspector.id === info?.node?.key)[0]
      );
    };

    const handleMenuClick = (e: any) => {
      setShowPhoto(stringToBoolean(e?.key));
      setShouldGenerateListingReport(true);
    };

    const handleSortingChange = (sortBy: string, order: "asc" | "desc") => {
      setSortBy(sortBy);
      setOrder(order);
    };

    const handleTabChange = (activeKey: string) => {
      setActiveTab(activeKey);
    };

    useEffect(() => {
      getInspectors(entityId!, locationGroupId!, serviceConfig).then(
        (inspectors) => {
          setInspectors(
            users
              .filter((user) =>
                inspectors.some(
                  (inspector: { userId: string }) =>
                    user.id === inspector.userId
                )
              )
              .sort(function (a, b) {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })
          );
        }
      );
    }, [entityId, locationGroupId, serviceConfig, users]);
    const tableItem: {
      label: string;
      getVal: string[];
      key?: keyof Defect;
      width: string;
    }[] = [
      {
        label: "#",
        getVal: ["no"],
        key: "no",
        width: "5%",
      },
      {
        label: translation("app.common.responsibleParty"),
        getVal: ["responsibleParty.name"],
        key: "responsibleParty",
        width: "10%",
      },
      {
        label: translation("app.common.raisedOn"),
        key: "raiseDate",
        getVal: ["raiseDate"],
        width: "10%",
      },
      {
        label: translation("app.common.dueDate"),
        key: "dueDate",
        getVal: ["dueDate"],
        width: "10%",
      },
      {
        label: translation("app.common.status"),
        key: "status",
        getVal: ["status"],
        width: "10%",
      },
      {
        label: translation("app.common.type"),
        key: "defectGroup",
        getVal: ["defectGroup.name"],
        width: "10%",
      },
      {
        label: translation("app.common.location"),
        key: "location",
        getVal: ["location"],
        width: "20%",
      },
      {
        label: translation("app.common.description"),
        key: "defectGroup",
        getVal: [
          "defectGroup.name",
          "defectSubject.name",
          "defectDescription.name",
        ],
        width: "15%",
      },
      {
        label: translation("app.common.round"),
        key: "round",
        getVal: ["round"],
        width: "10%",
      },
      {
        label: translation("app.common.remarks"),
        key: "remarks",
        getVal: ["remarks"],
        width: "10%",
      },
    ];

    return (
      <>
        <InspectorsLayout
          activeTab={activeTab!}
          blockedFilterFields={blockedFilterFields}
          extraQueries={extraQueries}
          filterModalVisible={filterModalVisible}
          inspector={inspector}
          inspectors={inspectors!}
          invalidateAt={invalidateAt}
          queryString={queryString}
          handleDefectCreated={handleDefectCreated}
          handleExportExcel={handleExportExcel}
          handleFilterApply={handleFilterApply}
          handleItemSelect={handleItemSelect}
          handleMenuClick={handleMenuClick}
          handleSortingChange={handleSortingChange}
          handleTabChange={handleTabChange}
          setFilterModalVisible={setFilterModalVisible}
        />
        <AppDefectSummaryReportGenerationModal
          reportTitle={translation("inspectors.inspectorDefectsListReport")}
          tableItem={tableItem}
          execute={shouldGenerateListingReport}
          queryString={queryString}
          sortBy={sortBy || "no"}
          order={order}
          onClose={() => setShouldGenerateListingReport(false)}
          showAttachmentPhoto={showPhoto}
        />
      </>
    );
  };

export default InspectorsLayoutContainer;
