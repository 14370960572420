import React from "react";

import SubcontractorTable from "./SubcontractorTable";

interface SubcontractorListContainerProps {}

const SubcontractorListContainer: React.FC<SubcontractorListContainerProps> =
  () => {
    return <SubcontractorTable />;
  };

export default SubcontractorListContainer;
