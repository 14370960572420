import { apiRequest, ServiceConfig } from "../utils/backend";
import { Team } from "./app";

export interface TeamCreateForm extends Pick<Team, "name" | "description"> {}

export const createTeam = async (
  entityId: string,
  teamCreateForm: TeamCreateForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Team>(`/entities/${entityId}/teams`, serviceConfig, {
    method: "POST",
    body: JSON.stringify(teamCreateForm),
  });
};

export const updateTeam = async (
  entityId: string,
  team: Team,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Team>(
    `/entities/${entityId}/teams/${team.id}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        name: team?.name,
        description: team?.description,
      }),
    }
  );
};

export const deleteTeam = async (
  entityId: string,
  id: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(`/entities/${entityId}/teams/${id}`, serviceConfig, {
    method: "DELETE",
  });
};

export const updateTeamMemberList = async (
  entityId: string,
  id: number,
  userIds: string[],
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Team>(
    `/entities/${entityId}/teams/${id}/members`,
    serviceConfig,
    {
      method: "PUT",
      body: JSON.stringify({ userIds }),
    }
  );
};
