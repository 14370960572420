import React, { useEffect, useState } from "react";

import { Defect } from "../../../../services/defect";

import CurrentAreaTableLayout from "../CurrentAreaTableLayout";

import { useTranslation } from "react-i18next";

interface CurrentAreaByResponsiblePartyContainerProps {
  defects: Defect[];
}

let initialData: {
  key: string;
  party: string;
  total: string;
  fixed: string;
  outstanding: string;
}[] = [];

const CurrentAreaByResponsiblePartyContainer: React.FC<CurrentAreaByResponsiblePartyContainerProps> =
  ({ defects }) => {
    const { t: translation } = useTranslation();

    const [data, setData] = useState(initialData);

    const columns = [
      {
        title: translation(
          "dashboard.defectAnalysis.currentAreaByResponsibleParty.party"
        ),
        dataIndex: "party",
        align: "left",
      },
      {
        title: translation("app.common.total"),
        dataIndex: "total",
        align: "center",
      },
      {
        title: translation("app.common.fixed"),
        dataIndex: "fixed",
        align: "center",
      },
      {
        title: translation("app.common.outstanding"),
        dataIndex: "outstanding",
        align: "center",
      },
    ];

    useEffect(() => {
      initialData = [];

      const responsibleParties = new Set(
        defects.map((defect) => defect.responsibleParty?.id)
      );

      responsibleParties.forEach((value) => {
        initialData.push({
          key: `${value}`,
          party: `${
            defects.find((defect) => defect.responsibleParty?.id === value)
              ?.responsibleParty?.name
          }`,
          total: `${
            defects.filter((defect) => defect.responsibleParty?.id === value)
              .length
          }`,
          fixed: `${
            defects.filter(
              (defect) =>
                defect.responsibleParty?.id === value &&
                defect?.status === "closed"
            ).length
          }`,
          outstanding: `${defects
            .filter(
              (defect) =>
                defect.responsibleParty?.id === value &&
                (defect?.status === "open" ||
                  defect?.status === "mark-fixed" ||
                  defect?.status === "mark-closed") &&
                new Date(defect.dueDate) >= new Date()
            )
            .length.toString()}`,
        });

        initialData.sort((a, b) => Number(b.total) - Number(a.total));
        setData([...initialData]);
      });
    }, [defects]);
    return (
      <CurrentAreaTableLayout
        header={translation(
          "dashboard.defectAnalysis.currentAreaByResponsibleParty.noOfDefectInCurrentAreaByResponsibleParty"
        )}
        columns={columns}
        data={[...data]}
      />
    );
  };

export default CurrentAreaByResponsiblePartyContainer;
