import React from "react";

import ChecklistLayout from "./ChecklistLayout";

interface ChecklistContainerProps {}

const ChecklistContainer: React.FC<ChecklistContainerProps> = () => {
  return <ChecklistLayout />;
};

export default ChecklistContainer;
