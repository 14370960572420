import { useRef, useState } from "react";
import { useRouteMatch } from "react-router";
import useAppContext from "../../../hooks/useAppContext";
import {
  DefectDescription,
  DefectSubject,
  Enum,
  Location,
  getDefectGroups,
  getEntityInfo,
  getEnums,
  getLocationGroups,
  getLocationTree,
  getMyself,
  getSubcontractors,
  getTeams,
  getUsers,
  getUserRightList,
  getEntityParameter,
  getMyUserPreference,
  createMyUserPreference,
} from "../../../services/app";
import notify from "../../../utils/notify";
import { useTranslation } from "react-i18next";

// for setting album in app context based on route param
const useRouteAppContextUpdate = () => {
  const appContext = useAppContext();
  const {
    activeEntity,
    activeLocationGroup,
    locationFullPaths,
    routeParams,
    serviceConfig,
    onAppContextCacheItemUpdate,
  } = appContext;
  const { t: translation } = useTranslation();
  const [loadingEntityId, setLoadingEntityId] = useState<string>("");
  const [loadingLocationGroupId, setLoadingLocationGroupId] =
    useState<number>(0);
  const loadingLanguage = useRef(false);

  let routeParamsChanged = false;

  let entityId: string | undefined;
  {
    const route = useRouteMatch<{ entityId: string }>("/entities/:entityId");
    entityId = route?.params.entityId;
  }
  if (entityId !== routeParams.entityId) {
    routeParamsChanged = true;
  }

  let locationGroupId: number | undefined;
  {
    const route = useRouteMatch<{ locationGroupId: string }>(
      "/entities/:entityId/location-groups/:locationGroupId"
    );
    locationGroupId = Number(route?.params.locationGroupId);
    if (!Number.isFinite(locationGroupId)) {
      locationGroupId = undefined;
    }
  }
  if (locationGroupId !== routeParams.locationGroupId) {
    routeParamsChanged = true;
  }

  let locationId: number | undefined = undefined;
  {
    const route = useRouteMatch<{ locationId: string }>(
      "/entities/:entityId/location-groups/:locationGroupId/locations/:locationId"
    );
    locationId = Number(route?.params.locationId);
    if (!Number.isFinite(locationId)) {
      locationId = undefined;
    }
  }
  if (locationId !== routeParams.locationId) {
    routeParamsChanged = true;
  }

  let defectId: number | undefined = undefined;
  {
    const route = useRouteMatch<{ defectId: string }>(
      "/entities/:entityId/location-groups/:locationGroupId/defects/:defectId"
    );
    defectId = Number(route?.params.defectId);
    if (!Number.isFinite(defectId)) {
      defectId = undefined;
    }
  }
  if (defectId !== routeParams.defectId) {
    routeParamsChanged = true;
  }

  let subcontractorId: number | undefined = undefined;
  {
    const route = useRouteMatch<{ subcontractorId: string }>(
      "/entities/:entityId/location-groups/:locationGroupId/subcontractor/:subcontractorId"
    );

    subcontractorId = Number(route?.params.subcontractorId);
    if (!Number.isFinite(subcontractorId)) {
      subcontractorId = undefined;
    }
  }
  if (subcontractorId !== routeParams.subcontractorId) {
    routeParamsChanged = true;
  }

  let reportType: string | undefined = undefined;
  {
    const route = useRouteMatch<{ reportType: string }>(
      "/entities/:entityId/location-groups/:locationGroupId/subcontractor/:subcontractorId/report/:reportType"
    );
    reportType = route?.params.reportType.toString();
  }
  if (reportType !== routeParams.reportType) {
    routeParamsChanged = true;
  }

  if (routeParamsChanged) {
    onAppContextCacheItemUpdate("routeParams", {
      entityId,
      locationGroupId,
      locationId,
      defectId,
      subcontractorId,
      reportType,
    });
  }

  if (!serviceConfig.token) return;

  if (entityId) {
    if (appContext.activeUser && !loadingLanguage.current) {
      loadingLanguage.current = true;
      getMyUserPreference(appContext.serviceConfig).then((userPreference) => {
        const languagePreference = userPreference.find(
          (u) => u.application === "entity" && u.parameter === "language"
        );
        if (languagePreference) {
          appContext.onAppContextCacheItemUpdate(
            "language",
            languagePreference.value
          );
        } else {
          createMyUserPreference(appContext.serviceConfig, {
            application: "entity",
            parameter: "language",
            value: "en",
          }).then((newUserPreference) => {
            appContext.onAppContextCacheItemUpdate(
              "language",
              newUserPreference.value
            );
          });
        }
      });
    }
    if (activeEntity?.id !== entityId && loadingEntityId !== entityId) {
      setLoadingEntityId(entityId);

      getEntityInfo(entityId, serviceConfig)
        .then((entity) => {
          onAppContextCacheItemUpdate("activeEntity", entity);
        })
        .catch((e) =>
          notify.error(
            `${translation("app.common.error")} ${entityId} : ${e.message}`
          )
        );

      getEntityParameter(entityId, serviceConfig)
        .then((entityParameter) => {
          onAppContextCacheItemUpdate("activeEntityParameter", entityParameter);
        })
        .catch((e) =>
          notify.error(
            `${translation("app.common.error")} ${entityId} : ${e.message}`
          )
        );

      getSubcontractors(entityId, serviceConfig)
        .then((subcontractors) => {
          onAppContextCacheItemUpdate("subcontractors", subcontractors);
        })
        .catch((e) =>
          notify.error(
            `${translation(
              "appRouter.getSubcontractors.error"
            )} ${entityId} : ${e.message}`
          )
        );

      getTeams(entityId, serviceConfig)
        .then((teams) => {
          onAppContextCacheItemUpdate("teams", teams);
        })
        .catch((e) =>
          notify.error(
            `${translation("appRouter.getTeams.error")} ${entityId} : ${
              e.message
            }`
          )
        );

      getMyself(entityId, serviceConfig)
        .then((user) => {
          onAppContextCacheItemUpdate("activeUser", user);
        })
        .catch((e) =>
          notify.error(
            `${translation("appRouter.getMyself.error")}: ${e.message}`
          )
        );

      getUserRightList(entityId, serviceConfig)
        .then((userRightList) => {
          onAppContextCacheItemUpdate("userRightList", userRightList);
        })
        .catch((e) =>
          notify.error(
            `${translation("appRouter.getUserRightList.error")}: ${e.message}`
          )
        );

      getUsers(entityId, serviceConfig)
        .then((users) => {
          onAppContextCacheItemUpdate("users", users);
        })
        .catch((e) =>
          notify.error(
            `${translation("appRouter.getUsers.error")} ${entityId} : ${
              e.message
            }`
          )
        );

      getLocationGroups(entityId, serviceConfig)
        .then((locationGroups) => {
          onAppContextCacheItemUpdate("locationGroups", locationGroups);
        })
        .catch((e) =>
          notify.error(
            `${translation(
              "appRouter.getLocationGroups.error"
            )} ${entityId} : ${e.message}`
          )
        );

      getEnums(entityId, serviceConfig)
        .then((enums: Enum[]) => {
          onAppContextCacheItemUpdate(
            "defectCauses",
            enums.filter((en) => en.type === "defectCause")
          );
          onAppContextCacheItemUpdate(
            "defectTypes",
            enums.filter((en) => en.type === "defectType")
          );
          onAppContextCacheItemUpdate(
            "inspectionTypes",
            enums.filter((en) => en.type === "inspectionType")
          );
        })
        .catch((e) =>
          notify.error(
            `${translation("appRouter.getEnums.error")} ${entityId} : ${
              e.message
            }`
          )
        );

      getDefectGroups(entityId, serviceConfig)
        .then((defectGroups) => {
          onAppContextCacheItemUpdate("defectGroups", defectGroups);

          let defectSubjects: DefectSubject[] = [];
          for (const defectGroup of defectGroups) {
            defectSubjects = defectSubjects.concat(defectGroup.defectSubjects);
          }
          onAppContextCacheItemUpdate("defectSubjects", defectSubjects);

          let defectDescriptions: DefectDescription[] = [];
          for (const defectSubject of defectSubjects) {
            defectDescriptions = defectDescriptions.concat(
              defectSubject.defectDescriptions
            );
          }
          onAppContextCacheItemUpdate("defectDescriptions", defectDescriptions);
        })
        .catch((e) =>
          notify.error(
            `${translation("appRouter.getDefectGroups.error")} ${entityId} : ${
              e.message
            }`
          )
        );
    }
  }

  if (entityId && locationGroupId) {
    if (
      activeLocationGroup?.id !== locationGroupId &&
      loadingLocationGroupId !== locationGroupId
    ) {
      setLoadingLocationGroupId(locationGroupId);

      getLocationTree(entityId, locationGroupId, serviceConfig)
        .then((location) => {
          sortChildLocations(location);
          onAppContextCacheItemUpdate("activeLocationGroup", location);
          const dict = { ...locationFullPaths };
          buildFullPathDict(location, null, dict);
          onAppContextCacheItemUpdate("locationFullPaths", dict);
        })
        .catch((e) =>
          notify.error(
            `${translation(
              "appRouter.getLocationTree.error"
            )} ${locationGroupId} : ${e.message}`
          )
        );
    }
  }
};

const sortChildLocations = (root: Location) => {
  if (root.children && root.children.length > 0) {
    root.children.sort((a, b) => (a.name > b.name ? 1 : -1));
    root.children.forEach((child) => sortChildLocations(child));
  }
};

const buildFullPathDict = (
  root: Location,
  parentPath: string | null,
  dict: Record<number, string>
) => {
  const myPath = parentPath ? `${parentPath} > ${root.name}` : root.name;
  dict[root.id] = myPath;
  if (!root.children) return;
  for (const child of root.children) {
    buildFullPathDict(child, myPath, dict);
  }
};

export default useRouteAppContextUpdate;
