import React from "react";

import useAppContext from "../../../hooks/useAppContext";
import { Team } from "../../../services/app";
import { updateTeamMemberList } from "../../../services/team";
import notify from "../../../utils/notify";
import TeamMembersLayout from "./TeamMembersLayout";
import { useTranslation } from "react-i18next";

interface TeamMembersContainerProps {}

const TeamMembersContainer: React.FC<TeamMembersContainerProps> = () => {
  const appContext = useAppContext();
  const { serviceConfig, teams, routeParams, onAppContextCacheItemUpdate } =
    appContext;
  const { entityId } = routeParams;

  const { t: translation } = useTranslation();

  const handleSaveUserList = (team: Team, userIds: string[]) => {
    updateTeamMemberList(entityId!, team.id, userIds, serviceConfig)
      .then((team) => {
        const newTeams = [...teams];
        newTeams[teams.findIndex((user) => user.id === team.id)] = team;
        onAppContextCacheItemUpdate("teams", newTeams);
        notify.success(`User List saved for ${team.name}`);
      })
      .catch((err) =>
        notify.error(`${translation("app.common.error")} ${team.name} : ${err}`)
      );
  };

  return (
    <TeamMembersLayout teams={teams} onSaveUserList={handleSaveUserList} />
  );
};

export default TeamMembersContainer;
