import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { UploadOutlined } from "@ant-design/icons";
import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import { Upload } from "antd";
import moment from "moment";
import * as yup from "yup";

import useAppContext from "../../../../hooks/useAppContext";
import { useValidate } from "../../../../hooks/useValidate";
import { File } from "../../../../services/app";
import notify from "../../../../utils/notify";
import { DefectDetailsContext } from "../../DefectDetailsContainer";

interface AddResponseModalProps {
  visible: boolean;
  onDismiss: () => void;
}

export interface AddResponseGridForm {
  date: Date;
  comment: string;
  attachments: number[];
}

const emptyForm = {
  date: new Date(),
  comment: "",
  attachments: [],
};

const AddResponseModel: React.FC<AddResponseModalProps> = (props) => {
  const { serviceConfig, routeParams } = useAppContext();
  const { entityId } = routeParams;
  const { defect, onAddResponseSubmit } = useContext(DefectDetailsContext);

  const { t: translation } = useTranslation();

  const formSchema = yup.object().shape({
    date: yup.date().required(translation("app.common.dateIsRequired")),
    comment: yup
      .string()
      .nullable()
      .required(translation("app.common.commentIsRequired")),
  });

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [addResponseForm, setAddResponseForm] =
    useState<AddResponseGridForm>(emptyForm);

  const formItems: NxpFormGridItemProps<AddResponseGridForm>[] = [
    {
      controlType: "datePicker",
      label: translation("app.common.date"),
      itemFieldName: "date",
      controlProps: {
        disabledDate: (current) => {
          return current < moment(Number(defect.createdAt));
        },
      },
      span: 8,
    },
    {
      controlType: "input",
      label: translation("app.common.comment"),
      itemFieldName: "comment",
      span: 24,
      startOnNewRow: true,
    },
    {
      controlType: "custom",
      label: translation("app.common.attachments"),
      itemFieldName: "attachments",
      customContent: (
        <Upload
          name="file"
          multiple={true}
          action={`${serviceConfig.apiBaseUrl}/entities/${entityId}/files`}
          headers={{
            Authorization: `Bearer ${serviceConfig.token}`,
          }}
          onChange={(info) => {
            const { status } = info.file;
            if (status !== "uploading") {
              console.log(info.fileList);
            }

            if (status === "done") {
              const file: File = info.file.response as File;
              handleFormGridStateChange("attachments", [
                ...addResponseForm.attachments,
                file.id,
              ]);
            } else if (status === "error") {
              notify.error(`${info.file.name} upload failed.`);
            } else if (status === "removed") {
              const file: File = info.file.response as File;
              handleFormGridStateChange("attachments", [
                addResponseForm.attachments.filter(
                  (fileId) => fileId !== file.id
                ),
              ]);
            }
          }}
          onDrop={(event) => {
            console.log("Dropped files", event.dataTransfer.files);
          }}
        >
          <NxpButton icon={<UploadOutlined />} type="default">
            {translation("app.common.clickToUpload")}
          </NxpButton>
        </Upload>
      ),
      span: 12,
    },
  ];

  const handleSaveValidated = () => {
    if (!submitting) {
      setSubmitting(true);
      onAddResponseSubmit(addResponseForm)
        .then((success) => {
          if (success) {
            props.onDismiss();
            setAddResponseForm(emptyForm);
          }
          setSubmitting(false);
        })
        .catch((e) => {
          setSubmitting(false);
        });
    }
  };

  const [validationError, , , saveWithValidate] =
    useValidate<AddResponseGridForm>(
      addResponseForm,
      formSchema,
      handleSaveValidated
    );

  const handleSaveClick = () => {
    saveWithValidate(undefined);
  };

  const handleFormGridStateChange = (
    fieldName: keyof AddResponseGridForm,
    value: unknown
  ) => {
    setAddResponseForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  return (
    <NxpModal
      title={translation("app.common.inspection.addResponse.addResponse")}
      destroyOnClose={true}
      visible={props.visible}
      onCancel={() => {
        if (!submitting) props.onDismiss();
      }}
      footer={
        <NxpButton onClick={handleSaveClick}>
          {translation("app.common.save")}
        </NxpButton>
      }
      width="small"
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={addResponseForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddResponseModel;
