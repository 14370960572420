import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import AddDefectGroupModal from "./AddDefectGroupModal";

interface AddDefectGroupButtonProps {
  entityId: string;
  handleAddToList: (item: any) => void;
}

const AddDefectGroupButton: React.FC<AddDefectGroupButtonProps> = ({
  entityId,
  handleAddToList,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const { t: translation } = useTranslation();

  const handleModalVisible = () => {
    setModalVisible(false);
  };

  return (
    <>
      <NxpButton onClick={() => setModalVisible(true)}>
        {translation("settings.defectGroup.addDefectGroup.addDefectGroup")}
      </NxpButton>
      <AddDefectGroupModal
        entityId={entityId}
        modalVisible={modalVisible}
        handleAddToList={handleAddToList}
        handleModalVisible={handleModalVisible}
      />
    </>
  );
};

export default AddDefectGroupButton;
