import clsx from "clsx";
import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Button, Upload, UploadProps, Modal } from "antd";
import { createUseStyles } from "react-jss";
import AppFloorPlan from "../../../components/AppFloorPlan";
import { LocationDetailsContext } from "../LocationDetailsContainer";
import "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import {
  EditFilled,
  ExclamationCircleOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import useAppContext from "../../../hooks/useAppContext";
import {
  deleteFloorPlan,
  linkUpFloorPlan,
  updateFloorPlanArea,
  uploadFloorPlan,
} from "../../../services/plan";
import { NxpButton } from "@nexploretechnology/nxp-ui";
import DeleteFloorPlanContainer from "../LocationDeleteFloorPlan";
import notify from "../../../utils/notify";
import TabBar from "../TabBar";
import { NxpHeader } from "@nexploretechnology/nxp-ui";
import { StatisticQuery, getDefectStatistic } from "../../../services/defect";
import AppEmptyFloorPlan from "../../../components/AppEmptyFloorPlan";

import { useTranslation } from "react-i18next";
import { map, omit } from "lodash";

interface LocationFloorPlanProps {
  onActiveKeyChange: (activeKey: string) => void;
}
const useStyles = createUseStyles((theme) => ({
  root: {
    "& .floor-plan-action": {
      "& .ant-btn": {
        margin: theme.spacing(0, 1),
      },
      "& .ant-btn-default": {
        color: theme.palette.background,
        background: theme.palette.warning,
        border: "none",
      },
      "& .ant-btn-primary": {
        color: theme.palette.background,
        background: theme.palette.primary,
        border: "none",
      },
    },
    "& .ant-btn": {
      color: theme.palette.primary,
    },
    "& .upload_btn": {
      fontWeight: theme.fontWeight.bolder,
      background: theme.palette.primary,
      color: theme.palette.background,
      fontSize: theme.pxToRem(12),
    },
  },
  empty: {
    background: theme.palette.backgroundLight,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",

    "& .file-upload": {
      display: "flex",
      alignItems: "center",
    },
    "& .upload_btn": {
      marginRight: theme.spacing(1),
    },
    "& .ant-upload.ant-upload-select": {
      display: "block",
    },
    "& .upload": {
      margin: theme.spacing(1, 0),
      width: "100%",
      "& .file-type": {
        fontWeight: theme.fontWeight.bolder,
        color: theme.palette.textLabel,
        padding: theme.spacing(0, 2),
      },
    },
    "& .ant-upload-list": {
      color: theme.palette.primary,
    },
  },
}));
const LocationFloorPlan: React.FC<LocationFloorPlanProps> = ({
  onActiveKeyChange,
}) => {
  const classes = useStyles();
  const {
    location,
    onUpdateLocation,
    onGenerateAutoImage,
    onCopyFloorPlanFromParent,
    onEditFloorPlanImageStart,
    defectStatistics,
  } = useContext(LocationDetailsContext);

  const { t: translation } = useTranslation();

  const [fileList, setFileList] = useState<any[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const appContext = useAppContext();
  const { serviceConfig, routeParams, hasRight } = appContext;
  const { entityId, locationGroupId, locationId } = routeParams;
  const [delectFloorPlanModalVisible, setDelectFloorPlanMModalVisible] =
    useState(false);
  let floorPlanArea: any[] = [];

  const [defectAffacted, setDefectAffacted] = useState<number>(0);
  useEffect(() => {
    const checking = async () => await handleCheckingDeletable();
    checking().then((res) => {
      if (res) setDefectAffacted(res);
    });
    return () => {
      setFileList([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, locationId, entityId, location]);
  const props = {
    onRemove: (file: any) => {
      setFileList([]);
    },
    beforeUpload: (file: any) => {
      setFileList((preState) => [...preState, file]);
      return false;
    },
    fileList,
  } as UploadProps;
  const handleUpload = async () => {
    setUploading(true);
    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append("file", file);
    });
    const result = await uploadFloorPlan(entityId, formData, serviceConfig);
    if (result?.id) {
      try {
        const linkUpRes = await linkUpFloorPlan(
          entityId,
          locationGroupId,
          locationId,
          result?.id,
          serviceConfig
        );
        setUploading(false);
        notify.success("Floor Plan created");
        if (linkUpRes) onUpdateLocation();
      } catch (error) {
        notify.error(error);
        setUploading(false);
        return false;
      }
    }
  };

  const handleAutoImageClick = () => {
    const defectCount = parseInt(defectStatistics?.pinned?.count || "0");
    if (defectCount > 0) {
      if (hasRight("location-admin")) {
        Modal.confirm({
          title: "Replace existing Floor Plan?",
          icon: <ExclamationCircleOutlined />,
          okText: "Replace anyway",
          cancelText: "Cancel",
          content: `${defectCount} defect(s) are pinned onto this floor plan. Once this floor plan is replaced, the defect pins will be lost.`,
          onOk: async () => {
            await onGenerateAutoImage();
          },
          onCancel: () => {},
        });
      } else {
        notify.info(
          `${defectCount} defect(s) are related to this floor plan. Only Location Admin can replace this floor plan.`
        );
      }
    } else {
      onGenerateAutoImage();
    }
  };

  const handleCopyParentClick = () => {
    const defectCount = parseInt(defectStatistics?.pinned?.count || "0");
    if (defectCount > 0) {
      if (hasRight("location-admin")) {
        Modal.confirm({
          title: "Replace existing Floor Plan?",
          icon: <ExclamationCircleOutlined />,
          okText: "Replace anyway",
          cancelText: "Cancel",
          content: `${defectCount} defect(s) are pinned onto this floor plan. Once this floor plan is replaced, the defect pins will be lost.`,
          onOk: async () => {
            await onCopyFloorPlanFromParent();
          },
          onCancel: () => {},
        });
      } else {
        notify.info(
          `${defectCount} defect(s) are related to this floor plan. Only Location Admin can replace this floor plan.`
        );
      }
    } else {
      onCopyFloorPlanFromParent();
    }
  };
  const handleSaveFloorPlan = async (): Promise<boolean> => {
    try {
      let creatFloorPlanRes = await updateFloorPlanArea(
        entityId,
        locationId,
        locationGroupId,
        location?.floorPlans[0]?.id,
        map(floorPlanArea, (item) => omit(item, ["id"])),
        serviceConfig
      );
      if (creatFloorPlanRes)
        notify.success(translation("app.common.floorPlan.success"));
      onUpdateLocation();
      return true;
    } catch (error) {
      notify.error(error);
      return false;
    }
  };
  const handleOnFloorPlanChange = (area: any) => {
    floorPlanArea = [...area];
  };
  const handleCheckingDeletable = async () => {
    const query = {
      locationId: locationId,
      locationGroupId: locationGroupId,
    } as StatisticQuery;
    if (entityId) {
      try {
        const defectCount = await getDefectStatistic(
          entityId,
          query,
          serviceConfig
        );
        return Number(defectCount?.count);
      } catch (error) {
        notify.error(error);
        // return false;
      }
    }
    // return true;
  };
  const handleOnDelete = async () => {
    if (entityId && locationId && locationGroupId) {
      try {
        const deleteResult = await deleteFloorPlan(
          entityId,
          locationId,
          locationGroupId,
          serviceConfig
        );
        notify.success(translation("app.common.floorPlan.success"));
        let { success } = deleteResult;
        if (success) onUpdateLocation();

        return success ?? false;
      } catch (error) {
        notify.error(error);
        return false;
      }
    }
  };
  return (
    <div className={clsx("location-floor-plan", classes.root)}>
      <NxpHeader
        titleContent={location.name}
        actionContent={
          <Row className="floor-plan-action">
            {(hasRight("location-edit") || hasRight("location-admin")) &&
              !!location?.floorPlans.length && (
                <Col>
                  <NxpButton
                    type="default"
                    onClick={() => setDelectFloorPlanMModalVisible(true)}
                  >
                    {translation("app.common.delete")}
                  </NxpButton>
                  <DeleteFloorPlanContainer
                    isAdmin={hasRight("location-admin")}
                    defectAffacted={defectAffacted}
                    showModal={delectFloorPlanModalVisible}
                    handleOnDelete={handleOnDelete}
                    handleDialogClose={() => {
                      setDelectFloorPlanMModalVisible(false);
                    }}
                  />
                </Col>
              )}
            {(hasRight("location-edit") || hasRight("location-admin")) &&
              !!(
                location?.children.length && location?.floorPlans.length === 0
              ) && (
                <Col>
                  <NxpButton type="primary" onClick={handleAutoImageClick}>
                    {translation("locationDetails.locationFloorPlan.autoImage")}
                  </NxpButton>
                </Col>
              )}
            {(hasRight("location-edit") || hasRight("location-admin")) &&
              !!location.parent?.floorPlans?.filter(
                (floorPlan) => floorPlan.type === "image"
              ).length && (
                <Col>
                  <NxpButton type="primary" onClick={handleCopyParentClick}>
                    {translation(
                      "locationDetails.locationFloorPlan.copyParent"
                    )}
                  </NxpButton>
                </Col>
              )}
            {(hasRight("location-edit") || hasRight("location-admin")) &&
              location?.floorPlans.length > 0 && (
                <Col>
                  <NxpButton
                    type="primary"
                    icon={<SaveOutlined />}
                    onClick={handleSaveFloorPlan}
                  >
                    {translation("app.common.save")}
                  </NxpButton>
                </Col>
              )}
            {(hasRight("location-edit") || hasRight("location-admin")) &&
              !!(
                location?.floorPlans?.length &&
                location.floorPlans[0].type === "image"
              ) && (
                <Col>
                  <NxpButton
                    type="primary"
                    icon={<EditFilled />}
                    onClick={onEditFloorPlanImageStart}
                  >
                    {translation("locationDetails.locationFloorPlan.editImage")}
                  </NxpButton>
                </Col>
              )}
          </Row>
        }
      />
      <TabBar activeKey={"floor-plan"} onChange={onActiveKeyChange} />
      {(hasRight("location-edit") ||
        hasRight("location-admin") ||
        defectAffacted <= 0) && (
        <div className={classes.empty}>
          <Row justify="start" className="upload" align="middle">
            <Col className="file-type">
              {translation("locationDetails.locationFloorPlan.fileJpgPdf")}
            </Col>
            <Col flex="1">
              <Row justify="space-between" className="upload" align="middle">
                <Col className="file-upload">
                  <Upload {...props} data-testid="upload-container">
                    {fileList.length >= 1 ? (
                      <></>
                    ) : (
                      <Button icon={<UploadOutlined />}>
                        {translation(
                          "appDefectSummaryReportGenerationModel.addFile"
                        )}
                      </Button>
                    )}
                  </Upload>
                </Col>
                <Col>
                  <NxpButton
                    type="primary"
                    onClick={handleUpload}
                    disabled={fileList.length === 0}
                    loading={uploading}
                    size="large"
                    className="upload_btn"
                  >
                    {uploading
                      ? translation("app.common.uploading")
                      : translation("app.common.upload")}
                  </NxpButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}

      {location?.floorPlans.length ? (
        <AppFloorPlan
          editFloorPlan={true}
          handleOnFloorPlanChange={handleOnFloorPlanChange}
          locationChildNode={location?.children}
          type="location"
          floorPlan={{
            ...location?.floorPlans[0],
            container: {
              width: window.innerWidth - 650,
              height: window.innerHeight - 320,
            },
          }}
        />
      ) : (
        <AppEmptyFloorPlan />
      )}
    </div>
  );
};

export default LocationFloorPlan;
