import React from "react";
import { useTranslation } from "react-i18next";

import { NxpHeader } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../../hooks/useAppContext";
import { Subcontractor } from "../../../services/app";
import UserListPanel from "./UserListPanel";

interface SubcontractorUserLayoutProps {
  onSaveUserList: (subcontractor: Subcontractor, userIds: string[]) => void;
}

const SubcontractorUserLayout: React.FC<SubcontractorUserLayoutProps> = ({
  onSaveUserList,
}) => {
  const { subcontractors, hasRight } = useAppContext();

  const { t: translation } = useTranslation();

  return (
    <>
      <NxpHeader titleContent={translation("app.common.subcontractorUser")} />
      {hasRight("subcontractor-user-view") &&
        subcontractors.map((subcontractor, index) => (
          <UserListPanel
            key={`subcon-${index}`}
            subcontractor={subcontractor}
            onSave={onSaveUserList}
          />
        ))}
    </>
  );
};

export default SubcontractorUserLayout;
