import React from "react";

import { NxpFormItem } from "@nexploretechnology/nxp-ui";

import { AppDefectListItem } from "../AppDefectList/AppDefectListLayout";
import { Filter } from "./AppDefectFilterModalContainer";

interface OperatorWidgetProps {
  index: number;
  filter: Filter;
  onFilterOperatorChange: (index: number, value: any) => void;
}

const OPTION_IS = { label: "Is", value: "" };
const OPTION_NOT = { label: "Is Not", value: "Not" };
const OPTION_GTE = { label: "Greater Than Or Equal", value: "Gte" };
const OPTION_LTE = { label: "Less Than Or Equal", value: "Lte" };
const OPTION_INC = { label: "Contains", value: "Inc" };

const SETTING_FOR_ID_FIELD = {
  options: [OPTION_IS, OPTION_NOT],
};

const SETTING_FOR_SYSTEM_FIELD = {
  options: [OPTION_IS],
};

const SETTING_FOR_BOOLEAN_FIELD = {
  options: [OPTION_IS],
};

const SETTING_FOR_DATE_FIELD = {
  options: [OPTION_IS, OPTION_GTE, OPTION_LTE],
};

const SETTING_FOR_TIME_FIELD = {
  options: [OPTION_IS, OPTION_GTE, OPTION_LTE],
};

const SETTING_FOR_NUMBER_FIELD = {
  options: [OPTION_IS, OPTION_NOT, OPTION_GTE, OPTION_LTE],
};

const SETTING_FOR_STATUS_FIELD = {
  options: [OPTION_IS, OPTION_NOT],
};

const SETTING_FOR_FREE_TEXT_FIELD = {
  options: [OPTION_INC],
};

export const OperatorWidgetFields: Record<
  keyof AppDefectListItem | "isOutstanding" | "isJointInspection" | "isClosed",
  any
> = {
  key: null,
  id: null,
  raisedBy: SETTING_FOR_ID_FIELD,
  responsiblePerson: SETTING_FOR_ID_FIELD,
  isOutstanding: SETTING_FOR_SYSTEM_FIELD,
  isJointInspection: SETTING_FOR_SYSTEM_FIELD,
  isClosed: SETTING_FOR_SYSTEM_FIELD,
  no: SETTING_FOR_NUMBER_FIELD,
  status: SETTING_FOR_STATUS_FIELD,
  inspectionType: SETTING_FOR_ID_FIELD,
  jointInspectionId: SETTING_FOR_FREE_TEXT_FIELD,
  defectType: SETTING_FOR_ID_FIELD,
  createdAt: SETTING_FOR_TIME_FIELD,
  defectGroup: SETTING_FOR_ID_FIELD,
  defectSubject: SETTING_FOR_ID_FIELD,
  defectDescription: SETTING_FOR_ID_FIELD,
  responsibleParty: SETTING_FOR_ID_FIELD,
  nextInspectionDate: SETTING_FOR_DATE_FIELD,
  dueDate: SETTING_FOR_DATE_FIELD,
  isTopPriority: SETTING_FOR_BOOLEAN_FIELD,
  location: SETTING_FOR_ID_FIELD,
  remarks: SETTING_FOR_FREE_TEXT_FIELD,
  constructionLotNo: SETTING_FOR_FREE_TEXT_FIELD,
  ncrNo: SETTING_FOR_FREE_TEXT_FIELD,
};

const OperatorWidget: React.FC<OperatorWidgetProps> = (props) => {
  const { field, operator } = props.filter;

  if (!field) {
    return null;
  } else {
    const options = OperatorWidgetFields[field].options;
    return (
      <NxpFormItem
        controlType="select"
        controlProps={{
          options,
          value: operator,
          onChange: (value: any) =>
            props.onFilterOperatorChange(props.index, value as any),
        }}
      />
    );
  }
};

export default OperatorWidget;
