import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import AddInspectionTypeModal from "./AddInspectionTypeModal";

interface AddInspectionTypeButtonProps {
  handleAddToList: (item: any) => void;
}

const AddInspectionTypeButton: React.FC<AddInspectionTypeButtonProps> = ({
  handleAddToList,
}) => {
  const { t: translation } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const handleModalVisible = () => {
    setModalVisible(false);
  };

  return (
    <>
      <NxpButton onClick={() => setModalVisible(true)}>
        {translation("app.common.addInspectionType")}
      </NxpButton>
      <AddInspectionTypeModal
        modalVisible={modalVisible}
        handleAddToList={handleAddToList}
        handleModalVisible={handleModalVisible}
      />
    </>
  );
};

export default AddInspectionTypeButton;
