import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { NxpFormItem } from "@nexploretechnology/nxp-ui";
import { ColumnProps } from "antd/lib/table";
import * as yup from "yup";

import useAppContext from "../../../hooks/useAppContext";
import { useValidate, ValidationResult } from "../../../hooks/useValidate";
import { Team } from "../../../services/app";
import { deleteTeam, updateTeam } from "../../../services/team";
import notify from "../../../utils/notify";
import TeamLayout from "./TeamLayout";

interface TeamContainerProps {}

const TeamContainer: React.FC<TeamContainerProps> = () => {
  const appContext = useAppContext();
  const { serviceConfig, teams, routeParams, errorHandler, refetchTeams } =
    appContext;
  const { entityId } = routeParams;

  const [editItem, setEditItem] = useState<Team>();
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [teamListData, setTeamListData] = useState<Team[] | undefined>(
    undefined
  );
  const { t: translation } = useTranslation();

  const formSchema = yup.object().shape({
    name: yup
      .string()
      .nullable()
      .required(translation("app.common.teamNameIsRequired")),
    description: yup
      .string()
      .nullable()
      .required(translation("app.common.teamDescriptionIsRequired")),
  });

  const getColumns = (
    editRecord: Team | undefined,
    setEditRecord: (callback: SetStateAction<Team | undefined>) => void,
    validationError: ValidationResult<Team>
  ): ColumnProps<Team>[] => [
    {
      title: translation("app.common.name"),
      dataIndex: "name",
      fixed: "left",
      width: 300,
      render: (_: unknown, record: Team) => {
        const editing = editRecord?.id === record.id;
        return (
          <NxpFormItem
            editing={editing}
            controlType="input"
            error={validationError.name}
            controlProps={{
              value: editing ? editRecord?.name : record.name,
              onChange: (e) =>
                setEditRecord(
                  (prevState: any) =>
                    prevState && { ...prevState, name: e.target.value }
                ),
            }}
          />
        );
      },
    },
    {
      title: translation("app.common.teamDescription"),
      dataIndex: "description",
      fixed: "left",
      width: 300,
      render: (_: unknown, record: Team) => {
        const editing = editRecord?.id === record.id;
        return (
          <NxpFormItem
            editing={editing}
            controlType="input"
            error={validationError.description}
            controlProps={{
              value: editing ? editRecord?.description : record.description,
              onChange: (e) =>
                setEditRecord(
                  (prevState: any) =>
                    prevState && { ...prevState, description: e.target.value }
                ),
            }}
          />
        );
      },
    },
  ];

  const handleSaveValidated = async () => {
    setSaveInProgress(true);
    try {
      await updateTeam(entityId!, editItem!, serviceConfig);
      const rec = teamListData?.find((data) => data.id === editItem?.id);
      Object.assign(rec as {}, { ...editItem });
      refetchTeams(entityId, serviceConfig);
      setEditItem(undefined);
      notify.actionCompleted();
    } catch (ex) {
      errorHandler(
        ex,
        translation("team.handleSaveValidation.error.updatingTeam")
      );
    } finally {
      setSaveInProgress(false);
    }
  };

  const [validationError, , clearError, saveWithValidate] = useValidate<
    Pick<Team, "name" | "description">
  >(editItem, formSchema, handleSaveValidated);

  const columns = useMemo(
    () => getColumns(editItem, setEditItem, validationError),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editItem, validationError]
  );

  const handleAddToList = useCallback(
    (item: Team) => {
      refetchTeams(entityId, serviceConfig);
      setTeamListData([
        ...teamListData!,
        {
          id: item.id,
          name: item.name,
          description: item.description,
          members: [],
        },
      ]);
    },
    [entityId, refetchTeams, serviceConfig, teamListData]
  );

  const handleRowEdit = useCallback(
    (editItem: Team) => setEditItem({ ...editItem }),
    []
  );

  const handleRowSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  const handleRowCancel = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      setEditItem(undefined);
      clearError();
    },
    [clearError]
  );

  const handleRowDelete = useCallback(
    async (deleteItem: Team) => {
      setSaveInProgress(true);
      await deleteTeam(entityId!, deleteItem.id, serviceConfig);
      refetchTeams(entityId, serviceConfig);
      setTeamListData(teamListData?.filter((data) => data !== deleteItem));
      notify.actionCompleted();
      setEditItem(undefined);
      setTimeout(() => setSaveInProgress(false)); // need setTimeout only because this demo use synchronous procedure for deleting
    },
    [entityId, serviceConfig, refetchTeams, teamListData]
  );

  useEffect(() => {
    setTeamListData(teams);
  }, [teams, serviceConfig, entityId]);

  return (
    <TeamLayout
      columns={columns}
      editItem={editItem}
      saveInProgress={saveInProgress}
      teamListData={teamListData}
      handleAddToList={handleAddToList}
      handleRowCancel={handleRowCancel}
      handleRowDelete={handleRowDelete}
      handleRowEdit={handleRowEdit}
      handleRowSave={handleRowSave}
    />
  );
};

export default React.memo(TeamContainer);
