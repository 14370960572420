import React, { useEffect, useState } from "react";

import { Defect } from "../../../../services/defect";

import TotalDefectLayout from "./TotalDefectLayout";

import { useTranslation } from "react-i18next";

interface TotalDefectContainerProps {
  defects: Defect[];
}

let initialTotalDefectData: {
  type: string;
  value: number;
}[] = [];

const TotalDefectContainer: React.FC<TotalDefectContainerProps> = ({
  defects,
}) => {
  const { t: translation } = useTranslation();

  const [totalDefectData, setTotalDefectData] = useState(
    initialTotalDefectData
  );

  useEffect(() => {
    initialTotalDefectData = [];

    const defectGroups = new Set(
      defects.map((defect) => defect.defectGroup?.id)
    );

    defectGroups.forEach((value) => {
      initialTotalDefectData.push({
        type: `${translation("app.common.outstanding")} - ${
          defects.find((defect) => defect.defectGroup?.id === value)
            ?.defectGroup?.name
        }`,
        value: defects.filter(
          (defect) =>
            defect.defectGroup?.id === value &&
            (defect.status === "open" ||
              defect.status === "mark-fixed" ||
              defect.status === "mark-closed") &&
            new Date(defect.dueDate) >= new Date()
        ).length,
      });
    });

    defectGroups.forEach((value) => {
      initialTotalDefectData.push({
        type: `${translation("app.common.closed")} - ${
          defects.find((defect) => defect.defectGroup?.id === value)
            ?.defectGroup?.name
        }`,
        value: defects.filter(
          (defect) =>
            defect.defectGroup?.id === value && defect.status === "closed"
        ).length,
      });
    });

    setTotalDefectData([
      ...initialTotalDefectData.filter((item) => item.value !== 0),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defects]);

  return <TotalDefectLayout totalDefectData={totalDefectData} />;
};

export default TotalDefectContainer;
