import React from "react";
import { Location } from "../../../services/app";
import { createUseStyles } from "react-jss";

import icon from "../../../images/location-group-selection/location-group-icon.svg";

import { useTranslation } from "react-i18next";
import clsx from "clsx";

interface Props {
  locationGroup: Location;
  selected?: boolean;
  onSelect?: () => void;
}

const useStyles = createUseStyles((theme) => ({
  root: {
    width: 300,
    height: 360,
    display: "inline-block",
    border: `1px solid ${theme.palette.borderLight}`,
    borderRadius: theme.borderRadius.main,
    margin: 10,
    overflow: "hidden",
    background: theme.palette.backgroundLight,
    cursor: "pointer",
  },
  iconDiv: {
    width: "100%",
    height: 200,
    backgroundColor: "#FFD41E",
    background: `url(${icon}) no-repeat center;`,
  },
  contentDiv: {
    height: 104,
    padding: 20,
    boxSizing: "border-box",
    fontWeight: theme.fontWeight.bolder,
    fontSize: theme.fontSize.h2,
  },
  actionDiv: {
    borderTop: `1px solid ${theme.palette.borderLight}`,
    marginLeft: 20,
    marginRight: 20,
    paddingTop: 20,
    fontWeight: theme.fontWeight.bolder,
    fontSize: theme.fontSize.small,
    lineHeight: "16px",
    color: theme.palette.textLink,
    textTransform: "uppercase",
  },
}));

const LocationGroupCard: React.FC<Props> = ({
  locationGroup,
  onSelect = () => {},
}) => {
  const classes = useStyles();
  const { t: translation } = useTranslation();
  return (
    <div
      className={clsx(classes.root, "location_card")}
      onClick={onSelect}
      data-testid="location_card"
    >
      <div className={classes.iconDiv} />
      <div className={classes.contentDiv}>{locationGroup.name}</div>
      <div className={classes.actionDiv}>
        <span>{translation("app.common.select")}</span>
      </div>
    </div>
  );
};

export default LocationGroupCard;
