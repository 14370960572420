import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { UploadOutlined } from "@ant-design/icons";
import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import { Upload } from "antd";
import moment from "moment";
import * as yup from "yup";

import useAppContext from "../../../../hooks/useAppContext";
import { useValidate } from "../../../../hooks/useValidate";
import { File } from "../../../../services/app";
import notify from "../../../../utils/notify";
import { DefectDetailsContext } from "../../DefectDetailsContainer";

interface CloseOutModalProps {
  visible: boolean;
  onDismiss: () => void;
}
export interface CloseOutGridForm {
  date: Date;
  comment: string;
  actualcost: number;
  scheduleimpact: number;
  backcharge: string;
  cause: string; // need to change to Enum
  attachments: number[];
}

const emptyForm = {
  date: new Date(),
  comment: "",
  actualcost: 0,
  scheduleimpact: 0,
  backcharge: "",
  cause: "",
  attachments: [],
};

const CloseOutContainer: React.FC<CloseOutModalProps> = (props) => {
  const { defectCauses, serviceConfig, routeParams } = useAppContext();
  const { entityId } = routeParams;
  const { defect, onCloseOutSubmit } = useContext(DefectDetailsContext);

  const { t: translation } = useTranslation();

  const formSchema = yup.object().shape({
    date: yup.date().required(translation("app.common.dateIsRequired")),
    comment: yup
      .string()
      .nullable()
      .required(translation("app.common.commentIsRequired")),
    actualcost: yup
      .number()
      .nullable()
      .required(translation("app.common.actualCostIsRequired")),
    scheduleimpact: yup
      .number()
      .nullable()
      .required(translation("app.common.scheduleImpactIsRequired")),
    backcharge: yup
      .string()
      .nullable()
      .required(translation("app.common.backchargeIsRequired")),
    cause: yup
      .string()
      .nullable()
      .required(translation("app.common.causeIsRequired")),
  });

  const [submitting, setSubmitting] = useState<boolean>(false);

  const [closeOutForm, setCloseOutForm] = useState<CloseOutGridForm>(emptyForm);

  const handleSaveValidated = () => {
    if (!submitting) {
      setSubmitting(true);
      onCloseOutSubmit(closeOutForm)
        .then((success) => {
          if (success) {
            props.onDismiss();
            setCloseOutForm(emptyForm);
          }
          setSubmitting(true);
        })
        .catch((e) => setSubmitting(false));
    }
  };

  const [validationError, , , saveWithValidate] = useValidate<CloseOutGridForm>(
    closeOutForm,
    formSchema,
    handleSaveValidated
  );

  const handleSaveClick = () => {
    saveWithValidate(undefined);
  };

  const handleFormGridStateChange = (
    fieldName: keyof CloseOutGridForm,
    value: unknown
  ) => {
    setCloseOutForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const formItems: NxpFormGridItemProps<CloseOutGridForm>[] = [
    {
      controlType: "datePicker",
      label: translation("app.common.date"),
      itemFieldName: "date",
      controlProps: {
        disabledDate: (current) => {
          return current < moment(Number(defect.createdAt));
        },
      },
      span: 8,
    },
    {
      controlType: "input",
      label: translation("app.common.comment"),
      itemFieldName: "comment",
      span: 24,
      startOnNewRow: true,
    },
    {
      controlType: "inputNumber",
      label: translation("app.common.actualCost"),
      itemFieldName: "actualcost",
      span: 11,
      controlProps: {
        decimalPlace: 0,
        id: "actioncost",
        prefix: "$",
        keyboard: true,
      },
      startOnNewRow: true,
    },
    {
      controlType: "inputNumber",
      label: translation("app.common.scheduleImpact"),
      itemFieldName: "scheduleimpact",
      span: 11,
    },
    {
      controlType: "radioGroup",
      label: translation("app.common.backcharge"),
      itemFieldName: "backcharge",
      controlProps: {
        options: [
          {
            label: translation("app.common.yes"),
            value: "true",
          },
          {
            label: translation("app.common.no"),
            value: "false",
          },
        ],
      },
      span: 11,
      startOnNewRow: true,
    },
    {
      controlType: "select",
      controlProps: {
        allowClear: true,
        options: defectCauses.map((defectCause) => ({
          label: defectCause.name,
          value: "" + defectCause.id,
        })),
      },
      label: translation("app.common.cause"),
      itemFieldName: "cause",
      span: 13,
    },
    {
      controlType: "custom",
      label: translation("app.common.attachments"),
      itemFieldName: "attachments",
      customContent: (
        <Upload
          name="file"
          data-testid="uploadFile"
          multiple={true}
          action={`${serviceConfig.apiBaseUrl}/entities/${entityId}/files`}
          headers={{
            Authorization: `Bearer ${serviceConfig.token}`,
          }}
          onChange={(info) => {
            const { status } = info.file;
            if (status !== "uploading") {
              console.log(info.fileList);
            }

            if (status === "done") {
              const file: File = info.file.response as File;
              handleFormGridStateChange("attachments", [
                ...closeOutForm.attachments,
                file.id,
              ]);
            } else if (status === "error") {
              notify.error(`${info.file.name} upload failed.`);
            } else if (status === "removed") {
              const file: File = info.file.response as File;
              handleFormGridStateChange("attachments", [
                closeOutForm.attachments.filter((fileId) => fileId !== file.id),
              ]);
            }
          }}
          onDrop={(event) => {
            console.log("Dropped files", event.dataTransfer.files);
          }}
        >
          <NxpButton icon={<UploadOutlined />} type="default">
            {translation("app.common.clickToUpload")}
          </NxpButton>
        </Upload>
      ),
      span: 12,
    },
  ];

  return (
    <NxpModal
      title={translation("app.common.inspection.closeOut.defectCloseOut")}
      destroyOnClose={true}
      visible={props.visible}
      onCancel={() => {
        if (!submitting) props.onDismiss();
      }}
      footer={
        <NxpButton onClick={handleSaveClick}>
          {translation("app.common.save")}
        </NxpButton>
      }
      width="small"
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={closeOutForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default CloseOutContainer;
