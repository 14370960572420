import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../../../hooks/useAppContext";
import { useValidate } from "../../../../../hooks/useValidate";
import { createDefectSubject } from "../../../../../services/dictionary";
import notify from "../../../../../utils/notify";

interface AddSubjectModalProps {
  currentNode: { groupId: string };
  modalVisible: boolean;
  handleAddToList: (item: any, itemType: string) => void;
  handleModalVisible: () => void;
}

interface AddSubjectGridForm {
  name: string;
}

const AddSubjectContainer: React.FC<AddSubjectModalProps> = ({
  modalVisible,
  currentNode,
  handleAddToList,
  handleModalVisible,
}) => {
  const appContext = useAppContext();
  const { serviceConfig, routeParams, errorHandler, refetchDefectGroups } =
    appContext;
  const { entityId } = routeParams;

  const { t: translation } = useTranslation();

  const formSchema = yup.object().shape({
    name: yup
      .string()
      .nullable()
      .required(translation("app.common.defectSubjectIsRequired")),
  });

  const formItems: NxpFormGridItemProps<AddSubjectGridForm>[] = [
    {
      controlType: "input",
      label: translation("app.common.defectSubject"),
      itemFieldName: "name",
      span: 12,
    },
  ];

  const [addSubjectForm, setAddSubjectForm] = useState<AddSubjectGridForm>({
    name: "",
  });

  const handleSaveValidated = async () => {
    try {
      const response = await createDefectSubject(
        entityId!,
        addSubjectForm,
        Number(currentNode.groupId),
        serviceConfig
      );
      handleAddToList(
        {
          key: `Defect Group-${currentNode.groupId}-Defect Subject-${response.id}-`,
          title: response.name,
        },
        "subject"
      );
      handleModalVisible();
      notify.actionCompleted();
      refetchDefectGroups(entityId, serviceConfig);
    } catch (ex: any) {
      errorHandler(
        ex,
        translation(
          "app.common.subjectForm.addSubject.handleSaveValidated.error"
        )
      );
    }
  };

  const [validationError, , , saveWithValidate] =
    useValidate<AddSubjectGridForm>(
      addSubjectForm,
      formSchema,
      handleSaveValidated
    );

  const handleSaveClick = () => {
    saveWithValidate(undefined);
  };

  const handleFormGridStateChange = (
    fieldName: keyof AddSubjectGridForm,
    value: unknown
  ) => {
    setAddSubjectForm((prevState) => ({
      ...prevState,
      [fieldName]: value as string,
    }));
  };

  useEffect(() => {
    if (modalVisible) {
      setAddSubjectForm({ name: "" });
    }
  }, [modalVisible]);

  return (
    <NxpModal
      title={translation(
        "appDefectSummaryReportGenerationModel.addDefectSubject"
      )}
      visible={modalVisible}
      onCancel={handleModalVisible}
      footer={<NxpButton onClick={handleSaveClick}>Save</NxpButton>}
      width="small"
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={addSubjectForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddSubjectContainer;
