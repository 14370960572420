import React from "react";
import { createUseStyles } from "react-jss";

import {
  NxpFormGridItem,
  NxpFormGridItemProps,
} from "@nexploretechnology/nxp-ui";
import { Col, Form, FormInstance, FormProps, Row, RowProps } from "antd";
import clsx from "clsx";

import { GRID_COLUMN_TOTAL } from "../../../utils/const";

const useStyles = createUseStyles((theme) => ({
  AppLocationInfoLayout: {
    margin: theme.spacing(-2, 0, -2),
    position: "relative",
    "& > .ant-row::after": {
      // for hiding the last bottom border
      content: "''",
      position: "absolute",
      display: "block",
      height: 2,
      width: "101%",
      bottom: 0,
      left: -2,
      backgroundColor: theme.palette.backgroundLight,
    },
    "& .spread-col > div": {
      "& > .ant-form-item-label": {
        maxWidth: "calc(25% - 8px)",
      },
      "& > .ant-form-item-control": {
        maxWidth: "calc(75% + 8px)",
      },
    },
    "& .ant-form-item-label": {
      whiteSpace: "normal",
      textAlign: "left",
      "& > label": {
        height: "auto",
      },
      "& > label::after": {
        content: "''",
      },
    },

    "& .nxpFormStateItem": {
      padding: theme.spacing(2, 0),
      borderBottom: "1px solid",
      borderImageSlice: 1,
      borderImageSource: `linear-gradient(to right, transparent, transparent 15px, ${theme.palette.borderLight} 15px)`,
      "& + .nxpFormStateItem:not(.right-col)": {
        borderImageSource: `linear-gradient(to right, transparent, transparent 15px, ${theme.palette.borderLight} 15px, ${theme.palette.borderLight} calc(100% - 15px), transparent calc(100% - 15px))`,
      },
      "&.right-col": {
        borderImageSource: `linear-gradient(to right, ${theme.palette.borderLight} calc(100% - 15px), transparent calc(100% - 15px))`,
        "&::before": {
          content: "''",
          position: "absolute",
          display: "block",
          height: "calc(100% - 20px)",
          width: 1,
          top: 10,
          left: 0,
          backgroundColor: theme.palette.borderLight,
        },
      },
    },
    "& .ant-form-item.nxpFormItem": {
      marginBottom: 0,
    },
    "& .formItemDisplay": {
      marginBottom: 0,
    },
  },
}));

export interface NxpFormSurveyProps<T>
  extends Omit<FormProps, "children" | "layout">,
    Pick<RowProps, "gutter" | "align" | "justify" | "prefixCls" | "wrap"> {
  formItems: NxpFormGridItemProps<T>[];
  formRef?: React.Ref<FormInstance>;
  editing?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formState: T;
  validationError?: Partial<{ [k in keyof T]: string }>;
  onFormStateChange: (fieldName: keyof T, value: unknown) => void;
}

function AppLocationInfoLayout<T>(props: NxpFormSurveyProps<T>): JSX.Element {
  const {
    gutter = [32, 0],
    align,
    justify,
    prefixCls,
    wrap,
    editing,
    formItems,
    formRef,
    formState,
    validationError,
    onFormStateChange,
    ...restProps
  } = props;
  let spanRunningTotal = 0;

  const classes = useStyles();

  return (
    <Form
      className={clsx("AppLocationInfoLayout", classes.AppLocationInfoLayout)}
      ref={formRef}
      {...restProps}
    >
      <Row
        gutter={gutter}
        align={align}
        justify={justify}
        prefixCls={prefixCls}
        wrap={wrap}
      >
        {formItems.map((formItem, idx) => {
          const span =
            formItem.span === 1 ? GRID_COLUMN_TOTAL / 2 : GRID_COLUMN_TOTAL;
          const labelCol = { span: formItem.span > 1 ? 6 : 12 };
          const wrapperCol = { span: formItem.span > 1 ? 18 : 12 };

          const itemClass = clsx(
            spanRunningTotal % GRID_COLUMN_TOTAL === GRID_COLUMN_TOTAL / 2 &&
              "right-col",
            span === GRID_COLUMN_TOTAL && "spread-col",
            formItem.className
          );

          const formGridItem =
            formItem.itemFieldName === "custom" ? (
              <NxpFormGridItem
                key={idx}
                editing={editing}
                {...formItem}
                className={itemClass}
                span={span}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
              />
            ) : (
              <NxpFormGridItem
                key={idx} // use idx as fromItem has no unique key
                editing={editing}
                error={
                  validationError && validationError[formItem.itemFieldName]
                }
                {...formItem}
                className={itemClass}
                itemValue={formState[formItem.itemFieldName]}
                itemOnChange={(value) =>
                  formItem.itemFieldName !== "custom"
                    ? onFormStateChange(formItem.itemFieldName, value)
                    : undefined
                }
                span={span}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
              />
            );

          if (formItem.startOnNewRow) {
            const colSpanToFillRow =
              GRID_COLUMN_TOTAL - (spanRunningTotal % GRID_COLUMN_TOTAL);
            spanRunningTotal +=
              (formItem.span === 1 ? 12 : 24) + colSpanToFillRow;
            if (colSpanToFillRow > 0) {
              return (
                <React.Fragment key={idx}>
                  <Col span={colSpanToFillRow} />
                  {formGridItem}
                </React.Fragment>
              );
            }
          }

          spanRunningTotal += formItem.span === 1 ? 12 : 24;
          return formGridItem;
        })}
      </Row>
    </Form>
  );
}

export default AppLocationInfoLayout;
