import { apiRequest, ServiceConfig } from "../utils/backend";
import { Subcontractor, User } from "./app";

export interface EmailLog {
  id: number;
  endityId: number;
  subcon: Subcontractor;
  toEmail: string;
  ccUsers: User[];
  createdOn: string;
  subject: string;
}

export type ReminderType = "dueDate" | "newDefect";
export interface SubcontractorSubscription {
  entityId: string;
  id: number;
  isDaily: boolean;
  isCcSubconUser: boolean;
  isAttachDefectReport: boolean;
  subcon: Subcontractor;
  reminderType: ReminderType;
}

export const getAllReminders = async (
  entityId: string | undefined,
  serviceConfig: ServiceConfig
): Promise<SubcontractorSubscription[]> => {
  return await apiRequest(
    `/entities/${entityId}/reminder-settings`,
    serviceConfig
  );
};
export const createReminders = async (
  entityId: string | undefined,
  subcontactorId: Subcontractor["id"],
  reminderType: ReminderType,
  isDaily: boolean,
  isCcSubconUser: boolean,
  isAttachDefectReport: boolean,
  serviceConfig: ServiceConfig
): Promise<
  Omit<SubcontractorSubscription, "subcon"> & { subconId: number }
> => {
  return await apiRequest(
    `/entities/${entityId}/reminder-settings`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        subconId: subcontactorId,
        reminderType,
        isDaily,
        isCcSubconUser,
        isAttachDefectReport,
      }),
    }
  );
};
export const updateReminders = async (
  entityId: string | undefined,
  reminderId: number,
  reminderType: ReminderType,
  isDaily: boolean,
  isCcSubconUser: boolean,
  isAttachDefectReport: boolean,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/entities/${entityId}/reminder-settings/${reminderId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        reminderType,
        isDaily,
        isCcSubconUser,
        isAttachDefectReport,
      }),
    }
  );
};
export const deleteReminders = async (
  entityId: string | undefined,
  reminderId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/entities/${entityId}/reminder-settings/${reminderId}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};
export const getEmailLogBySubcon = async (
  entityId: string | undefined,
  subcontactorId: Subcontractor["id"],
  serviceConfig: ServiceConfig
): Promise<EmailLog[]> => {
  return await apiRequest(
    `/entities/${entityId}/mail-logs/${subcontactorId}`,
    serviceConfig
  );
};
