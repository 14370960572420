import React, { useEffect, useState } from "react";
import * as yup from "yup";

import { NxpButton } from "@nexploretechnology/nxp-ui";
import { NxpFormGrid } from "@nexploretechnology/nxp-ui";
import { NxpFormGridItemProps } from "@nexploretechnology/nxp-ui";
import { NxpModal } from "@nexploretechnology/nxp-ui";
import useAppContext from "../../../../hooks/useAppContext";
import { useValidate } from "../../../../hooks/useValidate";
import { createDefectGroup } from "../../../../services/defectGroup";
import notify from "../../../../utils/notify";

import { useTranslation } from "react-i18next";

interface AddDefectGroupModalProps {
  entityId: string;
  modalVisible: boolean;
  handleAddToList: (item: any) => void;
  handleModalVisible: () => void;
}

interface AddDefectGroupGridForm {
  name: string;
  localName: string;
  code: string;
}

const AddDefectGroupContainer: React.FC<AddDefectGroupModalProps> = ({
  modalVisible,
  handleAddToList,
  handleModalVisible,
}) => {
  const appContext = useAppContext();
  const { serviceConfig, routeParams, errorHandler } = appContext;
  const { entityId } = routeParams;

  const { t: translation } = useTranslation();

  const formItems: NxpFormGridItemProps<AddDefectGroupGridForm>[] = [
    {
      controlType: "input",
      label: translation("app.common.groupName"),
      itemFieldName: "name",
      span: 12,
    },
    {
      controlType: "input",
      label: translation("app.common.inLocalLanguage"),
      itemFieldName: "localName",
      span: 12,
    },
    {
      controlType: "input",
      label: translation("app.common.shortCode"),
      itemFieldName: "code",
      span: 12,
    },
  ];

  const formSchema = yup.object().shape({
    name: yup
      .string()
      .nullable()
      .required(translation("app.common.groupNameIsRequired")),
    localName: yup
      .string()
      .nullable()
      .required(translation("app.common.inLocalLanguageIsRequired")),
    code: yup
      .string()
      .nullable()
      .required(translation("app.common.shortCodeIsRequired")),
  });

  const [addDefectGroupForm, setAddDefectGroupForm] =
    useState<AddDefectGroupGridForm>({
      name: "",
      localName: "",
      code: "",
    });

  const handleSaveValidated = async () => {
    try {
      const response = await createDefectGroup(
        entityId!,
        addDefectGroupForm!,
        serviceConfig
      );
      handleAddToList({
        id: response.id,
        name: response.name,
        localName: response.localName,
        code: response.code,
      });
      handleModalVisible();
      notify.actionCompleted();
    } catch (ex) {
      errorHandler(
        ex,
        translation(
          "settings.defectGroup.addDefectGroup.handleSaveValidated.error"
        )
      );
    }
  };

  const [validationError, , , saveWithValidate] =
    useValidate<AddDefectGroupGridForm>(
      addDefectGroupForm,
      formSchema,
      handleSaveValidated
    );

  const handleSaveClick = () => {
    saveWithValidate(undefined);
  };

  const handleFormGridStateChange = (
    fieldName: keyof AddDefectGroupGridForm,
    value: unknown
  ) => {
    setAddDefectGroupForm((prevState) => ({
      ...prevState,
      [fieldName]: value as string,
    }));
  };

  useEffect(() => {
    if (modalVisible) {
      setAddDefectGroupForm({ name: "", localName: "", code: "" });
    }
  }, [modalVisible]);

  return (
    <NxpModal
      title={translation("settings.defectGroup.addDefectGroup.addDefectGroup")}
      visible={modalVisible}
      onCancel={handleModalVisible}
      footer={
        <NxpButton onClick={handleSaveClick}>
          {translation("app.common.save")}
        </NxpButton>
      }
      width="small"
    >
      <NxpFormGrid
        formItems={formItems}
        formState={addDefectGroupForm}
        validationError={validationError}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddDefectGroupContainer;
