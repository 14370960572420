import React from "react";
import { createUseStyles } from "react-jss";
import { Card } from "antd";

import { useTranslation } from "react-i18next";

import { formatNumber } from "../../../utils/formatNumber";

interface CostLayoutProps {
  cost: {
    key: string;
    title: string;
    value: string;
  }[];
}

const useStyles = createUseStyles(() => ({
  statisticsRow: {
    display: "flex",
    padding: "0px 0px 10px 0px",
    maxWidth: "calc(60vw + 25px)",
  },
  statisticsCard: {
    textAlign: "center",
    marginRight: "20px",
    minWidth: "200px",
    maxHeight: "100px",
    "& h1": {
      display: "flex",
      justifyContent: "center",
      fontSize: 25,
      color: "#005794",
      margin: 0,
    },
    "& p": {
      fontSize: 12,
      fontWeight: 700,
      color: "#8A9999",
      textTransform: "uppercase",
    },
  },
}));

const CostLayout: React.FC<CostLayoutProps> = ({ cost }) => {
  const classes = useStyles();
  const { t: translation } = useTranslation();
  return (
    <>
      <h2>{translation("app.common.cost")}</h2>
      <div className={classes.statisticsRow}>
        {cost.map((item: any) => (
          <Card size="small" className={classes.statisticsCard} key={item.key}>
            <h1>${formatNumber(item.value).toString()}</h1>
            <p>{item.title}</p>
          </Card>
        ))}
      </div>
    </>
  );
};

export default CostLayout;
