import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { NxpPanelEditable, NxpSelectUser } from "@nexploretechnology/nxp-ui";
import { Col, Row } from "antd";

import useAppContext from "../../../../hooks/useAppContext";
import { Team } from "../../../../services/app";

interface Props {
  team: Team;
  onSave?: (team: Team, userIds: string[]) => void;
}

const useStyles = createUseStyles((theme) => ({
  root: {
    marginBottom: 10,
    "& .ant-select": {
      width: "100%",
    },
  },
  label: {
    color: theme.palette.textLabel,
    fontSize: theme.fontSize.smaller,
    textTransform: "uppercase",
    fontWeight: theme.fontWeight.bold,
  },
}));

const MemberListPanel: React.FC<Props> = ({ team, onSave = () => {} }) => {
  const classes = useStyles();
  const appContext = useAppContext();
  const { users, hasRight } = appContext;

  const { t: translation } = useTranslation();

  const [userIds, setUserIds] = useState<string[]>([] as string[]);

  const handleSave = (setEditing: (editing: boolean) => void) => {
    setEditing(false);
    onSave(team, userIds);
  };

  const handleValueChange = (value: string | string[]) => {
    if (typeof value == "string") {
      value = [value];
    }
    setUserIds(value);
  };

  useEffect(() => {
    setUserIds(team.members?.map((user) => "" + user.id) || []);
  }, [team]);

  return (
    <NxpPanelEditable
      className={classes.root}
      titleContent={`${team.name} (${team.members?.length || 0})`}
      defaultCollapsed={true}
      onPanelSave={handleSave}
      editable={hasRight("setup-edit")}
    >
      {(editing) => {
        if (!editing) {
          return <div>{team.members?.map((user) => user.name).join(", ")}</div>;
        }

        return (
          <Row>
            <Col span={24}>
              <label className={classes.label}>
                {translation("app.common.user")}
              </label>
              <NxpSelectUser
                mode="multiple"
                allowClear={true}
                options={users.map((user) => ({
                  name: user?.name,
                  id: "" + user?.id,
                }))}
                value={userIds}
                onChange={handleValueChange}
                filterOption={(input, option: any) => {
                  return (
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
              ></NxpSelectUser>
            </Col>
          </Row>
        );
      }}
    </NxpPanelEditable>
  );
};

export default MemberListPanel;
