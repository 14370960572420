import React, { useEffect, useState } from "react";

import { Defect } from "../../../../services/defect";

import DefectCauseLayout from "./DefectCauseLayout";

interface DefectCauseContainerProps {
  defects: Defect[];
}

let initialData: {
  cause: string;
  total: string;
}[] = [];

const DefectCauseContainer: React.FC<DefectCauseContainerProps> = ({
  defects,
}) => {
  const [data, setData] = useState(initialData);

  useEffect(() => {
    initialData = [];

    const defectCauses = new Set(
      defects
        .filter((defect) => defect.status === "closed")
        .map((defect) => defect.analysis?.cause?.id)
    );

    defectCauses.forEach((value) => {
      initialData.push({
        cause: `${
          defects.find((defect) => defect.analysis?.cause?.id === value)
            ?.analysis?.cause?.name
        }`,
        total: `${
          defects.filter(
            (defect) =>
              defect.analysis?.cause?.id === value &&
              defect.status !== "cancelled"
          ).length
        }`,
      });

      initialData.sort((a, b) => Number(b.total) - Number(a.total));
      setData([...initialData]);
    });
  }, [defects]);

  return <DefectCauseLayout data={data} />;
};

export default DefectCauseContainer;
