import React from "react";
import InspectionLayout from "./InspectionLayout";

interface InspectionContainerProps {}

const InspectionContainer: React.FC<InspectionContainerProps> = () => {
  return <InspectionLayout />;
};

export default InspectionContainer;
