import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import { DefectDetailsContext } from "../../DefectDetailsContainer";
import AddResponseModal from "./AddResponseModal";

interface AddResponseButtonProps {}

const AddResponseButton: React.FC<AddResponseButtonProps> = () => {
  const { defect } = useContext(DefectDetailsContext);

  const { t: translation } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const handleModalVisible = () => {
    setModalVisible(false);
  };

  return (
    <>
      <NxpButton
        onClick={() => setModalVisible(true)}
        disabled={["closure", "cancelled"].includes(defect.status)}
      >
        {translation("app.common.inspection.addResponse.addResponse")}
      </NxpButton>
      <AddResponseModal visible={modalVisible} onDismiss={handleModalVisible} />
    </>
  );
};

export default AddResponseButton;
