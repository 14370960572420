import React, { useEffect, useState } from "react";

import { EntityParameter, updateEntityParameter } from "../../../services/app";
import useAppContext from "../../../hooks/useAppContext";
import { useValidate } from "../../../hooks/useValidate";
import notify from "../../../utils/notify";
import ParametersLayout from "./ParametersLayout";
import * as yup from "yup";

import { useTranslation } from "react-i18next";

interface ParametersContainerProps {}

const ParametersContainer: React.FC<ParametersContainerProps> = () => {
  const appContext = useAppContext();
  const {
    activeEntityParameter,
    serviceConfig,
    routeParams,
    hasRight,
    onAppContextCacheItemUpdate,
  } = appContext;
  const { entityId } = routeParams;

  const { t: translation } = useTranslation();

  const formSchema = yup.object({
    dueDate: yup
      .number()
      .nullable()
      .required(translation("app.common.dueDateIsRequired")),
    projectEntity: yup
      .string()
      .nullable()
      .required("settings.parameters.projectEntityIsRequired"),
    nextInspectionDate: yup
      .number()
      .nullable()
      .required(translation("app.common.nextInspectionDateIsRequired")),
    reinspectionDate: yup
      .number()
      .nullable()
      .required(translation("settings.parameters.reinspectionDateIsRequired")),
    dueDateReminderForSubcontractor: yup
      .number()
      .nullable()
      .required(
        translation("app.common.dueDateReminderForSubcontractorIsRequired")
      ),
    dueDateReminderForInspector: yup
      .number()
      .nullable()
      .required(
        translation("app.common.dueDateReminderForInspectorIsRequired")
      ),
    dueDatePriority: yup
      .number()
      .nullable()
      .required(translation("app.common.dueDateIsRequired")),
    nextInspectionDatePriority: yup
      .number()
      .nullable()
      .required(translation("app.common.nextInspectionDatePriorityIsRequired")),
    reinspectionDatePriority: yup
      .number()
      .nullable()
      .required(
        translation("settings.parameters.reinspectionDatePriorityIsRequired")
      ),
  });

  const [parametersForm, setParametersForm] = useState<
    EntityParameter | undefined
  >(undefined);

  const handleSaveValidated = async () => {
    const entityParameter = await updateEntityParameter(
      entityId!,
      parametersForm,
      serviceConfig
    );
    onAppContextCacheItemUpdate("activeEntityParameter", entityParameter);
    notify.actionCompleted();
  };

  const [validationError, , , saveWithValidate] = useValidate<
    Pick<
      EntityParameter,
      | "dueDate"
      | "projectEntity"
      | "nextInspectionDate"
      | "reinspectionDate"
      | "dueDateReminderForSubcontractor"
      | "dueDateReminderForInspector"
      | "dueDatePriority"
      | "nextInspectionDatePriority"
      | "reinspectionDatePriority"
    >
  >(parametersForm, formSchema, handleSaveValidated);

  const handleSave = () => {
    return (
      Object.values(saveWithValidate(undefined)).filter(
        (val) => val !== undefined
      ).length === 0
    );
  };

  const handleFormGridStateChange = (
    fieldName: keyof EntityParameter,
    value: unknown
  ) => {
    setParametersForm((prevState: any) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handlePanelSave = (setEditing: (editing: boolean) => void) => {
    if (handleSave()) {
      setEditing(false);
    }
  };

  useEffect(() => {
    setParametersForm(activeEntityParameter);
  }, [activeEntityParameter, serviceConfig, entityId]);

  return (
    <ParametersLayout
      parametersForm={parametersForm}
      validationError={validationError}
      handleFormGridStateChange={handleFormGridStateChange}
      handlePanelSave={handlePanelSave}
      hasRight={hasRight}
    />
  );
};

export default ParametersContainer;
