import React, { useEffect, useState } from "react";

import { Defect } from "../../../services/defect";

import PerformanceLayout from "./PerformanceLayout";

import { useTranslation } from "react-i18next";

function checkValid(value: number) {
  return Number.isFinite(value) ? value.toFixed(1) : 0;
}

interface PerformanceContainerProps {
  defects: Defect[];
}

const PerformanceContainer: React.FC<PerformanceContainerProps> = ({
  defects,
}) => {
  const { t: translation } = useTranslation();

  const initialPerformance: { key: string; title: string; value: string }[] = [
    {
      key: "Locations Inspected",
      title: translation("performanceContainer.locationsInspected"),
      value: "0",
    },
    {
      key: "Days Required In Average For Fixing One Defect",
      title: translation(
        "performanceContainer.daysRequiredInAverageForFixingOneDefect"
      ),
      value: "0",
    },
    {
      key: "Days Impact On Project Schedule",
      title: translation("performanceContainer.daysImpactOnProjectSchedule"),
      value: "0",
    },
    {
      key: "Rounds In Average For Fixing A Defect",
      title: translation(
        "performanceContainer.roundsInAverageForFixingADefect"
      ),
      value: "0",
    },
  ];

  const [performance, setPerformance] = useState(initialPerformance);

  useEffect(() => {
    initialPerformance[0].value = Array.from(
      new Set(
        defects
          .filter((defect) => defect?.status !== "cancelled")
          .map((defect) => defect.location.id)
      )
    ).length.toString();

    initialPerformance[1].value = checkValid(
      defects
        .filter((defect) => defect?.status === "closed")
        .map((defect) =>
          Math.round(
            (new Date(Date.parse(defect.closeDate)).getTime() -
              new Date(Date.parse(defect.raiseDate)).getTime()) /
              (1000 * 60 * 60 * 24)
          )
        )
        .reduce(
          (previousValue, currentValue) => previousValue + Number(currentValue),
          0
        ) / defects.filter((defect) => defect?.status === "closed").length
    ).toString();

    initialPerformance[2].value = defects
      .filter((defect) => defect?.status === "closed")
      .map((defect) => defect.analysis!.scheduleImpact)
      .reduce(
        (previousValue, currentValue) => previousValue + Number(currentValue),
        0
      )
      .toString();

    initialPerformance[3].value = checkValid(
      defects
        .filter((defect) => defect?.status === "closed")
        .map((defect) =>
          defect.messages.filter((message) => message.type !== "response")
        )
        .flat().length /
        defects.filter((defect) => defect?.status === "closed").length
    ).toString();

    setPerformance([...initialPerformance]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defects]);

  return <PerformanceLayout performance={[...performance]} />;
};

export default PerformanceContainer;
