import React from "react";
import { useTranslation } from "react-i18next";

import { NxpDescriptionList, NxpModal } from "@nexploretechnology/nxp-ui";

import { Defect } from "../../../../services/defect";

interface InspectionDetailsModalProps {
  detail: Defect;
  modalVisible: boolean;
  handleModalVisible: () => void;
}

const InspectionDetailsContainer: React.FC<InspectionDetailsModalProps> = ({
  detail,
  modalVisible,
  handleModalVisible,
}) => {
  const { t: translation } = useTranslation();

  return (
    <NxpModal
      title={translation("inspectors.calendar.inspectionDetails")}
      visible={modalVisible}
      onCancel={handleModalVisible}
      width="small"
    >
      <NxpDescriptionList headerWidth={200} hideFirstTopBorder={true}>
        <dt>{translation("app.common.inspector")}</dt>
        <dd>{detail.responsiblePerson?.name}</dd>
        <dt>{translation("app.common.date")}</dt>
        <dd>{detail.nextInspectionDate}</dd>
        <dt>{translation("app.common.defectNo")}</dt>
        <dd>{detail.no}</dd>
        <dt>{translation("inspectors.calendar.plannedNextInspection")}</dt>
        <dd>{translation("inspectors.calendar.next")}</dd>
        <dt>{translation("app.common.type")}</dt>
        <dd>{detail.defectType?.name}</dd>
        <dt>{translation("app.common.description")}</dt>
        <dd>{detail.defectDescription?.name}</dd>
        <dt>{translation("app.common.location")}</dt>
        <dd>{detail.location.name}</dd>
      </NxpDescriptionList>
    </NxpModal>
  );
};

export default InspectionDetailsContainer;
