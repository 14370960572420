import React from "react";
import { useTranslation } from "react-i18next";

import { CalendarFilled } from "@ant-design/icons";
import {
  LazyLoadStatus,
  NxpTableLazy,
  NxpTableProps,
} from "@nexploretechnology/nxp-ui";
import moment from "moment";

export interface AppDefectListItem {
  key: string;
  id: number;
  no: number;
  status: string;
  inspectionType: string;
  jointInspectionId: string;
  defectType: string;
  createdAt: string;
  raisedBy: string;
  defectGroup: string;
  defectSubject: string;
  defectDescription: string;
  responsiblePerson: string;
  responsibleParty: string;
  nextInspectionDate: string;
  dueDate: string;
  isTopPriority: string;
  location: string;
  remarks: string;
  constructionLotNo: string;
  ncrNo: string;
}

interface LayoutProps {
  listItems: AppDefectListItem[] | null;
  lazyLoadStatus: LazyLoadStatus;
  onSortingChange: (field: any, order: any) => void;
  onLazyLoad: () => void;
  onRowClick: (defectId: number) => void;
  tableProps: NxpTableProps<any>;
}

const AppDefectListLayout: React.FC<LayoutProps> = (props) => {
  const { t: translation } = useTranslation();

  const columns = [
    {
      title: translation("app.common.defectNumber"),
      dataIndex: "no",
      width: 130,
      fixed: "left",
      sorter: true,
    },
    {
      title: translation("app.common.status"),
      dataIndex: "status",
      width: 150,
      sorter: true,
      render: (value: unknown, _: AppDefectListItem) => {
        if (value === "open") {
          return "Open";
        } else if (value === "mark-fixed") {
          return "Mark Fixed";
        } else if (value === "mark-closed") {
          return "Mark Closed";
        } else if (value === "closed") {
          return "Closed";
        } else if (value === "cancelled") {
          return "Cancelled";
        } else {
          return value;
        }
      },
    },
    {
      title: translation("app.common.inspectionType"),
      dataIndex: "inspectionType",
      width: 150,
      sorter: true,
    },
    {
      title: translation("app.common.jointInspectionId"),
      dataIndex: "jointInspectionId",
      width: 150,
      sorter: true,
    },
    {
      title: translation("app.common.defectType"),
      dataIndex: "defectType",
      width: 150,
      sorter: true,
    },
    {
      title: translation("app.common.raisedOn"),
      dataIndex: "createdAt",
      width: 150,
      sorter: true,
      render: (value: unknown) => {
        return (
          <>
            <CalendarFilled />{" "}
            {moment(parseInt(value as string)).format("D-MMM-YYYY")}
          </>
        );
      },
    },
    {
      title: translation("app.common.raisedBy"),
      dataIndex: "raisedBy",
      width: 150,
      sorter: true,
    },
    {
      title: translation("app.common.defectGroup"),
      dataIndex: "defectGroup",
      width: 150,
      sorter: true,
    },
    {
      title: translation("app.common.defectSubject"),
      dataIndex: "defectSubject",
      width: 150,
      sorter: true,
    },
    {
      title: translation("app.common.defectDescription"),
      dataIndex: "defectDescription",
      width: 150,
      sorter: true,
    },
    {
      title: translation("app.common.responsiblePerson"),
      dataIndex: "responsiblePerson",
      width: 150,
      sorter: true,
    },
    {
      title: translation("app.common.responsibleParty"),
      dataIndex: "responsibleParty",
      width: 150,
      sorter: true,
    },
    {
      title: translation("app.common.nextInspectionDate"),
      dataIndex: "nextInspectionDate",
      width: 150,
      sorter: true,
      render: (value: unknown) => {
        return (
          <>
            <CalendarFilled />{" "}
            {moment(value as string, "YYYY-MM-DD").format("D-MMM-YYYY")}
          </>
        );
      },
    },
    {
      title: translation("app.common.dueDate"),
      dataIndex: "dueDate",
      width: 150,
      sorter: true,
      render: (value: unknown) => {
        return (
          <>
            <CalendarFilled />{" "}
            {moment(value as string, "YYYY-MM-DD").format("D-MMM-YYYY")}
          </>
        );
      },
    },
    {
      title: translation("appDefectList.priority"),
      dataIndex: "isTopPriority",
      width: 150,
      sorter: true,
    },
    {
      title: translation("app.common.location"),
      dataIndex: "location",
      width: 300,
      sorter: true,
    },
    {
      title: translation("app.common.remarks"),
      dataIndex: "remarks",
      width: 300,
      sorter: true,
      render: (val: string) =>
        val && val.length > 105 ? `${val.substring(0, 100)}...` : val,
    },
    {
      title: translation("app.common.constructionLotNo"),
      dataIndex: "constructionLotNo",
      width: 150,
      sorter: true,
    },
    {
      title: translation("app.common.ncrNo"),
      dataIndex: "ncrNo",
      width: 150,
      sorter: true,
    },
  ];

  return (
    <NxpTableLazy
      lazyLoadStatus={props.lazyLoadStatus}
      onLazyLoad={props.onLazyLoad}
      onChange={(pagination, filters, sorter: any) => {
        props.onSortingChange(sorter.field, sorter.order);
      }}
      columns={columns as any} //any for fixing antd type error
      dataSource={props.listItems || []}
      pagination={false}
      scroll={{ x: window.innerWidth - 130, y: window.innerHeight - 300 }}
      onRow={(record) => ({
        onClick: () => {
          if (props.onRowClick) {
            props.onRowClick(record.id);
          }
        },
      })}
      {...props.tableProps}
    />
  );
};

export default AppDefectListLayout;
