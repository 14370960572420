import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory, useLocation } from "react-router";

import {
  CaretDownFilled,
  DownloadOutlined,
  FilterFilled,
} from "@ant-design/icons";
import { NxpButton, NxpHeader } from "@nexploretechnology/nxp-ui";
import { Dropdown, Menu } from "antd";

import FilterModal from "../../components/AppDefectFilterModal";
import AppDefectList from "../../components/AppDefectList";
import { AppDefectListItem } from "../../components/AppDefectList/AppDefectListLayout";
import AppDefectSummaryReportGenerationModal from "../../components/AppDefectSummaryReportGenerationModal";
import useAppContext from "../../hooks/useAppContext";
import { Defect, getDefectsExcel } from "../../services/defect";
import { pxToRem } from "../../theme.config";
import notify from "../../utils/notify";
import { stringToBoolean } from "../../utils/throttle";
import AddDefectButton from "./AddDefect/AddDefectButton";

interface Props {}
const useStyles = createUseStyles((theme) => ({
  filterCounter: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.backgroundPrimaryLight,
    color: theme.palette.primary,
    borderRadius: theme.pxToRem(5),
    width: theme.pxToRem(20),
    height: pxToRem(20),
    margin: theme.spacing(0, 1),
  },
  dropdown: {
    border: `${theme.pxToRem(1)} solid ${theme.palette.borderLight}`,
    "& .ant-dropdown-menu-item": {
      color: theme.palette.primary,
      fontWeight: theme.fontWeight.bold,
      fontSize: theme.pxToRem(12),
    },
  },
}));

const DefectListing: React.FC<Props> = (props) => {
  const appContext = useAppContext();
  const { serviceConfig, routeParams, hasRight } = appContext;
  const { entityId, locationGroupId } = routeParams;

  const { t: translation } = useTranslation();

  const history = useHistory();
  const searchPhase = useLocation()?.search;
  const classes = useStyles();

  const [shouldGenerateListingReport, setShouldGenerateListingReport] =
    useState<boolean>(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [invalidateAt, setInvalidateAt] = useState<number>(0);
  const [extraQueries, setExtraQueries] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<undefined | string>("no"); // for reference only, AppDefectList handle sorting on it's own
  const [order, setOrder] = useState<"asc" | "desc">("desc"); // for reference only, AppDefectList handle sorting on it's own
  const [showPhoto, setShowPhoto] = useState<boolean>(true);
  const handleFilterApply = (queryStrings: string[]) => {
    setExtraQueries(queryStrings);
    setFilterModalVisible(false);
  };
  const tableItem: {
    label: string;
    getVal: string[];
    key?: keyof Defect;
    width: string;
  }[] = [
    {
      label: "#",
      getVal: ["no"],
      key: "no",
      width: "10%",
    },
    {
      label: translation("app.common.responsibleParty"),
      getVal: ["responsibleParty.name"],
      key: "responsibleParty",
      width: "15%",
    },
    {
      label: translation("app.common.raisedOn"),
      key: "raiseDate",
      getVal: ["raiseDate"],
      width: "10%",
    },
    {
      label: translation("app.common.dueDate"),
      key: "dueDate",
      getVal: ["dueDate"],
      width: "10%",
    },
    {
      label: translation("app.common.type"),
      key: "defectGroup",
      getVal: ["defectGroup.name"],
      width: "10%",
    },
    {
      label: translation("app.common.location"),
      key: "location",
      getVal: ["location"],
      width: "20%",
    },
    {
      label: translation("app.common.description"),
      key: "defectGroup",
      getVal: [
        "defectGroup.name",
        "defectSubject.name",
        "defectDescription.name",
      ],
      width: "15%",
    },
    {
      label: translation("app.common.round"),
      key: "round",
      getVal: ["round"],
      width: "10%",
    },
    {
      label: translation("app.common.remarks"),
      key: "remarks",
      getVal: ["remarks"],
      width: "10%",
    },
  ];
  const handleDefectCreated = () => {
    setInvalidateAt(new Date().getTime());
  };

  let queryString = [searchPhase, ...extraQueries].join("&");
  if (!queryString.startsWith("?")) queryString = `?${queryString}`;
  function handleMenuClick(e: any) {
    setShowPhoto(stringToBoolean(e?.key));
    setShouldGenerateListingReport(true);
  }

  const handleSortingChange = (sortBy: string, order: "asc" | "desc") => {
    setSortBy(sortBy);
    setOrder(order);
  };

  const handleExportExcel = async () => {
    try {
      await getDefectsExcel(
        entityId,
        locationGroupId,
        queryString,
        sortBy,
        order,
        serviceConfig
      );
    } catch (e) {
      notify.error(e);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick} className={classes.dropdown}>
      <Menu.Item key="true">
        {translation("app.common.exportIncPhotos")}
      </Menu.Item>
      <Menu.Item key="false">
        {translation("app.common.exportExcPhotos")}
      </Menu.Item>
    </Menu>
  );

  let title = translation("app.common.allDefects");
  let blockedFilterFields: (keyof AppDefectListItem)[] =
    [] as (keyof AppDefectListItem)[];
  if (searchPhase.startsWith("?raisedBy")) {
    title = translation("app.common.defectsRaisedByMe");
    blockedFilterFields = ["raisedBy"];
  }
  if (searchPhase.startsWith("?responsiblePerson")) {
    title = translation("app.common.defectsForMe");
    blockedFilterFields = ["responsiblePerson"];
  }
  if (searchPhase.startsWith("?isJointInspection")) {
    title = translation("app.common.jointInspectionDefects");
    blockedFilterFields = ["inspectionType"];
  }
  if (searchPhase.startsWith("?isOutstanding")) {
    title = translation("app.common.outstandingDefects");
    blockedFilterFields = ["status"];
  }
  if (searchPhase.startsWith("?isClosed")) {
    title = translation("app.common.closedDefects");
    blockedFilterFields = ["status"];
  }

  return (
    <>
      <NxpHeader
        titleContent={title}
        actionContent={
          <>
            {hasRight("defect-view") && (
              <NxpButton
                type="default"
                icon={<FilterFilled />}
                onClick={() => setFilterModalVisible(true)}
              >
                {extraQueries?.length > 0 && (
                  <div className={classes.filterCounter}>
                    <div>{extraQueries?.length}</div>
                  </div>
                )}
              </NxpButton>
            )}

            {hasRight("defect-view") && (
              <NxpButton
                type="primary"
                icon={<DownloadOutlined />}
                onClick={handleExportExcel}
              >
                {translation("app.common.exportToExcel")}
              </NxpButton>
            )}

            {hasRight("report-view") && (
              <Dropdown overlay={menu}>
                <NxpButton type="primary">
                  {translation("app.common.report")} <CaretDownFilled />
                </NxpButton>
              </Dropdown>
            )}

            {hasRight("defect-add") && (
              <AddDefectButton onDefectCreated={handleDefectCreated} />
            )}
          </>
        }
      />
      {hasRight("defect-view") && (
        <AppDefectList
          queryString={queryString}
          invalidateAt={invalidateAt}
          onDefectClick={(defectId) =>
            history.push(
              `/entities/${entityId}/location-groups/${locationGroupId}/defects/${defectId}`
            )
          }
          onSortingChange={handleSortingChange}
        />
      )}

      <FilterModal
        show={filterModalVisible}
        blockedFields={blockedFilterFields}
        onApply={handleFilterApply}
        onDismiss={() => {
          setFilterModalVisible(false);
        }}
      />

      <AppDefectSummaryReportGenerationModal
        reportTitle={translation("defectListing.defectsListSummaryReport")}
        tableItem={tableItem}
        execute={shouldGenerateListingReport}
        queryString={queryString}
        sortBy={sortBy || "no"}
        order={order}
        onClose={() => setShouldGenerateListingReport(false)}
        showAttachmentPhoto={showPhoto}
      />
    </>
  );
};

export default DefectListing;
