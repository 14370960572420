import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { NxpButton } from "@nexploretechnology/nxp-ui";
import { Card, Col, Row } from "antd";
import _ from "lodash";
import moment from "moment";

import useAppContext from "../../../hooks/useAppContext";
import noFloorPlan from "../../../images/report-no-floor-plan.png";
import pinIcon from "../../../images/vector.svg";
import { tablelItem } from "../../../pages/SubcontractorView/SubcontractorReportPage";
import { Location } from "../../../services/app";
import { Defect } from "../../../services/defect";
import Theme from "../../../theme.config";
import AppFileView from "../../AppFileView";

interface Props {
  defects: Defect[];
  defectRow: tablelItem[][];
}
const useStyles = createUseStyles((theme) => ({
  defectRow: {
    // display: "flex",
    // height: "280px",
  },
  defectRowLeft: {
    display: "block",
    width: "65%",
  },
  defectRowLeftInner: {
    display: "flex",
    height: "50%",
  },
  defectRowRight: {
    display: "block",
    // width: "35%",
  },
  defectRowFirstItem: {
    display: "flex",
    width: "20%",
  },
  defectRowSecondItem: {
    display: "flex",
    width: "25%",
  },
  floorPlan: {
    width: "100%",
    height: "100%",
  },
  cell: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
  card: {
    textAlign: "center",
    marginRight: "20px",
    width: "200px",
    minWidth: "200px",
    maxWidth: "200px",
    "& h1": {
      fontSize: 25,
      color: "#005794",
      margin: 0,
    },
    "& p": {
      fontSize: 12,
      fontWeight: 700,
      color: "#8A9999",
      textTransform: "uppercase",
    },
  },
  defectcCard: {
    width: "100%",
    border: "none",
    // height: 200,
    "& h1": {
      fontSize: 25,
      color: "#005794",
      margin: 0,
    },
    "& p": {
      fontSize: 12,
      fontWeight: 700,
      color: "#8A9999",
      textTransform: "uppercase",
    },
  },
  floorPlanContainer: {
    backgroundColor: theme.palette.backgroundLight,
    border: `${theme.pxToRem(1)} solid ${theme.palette.borderLight}`,
  },
  floorPlanDiv: {
    position: "relative",
    maxWidth: 200,
    maxHeight: 200,
    marginTop: 30,
    marginBottom: 10,
    marginLeft: "auto",
    marginRight: "auto",
  },
  normalImage: { maxWidth: 200, maxHeight: 200, padding: theme.spacing(1) },
  pinImage: {
    position: "absolute",
    transform: "translate(-50%, -100%)",
  },
  defectItemInfo: {
    marginBottom: theme.spacing(2),
    background: theme.palette.backgroundLight,
    border: `${theme.pxToRem(1)} solid ${theme.palette.borderLight}`,
    "& .summary-info-col": {
      borderBottom: `${theme.pxToRem(1)} solid ${theme.palette.borderLight}`,
    },
  },
  container: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& .outer": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "100%;",
      "& .inner": {
        display: "flex",
        flexDirection: "row",
        width: "100%",
      },
    },
    "& .flexible": {
      flexGrow: 1,
    },
  },
}));
type reportType = Defect & {
  expand: boolean;
};

const AppSubscontractorReportItem: React.FC<Props> = ({
  defects,
  defectRow,
}) => {
  const classes = useStyles();
  const { getLocationFullPath } = useAppContext();

  const { t: translation } = useTranslation();

  const [defectDetail, setDefectDetail] = useState<reportType[]>([]);
  useEffect(() => {
    setDefectDetail(
      defects.map((v) => ({
        ...v,
        expand: false,
        round: v.messages.filter((message) => message.type !== "response")
          .length,
      }))
    );
    return () => {
      setDefectDetail([]);
    };
  }, [defects]);
  const handleExpandPhoto = (id: number) => {
    setDefectDetail((preState) =>
      _.map(preState, (n) => {
        return n?.id === id ? { ...n, expand: n.expand ? false : true } : n;
      })
    );
  };
  return (
    <>
      {defectDetail?.map((defect, defectItem) => {
        defect = {
          ...defect,
          round: defect.messages.filter(
            (message) => message.type !== "response"
          ).length,
        };
        return (
          <>
            <Row
              className={classes.defectItemInfo}
              key={`defectItem-wrapper-${defectItem}`}
            >
              <Col span={15} className="summary-info-col">
                {defectRow.map((defectRowitem, index) => (
                  <div className={classes.container} key={`defect${index}`}>
                    <div className="outer">
                      <div
                        className="inner"
                        style={{
                          border:
                            index === 0
                              ? `${Theme.pxToRem(1)} solid ${
                                  Theme.palette.borderLight
                                }`
                              : "none",
                        }}
                      >
                        {defectRowitem.map((item, index) => {
                          return (
                            <Col
                              span={6}
                              key={`summary_${index}`}
                              style={{
                                borderLeft: `1px solid ${Theme.palette.border}`,
                              }}
                            >
                              <Card
                                size="small"
                                className={classes.defectcCard}
                              >
                                {item.key !== "raiseDate" &&
                                  item.key !== "dueDate" &&
                                  item.key !== "location" && (
                                    <React.Fragment key={`item-${item.key}`}>
                                      <p>{item?.label}</p>
                                      {item?.getVal.map((key) => (
                                        <div key={key}>
                                          {_.get(defect, key, false)}
                                        </div>
                                      ))}
                                    </React.Fragment>
                                  )}
                                {item.key === "raiseDate" &&
                                  item?.getVal.map((key) => (
                                    <React.Fragment key={`item-${item.key}`}>
                                      <p>{item?.label}</p>
                                      <div>
                                        {moment(
                                          _.get(
                                            defect,
                                            key,
                                            false
                                          ) as unknown as Date,
                                          "YYYY-MM-DD"
                                        ).format("DD-MMM-YYYY")}
                                      </div>
                                    </React.Fragment>
                                  ))}
                                {item.key === "dueDate" &&
                                  item?.getVal.map((key) => (
                                    <React.Fragment key={`item-${item.key}`}>
                                      <p>{item?.label}</p>
                                      <div>
                                        {moment(
                                          _.get(
                                            defect,
                                            key,
                                            false
                                          ) as unknown as Date,
                                          "YYYY-MM-DD"
                                        ).format("DD-MMM-YYYY")}
                                      </div>
                                    </React.Fragment>
                                  ))}
                                {item.key === "location" &&
                                  item?.getVal.map((key) => (
                                    <React.Fragment key={`item-${item.key}`}>
                                      <p>{item?.label}</p>
                                      <div>
                                        {getLocationFullPath(
                                          _.get(
                                            defect,
                                            key,
                                            false
                                          ) as unknown as Location
                                        )}
                                      </div>
                                    </React.Fragment>
                                  ))}
                              </Card>
                            </Col>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ))}
              </Col>
              <Col span={9} className={classes.floorPlanContainer}>
                <div className={classes.defectRowRight}>
                  <div className={classes.floorPlanDiv}>
                    <img
                      className={classes.normalImage}
                      src={
                        defect.location.floorPlans[0]?.file.thumbnailUrl ||
                        noFloorPlan
                      }
                      alt={"Floor plan"}
                    />
                    {defect.pinX && defect.pinY && (
                      <img
                        className={classes.pinImage}
                        style={{
                          left: `${defect.pinX * 100}%`,
                          top: `${defect.pinY * 100}%`,
                        }}
                        src={pinIcon}
                        alt={"Pin"}
                      />
                    )}
                  </div>
                  {_.indexOf(
                    defect.messages.map(
                      (message) => message.attachments.length > 0
                    ),
                    true
                  ) !== -1 && (
                    <NxpButton
                      type="text"
                      onClick={() => {
                        handleExpandPhoto(defect.id);
                      }}
                    >
                      {defect.expand
                        ? translation(
                            "app.appSubcontractorReportItem.hideAttachment"
                          )
                        : translation(
                            "app.appSubcontractorReportItem.showAttachment"
                          )}
                    </NxpButton>
                  )}
                </div>
              </Col>
              {defect.expand === true &&
                defect.messages.map((message) =>
                  message.attachments.map((attachment) =>
                    attachment.thumbnailUrl || attachment.url ? (
                      <Row
                        key={`File_${attachment?.id}`}
                        style={{ padding: "0 8px" }}
                      >
                        <Col span={24} className={classes.cell}>
                          <AppFileView file={attachment} disableAction={true} />
                        </Col>
                      </Row>
                    ) : null
                  )
                )}
            </Row>
          </>
        );
      })}
    </>
  );
};

export default AppSubscontractorReportItem;
