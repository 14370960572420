import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpFormGridItemProps,
  NxpPanelEditable,
} from "@nexploretechnology/nxp-ui";
import moment from "moment";
import * as yup from "yup";

import useAppContext from "../../../hooks/useAppContext";
import { useValidate } from "../../../hooks/useValidate";
import {
  Defect,
  DefectCreateForm,
  updateOneDefect,
} from "../../../services/defect";
import CustomApiError from "../../../utils/backend/customApiError";
import notify from "../../../utils/notify";
import { stringToBoolean } from "../../../utils/throttle";
import AppDefectDetailLayout from "./AppDefectDetailLayout";

interface AppDefectDetailContainerProps {
  defect: Defect;
}

const AppDefectDetailContainer: React.FC<AppDefectDetailContainerProps> = ({
  defect,
}) => {
  const { t: translation } = useTranslation();

  const [defectForm, setDefectForm] = useState<Partial<DefectCreateForm>>({});
  const [originState, setOriginState] = useState<Partial<DefectCreateForm>>({});
  const appContext = useAppContext();
  const {
    activeEntity,
    activeLocationGroup,
    defectSubjects,
    defectDescriptions,
    defectGroups,
    defectTypes,
    inspectionTypes,
    serviceConfig,
    subcontractors,
    users,
    hasRight,
  } = appContext;
  const formSchema = yup.object().shape({
    location: yup
      .string()
      .nullable()
      .required(translation("app.common.locationIsRequired")),
    defectType: yup
      .string()
      .nullable()
      .required(translation("app.common.defectTypeIsRequired")),
    inspectionType: yup
      .string()
      .nullable()
      .required(translation("app.common.inspectionTypeIsRequired")),
    defectGroup: yup
      .string()
      .nullable()
      .required(translation("app.common.defectGroupIsRequired")),
    responsibleParty: yup
      .string()
      .nullable()
      .required(translation("app.common.responsiblePartyIsRequired")),
    responsiblePerson: yup
      .string()
      .nullable()
      .required(translation("app.common.responsiblePersonIsRequired")),
    defectSubject: yup
      .string()
      .nullable()
      .required(translation("app.common.defectSubjectIsRequired")),
    defectDescription: yup
      .string()
      .nullable()
      .required(translation("app.common.defectDescriptionIsRequired")),
    remarks: yup
      .string()
      .nullable()
      .required(translation("app.common.defectRemarkIsRequired")),
    estimatedCost: yup
      .number()
      .nullable()
      .required(translation("app.common.estimatedCostIsRequired")),
    nextInspectionDate: yup
      .string()
      .nullable()
      .required(translation("app.common.nextInspectionDateIsRequired")),
    dueDate: yup
      .string()
      .nullable()
      .required(translation("app.common.dueDateIsRequired")),
  });
  const formItems: NxpFormGridItemProps<Partial<DefectCreateForm>>[] = [
    {
      controlType: "input",
      controlProps: {
        allowClear: true,
        disabled: true,
      },
      label: translation("app.common.status"),
      itemFieldName: "status",
      span: 8,
    },
    {
      controlType: "radioGroup",
      label: translation("app.common.topPriority"),
      itemFieldName: "isTopPriority",
      span: 8,
      controlProps: {
        options: [
          { label: translation("app.common.no"), value: "false" },
          { label: translation("app.common.yes"), value: "true" },
        ],
      },
    },
    {
      controlType: "datePicker",
      label: translation("app.common.dueDate"),
      itemFieldName: "dueDate",
      span: 8,
      startOnNewRow: true,
      controlProps: {
        disabledDate: (current) =>
          current < moment(Number(defect?.createdAt)).startOf("day"),
      },
    },
    {
      controlType: "select",
      label: translation("app.common.defectType"),
      itemFieldName: "defectType",
      span: 8,
      controlProps: {
        defaultValue: defectForm?.defectType,
        options: defectTypes.map((type) => ({
          label: type?.name,
          value: type?.id?.toString(),
        })),
      },
    },
    {
      controlType: "select",
      label: translation("app.common.inspectionType"),
      itemFieldName: "inspectionType",
      span: 8,
      controlProps: {
        defaultValue: defectForm?.inspectionType,
        options: inspectionTypes.map((type) => ({
          label: type?.name,
          value: type?.id?.toString(),
        })),
      },
      startOnNewRow: true,
    },
    {
      controlType: "select",
      controlProps: {
        defaultValue: defectForm?.defectGroup,
        onClear: () => {
          handleFormGridStateChange("defectSubject", undefined);
          handleFormGridStateChange("defectDescription", undefined);
          handleFormGridStateChange("responsibleParty", undefined);
        },
        onChange: (e) => {
          handleFormGridStateChange("defectSubject", undefined);
          handleFormGridStateChange("defectDescription", undefined);
          handleFormGridStateChange("responsibleParty", undefined);
          handleFormGridStateChange("defectGroup", e);
        },
        options: defectGroups.map((type) => ({
          label: type?.name,
          value: type?.id?.toString(),
          key: type?.code,
        })),
      },
      label: translation("app.common.defectGroup"),
      itemFieldName: "defectGroup",
      span: 8,
    },
    {
      controlType: "treeSelect",
      controlProps: {
        defaultValue: defectForm?.location,
        options: {
          ...activeLocationGroup,
          ...{
            title: activeLocationGroup?.name,
            value: activeLocationGroup?.id.toString(),
          },
        },
      },
      label: translation("app.common.location"),
      itemFieldName: "location",
      span: 12,
    },
    {
      controlType: "datePicker",
      label: translation("app.common.nextInspectionDate"),
      itemFieldName: "nextInspectionDate",
      span: 8,
    },
    {
      controlType: "select",
      label: translation("app.common.subject"),
      itemFieldName: "defectSubject",
      controlProps: {
        defaultValue: defectForm?.defectSubject,
        disabled: defectForm.defectGroup === undefined,
        onClear: () => {
          handleFormGridStateChange("defectDescription", undefined);
          handleFormGridStateChange("responsibleParty", undefined);
        },
        onChange: (e) => {
          handleFormGridStateChange("defectDescription", undefined);
          handleFormGridStateChange("responsibleParty", undefined);
          handleFormGridStateChange("defectSubject", e);
        },
        options:
          defectForm.defectGroup !== undefined
            ? defectGroups
                ?.find((grp) => grp.id === Number(defectForm?.defectGroup))
                ?.defectSubjects.map((opt) => ({
                  label: opt?.name,
                  value: opt?.id?.toString(),
                })) || []
            : [],
      },
      span: 8,
    },
    {
      controlType: "select",
      label: translation("app.common.description"),
      itemFieldName: "defectDescription",
      span: 12,
      controlProps: {
        defaultValue: defectForm?.defectDescription,
        disabled:
          defectForm.defectGroup === undefined ||
          defectForm.defectSubject === undefined,
        onClear: () => {
          handleFormGridStateChange("responsibleParty", undefined);
        },
        onChange: (e) => {
          handleFormGridStateChange("responsibleParty", undefined);
          handleFormGridStateChange("defectDescription", e);
        },
        options:
          defectForm.defectGroup !== undefined &&
          defectForm.defectSubject !== undefined
            ? defectSubjects
                ?.find(
                  (subject) => "" + subject.id === defectForm.defectSubject
                )
                ?.defectDescriptions.map((opt) => ({
                  label: opt?.name,
                  value: opt?.id?.toString(),
                })) || []
            : [],
      },
    },
    {
      controlType: "input",
      controlProps: {
        allowClear: true,
      },
      label: translation("app.common.remarks"),
      itemFieldName: "remarks",
      span: 12,
    },
    {
      controlType: "input",
      controlProps: {
        allowClear: true,
      },
      label: translation("app.common.remarksLocal"),
      itemFieldName: "remarksLocal",
      span: 12,
    },
    {
      controlType: "select",
      controlProps: {
        defaultValue: defectForm?.responsibleParty,
        disabled: defectForm.defectDescription === undefined,
        options:
          defectForm.defectDescription &&
          defectDescriptions.find(
            (description) =>
              description.id === Number(defectForm?.defectDescription)
          )?.subcontractors.length
            ? defectDescriptions
                .find(
                  (description) =>
                    description.id === Number(defectForm?.defectDescription)
                )!
                .subcontractors.map((opt) => ({
                  label: opt.name,
                  value: "" + opt.id,
                }))
            : subcontractors.map((subcontractor) => ({
                label: subcontractor.name,
                value: "" + subcontractor.id,
              })),
      },
      label: translation("app.common.responsibleParty"),
      itemFieldName: "responsibleParty",
      span: 8,
    },
    {
      controlType: "select",
      label: translation("app.common.responsiblePerson"),
      itemFieldName: "responsiblePerson",
      controlProps: {
        defaultValue: defectForm?.responsiblePerson,
        options: users.map((user) => ({
          label: user?.name,
          value: user?.id?.toString(),
        })),
      },
      span: 8,
    },
    {
      controlType: "select",
      label: translation("app.appDefectDetail.watcher"),
      itemFieldName: "watcher",
      controlProps: {
        defaultValue: defectForm?.watcher,
        options: users.map((user) => ({
          label: user?.name,
          value: user?.id?.toString(),
        })),
      },
      span: 8,
    },
    {
      controlType: "inputNumber",
      label: translation("app.common.estimatedCost"),
      itemFieldName: "estimatedCost",
      controlProps: {
        defaultValue: defectForm?.estimatedCost,
        decimalPlace: 0,
        id: "estimatedCost",
        prefix: "$",
        keyboard: true,
      },
      span: 8,
    },
    {
      controlType: "input",
      controlProps: {
        defaultValue: defectForm?.jointInspectionId,
      },
      label: translation("app.common.jointInspectionId"),
      itemFieldName: "jointInspectionId",
      span: 8,
    },
    {
      controlType: "input",
      controlProps: {
        defaultValue: defectForm?.planningId,
      },
      label: translation("app.appDefectDetail.planningId"),
      itemFieldName: "planningId",
      span: 8,
    },
    {
      controlType: "input",
      controlProps: {
        defaultValue: defectForm?.ncrNo,
      },
      label: translation("app.common.ncrNo"),
      itemFieldName: "ncrNo",
      span: 8,
    },
    {
      controlType: "input",
      controlProps: {
        defaultValue: defectForm?.incomingRef01,
      },
      label: translation("app.appDefectDetail.incomingRef01"),
      itemFieldName: "incomingRef01",
      span: 8,
    },
    {
      controlType: "input",
      controlProps: {
        defaultValue: defectForm?.incomingRef02,
      },
      label: translation("app.appDefectDetail.incomingRef02"),
      itemFieldName: "incomingRef02",
      span: 12,
    },
  ];
  const reformatting = (defect: Defect): Partial<DefectCreateForm> => ({
    ...defect,
    ...{
      inspectionType: defect.inspectionType?.id.toString(),
      location: defect?.location?.id.toString(),
      defectDescription: defect?.defectDescription?.id.toString(),
      responsibleParty: defect.responsibleParty?.id.toString(),
      watcher: defect?.watcher?.id.toString(),
      isTopPriority:
        defect?.isTopPriority !== undefined
          ? defect?.isTopPriority.toString()
          : "false",
      defectType: defect.defectType?.id.toString(),
      defectGroup: defect.defectGroup?.id.toString(),
      defectSubject: defect?.defectSubject?.id.toString(),
      responsiblePerson: defect?.responsiblePerson?.id,
      estimatedCost: Number(defect.estimatedCost),
    },
  });

  const handleSaveValidated = async () => {
    const { id: defectId } = defect;
    if (activeLocationGroup?.id && activeEntity?.id) {
      try {
        const defect = await updateOneDefect(
          activeEntity?.id,
          activeLocationGroup?.id,
          defectId,
          {
            dueDate: defectForm.dueDate,
            nextInspectionDate: defectForm.nextInspectionDate,
            defectType: Number(defectForm?.defectType),
            inspectionType: Number(defectForm?.inspectionType),
            location: Number(defectForm?.location),
            defectGroup: Number(defectForm?.defectGroup),
            defectSubject: Number(defectForm?.defectSubject),
            defectDescription: Number(defectForm?.defectDescription),
            responsibleParty: Number(defectForm?.responsibleParty),
            responsiblePerson: defectForm?.responsiblePerson!,
            watcher: defectForm?.watcher!,
            isTopPriority:
              defectForm?.isTopPriority !== undefined
                ? stringToBoolean(defectForm?.isTopPriority)
                : false,
            remarks: defectForm.remarks,
            remarksLocal: defectForm.remarksLocal,
            estimatedCost: defectForm.estimatedCost,
            jointInspectionId: defectForm.jointInspectionId,
            planningId: defectForm.planningId,
            ncrNo: defectForm.ncrNo,
            incomingRef01: defectForm.incomingRef01,
            incomingRef02: defectForm.incomingRef02,
          },
          serviceConfig
        );
        if (defect) {
          notify.success(translation("app.appDefectDetail.updatedDefect"));
          setOriginState(reformatting(defect));
        }
      } catch (e: any) {
        if (e instanceof CustomApiError) {
          const apiError: CustomApiError = e;
          if (apiError.status === 403) {
            notify.error(translation("app.common.errorOccurred"));
          } else {
            notify.error(apiError);
          }
        } else {
          notify.error(e);
        }
      }
    }
  };

  const [validationError, , , saveWithValidate] = useValidate<
    Partial<DefectCreateForm>
  >(defectForm, formSchema, handleSaveValidated);

  const handleSave = () =>
    Object.values(saveWithValidate(undefined)).filter(
      (val) => val !== undefined
    ).length === 0;

  const handleFormGridStateChange = (
    fieldName: keyof typeof defectForm,
    value: unknown
  ) => {
    setDefectForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    function fetchDefect() {
      if (defect) {
        setDefectForm(reformatting(defect));
        setOriginState(reformatting(defect));
      }
    }
    fetchDefect();
  }, [defect]);
  const handlePanelSave = (setEditing: (editing: boolean) => void) => {
    if (handleSave()) {
      setEditing(false);
    }
  };
  return (
    <NxpPanelEditable
      titleContent={translation("app.appDefectDetail.title")}
      editable={hasRight("defect-edit")}
      onPanelSave={handlePanelSave}
      onPanelCancel={() => setDefectForm(originState)}
    >
      {(editing, updateEditing) => (
        <AppDefectDetailLayout
          editing={editing}
          validationError={validationError}
          formItems={formItems.map((item) => ({
            ...item,
            span: item.span > 8 ? 2 : 1,
          }))}
          formState={defectForm}
          onFormStateChange={handleFormGridStateChange}
        />
      )}
    </NxpPanelEditable>
  );
};

export default AppDefectDetailContainer;
