import React from "react";
import { createUseStyles } from "react-jss";
import { Card, Col, Row } from "antd";

import { InspectorStatistic } from "./SummaryContainer";

interface SummaryLayoutProps {
  inspectorStatistics: InspectorStatistic[];
}

const useStyles = createUseStyles(() => ({
  card: {
    marginRight: "20px",
    maxWidth: "200px",
    minWidth: "200px",
    textAlign: "center",
    width: "200px",
    "& h1": {
      fontSize: 25,
      color: "#005794",
      margin: 0,
    },
    "& p": {
      fontSize: 12,
      fontWeight: 700,
      color: "#8A9999",
      textTransform: "uppercase",
    },
  },
}));

const SummaryLayout: React.FC<SummaryLayoutProps> = ({
  inspectorStatistics,
}) => {
  const classes = useStyles();

  return (
    <Row gutter={[16, 16]}>
      {inspectorStatistics.map((inspectorStatistic, idx) => (
        <Col span={4} key={`summary-${idx}`}>
          <Card size="small" className={classes.card}>
            <h1>{inspectorStatistic.value}</h1>
            <p>{inspectorStatistic.title}</p>
          </Card>
        </Col>
      ))}
    </Row>
  );
};
export default SummaryLayout;
