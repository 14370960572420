import React, { useContext } from "react";
import { LocationDetailsContext } from "../LocationDetailsContainer";

import { NxpHeader } from "@nexploretechnology/nxp-ui";
import TabBar from "../TabBar";
import { tabKey } from "../TabBar/TabBar";

interface Props {
  activeKey: tabKey;
  onActiveKeyChange: (activeKey: string) => void;
}

const LocationEmptyTab: React.FC<Props> = ({
  activeKey,
  onActiveKeyChange,
}) => {
  const { location } = useContext(LocationDetailsContext);

  if (!location) return null;
  if (!location.floorPlans) return null;

  return (
    <>
      <NxpHeader titleContent={location.name} />
      <TabBar activeKey={activeKey} onChange={onActiveKeyChange} />
    </>
  );
};

export default LocationEmptyTab;
