import React from "react";
import { useTranslation } from "react-i18next";

import {
  NxpHeader,
  NxpTable,
  NxpTableEditable,
} from "@nexploretechnology/nxp-ui";

import { Enum, UserRightList } from "../../../services/app";
import AddCauseButton from "./AddCause/AddCauseButton";

interface CauseLayoutProps {
  causeListData: Enum[] | undefined;
  columns: any;
  editItem: Enum | undefined;
  saveInProgress: boolean;
  handleAddToList: (item: Enum) => void;
  handleRowCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleRowDelete: (deleteItem: Enum) => void;
  handleRowEdit: (editItem: Enum) => void;
  handleRowSave: () => void;
  hasRight: (
    rightType: keyof UserRightList,
    source?: "module" | "locationGroup" | undefined,
    objectId?: number | undefined
  ) => boolean;
}

const CauseLayout: React.FC<CauseLayoutProps> = ({
  causeListData,
  columns,
  editItem,
  saveInProgress,
  handleAddToList,
  handleRowCancel,
  handleRowDelete,
  handleRowEdit,
  handleRowSave,
  hasRight,
}) => {
  const { t: translation } = useTranslation();

  return (
    <>
      <NxpHeader
        titleContent={translation("app.common.cause")}
        actionContent={
          hasRight("setup-add") && (
            <AddCauseButton handleAddToList={handleAddToList} />
          )
        }
      />
      {hasRight("setup-view") &&
        (hasRight("setup-edit", "module") ? (
          <NxpTableEditable
            columns={columns} //any for fixing antd type error
            dataSource={causeListData}
            editItem={editItem}
            hideDelete={!hasRight("setup-delete")}
            itemCompareKey="id"
            onRowCancel={handleRowCancel}
            onRowDelete={handleRowDelete}
            onRowEdit={handleRowEdit}
            onRowSave={handleRowSave}
            pagination={false}
            saveInProgress={saveInProgress}
          />
        ) : (
          <NxpTable dataSource={causeListData} rowKey="id" columns={columns} />
        ))}
    </>
  );
};

export default React.memo(CauseLayout);
