import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import useAppContext from "../../hooks/useAppContext";
import { File, Location } from "../../services/app";
import {
  DefectStatisticResult,
  getDefectStatistic,
} from "../../services/defect";
import {
  attachFileToLocation,
  detechFileFromLocation,
  getLocationDetails,
} from "../../services/location";
import {
  deleteFloorPlan,
  generateAutoImage,
  linkUpFloorPlan,
} from "../../services/plan";
import notify from "../../utils/notify";
import LocationDetailsLayout from "./LocationDetailsLayout";

import { useTranslation } from "react-i18next";

interface Props {}

interface LocationDetailsContextType {
  location: Location;
  defectStatistics: Record<string, DefectStatisticResult | null>;
  requestDefectStatistic: (query: string) => void;
  onOtherFileAdd: (file: File) => Promise<boolean>;
  onOtherFileDelete: (file: File) => Promise<boolean>;
  onGenerateAutoImage: () => Promise<boolean>;
  onCopyFloorPlanFromParent: () => Promise<boolean>;
  onDeleteFloorPlan: () => Promise<boolean>;
  onEditFloorPlanImageStart: () => void;
  onEditFloorPlanImageSave: (file: File) => Promise<boolean>;
  onEditFloorPlanImageCancel: () => void;
  onUpdateLocation: () => void;
}

export interface StatisticResult {
  loading: boolean;
  result?: DefectStatisticResult;
}

export const LocationDetailsContext =
  React.createContext<LocationDetailsContextType>(
    {} as LocationDetailsContextType
  );

const LocationDetails: React.FC<Props> = () => {
  const appContext = useAppContext();
  const history = useHistory();

  const { activeLocationGroup, routeParams, serviceConfig } = appContext;
  const { entityId, locationGroupId, locationId } = routeParams;

  const { t: translation } = useTranslation();

  const [location, setLocation] = useState<Location | null>(null);
  const [defectStatistics, setDefectStatistics] = useState<
    Record<string, null | DefectStatisticResult>
  >({});

  const [isEditingFloorPlanImage, setFloorPlanImageEditing] =
    useState<boolean>(false);

  const defectStatisticsRef = useRef(defectStatistics);
  defectStatisticsRef.current = defectStatistics;

  const fetchLocation = useCallback(() => {
    if (!entityId) return;
    getLocationDetails(
      entityId,
      locationGroupId,
      locationId,
      serviceConfig
    ).then((location) => setLocation(location));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, location, locationGroupId, locationId, serviceConfig]);

  useEffect(() => {
    if (!entityId) return;
    if (location?.id === locationId) return;

    fetchLocation();

    return () => setFloorPlanImageEditing(false);
  }, [
    entityId,
    fetchLocation,
    location,
    locationGroupId,
    locationId,
    serviceConfig,
  ]);

  if (!activeLocationGroup) {
    return <></>;
  }

  const handleRequestDefectStatistic = (queryString: string) => {
    if (!entityId) return;
    if (defectStatisticsRef.current.hasOwnProperty(queryString)) return;
    setDefectStatistics({
      ...defectStatisticsRef.current,
      [queryString]: null,
    });
    getDefectStatistic(entityId, queryString, serviceConfig).then((result) => {
      setDefectStatistics({
        ...defectStatisticsRef.current,
        [queryString]: result,
      });
    });
  };

  const handleLocationSelect = (selectedKeys: React.Key[], info: any) => {
    if (selectedKeys[0]) {
      history.push(
        `/entities/${entityId}/location-groups/${locationGroupId}/locations/${selectedKeys[0]}`
      );
    }
  };

  const handleCopyFloorPlanFromParent = async (): Promise<boolean> => {
    if (!location) return false;
    if (!location?.parent?.floorPlans?.length) return false;
    const floorPlan = await linkUpFloorPlan(
      entityId,
      locationGroupId,
      locationId,
      location.parent.floorPlans[location.parent.floorPlans.length - 1].file.id,
      serviceConfig
    );
    setLocation({
      ...location,
      floorPlans: [...location.floorPlans, floorPlan],
    });
    notify.success(
      translation("locationDetails.handleCopyFloorPlanFromParent.success")
    );
    return true;
  };

  const handleGenerateAutoImage = async (): Promise<boolean> => {
    if (!location) return false;

    const floorPlan = await generateAutoImage(
      entityId,
      locationGroupId,
      locationId,
      serviceConfig
    );
    setLocation({
      ...location,
      floorPlans: [...location.floorPlans, floorPlan],
    });
    notify.success(
      translation("locationDetails.handleGenerateAutoImage.success")
    );
    return true;
  };

  const handleDeleteFloorPlan = async (): Promise<boolean> => {
    if (!location) return false;

    try {
      const deleteResult = await deleteFloorPlan(
        entityId as string,
        locationId as number,
        locationGroupId as number,
        serviceConfig
      );
      notify.success(translation("app.common.floorPlan.success"));
      let { success } = deleteResult;
      if (success) fetchLocation();
      return success ?? false;
    } catch (e) {
      notify.error(e);
      return false;
    }
  };

  const handleOtherFileAdd = async (file: File): Promise<boolean> => {
    try {
      const updated = await attachFileToLocation(
        entityId,
        locationGroupId,
        location?.id,
        file.id,
        serviceConfig
      );

      setLocation(updated);
      notify.success(translation("app.common.fileUploaded"));
      return true;
    } catch (error) {
      notify.error(error);
      return false;
    }
  };

  const handleOtherFileDelete = async (file: File): Promise<boolean> => {
    try {
      const updated = await detechFileFromLocation(
        entityId,
        locationGroupId,
        location?.id,
        file.id,
        serviceConfig
      );

      setLocation(updated);
      notify.success(
        translation("locationDetails.handleOtherFileDelete.success")
      );
      return true;
    } catch (error) {
      notify.error(error);
      return false;
    }
  };

  const handleEditFloorPlanStart = () => {
    setFloorPlanImageEditing(true);
  };

  const handleEditFloorPlanSave = async (file: File): Promise<boolean> => {
    if (!location) return false;

    const floorPlan = await linkUpFloorPlan(
      entityId,
      locationGroupId,
      locationId,
      file.id,
      serviceConfig
    );
    setLocation({
      ...location,
      floorPlans: [...location.floorPlans, floorPlan],
    });
    setFloorPlanImageEditing(false);
    notify.success(
      translation("locationDetails.handleEditFloorPlanSave.success")
    );
    return true;
  };

  const handleEditFloorPlanCancel = () => {
    setFloorPlanImageEditing(false);
  };

  return (
    <LocationDetailsContext.Provider
      value={{
        location: location as Location,
        defectStatistics: defectStatistics,
        requestDefectStatistic: handleRequestDefectStatistic,
        onUpdateLocation: fetchLocation,
        onOtherFileAdd: handleOtherFileAdd,
        onOtherFileDelete: handleOtherFileDelete,
        onGenerateAutoImage: handleGenerateAutoImage,
        onDeleteFloorPlan: handleDeleteFloorPlan,
        onCopyFloorPlanFromParent: handleCopyFloorPlanFromParent,
        onEditFloorPlanImageStart: handleEditFloorPlanStart,
        onEditFloorPlanImageSave: handleEditFloorPlanSave,
        onEditFloorPlanImageCancel: handleEditFloorPlanCancel,
      }}
    >
      <LocationDetailsLayout
        onLocationSelect={handleLocationSelect}
        isEditingFloorPlanImage={isEditingFloorPlanImage}
        onCopyFloorPlanFromParent={handleCopyFloorPlanFromParent}
      />
    </LocationDetailsContext.Provider>
  );
};

export default LocationDetails;
