import React from "react";
import { useTranslation } from "react-i18next";

import {
  NxpHeader,
  NxpTable,
  NxpTableEditable,
} from "@nexploretechnology/nxp-ui";

import useAppContext from "../../../hooks/useAppContext";
import { Team } from "../../../services/app";
import AddRecordButton from "./AddRecord/AddRecordButton";

interface TeamLayoutProps {
  columns: any;
  editItem: Team | undefined;
  saveInProgress: boolean;
  teamListData: Team[] | undefined;
  handleAddToList: (item: Team) => void;
  handleRowCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleRowDelete: (deleteItem: Team) => void;
  handleRowEdit: (editItem: Team) => void;
  handleRowSave: () => void;
}

const TeamLayout: React.FC<TeamLayoutProps> = ({
  columns,
  editItem,
  saveInProgress,
  teamListData,
  handleAddToList,
  handleRowCancel,
  handleRowDelete,
  handleRowEdit,
  handleRowSave,
}) => {
  const appContext = useAppContext();
  const { hasRight } = appContext;

  const { t: translation } = useTranslation();

  return (
    <>
      <NxpHeader
        titleContent={translation("app.common.team")}
        actionContent={
          hasRight("setup-add") && (
            <AddRecordButton handleAddToList={handleAddToList} />
          )
        }
      />
      {hasRight("setup-view") &&
        (hasRight("setup-edit", "module") ? (
          <NxpTableEditable
            columns={columns} //any for fixing antd type error
            dataSource={teamListData}
            editItem={editItem}
            hideDelete={!hasRight("setup-delete")}
            itemCompareKey="id"
            onRowCancel={handleRowCancel}
            onRowDelete={handleRowDelete}
            onRowEdit={handleRowEdit}
            onRowSave={handleRowSave}
            pagination={false}
            saveInProgress={saveInProgress}
          />
        ) : (
          <NxpTable dataSource={teamListData} rowKey="id" columns={columns} />
        ))}
    </>
  );
};

export default React.memo(TeamLayout);
