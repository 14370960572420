import React from "react";

import AppDefectList from "../../../components/AppDefectList";
import FilterModal from "../../../components/AppDefectFilterModal";

interface DefectsLayoutProps {
  filterModalVisible: boolean;
  invalidateAt: number | undefined;
  queryString: string | undefined;
  handleFilterApply: (queryStrings: string[]) => void;
  handleSortingChange: (sortBy: string, order: "asc" | "desc") => void;
  setFilterModalVisible: (value: React.SetStateAction<boolean>) => void;
  handleDefectClick?: (defectId: number) => void;
}

const DefectsLayout: React.FC<DefectsLayoutProps> = ({
  filterModalVisible,
  invalidateAt,
  queryString,
  handleFilterApply,
  handleSortingChange,
  setFilterModalVisible,
  handleDefectClick,
}) => {
  return (
    <>
      <AppDefectList
        queryString={queryString}
        invalidateAt={invalidateAt}
        onSortingChange={handleSortingChange}
        onDefectClick={handleDefectClick}
      />
      <FilterModal
        show={filterModalVisible}
        blockedFields={["responsibleParty"]}
        onApply={handleFilterApply}
        onDismiss={() => {
          setFilterModalVisible(false);
        }}
      />
    </>
  );
};

export default DefectsLayout;
