import React from "react";
import { createUseStyles } from "react-jss";
import { Card } from "antd";
import { useTranslation } from "react-i18next";

interface DefectAnalysisLayoutProps {
  defectAnalysis: {
    key: string;
    title: string;
    value: string;
  }[];
}

const useStyles = createUseStyles(() => ({
  statisticsRow: {
    display: "flex",
    flexWrap: "wrap",
    padding: "0px 0px 10px 0px",
    maxWidth: "660px",
  },
  statisticsCard: {
    textAlign: "center",
    width: "200px",
    minWidth: "200px",
    maxWidth: "200px",
    maxHeight: "100px",
    margin: "0px 20px 10px 0px",
    "& h1": {
      fontSize: 25,
      color: "#005794",
      margin: 0,
    },
    "& h2": {
      fontSize: 15,
      fontWeight: "bold",
      color: "#005794",
      margin: "0px 5px 0px 5px",
    },
    "& p": {
      fontSize: 12,
      fontWeight: 700,
      color: "#8A9999",
      textTransform: "uppercase",
    },
  },
  cardValue: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "center",
  },
}));

const DefectAnalysisLayout: React.FC<DefectAnalysisLayoutProps> = ({
  defectAnalysis,
}) => {
  const classes = useStyles();

  const { t: translation } = useTranslation();

  return (
    <>
      <h2>{translation("app.common.defectAnalysis")}</h2>
      <div className={classes.statisticsRow}>
        {defectAnalysis.map((item: any) => (
          <Card size="small" className={classes.statisticsCard} key={item.key}>
            <div className={classes.cardValue}>
              <h1>{item.value}</h1>
              {item.percentage && <h2>({item.percentage}%)</h2>}
            </div>
            <p>{item.title}</p>
          </Card>
        ))}
      </div>
    </>
  );
};

export default DefectAnalysisLayout;
