import React, { useCallback, useEffect, useState } from "react";
import deepClone from "lodash/cloneDeep";
import { useTranslation } from "react-i18next";

import useAppContext from "../../../hooks/useAppContext";
import { Subcontractor } from "../../../services/app";

import SubjectLayout from "./SubjectLayout";

export enum ActionType {
  add,
  delete,
  edit,
}

export interface TreeNode {
  key: string;
  title: string;
  children: any[] | undefined;
}

interface SubjectContainerProps {}

const SubjectContainer: React.FC<SubjectContainerProps> = () => {
  const appContext = useAppContext();
  const { defectGroups } = appContext;

  const { t: translation } = useTranslation();

  const [currentNode, setCurrentNode] = useState<TreeNode | undefined>(
    undefined
  );
  const [defectGroupList, setDefectGroupList] = useState<TreeNode[]>([
    {
      key: "Defect Group",
      title: translation("app.common.defectGroup"),
      children: [],
    },
  ]);
  const [descriptionSubcontractors, setDescriptionSubcontractors] = useState<
    Subcontractor[] | undefined
  >(undefined);

  const traverseDefectList = useCallback(
    (comparatorKey: any, depth: number) => {
      let positions: any[] = [];
      let defectGroupPosition = defectGroupList[0].children!.findIndex(
        (item) =>
          item.key
            ?.split("-")
            .map((item: string) => item.replace(/[A-Za-z]+-/g, ""))?.[1] ===
          comparatorKey
            ?.split("-")
            .map((item: string) => item.replace(/[A-Za-z]+-/g, ""))?.[1]
      );

      /** Do not refactor into switch-case. It will not work in this scenario. **/
      if (depth === 1) {
        positions.push(defectGroupPosition);
        return positions;
      }
      let defectSubjectPosition = defectGroupList[0].children![
        defectGroupPosition
      ].children.findIndex(
        (item: any) =>
          item.key
            ?.split("-")
            .map((item: string) => item.replace(/[A-Za-z]+-/g, ""))?.[3] ===
          comparatorKey
            ?.split("-")
            .map((item: string) => item.replace(/[A-Za-z]+-/g, ""))?.[3]
      );
      if (depth === 3) {
        positions.push(defectGroupPosition);
        positions.push(defectSubjectPosition);
        return positions;
      }
      let defectDescriptionPosition = defectGroupList[0].children![
        defectGroupPosition
      ].children[defectSubjectPosition].children.findIndex(
        (item: any) =>
          item.key
            ?.split("-")
            .map((item: string) => item.replace(/[A-Za-z]+-/g, ""))?.[5] ===
          comparatorKey
            ?.split("-")
            .map((item: string) => item.replace(/[A-Za-z]+-/g, ""))?.[5]
      );
      if (depth === 5) {
        positions.push(defectGroupPosition);
        positions.push(defectSubjectPosition);
        positions.push(defectDescriptionPosition);
        return positions;
      }
    },
    [defectGroupList]
  );

  const handleModifiedSubcontractors = (subcontractors: any[]) => {
    let positions = traverseDefectList(currentNode?.key, 5) || undefined;
    defectGroupList[0].children![positions![0]].children[
      positions![1]
    ].children[positions![2]].subcontractors = subcontractors;
  };

  const handleModifyTree = (
    item: any,
    itemType: string,
    actionType: ActionType
  ) => {
    if (itemType === "subject") {
      let positions = traverseDefectList(currentNode?.key, 1) || undefined;
      switch (actionType) {
        case ActionType.add:
          defectGroupList[0].children![positions![0]].children.push({
            key: item.key,
            title: <span title="defect-group-node-title">{item.title}</span>,
            children: [],
          });
          break;
        case ActionType.delete:
          defectGroupList[0].children![positions![0]].children =
            defectGroupList[0].children![positions![0]].children.filter(
              (children: { key: any }) => item.key !== children.key
            );
          break;
        case ActionType.edit:
          defectGroupList[0].children![positions![0]].children.filter(
            (children: { key: any }) => item.key === children.key
          )[0].title = item.name;
          break;
      }
    } else if (itemType === "description") {
      let positions = traverseDefectList(currentNode?.key, 3) || undefined;
      switch (actionType) {
        case ActionType.add:
          defectGroupList[0].children![positions![0]].children[
            positions![1]
          ].children.push({
            key: item.key,
            title: item.title,
            children: [],
          });
          break;
        case ActionType.delete:
          defectGroupList[0].children![positions![0]].children[
            positions![1]
          ].children = defectGroupList[0].children![positions![0]].children[
            positions![1]
          ].children.filter(
            (children: { key: any }) => item.key !== children.key
          );
          break;
        case ActionType.edit:
          defectGroupList[0].children![positions![0]].children[
            positions![1]
          ].children.filter(
            (children: { key: any }) => item.key === children.key
          )[0].title = item.name;
          break;
      }
    }
    /** Set defect group list again to refresh the values. **/
    setDefectGroupList([...defectGroupList]);
  };

  /** Do not remove selectedKeys attribute. **/
  const onSelect = useCallback(
    (selectedKeys: React.Key[], info: any) => {
      setCurrentNode({
        key: info.node.key,
        title: info.node.title,
        children: info.node.children,
      });
      if (info.node.key.includes("Defect Description")) {
        let positions = traverseDefectList(info.node.key, 5) || undefined;
        setDescriptionSubcontractors(
          defectGroupList[0].children![positions![0]].children[positions![1]]
            .children[positions![2]].subcontractors
        );
      }
    },
    [defectGroupList, traverseDefectList]
  );

  useEffect(() => {
    /*
     *      On initial load, check whether or not Defect Group List contains children objects.
     *      If not, get Defect Groups from AppContext and set it to a local variable.
     *      The `id` attribute of Defect Group's children and its children are set to a unique key.
     *
     *      After the unique keys are modified, we set Defect Groups to children of Defect Group List.
     *      A temporary variable is assigned to Defect Group List so that the object can be stringified.
     *      The object's key's attribute names is shaped/modified to be suitable for antd's Tree API.
     */
    if (defectGroupList[0].children!.length === 0) {
      var defectGroups_ = deepClone(defectGroups);
      defectGroups_.sort((a, b) => a.name.localeCompare(b.name));
      defectGroups_?.forEach((group: any) => {
        group.id = `Defect Group-${group.id}-`;
        group.defectSubjects.sort((a: { name: string }, b: { name: any }) =>
          a.name.localeCompare(b.name)
        );
        group.defectSubjects
          .filter((subject: any) => !subject.disabled)
          .forEach((subject: any) => {
            subject.id = group.id + `Defect Subject-${subject.id}-`;
            subject.defectDescriptions.sort(
              (a: { name: string }, b: { name: any }) =>
                a.name.localeCompare(b.name)
            );
            subject.defectDescriptions
              .filter((description: any) => !description.disabled)
              .forEach((description: any) => {
                description.id =
                  subject.id + `Defect Description-${description.id}-`;
              });
          });
      });

      defectGroupList[0].children = defectGroups_;
      setDefectGroupList(
        JSON.parse(
          JSON.stringify(defectGroupList)
            .replace(/"id":/g, '"key":')
            .replace(/"name":/g, '"title":')
            .replace(/"defectSubjects":/g, '"children":')
            .replace(/"defectDescriptions":/g, '"children":')
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defectGroupList, defectGroups]);

  return (
    <SubjectLayout
      currentNode={currentNode!}
      defectGroupList={[...defectGroupList]}
      descriptionSubcontractors={descriptionSubcontractors}
      handleModifiedSubcontractors={handleModifiedSubcontractors}
      handleModifyTree={handleModifyTree}
      onSelect={onSelect}
    />
  );
};

export default SubjectContainer;
