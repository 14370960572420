import { PlusOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";

interface Props {
  onClick: () => void;
}

const useStyles = createUseStyles((theme) => ({
  card: {
    backgroundColor: "rgba(251, 251, 253, 1)",
    color: theme.palette.primary,
    marginBottom: 10,
    "& .ant-card-body": {
      padding: 5,
    },
  },
  addIcon: {
    display: "inline-block",
    width: 40,
    height: 40,
    background: "#EAEDF3",
    borderRadius: 5,
    marginRight: 10,
    color: theme.palette.primary,
    lineHeight: 2.5,
    verticalAlign: "middle",
    "& span": {
      verticalAlign: "middle",
      color: theme.palette.primary,
    },
  },
  content: {
    textAlign: "center",
    width: "100%",
    color: theme.palette.primary,
    fontSize: theme.fontSize.small,
    fontWeight: theme.fontWeight.bolder,
    textTransform: "uppercase",
  },
}));

const UploaderListItem: React.FC<Props> = ({ onClick }) => {
  const classes = useStyles();

  const { t: translation } = useTranslation();

  return (
    <Card className={classes.card} onClick={onClick}>
      <Row>
        <Col span={24}>
          <div className={classes.content}>
            <div className={classes.addIcon}>
              <PlusOutlined color={"rgb(0, 87, 148)"} />
            </div>
            {translation(
              "locationDetails.locationFileTab.dragOrBrowseFileToUpload"
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default UploaderListItem;
