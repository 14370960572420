import React from "react";

import { NxpHeader } from "@nexploretechnology/nxp-ui";

import { useTranslation } from "react-i18next";

interface ChecklistLayoutProps {}

const ChecklistLayout: React.FC<ChecklistLayoutProps> = () => {
  const { t: translation } = useTranslation();

  return (
    <>
      <NxpHeader titleContent={translation("app.common.checklist")} />
    </>
  );
};

export default ChecklistLayout;
