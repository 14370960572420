import { notification } from "antd";
import { ArgsProps } from "antd/lib/notification";

// for suppressing duplicate error
const activeErrorSet = new Set<string>();

const notify = {
  success: (message: string, args?: Omit<ArgsProps, "message">) =>
    notification.success({ ...args, message }),
  info: (message: string, args?: Omit<ArgsProps, "message">) =>
    notification.info({ ...args, message }),
  warning: (message: string, args?: Omit<ArgsProps, "message">) =>
    notification.warning({ ...args, message }),
  error: (ex: Error | string | unknown) => {
    const errorStr =
      typeof ex === "string" ? ex : ex instanceof Error ? ex.message : `${ex}`;

    if (!activeErrorSet.has(errorStr)) {
      activeErrorSet.add(errorStr);
      notification.warning({
        message: (
          <>
            <h3>Error occurred.</h3>
            <p>{errorStr}</p>
          </>
        ),
        duration: 0,
        onClose: () => activeErrorSet.delete(errorStr),
      });
    }
  },
  actionCompleted: () =>
    notification.success({
      message: "Action completed successfully.",
    }),
};

export default notify;
