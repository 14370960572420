import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";

// import useAppContext from "../../../hooks/useAppContext";
import { NxpButton, NxpHeader } from "@nexploretechnology/nxp-ui";
import { Col, Row } from "antd";
import clsx from "clsx";
import _ from "lodash";

import { AppTheme } from "../../../components/App/appTheme";
import useAppContext from "../../../hooks/useAppContext";
import Theme from "../../../theme.config";
import { familyTree } from "../../../utils/dataPath/findTreePath";
import notify from "../../../utils/notify";
import { LocationDetailsContext } from "../LocationDetailsContainer";
import TabBar from "../TabBar";

interface Props {
  onActiveKeyChange: (activeKey: string) => void;
}

const useStyles = createUseStyles<
  "container",
  {
    printing: boolean;
  },
  AppTheme
>((theme) => ({
  container: {
    width: "100%",
    height: "calc( 100vh - 320px )",
    padding: theme.spacing(5, 2),
    background: theme.palette.backgroundDark,
    fontWeight: theme.fontWeight.bolder,
    fontSize: theme.pxToRem(20),
    color: theme.palette.text,
    "& .ant-col": {
      display: "inline-flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      "& .locactionTreePath": {
        padding: theme.spacing(3, 0),
        "& .title": {
          padding: theme.spacing(1, 0),
          textAlign: "center",
        },
      },
    },
  },
}));

const LocationQRCode: React.FC<Props> = ({ onActiveKeyChange }) => {
  const { location } = useContext(LocationDetailsContext);
  const appContext = useAppContext();

  const { t: translation } = useTranslation();

  const [printing, setPrinting] = useState<boolean>(false);
  const { activeLocationGroup, getLocationFullPath, activeEntity } = appContext;
  const locationTree = {
    ...activeLocationGroup,
    ...{
      title: activeLocationGroup?.name,
      value: activeLocationGroup?.id.toString(),
    },
  };
  const classes = useStyles({ printing });
  const componentRef = useRef<HTMLInputElement | null>(null);
  const pathArr = familyTree([locationTree], location?.id.toString());
  const handlePrint = useReactToPrint({
    bodyClass: "floor-plan-location-code",
    documentTitle: pathArr.find((node) => node.id === location?.id)?.code,
    content: () => componentRef.current,
    onBeforeGetContent: () => setPrinting(true),
    copyStyles: false,
    onAfterPrint: () => setPrinting(false),
    onPrintError: (error) => notify.warning(`print ${error} !`),
    pageStyle: `
        @media all{
            
            .page-container{
                width: 100vw;
                height: 70vh;
                margin: auto 0;
                text-align: center;
                display: flex;
                align-items: center;
                font-size: ${Theme.pxToRem(30)};
            }

        }
        
        @page {
            background: ${Theme.palette.background} !important; 
            align-items: center;
            display: flex;
        }
        @media print {
            body {
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
            }
            .locactionTreePath{
                padding: ${Theme.spacing(2)}
            }
            .locactionTreePath .title{
                padding: ${Theme.spacing(2)}
            }
            @page {
               margin-left: 0;
               margin-right: 0;
               margin-top: 0;
               margin-bottom: 0;
             }
       }`,
  });
  useEffect(() => {
    return () => {
      setPrinting(false);
    };
  }, []);
  const tmpl = () => (
    <Row
      align="middle"
      justify="center"
      className={clsx(classes.container, "page-container")}
      ref={componentRef}
    >
      <Col flex="auto">
        <div className="locactionTreePath" id="locactionTreePath">
          <div className="title">
            {`${_.upperCase(activeEntity?.id)}_${_.join(
              _.map(_.words(locationTree?.name), (word) => _.upperCase(word)),
              "_"
            )}_${pathArr.find((node) => node.id === location?.id)?.code}`}
          </div>
          {activeLocationGroup && getLocationFullPath(location)}
        </div>
        <QRCode value={location.code} />
      </Col>
    </Row>
  );
  return (
    <>
      <NxpHeader
        titleContent={location.name}
        actionContent={
          <>
            <NxpButton
              type="primary"
              loading={printing}
              onClick={() => {
                setPrinting(true);
                if (handlePrint) handlePrint();
              }}
            >
              {translation("app.common.print")}
            </NxpButton>
          </>
        }
      />
      <TabBar activeKey="qr-code" onChange={onActiveKeyChange} />
      {tmpl()}
    </>
  );
};

export default LocationQRCode;
