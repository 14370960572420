import React from "react";
import { Tree } from "antd";

interface SubjectTreeProps {
  defectGroupList: any;
  onSelect: (selectedKeys: React.Key[], info: any) => void;
}

const SubjectTree: React.FC<SubjectTreeProps> = ({
  defectGroupList,
  onSelect,
}) => {
  return (
    <Tree
      defaultExpandedKeys={["Defect Group"]}
      treeData={defectGroupList}
      onSelect={onSelect}
    />
  );
};

export default React.memo(SubjectTree);
