import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { notify, NxpHeader } from "@nexploretechnology/nxp-ui";
import { Col, Row, Spin } from "antd";
import { groupBy, head } from "lodash";

import useAppContext from "../../../hooks/useAppContext";
import { Subcontractor } from "../../../services/app";
import {
  getAllReminders,
  ReminderType,
  SubcontractorSubscription,
} from "../../../services/email";
import { reminderTypeList } from "./helper";
import EmailNotificationLogs from "./Logs/EmailNotificationLogs";
import EmailNotificationMenu from "./Menu/EmailNotificationMenu";
import EmailNotificationSetup from "./Setup/EmailNotificationSetup";
import EmailNotificationSetupAll from "./Setup/EmailNotificationSetupAll";
import TabBar, { tabKey } from "./TabBar/TabBar";

interface EmailNotificationLayoutProps {}
const useStyles = createUseStyles(() => ({
  root: {
    margin: "-16px -40px",
    width: "calc(100% + 80px);",
  },
  header: {
    marginLeft: "25px",
    fontWeight: "bold",
  },
  content: {
    height: "calc(100vh - 85px)",
    overflowY: "scroll",
    padding: "16px 40px",
    flexGrow: 1,
  },
  logs: {},
}));

const EmailNotificationLayout: React.FC<EmailNotificationLayoutProps> = () => {
  const { t: translation } = useTranslation();
  const classes = useStyles();

  const { subcontractors, routeParams, serviceConfig } = useAppContext();
  const { entityId } = routeParams;

  const [activeTabKey, setActiveTabKey] = useState<tabKey>("setup");
  const [selectedKey, setSelectedKey] = useState<React.Key[]>(["0"]);
  const [loading, setLoading] = useState<boolean>(false);
  const isParentNode = Number(head(selectedKey)) === 0;

  const [currentSubcontractor, setCurrentSubcontractor] =
    useState<Subcontractor>();

  const [allReminderSettings, setAllReminderSettings] = useState<
    SubcontractorSubscription[]
  >([]);

  // list of reminderType that subscribed by all subcontractors
  const [allSubscribeList, setAllSubscribeList] = useState<ReminderType[]>([]);

  const fetchAllReminders = useCallback(async () => {
    setLoading(true);
    try {
      const newAllReminderSettings = await getAllReminders(
        entityId,
        serviceConfig
      );
      setAllReminderSettings(newAllReminderSettings);
    } catch (ex) {
      notify.error(`Fetch ${ex}`);
    } finally {
      setLoading(false);
    }
  }, [entityId, serviceConfig]);

  useEffect(() => {
    // check is all subscribed for each reminderType
    const newAllSubscribeList: ReminderType[] = [];
    for (const reminderTypeObj of reminderTypeList) {
      const reminderType = reminderTypeObj.value;
      const result = groupBy(
        allReminderSettings.filter(
          (reminder) => reminder.reminderType === reminderType
        ),
        "subcon.id"
      );
      const isAllSubscribe =
        subcontractors.length === Object.keys(result).length;
      if (isAllSubscribe) {
        newAllSubscribeList.push(reminderType as ReminderType);
      }
    }
    setAllSubscribeList(newAllSubscribeList);
  }, [allReminderSettings, subcontractors.length]);

  useEffect(() => {
    fetchAllReminders();
  }, [currentSubcontractor, fetchAllReminders]);

  return (
    <div className={classes.root}>
      <Row wrap>
        <Col flex="auto">
          <EmailNotificationMenu
            selectedKey={selectedKey}
            setSelectedKey={setSelectedKey}
            setActiveTabKey={setActiveTabKey}
            setCurrentSubcontractor={setCurrentSubcontractor}
            allReminderSettings={allReminderSettings}
            allSubscribeList={allSubscribeList}
          />
        </Col>
        <Col span={16}>
          <Spin spinning={loading}>
            <div className={classes.content}>
              <NxpHeader
                titleContent={`${translation(
                  "settings.emailNotification.emailNotification"
                )} ${
                  currentSubcontractor ? `- ${currentSubcontractor.name}` : ""
                }`}
              />
              {!isParentNode && (
                <TabBar
                  activeTabKey={activeTabKey}
                  onChange={setActiveTabKey}
                />
              )}
              {activeTabKey === ("setup" as tabKey) && (
                <>
                  {isParentNode ? (
                    <EmailNotificationSetupAll
                      allReminderSettings={allReminderSettings}
                      allSubscribeList={allSubscribeList}
                      fetchAllReminders={fetchAllReminders}
                      setLoading={setLoading}
                    />
                  ) : (
                    <EmailNotificationSetup
                      currentSubcontractor={currentSubcontractor!}
                      allReminderSettings={allReminderSettings}
                      fetchAllReminders={fetchAllReminders}
                      setLoading={setLoading}
                    />
                  )}
                </>
              )}
              {activeTabKey === ("logs" as tabKey) && (
                <EmailNotificationLogs
                  currentSubcontractor={currentSubcontractor!}
                  setLoading={setLoading}
                />
              )}
            </div>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export default EmailNotificationLayout;
