import React from "react";

import EmailNotificationLayout from "./EmailNotificationLayout";

interface EmailNotificationContainerProps {}

const EmailNotificationContainer: React.FC<EmailNotificationContainerProps> =
  () => {
    return <EmailNotificationLayout />;
  };

export default EmailNotificationContainer;
