import { apiRequest, ServiceConfig } from "../utils/backend";

export const getInspectors = async (
  entityId: string,
  locationGroupId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/entities/${entityId}/location-groups/${locationGroupId}/reports/defect-count-by-responsible-person`,
    serviceConfig
  );
};
