import React from "react";
import { NxpButton } from "@nexploretechnology/nxp-ui";
import { Row, Col, Alert } from "antd";
import { NxpModal } from "@nexploretechnology/nxp-ui";

import { useTranslation } from "react-i18next";

interface DeleteFloorPlanModalProps {
  showModal: boolean;
  handleDialogClose: () => void;
  handleOnDelete: () => Promise<boolean | undefined>;
  defectAffacted: number;
  isAdmin: boolean;
}

const DeleteFloorPlanContainer: React.FC<DeleteFloorPlanModalProps> = ({
  showModal,
  handleDialogClose,
  handleOnDelete,
  defectAffacted,
  isAdmin,
}) => {
  const { t: translation } = useTranslation();

  const handleConfirm = async () => {
    const res = await handleOnDelete();
    if (res) {
      handleDialogClose();
    }
  };

  return (
    <NxpModal
      width="large"
      title={translation(
        "locationDetails.locationDeleteFloorPlan.deleteFloorPlan"
      )}
      visible={showModal}
      onCancel={handleDialogClose}
      footer={
        <NxpButton
          onClick={handleConfirm}
          disabled={defectAffacted > 0 && !isAdmin}
        >
          {translation("app.common.confirm")}
        </NxpButton>
      }
    >
      <Row justify="start">
        <Col>
          <div style={{ padding: "16px" }}>
            {translation(
              "locationDetails.locationDeleteFloorPlan.doYouWantToDeleteTheFloorPlan"
            )}
          </div>
        </Col>
      </Row>
      {defectAffacted > 0 && (
        <Alert
          message={`This floor plan cannot be deleted! This plan are used in ${defectAffacted} defect`}
          type="error"
        />
      )}
    </NxpModal>
  );
};

export default DeleteFloorPlanContainer;
