import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { PlusOutlined } from "@ant-design/icons";
import { NxpButton, NxpFormItem, NxpModal } from "@nexploretechnology/nxp-ui";

import { AppDefectListItem } from "../AppDefectList/AppDefectListLayout";
import { Filter } from "./AppDefectFilterModalContainer";
import OperatorWidget from "./OperatorWidget";
import ValueWidget from "./ValueWidget";

interface Props {
  show: boolean;
  blockedFields: (keyof AppDefectListItem)[];
  filters: Filter[];
  onFilterFieldChange: (
    index: number,
    field: keyof AppDefectListItem | ""
  ) => void;
  onFilterOperatorChange: (index: number, value: Filter["operator"]) => void;
  onFilterValueChange: (index: number, value: any) => void;
  onAddFilter: () => void;
  onDeleteFilter: (index: number) => void;
  onClearAllFilters: () => void;
  onApply: () => void;
  onDismiss: () => void;
}

const useStyles = createUseStyles((theme) => ({
  gridRoot: {
    display: "flex",
    flexDirection: "column",
  },
  gridCaptionRow: {
    margin: theme.spacing(1, 0),
    fontSize: theme.fontSize.small,
    color: theme.palette.textLabel,
    fontWeight: theme.fontWeight.bold,
    textTransform: "uppercase",
    display: "flex",
    width: "100%",
  },
  gridRow: {
    display: "flex",
    width: "100%",
  },
  gridCell: { width: "30%", paddingRight: 20 },
  gridRowAction: { width: "10%" },

  addButton: {
    color: theme.palette.textLink,
    backgroundColor: "rgba(138, 153, 153, 0.1)",
    border: "1px solid rgba(138, 153, 153, 0.1)",
  },
}));

const FilterModalLayout: React.FC<Props> = (props) => {
  const { t: translation } = useTranslation();
  const classes = useStyles();

  let fieldOptions: { label: string; value: "" | keyof AppDefectListItem }[] = [
    { label: "", value: "" },
    {
      label: translation("app.common.defectNumber"),
      value: "no",
    },
    {
      label: translation("app.common.status"),
      value: "status",
    },
    {
      label: translation("app.common.inspectionType"),
      value: "inspectionType",
    },
    {
      label: translation("app.common.defectType"),
      value: "defectType",
    },
    {
      label: translation("app.common.raisedOn"),
      value: "createdAt",
    },
    {
      label: translation("app.common.raisedBy"),
      value: "raisedBy",
    },
    {
      label: translation("app.common.defectGroup"),
      value: "defectGroup",
    },
    {
      label: translation("app.common.defectSubject"),
      value: "defectSubject",
    },
    {
      label: translation("app.common.defectDescription"),
      value: "defectDescription",
    },
    {
      label: translation("app.common.responsiblePerson"),
      value: "responsiblePerson",
    },
    {
      label: translation("app.common.responsibleParty"),
      value: "responsibleParty",
    },
    {
      label: translation("app.common.nextInspectionDate"),
      value: "nextInspectionDate",
    },
    {
      label: translation("app.common.dueDate"),
      value: "dueDate",
    },
    {
      label: translation("appDefectFilterModal.isTopPriority"),
      value: "isTopPriority",
    },
    {
      label: translation("app.common.location"),
      value: "location",
    },
    {
      label: translation("app.common.remarks"),
      value: "remarks",
    },
    {
      label: translation("app.common.constructionLotNo"),
      value: "constructionLotNo",
    },
    { label: translation("app.common.ncrNo"), value: "ncrNo" },
  ];

  fieldOptions = fieldOptions.map((option) =>
    option.value !== "" &&
    !props.blockedFields.includes(option.value as keyof AppDefectListItem)
      ? option
      : { ...option, disabled: true }
  );

  return (
    <NxpModal
      title={translation("appDefectFilterModal.filter")}
      visible={props.show}
      onCancel={props.onDismiss}
      footer={
        <>
          <NxpButton type="default" onClick={props.onClearAllFilters}>
            {translation("appDefectFilterModal.clearAllFilters")}
          </NxpButton>
          <NxpButton type="primary" onClick={props.onApply}>
            {translation("app.common.save")}
          </NxpButton>
        </>
      }
      width="medium"
    >
      <div className={classes.gridRoot}>
        <div className={classes.gridCaptionRow}>
          <div className={classes.gridCell}>
            {translation("appDefectFilterModal.filterField")}
          </div>
          <div className={classes.gridCell}>
            {translation("appDefectFilterModal.filterSettings")}
          </div>
          <div className={classes.gridCell}>
            {translation("appDefectFilterModal.filterValue")}
          </div>
          <div className={classes.gridRowAction}></div>
        </div>
        {props.filters.map((filter, index) => (
          <div className={classes.gridRow} key={index}>
            <div className={classes.gridCell}>
              <NxpFormItem
                controlType="select"
                controlProps={{
                  options: fieldOptions,
                  value: filter.field,
                  placeholder: translation("app.common.pleaseSelect"),
                  onChange: (value) =>
                    props.onFilterFieldChange(
                      index,
                      value as keyof AppDefectListItem
                    ),
                }}
              />
            </div>
            <div className={classes.gridCell}>
              <OperatorWidget
                index={index}
                filter={filter}
                onFilterOperatorChange={props.onFilterOperatorChange}
              />
            </div>
            <div className={classes.gridCell}>
              <ValueWidget
                index={index}
                filter={filter}
                onFilterValueChange={props.onFilterValueChange}
              />
            </div>
            <div className={classes.gridRowAction}>
              <NxpButton
                danger
                className={classes.addButton}
                type="text"
                onClick={() => props.onDeleteFilter(index)}
              >
                {translation("app.common.delete")}
              </NxpButton>
            </div>
          </div>
        ))}
        <NxpButton
          className={classes.addButton}
          icon={<PlusOutlined />}
          type="default"
          onClick={props.onAddFilter}
        >
          {translation("appDefectFilterModal.addFilter")}
        </NxpButton>
      </div>
    </NxpModal>
  );
};

export default FilterModalLayout;
