import { omit } from "lodash";
import { apiRequest, ServiceConfig } from "../utils/backend";
import { Subcontractor } from "./app";

export interface SubcontractorCreateForm
  extends Pick<
    Subcontractor,
    | "name"
    | "primaryContactPerson"
    | "primaryContactEmail"
    | "address"
    | "smsNo"
    | "smsAreaCode"
  > {}

export const createSubcontractor = async (
  entityId: string,
  subcontractorCreateForm: SubcontractorCreateForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Subcontractor>(
    `/entities/${entityId}/subcontractors`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(subcontractorCreateForm),
    }
  );
};

export const updateSubcontractor = async (
  entityId: string,
  subcontractor: Subcontractor,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Subcontractor>(
    `/entities/${entityId}/subcontractors/${subcontractor.id}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(
        omit(
          subcontractor,
          "entityId",
          "id",
          "deletedOn",
          "createdOn",
          "users",
          "updatedOn"
        )
      ),
    }
  );
};

export const deleteSubcontractor = async (
  entityId: string,
  id: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/entities/${entityId}/subcontractors/${id}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};

export const updateSubcontractorUserList = async (
  entityId: string,
  id: number,
  userIds: string[],
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Subcontractor>(
    `/entities/${entityId}/subcontractors/${id}/users`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({ userIds }),
    }
  );
};
