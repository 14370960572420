import React, { useContext, useEffect, useMemo } from "react";
import { LocationDetailsContext } from "../LocationDetailsContainer";
import { NxpHeader } from "@nexploretechnology/nxp-ui";
import TabBar from "../TabBar";
import useAppContext from "../../../hooks/useAppContext";
import buildQueryString from "../../../utils/backend/buildQueryString";
import AppFloorPlan from "../../../components/AppFloorPlan";
import _ from "lodash";
import AppEmptyFloorPlan from "../../../components/AppEmptyFloorPlan";

interface Props {
  onActiveKeyChange: (activeKey: string) => void;
}
export type HeatMapCount = {
  key: number;
  type: "outstanding" | "total";
  averageDuration: string;
  count: string;
  totalBackchargeCost: string;
  totalCost: string;
  totalScheduleImpact: string;
};

const LocationHeatmap: React.FC<Props> = ({ onActiveKeyChange }) => {
  const { getChildLocationIdList } = useAppContext();
  const { location, defectStatistics, requestDefectStatistic } = useContext(
    LocationDetailsContext
  );

  const queries = useMemo(() => {
    if (!location) return {};
    const childProfiles = location.children?.map((location) => ({
      id: location.id,
      allIds: getChildLocationIdList(location),
    }));
    const result = {} as Record<string, Record<string, any>>;

    for (const profile of childProfiles) {
      result[`${profile.id}_outstanding`] = {
        locationId: profile.allIds.join(","),
        statusNot: "closed,cancelled",
      };
      result[`${profile.id}_total`] = {
        locationId: profile.allIds.join(","),
        statusNot: "cancelled",
      };
    }

    return result;
  }, [getChildLocationIdList, location]);

  useEffect(() => {
    if (!location) return;
    Object.keys(queries).forEach((key: keyof typeof queries) => {
      requestDefectStatistic(buildQueryString(queries[key]));
    });
  }, [location, queries, requestDefectStatistic]);

  const handleOnFloorPlanChange = (area: any) => console.log(area);

  return (
    <>
      <NxpHeader titleContent={location.name} />
      <TabBar activeKey="heat-map" onChange={onActiveKeyChange} />
      {location?.floorPlans.length ? (
        <AppFloorPlan
          editFloorPlan={false}
          handleOnFloorPlanChange={handleOnFloorPlanChange}
          locationChildNode={location?.children}
          type="heatMap"
          heatMapCount={_.map(queries, (v, k) => {
            return {
              key: Number(_.split(k, "_")[0]),
              type: _.split(k, "_")[1],
              ...defectStatistics[buildQueryString(queries[k])],
            } as HeatMapCount;
          })}
          floorPlan={{
            ...location?.floorPlans[0],
            container: {
              width: window.innerWidth - 650,
              height: window.innerHeight - 320,
            },
          }}
        />
      ) : (
        <AppEmptyFloorPlan />
      )}
    </>
  );
};

export default LocationHeatmap;
