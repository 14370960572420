import React, { useState } from "react";

import ReportMenuLayout from "./ReportMenuLayout";

interface ReportMenuContainerProps {}

export type View =
  | "defect-summary-by-responsible-party"
  | "outstanding-defect-summary-by-responsible-party"
  | "overdue-defect-summary-by-responsible-party"
  | "back-charge-summary-by-responsible-party"
  | "monthly-defect-count-by-day"
  | "location-and-responsible-party-report"
  | "location-and-defect-group-report"
  | "back-charge-cost-summary-by-responsible-party";

const ReportMenuContainer: React.FC<ReportMenuContainerProps> = () => {
  const [view, setView] = useState<View | string>("");

  return <ReportMenuLayout view={view} onViewChange={setView} />;
};

export default ReportMenuContainer;
