import React from "react";
import {
  DefectGroup,
  Entity,
  Location,
  Enum,
  User,
  Subcontractor,
  DefectSubject,
  DefectDescription,
  UserRightList,
  Team,
  EntityParameter,
} from "../../services/app";
import { ServiceConfig } from "../../utils/backend";

export interface AppRouteParams {
  entityId?: string;
  locationGroupId?: number;
  locationId?: number;
  defectId?: number;
  subcontractorId?: number;
  reportType?: string;
}

export interface AppContextCache {
  locationGroups: Location[];
  locationFullPaths: Record<number, string>;
  defectTypes: Enum[];
  defectCauses: Enum[];
  inspectionTypes: Enum[];
  subcontractors: Subcontractor[];
  users: User[];
  defectGroups: DefectGroup[];
  defectSubjects: DefectSubject[];
  defectDescriptions: DefectDescription[];
  routeParams: AppRouteParams;
  activeEntity?: Entity;
  activeEntityParameter?: EntityParameter;
  activeLocationGroup?: Location;
  activeUser?: User;
  userRightList?: UserRightList;
  teams: Team[];
  language?: string;
}

type AppContextType = AppContextCache & {
  getLocationFullPath: (location: Location) => string;
  getChildLocationIdList: (location: Location) => number[];
  onAppContextCacheUpdate: React.Dispatch<
    React.SetStateAction<AppContextCache>
  >;
  refetchDefectGroups: (
    entityId?: string,
    serviceConfig?: ServiceConfig
  ) => void;
  refetchEnums: (entityId?: string, serviceConfig?: ServiceConfig) => void;
  refetchSubcontractors: (
    entityId?: string,
    serviceConfig?: ServiceConfig
  ) => void;
  refetchTeams: (entityId?: string, serviceConfig?: ServiceConfig) => void;
  onAppContextCacheItemUpdate: <K extends keyof AppContextCache>(
    stateItem: K,
    itemValue: AppContextCache[K]
  ) => void;
  hasRight: (
    rightType: keyof UserRightList,
    source?: "module" | "locationGroup",
    objectId?: number
  ) => boolean;
};

const AppContext = React.createContext<AppContextType>({} as AppContextType);

export default AppContext;
