import React, { useEffect, useState } from "react";
import { Spin } from "antd";

import useAppContext from "../../hooks/useAppContext";

import { Defect, getDefects } from "../../services/defect";

import DashboardLayout from "./DashboardLayout";

interface DashboardContainerProps {
  locations?: number[];
}

const DashboardContainer: React.FC<DashboardContainerProps> = ({
  locations,
}) => {
  const appContext = useAppContext();
  const { serviceConfig, routeParams, activeLocationGroup } = appContext;
  const { entityId, locationGroupId } = routeParams;

  const [defects, setDefects] = useState<Defect[]>();

  const [isLoading, setIsLoading] = useState<boolean>();

  useEffect(() => {
    setIsLoading(true);

    let query = `?full=true&statusNot=cancelled`;
    if (locations?.length) {
      query += `&location=${locations.toString()}`;

      getDefects(
        entityId,
        locationGroupId,
        query,
        undefined,
        "asc",
        0,
        0,
        serviceConfig
      ).then((defects: Defect[]) => {
        setDefects(defects);
      });
    } else if (locations === undefined) {
      getDefects(
        entityId,
        locationGroupId,
        query,
        undefined,
        "asc",
        0,
        0,
        serviceConfig
      ).then((defects: Defect[]) => setDefects(defects));
    }
    setIsLoading(false);
  }, [entityId, locationGroupId, locations, serviceConfig]);

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <DashboardLayout
          locationGroup={locations?.length ? "" : activeLocationGroup?.name}
          defects={defects!}
        />
      )}
    </>
  );
};

export default DashboardContainer;
