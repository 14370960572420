import React, { useEffect, useState } from "react";

import { Defect } from "../../../../services/defect";

import DefectGroupLayout from "./DefectGroupLayout";

interface DefectGroupContainerProps {
  defects: Defect[];
}

let initialData: {
  group: string;
  total: string;
}[] = [];

const DefectGroupContainer: React.FC<DefectGroupContainerProps> = ({
  defects,
}) => {
  const [data, setData] = useState(initialData);

  useEffect(() => {
    initialData = [];

    const defectGroups = new Set(
      defects.map((defect) => defect.defectGroup?.id)
    );

    defectGroups.forEach((value) => {
      initialData.push({
        group: `${
          defects.find((defect) => defect.defectGroup?.id === value)
            ?.defectGroup?.name
        }`,
        total: `${
          defects.filter(
            (defect) =>
              defect.defectGroup?.id === value && defect.status !== "cancelled"
          ).length
        }`,
      });

      initialData.sort((a, b) => Number(b.total) - Number(a.total));
      setData([...initialData]);
    });
  }, [defects]);

  return <DefectGroupLayout data={data} />;
};

export default DefectGroupContainer;
