import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { createUseStyles } from "react-jss";
import TuiImageEditor from "tui-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";
import { AppTheme } from "../App/appTheme";

interface Props {
  src: string;
  height: number;
  onInstanceMount: (editor: TuiImageEditor) => void;
}

const useStyles = createUseStyles<
  "editorContainer",
  { height: number },
  AppTheme
>((theme) => {
  return {
    editorContainer: {
      minHeight: (props) => (props?.height ? props.height : 600),
      "& .tui-image-editor-controls": {
        backgroundColor: "#ccc",
        "& .tui-image-editor-controls-buttons": {
          display: "none",
        },
      },
      "& .tui-colorpicker-palette-button": {
        width: 30,
        height: 30,
      },
      "& .tui-image-editor-main": {
        top: 0,
      },
      "& .tui-image-editor-main-container": {
        "& .tui-image-editor-header": {
          display: "none",
        },
      },
      "& .tui-image-editor-container ": {
        "& .tui-image-editor-main ": { top: 0 },
      },
    },
  };
});

const AppImageEditor: React.FC<Props> = ({ src, onInstanceMount, height }) => {
  const classes = useStyles({ height });

  const rootEl = useRef<HTMLDivElement>(null);
  const [instance, setInstance] = useState<TuiImageEditor | null>(null);

  useLayoutEffect(() => {
    if (!instance) {
      const newInstance = new TuiImageEditor(rootEl.current as Element, {
        usageStatistics: false,
        includeUI: {
          loadImage: {
            path: src,
            name: "SampleImage",
          },
          theme: {
            "common.bi.image": "",
            "header.backgroundColor": "#ccc",
            "common.backgroundColor": "#fff",
            "submenu.backgroundColor": "#ccc",
          },
          menu: ["crop", "shape", "text"],
          menuBarPosition: "left",
        },
        selectionStyle: {
          cornerSize: 20,
          rotatingPointOffset: 70,
        },
      });
      setInstance(newInstance);
      onInstanceMount(newInstance);
    }
  }, [instance, src, onInstanceMount]);

  useEffect(() => {
    if (instance) {
      instance?.loadImageFromURL(src);
    }
  }, [instance, src]);

  return (
    <div>
      <div
        data-testid="edit-image-container"
        ref={rootEl}
        className={classes.editorContainer}
      ></div>
    </div>
  );
};

export default AppImageEditor;
