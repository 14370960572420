import React from "react";
import { createUseStyles } from "react-jss";
import emptyFloorPlan from "../../images/FloorPlanEmpty.svg";
import { useTranslation } from "react-i18next";

const useStyles = createUseStyles((theme) => ({
  centerMsg: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: theme.spacing(4, 0),
    "& i.nxpSidebarIcon": {
      width: 102,
      height: 95,
      backgroundImage: `url(${emptyFloorPlan})`,
      transform: "scale(.8)",
    },
    "& .msg": {
      color: theme.palette.text,
      fontWeight: theme.fontWeight.bolder,
      fontSize: theme.pxToRem(20),
    },
  },
  empty: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    "& .upload": {
      width: "100%",
    },
    "& .ant-upload-list": {
      color: theme.palette.primary,
    },
    "& .upload.ant-row": {
      padding: theme.spacing(1),
      background: theme.palette.background,
      borderRadius: theme.borderRadius.main,
      // width: 500,
      fontWeight: theme.fontWeight.bolder,
      fontSize: theme.pxToRem(12),
      "& .ant-col": {
        padding: theme.spacing(0, 2),
        color: theme.palette.textLabel,
        "& .ant-upload-list-item-card-actions": {
          color: theme.palette.primary,
        },
        "&.file-upload": {
          "& > span": {
            display: "inline-flex",
          },
        },
      },
    },
  },
}));

export default function AppEmptyFloorPlan() {
  const classes = useStyles();
  const { t: translation } = useTranslation();
  return (
    <div id="empty-map" className={classes.empty}>
      <div className={classes.centerMsg}>
        <i className="nxpSidebarIcon" />
        <div className="msg">
          {translation("appEmptyFloorPlan.noFloorPlanToDisplay")}
        </div>
      </div>
    </div>
  );
}
