import { omit } from "lodash";
import { apiRequest, ServiceConfig } from "../utils/backend";
import { DefectSubject, DefectDescription, Enum } from "./app";

export interface DefectSubjectForm extends Pick<DefectSubject, "name"> {}

export interface DefectDescriptionForm
  extends Pick<DefectDescription, "name"> {}

export const createDefectSubject = async (
  entityId: string,
  defectSubjectForm: DefectSubjectForm,
  defectGroupId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<DefectSubject>(
    `/entities/${entityId}/defect-groups/${defectGroupId}/defect-subjects`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(defectSubjectForm),
    }
  );
};

export const updateDefectSubject = async (
  entityId: string,
  defectSubjectForm: DefectSubjectForm,
  defectGroupId: number,
  defectSubjectId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<DefectSubject>(
    `/entities/${entityId}/defect-groups/${defectGroupId}/defect-subjects/${defectSubjectId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(defectSubjectForm),
    }
  );
};

export const deleteDefectSubject = async (
  entityId: string,
  defectSubjectForm: DefectSubjectForm,
  defectGroupId: number,
  defectSubjectId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<DefectSubject>(
    `/entities/${entityId}/defect-groups/${defectGroupId}/defect-subjects/${defectSubjectId}`,
    serviceConfig,
    {
      method: "DELETE",
      body: JSON.stringify(defectSubjectForm),
    }
  );
};

export const createDefectDescription = async (
  entityId: string,
  defectDescriptionForm: DefectDescriptionForm,
  defectGroupId: number,
  defectSubjectId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<DefectDescription>(
    `/entities/${entityId}/defect-groups/${defectGroupId}/defect-subjects/${defectSubjectId}/defect-descriptions`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(defectDescriptionForm),
    }
  );
};

export const updateDefectDescription = async (
  entityId: string,
  defectDescriptionForm: DefectDescriptionForm,
  defectGroupId: number,
  defectSubjectId: number,
  defectDescriptionId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<DefectDescription>(
    `/entities/${entityId}/defect-groups/${defectGroupId}/defect-subjects/${defectSubjectId}/defect-descriptions/${defectDescriptionId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(defectDescriptionForm),
    }
  );
};

export const deleteDefectDescription = async (
  entityId: string,
  defectDescriptionForm: DefectDescriptionForm,
  defectGroupId: number,
  defectSubjectId: number,
  defectDescriptionId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<DefectDescription>(
    `/entities/${entityId}/defect-groups/${defectGroupId}/defect-subjects/${defectSubjectId}/defect-descriptions/${defectDescriptionId}`,
    serviceConfig,
    {
      method: "DELETE",
      body: JSON.stringify(defectDescriptionForm),
    }
  );
};

export const createDictionary = async (
  entityId: string,
  dictionary: Enum,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Enum>(`/entities/${entityId}/enums`, serviceConfig, {
    method: "POST",
    body: JSON.stringify(omit(dictionary, "id")),
  });
};

export const updateDictionary = async (
  entityId: string,
  enumId: number,
  dictionary: Enum,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Enum>(
    `/entities/${entityId}/enums/${enumId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(omit(dictionary, "id", "type")),
    }
  );
};

export const deleteDictionary = async (
  entityId: string,
  enumId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Enum>(
    `/entities/${entityId}/enums/${enumId}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};

export const updateDescriptionSubcontractor = async (
  entityId: string,
  defectGroupId: number,
  defectSubjectId: number,
  defectDescriptionId: number,
  subcontractorId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(
    `/entities/${entityId}/defect-groups/${defectGroupId}
    /defect-subjects/${defectSubjectId}/defect-descriptions/${defectDescriptionId}
    /subcontractors/${subcontractorId}`,
    serviceConfig,
    {
      method: "PUT",
    }
  );
};

export const deleteDescriptionSubcontractor = async (
  entityId: string,
  defectGroupId: number,
  defectSubjectId: number,
  defectDescriptionId: number,
  subcontractorId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<any>(
    `/entities/${entityId}/defect-groups/${defectGroupId}
    /defect-subjects/${defectSubjectId}/defect-descriptions/${defectDescriptionId}
    /subcontractors/${subcontractorId}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};
