import React, { useEffect, useState } from "react";

import { Defect } from "../../../../services/defect";

import DefectSummaryByResponsiblePartyLayout from "./DefectSummaryByResponsiblePartyLayout";

interface DefectSummaryByResponsiblePartyContainerProps {
  defects: Defect[];
}

let initialData: {
  party: string;
  Total: number;
}[] = [];

const DefectSummaryByResponsiblePartyContainer: React.FC<DefectSummaryByResponsiblePartyContainerProps> =
  ({ defects }) => {
    const [data, setData] = useState(initialData);

    useEffect(() => {
      initialData = [];

      const responsibleParties = new Set(
        defects.map((defect) => defect.responsibleParty?.id)
      );

      responsibleParties.forEach((value) => {
        initialData.push({
          party: `${
            defects.find((defect) => defect.responsibleParty?.id === value)
              ?.responsibleParty?.name
          }`,
          Total: defects.filter(
            (defect) =>
              defect.responsibleParty?.id === value &&
              defect.status !== "cancelled"
          ).length,
        });
      });

      setData([...initialData]);
    }, [defects]);

    return <DefectSummaryByResponsiblePartyLayout data={data} />;
  };

export default DefectSummaryByResponsiblePartyContainer;
