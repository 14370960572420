import React, { useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import "./leaflet-custom.css";
import { FloorPlan, FloorPlanArea } from "../../services/location";
import { Location } from "../../services/app";
import ReactDOMServer from "react-dom/server";
import _ from "lodash";
import { ReactComponent as ReactLogo } from "../../images/vector.svg";
import { AppTheme } from "../App/appTheme";
import { HeatMapCount } from "../../pages/LocationDetails/LocationHeatmap/LocationHeatmap";
import { Col, Row } from "antd";

import { useTranslation } from "react-i18next";

const L = (window as any)["L"];

interface AppFloorPlanProps {
  floorPlan: FloorPlan;
  locationChildNode: Location[];
  handleOnFloorPlanChange: (area: any) => void;
  editFloorPlan: boolean;
  onAreaClick?: (area: FloorPlanArea) => void;
  type: "location" | "defect" | "heatMap";
  heatMapCount?: HeatMapCount[];
}
const useStyles = createUseStyles<
  "map",
  {
    floorPlan: FloorPlan;
  },
  AppTheme
>((theme) => ({
  map: {
    "& .new_pin_marker": {
      filter: "hue-rotate(270deg)",
    },
    height: (props) => _.get(props, "floorPlan.container.height", "70vh"),
    width: "100%",
    minWidth: 320,
    minHeight: 320,
    margin: theme.spacing(1, 0),
    position: "relative",
    "& .leaflet-top, .leaflet-bottom": {
      zIndex: 500,
    },
    "& .leaflet-popup-content": {
      title: theme.palette.text,
      fontSize: theme.pxToRem(12),
      padding: theme.spacing(1),
    },
    "& .leaflet-tooltip": {
      border: `1px solid ${theme.palette.primary}`,
      background: theme.palette.primary,
      borderRadius: theme.pxToRem(5),
      color: theme.palette.background,
      fontSize: theme.pxToRem(16),
      fontWeight: theme.fontWeight.bolder,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .heatmap-remarks": {
      color: theme.palette.text,
      fontWeight: theme.fontWeight.bolder,
      padding: theme.spacing(1),
      fontSize: theme.pxToRem(14),
    },
    overflow: "hidden",
  },
}));

const AppFloorPlan: React.FC<AppFloorPlanProps> = ({
  floorPlan,
  onAreaClick = () => {},
  locationChildNode,
  handleOnFloorPlanChange,
  type,
  heatMapCount,
}) => {
  const classes = useStyles({ floorPlan });
  const mapContainerRef = useRef(null);

  const { t: translation } = useTranslation();

  const handleLayerAdd = (floorPlan: FloorPlan, layer: any) => {
    const polygon = [];
    for (let i = 0; i < layer._latlngs[0].length; i++) {
      const latlng = layer._latlngs[0][i];
      polygon.push([
        latlng.lng / floorPlan.width,
        -latlng.lat / floorPlan.height,
      ]);
    }
    return polygon;
  };

  const popupContentHtml = (layer: any, child: Location[]) =>
    ReactDOMServer.renderToString(
      <div
        className={`polygon-${layer._leaflet_id}`}
        id={`polygon-${layer._leaflet_id}`}
      >
        <div className="title" style={{ padding: "4px 0px" }}>
          {translation("appFloorPlan.locationArea")}
        </div>
        <select
          id={`location-child-opt-${layer._leaflet_id}`}
          style={{
            padding: "4px 0px",
            display: "block",
            minWidth: "100%",
          }}
        >
          {child.map((location: any, index: number) => (
            <option value={location?.id} key={index}>
              {location.name}
            </option>
          ))}
        </select>

        <div
          className={`polygon-action-${layer._leaflet_id}`}
          style={{ display: "flex", justifyContent: "space-between" }}
        ></div>
      </div>
    );

  //drawing : https://jsfiddle.net/8przLuwa/

  useEffect(() => {
    const polygonList: any[] = [];
    const container = L.DomUtil.get(mapContainerRef.current);
    if (container != null) {
      container._leaflet_id = null;
    }
    if (container) {
      const map = L.map(mapContainerRef.current, {
        crs: L.CRS.Simple,
        minZoom: -5,
      }).setZoom(0);
      map.attributionControl.addAttribution(
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      );
      const bounds = [
        [0, 0],
        [-floorPlan.height, floorPlan.width],
      ];
      L.imageOverlay(floorPlan.file.url, bounds, {
        className: "floor-plan",
      }).addTo(map);
      map.fitBounds(bounds);

      const newAreaGroup = new L.FeatureGroup({ crs: L.CRS.Simple });
      map.addLayer(newAreaGroup);
      let marker: any;
      const svgIcon = L.divIcon({
        html: ReactDOMServer.renderToStaticMarkup(<ReactLogo />),
        className: "new_pin_marker",
        iconSize: [24, 40],
        iconAnchor: [12, 40],
      });
      if (type === "defect") {
        map.on("click", function (e: any) {
          if (marker) {
            map.removeLayer(marker);
          }
          marker = new L.Marker(e.latlng, { icon: svgIcon })
            .on("add", (e: any) => {
              const getPolygon = handleLayerAdd(floorPlan, {
                ...e.target,
                _latlngs: [[e.target._latlng]],
              });
              polygonList.push({
                polygon: getPolygon,
                id: e.target._leaflet_id,
              });
              handleOnFloorPlanChange(polygonList);
            })
            .on("remove", (e: any) => {
              _.remove(
                polygonList,
                (item) => item.id === e.target?._leaflet_id
              );
            })
            .addTo(map);
        });
      }

      var drawControl = new L.Control.Draw({
        position: "topleft",
        draw: {
          polygon: false,
          rectangle:
            locationChildNode?.length > 0
              ? {
                  drawError: {
                    color: "#b00b00",
                    timeout: 1000,
                  },
                  shapeOptions: {
                    color: "#bada55",
                  },
                }
              : false,
          polyline: false,
          circle: false,
          circlemarker: false,
          marker: false,
        },
        edit: {
          featureGroup: newAreaGroup,
          remove: true,
        },
      });
      if (
        locationChildNode &&
        locationChildNode.length > 0 &&
        type !== "heatMap"
      )
        map.addControl(drawControl);

      map.on(L.Draw.Event.EDITED, function (e: any) {
        var layers = e.layers;

        layers.eachLayer((layer: any) => {
          const getPolygon = handleLayerAdd(floorPlan, layer);
          layer.closePopup();

          _.update(
            _.find(polygonList, { id: layer?._leaflet_id }),
            "polygon",
            (n) => getPolygon
          );
        });
        handleOnFloorPlanChange(polygonList);
      });
      map.on(L.Draw.Event.EDITSTART, function (e: any) {
        map.eachLayer(function (layer: any) {
          layer.closePopup();
        });
      });
      map.on("draw:created", function (e: any) {
        const layer = e.layer;
        newAreaGroup.addLayer(layer);
      });
      map.on(L.Draw.Event.CREATED, (e: any) => {
        const layer = e.layer;
        const type = e.layerType;
        if (type === "rectangle") {
          const getPolygon = handleLayerAdd(floorPlan, layer);
          layer
            .bindPopup(
              L.Util.template(popupContentHtml(layer, locationChildNode)),
              {
                className: `leaflet-popup-photo-${e.layer._leaflet_id}`,
              }
            )
            .on("popupopen", function (e: any) {
              const this_select = document.getElementsByClassName(
                `polygon-${layer._leaflet_id}`
              );
              const element = document.getElementById(
                `location-child-opt-${layer._leaflet_id}`
              ) as HTMLInputElement;
              if (element !== null) {
                element.value = _.get(
                  _.find(polygonList, (item) => item.id === layer._leaflet_id),
                  "location",
                  _.head(locationChildNode)?.id
                );
              }
              this_select[0].addEventListener("change", (e: any) => {
                _.update(
                  _.find(polygonList, { id: layer?._leaflet_id }),
                  "location",
                  (n) => Number(e.target.value)
                );

                handleOnFloorPlanChange(polygonList);
              });
            })
            .openPopup();

          polygonList.push({
            polygon: getPolygon,
            id: e.layer._leaflet_id,
            location: Number(
              _.get(
                _.find(polygonList, (item) => item.id === layer._leaflet_id),
                "location",
                _.head(locationChildNode)?.id
              )
            ),
          });
          handleOnFloorPlanChange(polygonList);
        }
      });
      map.on(L.Draw.Event.DELETED, (e: any) => {
        e.layers.eachLayer((layer: any) => {
          _.remove(polygonList, (item) => item.id === layer?._leaflet_id);
        });
        handleOnFloorPlanChange(polygonList);
      });
      map.whenReady(function () {
        if (floorPlan.areas.length > 0) {
          // 	load history
          if (locationChildNode) {
            for (const area of floorPlan.areas) {
              const rectangle = L.rectangle(
                area.polygon.map((vertex) => [
                  -vertex[1] * floorPlan.height,
                  vertex[0] * floorPlan.width,
                ])
              ).on({
                add: (e: any) => {
                  polygonList.push({
                    polygon: area.polygon,
                    id: e?.target._leaflet_id,
                    location: Number(_.get(area, "location.id")),
                  });
                  handleOnFloorPlanChange(polygonList);
                },
                click: (e: any) => {
                  onAreaClick(area);
                },
              });
              const makeHtml =
                type === "heatMap"
                  ? ReactDOMServer.renderToStaticMarkup(
                      <div>
                        <Row justify="center">
                          <Col flex="auto">{_.get(area, "location.name")}</Col>
                        </Row>
                        <Row justify="center">
                          <Col flex="auto">
                            {`
												${
                          _.find(heatMapCount, {
                            key: _.get(area, "location.id"),
                            type: "outstanding",
                          })?.count
                        } / 													${
                              _.find(heatMapCount, {
                                key: _.get(area, "location.id"),
                                type: "total",
                              })?.count
                            }
											`}
                          </Col>
                        </Row>
                      </div>
                    )
                  : _.get(area, "location.name");

              rectangle.bindTooltip(makeHtml, {
                permanent: type === "heatMap",
                direction: "center" as keyof typeof type,
                className: `leaflet-tooltip`,
              });
              newAreaGroup.addLayer(rectangle);
            }
          } else {
            const preSvgIcon = L.divIcon({
              html: ReactDOMServer.renderToStaticMarkup(<ReactLogo />),
              className: "pin_marker",
              iconSize: [24, 40],
              iconAnchor: [12, 40],
            });
            for (const area of floorPlan.areas) {
              const formatting = area.polygon.map((vertex) => [
                -vertex[1] * floorPlan.height,
                vertex[0] * floorPlan.width,
              ]);
              new L.Marker(formatting[0], {
                icon: preSvgIcon,
              }).addTo(map);
            }
          }
        }
      });
      return () => map.remove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    floorPlan,
    handleOnFloorPlanChange,
    locationChildNode,
    onAreaClick,
    type,
  ]);
  return (
    <div className={classes.map} id="leafletContainer" ref={mapContainerRef}>
      {type === "heatMap" && (
        <div className="heatmap-remarks leaflet-bottom leaflet-left">
          {translation("appFloorPlan.remarkNoOfOutstandingDefectNoOfDefect")}
        </div>
      )}
    </div>
  );
};

export default AppFloorPlan;
