export function reviveJsonDateTime(_: unknown, value: unknown): any {
  if (typeof value === "string") {
    const d = convertJsonDateTime(value);
    if (d) {
      return d;
    }
  }
  return value;
}

export function convertJsonDateTime(value: string) {
  if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(value)) {
    return new Date(value);
  }
  return undefined;
}
