import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../../../hooks/useAppContext";
import { useValidate } from "../../../../../hooks/useValidate";
import { createDefectDescription } from "../../../../../services/dictionary";
import notify from "../../../../../utils/notify";

const formSchema = yup.object().shape({
  name: yup.string().nullable().required("Defect Description is required."),
});

interface AddDescriptionModalProps {
  currentNode: { groupId: string; subjectId: string };
  modalVisible: boolean;
  handleAddToList: (item: any, itemType: string) => void;
  handleModalVisible: () => void;
}

interface AddDescriptionGridForm {
  name: string;
}

const AddDescriptionContainer: React.FC<AddDescriptionModalProps> = ({
  currentNode,
  modalVisible,
  handleAddToList,
  handleModalVisible,
}) => {
  const appContext = useAppContext();
  const { serviceConfig, routeParams, errorHandler, refetchDefectGroups } =
    appContext;
  const { entityId } = routeParams;

  const { t: translation } = useTranslation();

  const formItems: NxpFormGridItemProps<AddDescriptionGridForm>[] = [
    {
      controlType: "input",
      label: translation("app.common.defectDescription"),
      itemFieldName: "name",
      span: 12,
    },
  ];

  const [addDescriptionForm, setAddDescriptionForm] =
    useState<AddDescriptionGridForm>({
      name: "",
    });

  const handleSaveValidated = async () => {
    try {
      const response = await createDefectDescription(
        entityId!,
        addDescriptionForm,
        Number(currentNode.groupId),
        Number(currentNode.subjectId),
        serviceConfig
      );
      handleAddToList(
        {
          key: `Defect Group-${currentNode.groupId}-Defect Subject-${currentNode.subjectId}-Defect Description-${response.id}-`,
          title: response.name,
        },
        "description"
      );
      handleModalVisible();
      notify.actionCompleted();
      refetchDefectGroups(entityId, serviceConfig);
    } catch (ex: any) {
      errorHandler(
        ex,
        translation(
          "app.common.subjectForm.addDescription.handleSaveValidated.error"
        )
      );
    }
  };

  const [validationError, , , saveWithValidate] =
    useValidate<AddDescriptionGridForm>(
      addDescriptionForm,
      formSchema,
      handleSaveValidated
    );

  const handleSaveClick = () => {
    saveWithValidate(undefined);
  };

  const handleFormGridStateChange = (
    fieldName: keyof AddDescriptionGridForm,
    value: unknown
  ) => {
    setAddDescriptionForm((prevState) => ({
      ...prevState,
      [fieldName]: value as string,
    }));
  };

  useEffect(() => {
    if (modalVisible) {
      setAddDescriptionForm({ name: "" });
    }
  }, [modalVisible]);

  return (
    <NxpModal
      title={translation(
        "app.common.subjectForm.addDescription.addDefectDescription"
      )}
      visible={modalVisible}
      onCancel={handleModalVisible}
      footer={<NxpButton onClick={handleSaveClick}>Save</NxpButton>}
      width="small"
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={addDescriptionForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddDescriptionContainer;
