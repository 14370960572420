import { NxpAppTheme } from "@nexploretechnology/nxp-ui";

declare global {
  namespace Jss {
    export interface Theme extends AppTheme {}
  }
}

export interface AppTheme extends NxpAppTheme {}

export const appTheme: AppTheme = require("../../theme.config");
