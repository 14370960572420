import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useReactToPrint } from "react-to-print";

import { NxpButton } from "@nexploretechnology/nxp-ui";
import { Col, Modal, Row } from "antd";
import clsx from "clsx";
import moment from "moment";

import useAppContext from "../../hooks/useAppContext";
import { SubcontractorStatistic } from "../../pages/SubcontractorView/SubcontractorReducer";
import { Subcontractor } from "../../services/app";
import { Defect, getDefectsFullSet } from "../../services/defect";
import themeConfig from "../../theme.config";
import notify from "../../utils/notify";
import DefectView from "./DefectView";

interface Props {
  execute: boolean;
  queryString: string;
  sortBy: string;
  order: "asc" | "desc";
  onClose: () => void;
  reportTitle: string;
  showAttachmentPhoto: boolean;
  subcontractorStatistic?: SubcontractorStatistic[];
  subcontractor?: Subcontractor;
  tableItem: {
    label: string;
    getVal: string[];
    key?: keyof Defect;
    width: string;
  }[];
}

const useStyles = createUseStyles((theme) => ({
  card: {
    marginBottom: 10,
    "& .ant-card-body": { padding: "10px 24px" },
  },
  content: {
    height: 400,
    paddingTop: 180,
    textAlign: "center",
  },
  message: {
    fontSize: theme.fontSize.h1,
    margin: 10,
  },
  printable: {
    "@media screen": {
      display: "none",
    },
  },
  statistics: {
    display: "flex",
    padding: "20px 0px",
  },
  subConCard: {
    textAlign: "center",
    marginRight: "20px",
    margin: theme.spacing(1, 3, 1, 0),
    minWidth: "200px",
    "& p": {
      fontSize: theme.pxToRem(12),
      fontWeight: 700,
      color: "#8A9999",
      textTransform: "uppercase",
    },
  },
  statisticCol: {
    background: theme.palette.background,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    textAlign: "center",
    border: "1px solid black",
    padding: theme.spacing(1),
  },
  fakeHeader: {
    borderCollapse: "collapse",
    // border: "1px solid black",
    width: "100%",
    tableLayout: "fixed",
    background: "#dddddd",
    "& td": {
      borderTop: "1px solid black",
      borderRight: "1px solid black",
      borderLeft: "1px solid black",
      textAlign: "center",
      fontSize: "10px",
      overflowX: "hidden",
      fontWeight: "bold",
      background: "#dddddd",
    },
  },
}));

const AppDefectSummaryReportGenerationModal: React.FC<Props> = ({
  execute,
  queryString,
  sortBy,
  order,
  onClose,
  showAttachmentPhoto,
  tableItem,
  reportTitle,
  subcontractor,
  subcontractorStatistic,
}) => {
  const { t: translation } = useTranslation();
  const classes = useStyles();
  const { routeParams, serviceConfig, activeEntity } = useAppContext();
  const { entityId, locationGroupId, subcontractorId } = routeParams;

  const [defects, setDefects] = useState<Defect[] | null>(null);
  const [renderingPrintable, setRenderingPrintable] = useState<boolean>(false);

  const printDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!execute) return;
    if (!entityId) return;
    if (!locationGroupId) return;
    setDefects(null);
    getDefectsFullSet(
      entityId,
      locationGroupId,
      queryString,
      sortBy,
      order,
      serviceConfig
    )
      .then((rows) => setDefects(rows))
      .catch((e) => notify.error(e));
  }, [
    entityId,
    subcontractorId,
    locationGroupId,
    serviceConfig,
    execute,
    queryString,
    sortBy,
    order,
  ]);

  const handlePrint = useReactToPrint({
    bodyClass: "floor-plan-location-code",
    documentTitle: `${reportTitle} - ${moment().format("DD-MMM-YYYY")}`,
    content: () => printDivRef.current,
    onBeforeGetContent: () => {
      setRenderingPrintable(true);
    },
    copyStyles: true,
    onAfterPrint: () => {
      setRenderingPrintable(false);
    },
    onPrintError: (error) => notify.warning(`print ${error} !`),
    pageStyle: `
      @media print {
          @page { size: landscape !important; 
            margin-left: 0.5in;
       margin-right: 0.5in;
       margin-bottom: 0; }
       td{
        padding: 0 !important;
        }
      }
    `,
  });

  const handleClose = () => {
    if (renderingPrintable) return;
    onClose();
  };

  return (
    <Modal
      visible={execute}
      cancelButtonProps={{ style: { display: "none" } }}
      onOk={handleClose}
      onCancel={handleClose}
      okText="Done"
    >
      <Row>
        <Col flex={"auto"}>
          <div className={classes.content}>
            <div className={classes.message}>
              {defects
                ? translation(
                    "appDefectSummaryReportGenerationModel.yourReportIsReady"
                  )
                : translation(
                    "appDefectSummaryReportGenerationModel.yourReportIsBeingPrepared"
                  )}
            </div>
            {!!defects && (
              <NxpButton onClick={handlePrint} loading={renderingPrintable}>
                {translation("app.common.print")}
              </NxpButton>
            )}
          </div>
        </Col>
      </Row>

      <div ref={printDivRef} className={clsx(classes.printable, "printable")}>
        <h1>{reportTitle}</h1>
        <h2>{activeEntity?.name}</h2>
        {subcontractor && <h2>{subcontractor?.name}</h2>}
        <h2>
          {translation("app.common.reportDate")}:
          {moment().format("DD-MMM-YYYY")}
        </h2>

        {subcontractorStatistic && (
          <div className={classes.statistics}>
            <Row
              className={classes.fakeHeader}
              style={{
                background: themeConfig.palette.background,
                border: "none",
              }}
            >
              {subcontractorStatistic
                .filter((subcontractorStatistic) =>
                  routeParams?.reportType === "normal"
                    ? (subcontractorStatistic.title ===
                        translation("app.common.fixed") &&
                        subcontractorStatistic.get === "count") ||
                      (subcontractorStatistic.title ===
                        translation("app.common.outstanding") &&
                        subcontractorStatistic.get === "count") ||
                      (subcontractorStatistic.title ===
                        translation("app.common.overdue") &&
                        subcontractorStatistic.get === "count") ||
                      subcontractorStatistic.title ===
                        translation("app.common.averageDays") ||
                      subcontractorStatistic.title ===
                        translation("app.common.relatedDefects")
                    : subcontractorStatistic.title ===
                        translation("app.common.fixed") ||
                      subcontractorStatistic.title ===
                        translation("app.common.outstanding") ||
                      subcontractorStatistic.title ===
                        translation("app.common.overdue") ||
                      subcontractorStatistic.title ===
                        translation("app.common.averageDays") ||
                      subcontractorStatistic.title ===
                        translation("app.common.relatedDefects") ||
                      subcontractorStatistic.title ===
                        translation("app.common.fixedOverdue") ||
                      subcontractorStatistic.title ===
                        translation("app.common.notYetFixed") ||
                      subcontractorStatistic.title ===
                        translation("app.common.totalDefects")
                )
                .map((subcontractorStatistic, index) => (
                  <Col flex="auto" className={classes.statisticCol}>
                    <p>{`${
                      subcontractorStatistic.get === "totalCost" ? "$" : ""
                    } ${subcontractorStatistic.value}`}</p>
                    <p>{subcontractorStatistic.title}</p>
                  </Col>
                ))}
            </Row>
          </div>
        )}

        <table className={clsx(classes.fakeHeader)}>
          <thead>
            <tr>
              {tableItem.map((item, index) => (
                <td width={item?.width} key={`tabel-${index}`}>
                  {item?.label}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                colSpan={tableItem.length}
                style={{
                  borderLeft: "none",
                  borderRight: "none",
                  padding: 0,
                }}
              >
                {defects &&
                  defects.map((defect, index) => (
                    <DefectView
                      tableItem={tableItem}
                      key={index}
                      defect={{
                        ...defect,
                        round: defect.messages.filter(
                          (message) => message.type !== "response"
                        ).length,
                      }}
                      showAttachmentPhoto={showAttachmentPhoto}
                    />
                  ))}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default AppDefectSummaryReportGenerationModal;
