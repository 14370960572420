import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { NxpFormItem } from "@nexploretechnology/nxp-ui";
import { ColumnProps } from "antd/lib/table";
import * as yup from "yup";

import useAppContext from "../../../hooks/useAppContext";
import { useValidate, ValidationResult } from "../../../hooks/useValidate";
import { Enum } from "../../../services/app";
import {
  deleteDictionary,
  updateDictionary,
} from "../../../services/dictionary";
import notify from "../../../utils/notify";
import InspectionTypeLayout from "./InspectionTypeLayout";

interface InspectionTypeContainerProps {}

const InspectionTypeContainer: React.FC<InspectionTypeContainerProps> = () => {
  const appContext = useAppContext();
  const {
    inspectionTypes,
    serviceConfig,
    routeParams,
    errorHandler,
    hasRight,
    refetchEnums,
  } = appContext;
  const { entityId } = routeParams;

  const { t: translation } = useTranslation();

  const formSchema = yup.object().shape({
    name: yup
      .string()
      .nullable()
      .required(translation("app.common.inspectionTypeIsRequired")),
  });

  const getColumns = (
    editRecord: Enum | undefined,
    setEditRecord: (callback: SetStateAction<Enum | undefined>) => void,
    validationError: ValidationResult<Enum>
  ): ColumnProps<Enum>[] => [
    {
      title: translation("app.common.inspectionType"),
      dataIndex: "name",
      fixed: "left",
      render: (_: unknown, record: Enum) => {
        const editing = editRecord?.id === record.id;
        return (
          <NxpFormItem
            editing={editing}
            controlType="input"
            error={validationError.name}
            controlProps={{
              value: editing ? editRecord?.name : record.name,
              onChange: (e) =>
                setEditRecord(
                  (prevState: any) =>
                    prevState && { ...prevState, name: e.target.value }
                ),
            }}
          />
        );
      },
    },
  ];

  const [editItem, setEditItem] = useState<Enum>();
  const [inspectionTypeListData, setInspectionTypeListData] = useState<
    Enum[] | undefined
  >(undefined);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleSaveValidated = async () => {
    setSaveInProgress(true);
    try {
      await updateDictionary(
        entityId!,
        editItem?.id || 0,
        {
          name: editItem?.name!,
          id: editItem!.id,
          type: "",
        },
        serviceConfig
      );
      const rec = inspectionTypeListData?.find(
        (data) => data.id === editItem?.id
      );
      Object.assign(rec as {}, { ...editItem });
      setEditItem(undefined);
      notify.actionCompleted();
      refetchEnums(entityId, serviceConfig);
    } catch (ex: any) {
      errorHandler(
        ex,
        translation("settings.inspectionType.handleSaveValidated.error")
      );
    } finally {
      setSaveInProgress(false);
    }
  };

  const [validationError, , clearError, saveWithValidate] = useValidate<
    Pick<Enum, "name">
  >(editItem, formSchema, handleSaveValidated);

  const columns = useMemo(
    () => getColumns(editItem, setEditItem, validationError),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editItem, validationError]
  );

  const handleAddToList = useCallback(
    (item: Enum) => {
      refetchEnums(entityId, serviceConfig);
      setInspectionTypeListData([...inspectionTypeListData!, { ...item }]);
    },
    [entityId, inspectionTypeListData, refetchEnums, serviceConfig]
  );

  const handleRowCancel = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      setEditItem(undefined);
      clearError();
    },
    [clearError]
  );

  const handleRowDelete = useCallback(
    async (deleteItem: Enum) => {
      setSaveInProgress(true);
      await deleteDictionary(entityId!, deleteItem?.id || 0, serviceConfig);
      refetchEnums(entityId, serviceConfig);
      setInspectionTypeListData(
        inspectionTypeListData?.filter((data) => data.id !== deleteItem.id)
      );
      notify.actionCompleted();
      setEditItem(undefined);
      setTimeout(() => setSaveInProgress(false)); // need setTimeout only because this demo use synchronous procedure for deleting
    },
    [entityId, inspectionTypeListData, refetchEnums, serviceConfig]
  );

  const handleRowEdit = useCallback(
    (editItem: Enum) => setEditItem({ ...editItem }),
    []
  );

  const handleRowSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  useEffect(() => {
    setInspectionTypeListData(inspectionTypes);
  }, [inspectionTypes, serviceConfig, entityId]);

  return (
    <InspectionTypeLayout
      columns={columns}
      editItem={editItem}
      inspectionTypeListData={inspectionTypeListData}
      saveInProgress={saveInProgress}
      handleAddToList={handleAddToList}
      handleRowCancel={handleRowCancel}
      handleRowDelete={handleRowDelete}
      handleRowEdit={handleRowEdit}
      handleRowSave={handleRowSave}
      hasRight={hasRight}
    />
  );
};

export default React.memo(InspectionTypeContainer);
