import React from "react";
import { createUseStyles } from "react-jss";
import { Card, Table } from "antd";

interface CurrentAreaByDefectGroupLayoutProps {
  header: string;
  columns: any;
  data: any;
}

const useStyles = createUseStyles(() => ({
  tableCard: {
    textAlign: "left",
    width: "500px",
    margin: "10px 10px 0px 0px",
    height: "fit-content",
    "& h6": {
      textTransform: "uppercase",
      fontSize: "13px",
      fontWeight: "bold",
    },
  },
  table: {
    textTransform: "uppercase",
    "& .ant-table-cell": {
      fontSize: "13px",
    },
    "& th.ant-table-cell": {
      background: "#8A9999",
      color: "#FFFFFF",
    },
    "& td.ant-table-cell": {
      textAlign: "center",
      fontWeight: "bold",
    },
  },
}));

const CurrentAreaByDefectGroupLayout: React.FC<CurrentAreaByDefectGroupLayoutProps> =
  ({ header, columns, data }) => {
    const classes = useStyles();
    return (
      <Card className={classes.tableCard}>
        <h6>{header}</h6>
        <Table
          className={classes.table}
          columns={columns}
          dataSource={data}
          bordered
          pagination={false}
        />
      </Card>
    );
  };

export default CurrentAreaByDefectGroupLayout;
