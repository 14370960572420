import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { Col, Drawer, Row } from "antd";
import clsx from "clsx";

import AppFileView from "../../../../components/AppFileView";
// import { createUseStyles } from "react-jss";
import AppUploader from "../../../../components/AppUploader";
import { DefectMessage } from "../../../../services/defect";
import { DefectDetailsContext } from "../../DefectDetailsContainer";

interface Props {
  editable?: boolean;
  message?: DefectMessage;
  onClose: () => void;
}

const useStyles = createUseStyles((theme) => ({
  root: {
    top: 85,
    height: "calc(100vh - 85px)",
    overflow: "auto",
    position: "fixed",
    boxSizing: "border-box",
    transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderColor: theme.palette.borderLight,
    "& .ant-drawer-body": {
      padding: 0,
    },
  },
  cell: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

const AttachmentDrawer: React.FC<Props> = ({ editable, message, onClose }) => {
  const classes = useStyles();

  const { onMessageAttachmentAdd, onMessageAttachmentRemove, onProgessing } =
    useContext(DefectDetailsContext);

  const { t: translation } = useTranslation();

  return (
    <Drawer
      placement="right"
      title={translation("app.common.inspection.attachmentDrawer.uploaded")}
      closable={true}
      mask={false}
      visible={!!message}
      className={clsx(classes.root, "attachment-drawer")}
      onClose={onClose}
      width={270}
    >
      {editable && (
        <Row>
          <Col span={24} className={classes.cell}>
            <AppUploader
              onProgessing={onProgessing}
              onFileUploaded={(file) => {
                if (message) {
                  onMessageAttachmentAdd(message, file);
                }
              }}
            />
          </Col>
        </Row>
      )}
      {message?.attachments
        ?.sort((a, b) => b.id - a.id)
        .map((file) => (
          <Row key={`File_${file.id}`}>
            <Col span={24} className={classes.cell}>
              <AppFileView
                file={file}
                onDelete={() => {
                  onMessageAttachmentRemove(message, file);
                }}
              />
            </Col>
          </Row>
        ))}
    </Drawer>
  );
};

export default AttachmentDrawer;
