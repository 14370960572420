import React from "react";
import { createUseStyles } from "react-jss";

import _ from "lodash";
import moment from "moment";

import useAppContext from "../../hooks/useAppContext";
import noFloorPlan from "../../images/report-no-floor-plan.png";
import pinIcon from "../../images/vector.svg";
import { Location } from "../../services/app";
import { Defect } from "../../services/defect";

interface Props {
  defect: Defect;
  showAttachmentPhoto: boolean;
  tableItem: {
    label: string;
    getVal: string[];
    key?: keyof Defect;
    width: string;
  }[];
}

const useStyles = createUseStyles((theme) => ({
  table: {
    pageBreakInside: "avoid",
    borderCollapse: "collapse",
    border: "1px solid black",
    width: "100%",
    tableLayout: "fixed",
    "& td": {
      border: "1px solid black",
      textAlign: "center",
      fontSize: "10px",
      overflowX: "hidden",
    },
  },
  floorPlanDiv: {
    position: "relative",
    maxWidth: 150,
    maxHeight: 150,
    marginTop: 30,
    marginBottom: 10,
    marginLeft: "auto",
    marginRight: "auto",
  },
  normalImage: { maxWidth: 150, maxHeight: 150, padding: theme.spacing(1) },
  pinImage: {
    position: "absolute",
    transform: "translate(-50%, -100%)",
  },
}));
const DefectView: React.FC<Props> = ({
  defect,
  showAttachmentPhoto,
  tableItem,
}) => {
  const classes = useStyles();
  const { getLocationFullPath } = useAppContext();
  return (
    <table className={classes.table}>
      <tbody>
        <>
          <tr className={classes.table}>
            {tableItem.map((item, index) => (
              <td key={`report-${index}`} width={item?.width}>
                {item.key !== "raiseDate" &&
                  item.key !== "dueDate" &&
                  item.key !== "location" &&
                  item?.getVal.map((key) => (
                    <div>{_.get(defect, key, false)}</div>
                  ))}
                {item.key === "raiseDate" &&
                  item?.getVal.map((key) => (
                    <div>
                      {moment(
                        _.get(defect, key, false) as unknown as Date,
                        "YYYY-MM-DD"
                      ).format("DD-MMM-YYYY")}
                    </div>
                  ))}
                {item.key === "dueDate" &&
                  item?.getVal.map((key) => (
                    <div>
                      {moment(
                        _.get(defect, key, false) as unknown as Date,
                        "YYYY-MM-DD"
                      ).format("DD-MMM-YYYY")}
                    </div>
                  ))}
                {item.key === "location" &&
                  item?.getVal.map((key) => (
                    <div>
                      {getLocationFullPath(
                        _.get(defect, key, false) as unknown as Location
                      )}
                    </div>
                  ))}
              </td>
            ))}
          </tr>
          <tr>
            {showAttachmentPhoto ? (
              <>
                <td colSpan={3}>
                  <div className={classes.floorPlanDiv}>
                    <img
                      className={classes.normalImage}
                      src={
                        defect.location.floorPlans[0]?.file.thumbnailUrl ||
                        noFloorPlan
                      }
                      alt={"Floor plan"}
                    />
                    {defect.pinX && defect.pinY && (
                      <img
                        className={classes.pinImage}
                        style={{
                          left: `${defect.pinX * 100}%`,
                          top: `${defect.pinY * 100}%`,
                        }}
                        src={pinIcon}
                        alt={"Pin"}
                      />
                    )}
                  </div>
                </td>
                <td colSpan={tableItem.length - 3}>
                  {defect.messages.map((message) =>
                    message.attachments
                      .filter((item) =>
                        _.includes(_.split(item?.type, "/"), "image")
                      )
                      .map((attachment) =>
                        attachment.thumbnailUrl || attachment.url ? (
                          <img
                            className={classes.normalImage}
                            key={`file_${attachment.id}`}
                            src={attachment.thumbnailUrl || attachment.url}
                            alt={message.comment}
                          />
                        ) : null
                      )
                  )}
                </td>
              </>
            ) : (
              <td colSpan={tableItem.length}>
                <div className={classes.floorPlanDiv}>
                  <img
                    className={classes.normalImage}
                    src={
                      defect.location.floorPlans[0]?.file.thumbnailUrl ||
                      noFloorPlan
                    }
                    alt={"Floor plan"}
                  />
                  {defect.pinX && defect.pinY && (
                    <img
                      className={classes.pinImage}
                      style={{
                        left: `${defect.pinX * 100}%`,
                        top: `${defect.pinY * 100}%`,
                      }}
                      src={pinIcon}
                      alt={"Pin"}
                    />
                  )}
                </div>
              </td>
            )}
          </tr>
        </>
      </tbody>
    </table>
  );
};

export default DefectView;
