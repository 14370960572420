import React from "react";

import { NxpFormGridItemProps } from "@nexploretechnology/nxp-ui";
import { NxpFormSurvey } from "@nexploretechnology/nxp-ui";
import { NxpHeader } from "@nexploretechnology/nxp-ui";
import { NxpPanelEditable } from "@nexploretechnology/nxp-ui";
import { ValidationResult } from "../../../hooks/useValidate";
import { EntityParameter, UserRightList } from "../../../services/app";

import { useTranslation } from "react-i18next";

interface ParametersLayoutProps {
  parametersForm: EntityParameter | undefined;
  validationError: ValidationResult<
    Pick<
      EntityParameter,
      | "dueDate"
      | "projectEntity"
      | "nextInspectionDate"
      | "reinspectionDate"
      | "dueDateReminderForSubcontractor"
      | "dueDateReminderForInspector"
      | "dueDatePriority"
      | "nextInspectionDatePriority"
      | "reinspectionDatePriority"
    >
  >;
  handleFormGridStateChange: (
    fieldName: keyof EntityParameter,
    value: unknown
  ) => void;
  handlePanelSave: (setEditing: (editing: boolean) => void) => void;
  hasRight: (
    rightType: keyof UserRightList,
    source?: "module" | "locationGroup" | undefined,
    objectId?: number | undefined
  ) => boolean;
}

const ParametersLayout: React.FC<ParametersLayoutProps> = ({
  parametersForm,
  validationError,
  handleFormGridStateChange,
  handlePanelSave,
  hasRight,
}) => {
  const { t: translation } = useTranslation();

  const formItems: NxpFormGridItemProps<EntityParameter>[] = [
    {
      controlType: "inputNumber",
      controlProps: {
        decimalPlace: 0,
      },
      label: translation("app.common.dueDate"),
      itemFieldName: "dueDate",
      span: 12,
    },
    {
      controlType: "input",
      label: translation("settings.parameters.projectEntity"),
      itemFieldName: "projectEntity",
      span: 12,
    },
    {
      controlType: "inputNumber",
      controlProps: {
        decimalPlace: 0,
      },
      label: translation("app.common.nextInspectionDate"),
      itemFieldName: "nextInspectionDate",
      span: 12,
    },
    {
      controlType: "inputNumber",
      controlProps: {
        decimalPlace: 0,
      },
      label: translation("settings.parameters.reinspectionDate"),
      itemFieldName: "reinspectionDate",
      span: 12,
    },
    {
      controlType: "inputNumber",
      controlProps: {
        decimalPlace: 0,
      },
      label: translation("app.common.dueDateReminderForSubcontractor"),
      itemFieldName: "dueDateReminderForSubcontractor",
      span: 12,
    },
    {
      controlType: "inputNumber",
      controlProps: {
        decimalPlace: 0,
      },
      label: translation("app.common.dueDateReminderForInspector"),
      itemFieldName: "dueDateReminderForInspector",
      span: 12,
    },
    {
      controlType: "inputNumber",
      controlProps: {
        decimalPlace: 0,
      },
      label: translation("app.common.dueDatePriority"),
      itemFieldName: "dueDatePriority",
      span: 12,
    },
    {
      controlType: "inputNumber",
      controlProps: {
        decimalPlace: 0,
      },
      label: translation("app.common.nextInspectionDatePriority"),
      itemFieldName: "nextInspectionDatePriority",
      span: 12,
    },
    {
      controlType: "inputNumber",
      controlProps: {
        decimalPlace: 0,
      },
      label: translation("settings.parameters.reinspectionDatePriority"),
      itemFieldName: "reinspectionDatePriority",
      span: 12,
    },
  ];

  return (
    <>
      <NxpHeader titleContent={translation("app.common.parameters")} />
      <NxpPanelEditable
        titleContent={translation("app.common.settings")}
        onPanelSave={handlePanelSave}
        editable={hasRight("setup-edit")}
        disableCollapse
      >
        {(editing, updateEditing) => (
          <NxpFormSurvey
            editing={editing}
            validationError={validationError}
            formItems={formItems.map((item) => ({
              ...item,
              span: item.span > 8 ? 2 : 1,
            }))}
            formState={parametersForm}
            onFormStateChange={handleFormGridStateChange}
          />
        )}
      </NxpPanelEditable>
    </>
  );
};

export default ParametersLayout;
