import clsx from "clsx";
import React, { useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import AppFloorPlan from "../../../components/AppFloorPlan";
import { DefectDetailsContext } from "../DefectDetailsContainer";
import useAppContext from "../../../hooks/useAppContext";
import { NxpButton } from "@nexploretechnology/nxp-ui";
import notify from "../../../utils/notify";
import { NxpHeader } from "@nexploretechnology/nxp-ui";
import { updateFloorPlanPin } from "../../../services/defect";
import { get, head } from "lodash";
import { SaveOutlined } from "@ant-design/icons";
import AppEmptyFloorPlan from "../../../components/AppEmptyFloorPlan";

import { useTranslation } from "react-i18next";

interface DefectFloorPlanProps {
  // onActiveKeyChange: (activeKey: string) => void;
}
const useStyles = createUseStyles((theme) => ({
  root: {},
}));
const DefectFloorPlan: React.FC<DefectFloorPlanProps> = () => {
  const classes = useStyles();

  const defectContext = useContext(DefectDetailsContext);
  const { defect, onFetchingDefect } = useContext(DefectDetailsContext);

  const appContext = useAppContext();
  const { serviceConfig, routeParams, hasRight } = appContext;
  const { entityId, locationGroupId } = routeParams;

  const { t: translation } = useTranslation();
  const [disbleSave, setDisbleSave] = useState<boolean>(true);
  let floorPlanArea: any[] = [];
  const handleSaveFloorPlan = async (): Promise<boolean> => {
    const floorPlan = {
      pinX: get(head(floorPlanArea), "polygon[0][0]"),
      pinY: get(head(floorPlanArea), "polygon[0][1]"),
    };
    try {
      let creatFloorPlanRes = await updateFloorPlanPin(
        entityId,
        locationGroupId,
        defectContext?.defect.id,
        floorPlan,
        serviceConfig
      );
      if (creatFloorPlanRes)
        notify.success(translation("app.common.floorPlan.success"));
      onFetchingDefect();
      return true;
    } catch (error) {
      notify.error(error);
      return false;
    }
  };
  const handleOnFloorPlanChange = (area: any) => {
    // setArea(area);
    floorPlanArea = [...area];
    if (floorPlanArea.length) setDisbleSave(false);
    else setDisbleSave(true);
  };
  return (
    <div className={clsx("Defect-floor-plan", classes.root)}>
      <NxpHeader
        titleContent={""}
        actionContent={
          hasRight("defect-edit") && (
            <NxpButton
              disabled={disbleSave}
              type="primary"
              icon={<SaveOutlined />}
              onClick={handleSaveFloorPlan}
            >
              {translation("app.common.save")}
            </NxpButton>
          )
        }
      />
      {defect.location?.floorPlans.length ? (
        <AppFloorPlan
          type="defect"
          editFloorPlan={true}
          handleOnFloorPlanChange={handleOnFloorPlanChange}
          locationChildNode={defect.location?.children}
          floorPlan={{
            ...defect.location?.floorPlans[0],
            areas:
              defect.pinX && defect.pinY
                ? [{ id: 0, polygon: [[defect.pinX, defect.pinY]] }]
                : [],
          }}
        />
      ) : (
        <AppEmptyFloorPlan />
      )}
    </div>
  );
};

export default DefectFloorPlan;
