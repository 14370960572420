import React from "react";
import { useTranslation } from "react-i18next";

import {
  NxpHeader,
  NxpTable,
  NxpTableEditable,
} from "@nexploretechnology/nxp-ui";

import { DefectGroup, UserRightList } from "../../../services/app";
import AddDefectGroupButton from "./AddDefectGroup/AddDefectGroupButton";

interface DefectGroupLayoutProps {
  columns: any;
  defectGroupListData: DefectGroup[] | undefined;
  editItem: DefectGroup | undefined;
  entityId: string;
  saveInProgress: boolean;
  handleAddToList: (item: DefectGroup) => void;
  handleRowCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleRowDelete: (deleteItem: DefectGroup) => void;
  handleRowEdit: (editItem: DefectGroup) => void;
  handleRowSave: () => void;
  hasRight: (
    rightType: keyof UserRightList,
    source?: "module" | "locationGroup" | undefined,
    objectId?: number | undefined
  ) => boolean;
}

const DefectGroupLayout: React.FC<DefectGroupLayoutProps> = ({
  columns,
  defectGroupListData,
  editItem,
  entityId,
  saveInProgress,
  handleAddToList,
  handleRowCancel,
  handleRowDelete,
  handleRowEdit,
  handleRowSave,
  hasRight,
}) => {
  const { t: translation } = useTranslation();

  return (
    <>
      <NxpHeader
        titleContent={translation("app.common.defectGroup")}
        actionContent={
          hasRight("setup-add") && (
            <AddDefectGroupButton
              handleAddToList={handleAddToList}
              entityId={entityId}
            />
          )
        }
      />
      {hasRight("setup-view") &&
        (hasRight("setup-edit", "module") ? (
          <NxpTableEditable
            columns={columns} //any for fixing antd type error
            dataSource={defectGroupListData}
            editItem={editItem}
            hideDelete={!hasRight("setup-delete")}
            itemCompareKey="id"
            onRowCancel={handleRowCancel}
            onRowDelete={handleRowDelete}
            onRowEdit={handleRowEdit}
            onRowSave={handleRowSave}
            pagination={false}
            saveInProgress={saveInProgress}
          />
        ) : (
          <NxpTable
            dataSource={defectGroupListData}
            rowKey="id"
            columns={columns}
          />
        ))}
    </>
  );
};

export default React.memo(DefectGroupLayout);
