import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { SearchOutlined } from "@ant-design/icons";
import { NxpFormItem } from "@nexploretechnology/nxp-ui";
import { Col, Row, Tree } from "antd";
import { DataNode } from "antd/lib/tree";
import _ from "lodash";

import useAppContext from "../../hooks/useAppContext";
import { Location } from "../../services/app";
import { getLocationDetails } from "../../services/location";
import LocationDashboardTab from "./LocationDashboardTab";
import LocationEmptyTab from "./LocationEmptyTab";
import LocationFileTab from "./LocationFileTab";
import LocationFloorPlan from "./LocationFloorPlan";
import LocationFloorPlanEditing from "./LocationFloorPlanImageEditing";
import LocationHeatmap from "./LocationHeatmap";
import LocationOtherInfo from "./LocationOtherInfo";
import LocationQRCode from "./LocationQRCode";
import LocationTreeDefect from "./LocationTreeDefect";

interface Props {
  onLocationSelect: (selectedKeys: React.Key[], info: any) => void;
  isEditingFloorPlanImage: boolean;
  onCopyFloorPlanFromParent: () => void;
}

const useStyles = createUseStyles((theme) => ({
  root: {
    margin: "-16px -40px",
    width: "calc(100% + 80px);",
  },
  tree: {
    background: "#FFFFFFFF",
    padding: "16px 40px",
    height: "calc(100vh - 85px)",
  },
  content: {
    height: "calc(100vh - 85px)",
    overflowY: "scroll",
    padding: "16px 40px",
    flexGrow: 1,
    "& .floor-plan-action": {
      "& .ant-btn": {
        margin: theme.spacing(0, 1),
      },
      "& .ant-btn-default": {
        color: theme.palette.background,
        background: theme.palette.warning,
        border: "none",
      },
      "& .ant-btn-primary": {
        color: theme.palette.background,
        background: theme.palette.primary,
        border: "none",
      },
    },
  },
}));

const LocationDetailsLayout: React.FC<Props> = (props) => {
  const classes = useStyles();
  const appContext = useAppContext();
  const { activeLocationGroup, routeParams, serviceConfig } = appContext;

  const { t: translation } = useTranslation();

  const { entityId, locationGroupId, locationId } = routeParams;
  const [location, setLocation] = useState<Location | null>(null);
  const [activeKey, setActiveKey] = useState<string>("dashboard");
  const [filter, setFilter] = useState<string>("");

  useEffect(() => {
    if (location?.id === locationId) return;
    getLocationDetails(
      entityId,
      locationGroupId,
      locationId,
      serviceConfig
    ).then((location) => setLocation(location!));
  }, [serviceConfig, entityId, location, locationGroupId, locationId]);

  if (!activeLocationGroup) return null;

  const treeData =
    filter.length > 0
      ? (pruneTree(toTreeNode(activeLocationGroup), filter) as DataNode)
      : toTreeNode(activeLocationGroup);
  return (
    <div className={classes.root}>
      <Row wrap>
        <Col span={6}>
          <div className={classes.tree}>
            <NxpFormItem
              controlType={"input"}
              controlProps={{
                value: filter,
                onChange: (e) => setFilter(e.target.value),
                prefix: <SearchOutlined />,
                placeholder: translation("app.common.search"),
              }}
            />
            <Tree
              defaultSelectedKeys={locationId ? [locationId] : []}
              onSelect={props.onLocationSelect}
              defaultExpandedKeys={["Location List"]}
              defaultExpandAll={true}
              treeData={treeData ? [treeData] : []}
            />
          </div>
        </Col>

        <Col span={18}>
          <div className={classes.content}>
            {location && (
              <>
                {activeKey === "dashboard" && (
                  <LocationDashboardTab onActiveKeyChange={setActiveKey} />
                )}
                {activeKey === "defects" && (
                  <LocationTreeDefect onActiveKeyChange={setActiveKey} />
                )}
                {activeKey === "heat-map" && (
                  <LocationHeatmap onActiveKeyChange={setActiveKey} />
                )}
                {activeKey === "floor-plan" &&
                  !props.isEditingFloorPlanImage && (
                    <LocationFloorPlan onActiveKeyChange={setActiveKey} />
                  )}
                {activeKey === "floor-plan" &&
                  props.isEditingFloorPlanImage && (
                    <LocationFloorPlanEditing
                      onActiveKeyChange={setActiveKey}
                    />
                  )}
                {activeKey === "qr-code" && (
                  <LocationQRCode onActiveKeyChange={setActiveKey} />
                )}
                {activeKey === "dates-and-other-info" && (
                  <LocationOtherInfo onActiveKeyChange={setActiveKey} />
                )}
                {activeKey === "other-files" && (
                  <LocationFileTab onActiveKeyChange={setActiveKey} />
                )}
                {activeKey === "planning" && (
                  <LocationEmptyTab
                    activeKey={activeKey}
                    onActiveKeyChange={setActiveKey}
                  />
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

const toTreeNode = (location: Location): DataNode => {
  if (!location.children || location.children.length === 0) {
    return { key: location.id, title: location.name, isLeaf: true };
  } else {
    return {
      key: location.id,
      title: location.name,
      isLeaf: false,
      children: location.children.map((child) => toTreeNode(child)),
    };
  }
};

const pruneTree = (node: DataNode, filter: string): DataNode | null => {
  if (node.isLeaf) {
    if (_.lowerCase(node.title as string).indexOf(_.lowerCase(filter)) !== -1) {
      return node;
    } else {
      return null;
    }
  } else {
    let children: DataNode[] = [] as DataNode[];
    if (node.children) {
      children = node.children
        .map((child) => pruneTree(child, filter))
        .filter((child) => child) as DataNode[];
    }
    if (
      _.lowerCase(node.title as string).indexOf(_.lowerCase(filter)) === -1 &&
      children.length === 0
    ) {
      return null;
    }

    return {
      ...node,
      children,
    };
  }
};

export default LocationDetailsLayout;
