import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../../hooks/useAppContext";
import { useValidate } from "../../../../hooks/useValidate";
import { createDictionary } from "../../../../services/dictionary";
import notify from "../../../../utils/notify";

interface AddDefectTypeModalProps {
  modalVisible: boolean;
  handleAddToList: (item: any) => void;
  handleModalVisible: () => void;
}

interface AddDefectTypeGridForm {
  name: string;
}

const AddDefectTypeContainer: React.FC<AddDefectTypeModalProps> = ({
  modalVisible,
  handleAddToList,
  handleModalVisible,
}) => {
  const appContext = useAppContext();
  const { serviceConfig, routeParams, errorHandler, refetchEnums } = appContext;
  const { entityId } = routeParams;

  const { t: translation } = useTranslation();

  const formItems: NxpFormGridItemProps<AddDefectTypeGridForm>[] = [
    {
      controlType: "input",
      label: translation("app.common.defectType"),
      itemFieldName: "name",
      span: 12,
    },
  ];

  const formSchema = yup.object().shape({
    name: yup
      .string()
      .nullable()
      .required(translation("app.common.defectTypeIsRequired")),
  });

  const [addDefectTypeForm, setAddDefectTypeForm] =
    useState<AddDefectTypeGridForm>({
      name: "",
    });

  const handleSaveValidated = async () => {
    try {
      const response = await createDictionary(
        entityId!,
        {
          id: 0,
          type: "defectType",
          name: addDefectTypeForm.name,
        },
        serviceConfig
      );
      handleAddToList({
        id: response.id,
        type: response.type,
        name: response.name,
      });
      handleModalVisible();
      notify.actionCompleted();
      refetchEnums(entityId, serviceConfig);
    } catch (ex: any) {
      errorHandler(
        ex,
        translation(
          "settings.defectType.addDefectType.handleSaveValidated.error"
        )
      );
    }
  };

  const [validationError, , , saveWithValidate] =
    useValidate<AddDefectTypeGridForm>(
      addDefectTypeForm,
      formSchema,
      handleSaveValidated
    );

  const handleSaveClick = () => {
    saveWithValidate(undefined);
  };

  const handleFormGridStateChange = (
    fieldName: keyof AddDefectTypeGridForm,
    value: unknown
  ) => {
    setAddDefectTypeForm((prevState) => ({
      ...prevState,
      [fieldName]: value as string,
    }));
  };

  useEffect(() => {
    if (modalVisible) {
      setAddDefectTypeForm({ name: "" });
    }
  }, [modalVisible]);

  return (
    <NxpModal
      title={translation("settings.defectType.addDefectType.addDefectType")}
      visible={modalVisible}
      onCancel={handleModalVisible}
      footer={
        <NxpButton onClick={handleSaveClick}>
          {translation("app.common.save")}
        </NxpButton>
      }
      width="small"
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={addDefectTypeForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddDefectTypeContainer;
