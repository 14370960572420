import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { FileAddOutlined } from "@ant-design/icons";
import { NxpHeader } from "@nexploretechnology/nxp-ui";
import Dragger from "antd/lib/upload/Dragger";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import clsx from "clsx";

import AppFakeTableHeader from "../../../components/AppFakeTableHeader";
import useAppContext from "../../../hooks/useAppContext";
import { File } from "../../../services/app";
import notify from "../../../utils/notify";
import { LocationDetailsContext } from "../LocationDetailsContainer";
import TabBar from "../TabBar";
import FileListItem from "./FileListItem";
import UploaderListItem from "./UploaderListItem";
import UploadingListItem from "./UploadingListItem";

interface Props {
  onActiveKeyChange: (activeKey: string) => void;
}

const useStyles = createUseStyles((theme) => ({
  draggerContainer: {
    border: "none",
    width: "calc(100% + 80px)",
    margin: "-16px -40px",
    "& .ant-upload-list": {
      display: "none",
    },
    "& span.ant-upload.ant-upload-btn": {
      display: "block",
      width: "100%,",
    },
    "& .ant-upload.ant-upload-drag": {
      cursor: "unset",
      padding: "0",
      textAlign: "left",
      border: "none",
      minHeight: "calc(100vh - 85px)",
      "& .ant-upload.ant-upload-btn": {
        minHeight: "calc(100vh - 85px)",
        "& .ant-upload-drag-container": {
          verticalAlign: "top",
          display: "block",
          width: "100%",
        },
      },
    },
    "& .ant-upload.ant-upload-drag.ant-upload-drag-hover": {
      "& .dropzone-overlay": {
        display: "unset",
      },
    },
  },
  content: {
    position: "relative",
    padding: "0 40px",
  },
  dropOverlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(255,255,255, 0.75)",
    verticalAlign: "middle",
    pointerEvents: "none",
    display: "none",
    fontSize: theme.fontSize.h1,
    fontWeight: theme.fontWeight.bold,
    color: theme.palette.primary,
    textAlign: "center",
  },
  dropOverlayContent: {
    position: "absolute",
    top: "50vh",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
  },
}));

const LocationFileTab: React.FC<Props> = ({ onActiveKeyChange }) => {
  const classes = useStyles();
  const { serviceConfig, routeParams, hasRight } = useAppContext();
  const { entityId } = routeParams;

  const { location, onOtherFileAdd, onOtherFileDelete } = useContext(
    LocationDetailsContext
  );

  const { t: translation } = useTranslation();

  const [uploadings, setUploadings] = useState<UploadFile[]>(
    [] as UploadFile[]
  );

  const [attachings, setAttachings] = useState<File[]>([] as File[]);

  const headers = {
    Authorization: `Bearer ${serviceConfig.token}`,
  };

  const action = `${serviceConfig.apiBaseUrl}/entities/${entityId}/files`;
  const accept = `.tif,.pip,.xbm,.jxl,.svgz,.jpg,.jpeg,.ico,.tiff,.gif,.svg,.jfif,.webp,.png,.bmp,.pjpeg,.avif,.opus,.flac,.webm,.weba,.wav,.ogg,.m4a,.mp3,.oga,.mid,.amr,.aiff,.wma,.au,.aac,.ogm,.wma,.mpg,.webm,.ogv,.mov,.asx,.mpeg,.mp4,.m4v,.avi,.csv,.doc,.xls,.ppt,.docx,.xlsx,.pptx,.pdf,.zip`;

  const handleUploaderChange = async (
    info: UploadChangeParam<UploadFile<any>>
  ) => {
    const { status } = info.file;

    setUploadings(info.fileList.filter((f) => f.status === "uploading"));
    if (status === "done") {
      const file: File = info.file.response as File;
      setAttachings((prev) => [...prev, file]);
      await onOtherFileAdd(file);
      setAttachings((prev) => prev.filter((prev) => prev.id !== file.id));
    } else if (status === "error") {
      notify.error(`${info.file.name} upload failed.`);
    }
  };

  const handleBeforeUpload = () => {
    return hasRight("location-file-add");
  };

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className={classes.draggerContainer} ref={containerRef}>
      <Dragger
        name={"file"}
        accept={accept}
        multiple={true}
        headers={headers}
        action={action}
        openFileDialogOnClick={false}
        onChange={handleUploaderChange}
        beforeUpload={handleBeforeUpload}
        data-testid="fileDropzone"
      >
        <div className={classes.content}>
          <NxpHeader titleContent={location.name} />
          <TabBar activeKey="other-files" onChange={onActiveKeyChange} />
          <AppFakeTableHeader
            padding={18}
            items={[
              {
                name: translation("locationDetails.locationFileTab.fileName"),
                span: 10,
              },
              {
                name: translation("locationDetails.locationFileTab.uploadedOn"),
                span: 6,
              },
              {
                name: translation("locationDetails.locationFileTab.uploadedBy"),
                span: 6,
              },
              { name: " ", span: 2 },
            ]}
          />
          {hasRight("location-file-add") && (
            <UploaderListItem
              onClick={() => {
                containerRef.current?.getElementsByTagName("input")[0]?.click();
              }}
            />
          )}

          {uploadings.map((uploading) => (
            <UploadingListItem
              name={uploading.name}
              percent={uploading.percent || 0}
            />
          ))}

          {attachings.map((attaching) => (
            <UploadingListItem name={attaching.name} percent={100} />
          ))}

          {location?.files
            ?.sort((a, b) => b.id - a.id)
            .map((file) => (
              <FileListItem file={file} onDelete={onOtherFileDelete} />
            ))}
        </div>
        {hasRight("location-file-add") && (
          <div className={clsx("dropzone-overlay", classes.dropOverlay)}>
            <div className={classes.dropOverlayContent}>
              <FileAddOutlined />
              <br />
              {translation("locationDetails.locationFileTab.dropToUploadFile")}
            </div>
          </div>
        )}
      </Dragger>
    </div>
  );
};

export default LocationFileTab;
