import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { NxpButton } from "@nexploretechnology/nxp-ui";
import { NxpFormGrid, NxpFormGridItemProps } from "@nexploretechnology/nxp-ui";
import { NxpModal } from "@nexploretechnology/nxp-ui";
import { useValidate } from "../../hooks/useValidate";
import { Role } from "../../services/role";

import { useTranslation } from "react-i18next";

interface Props {
  show: boolean;
  role: Role;
  onClose: () => void;
  onEditRole: (form: EditRoleForm) => Promise<boolean>;
  onDefectCreated?: () => void;
}

export interface EditRoleForm {
  name: string;
  code?: string;
  description: string;
}

const emptyForm = {
  name: "",
  code: "",
  description: "",
};

const EditRoleModal: React.FC<Props> = ({
  show,
  role,
  onEditRole,
  onClose,
}) => {
  const { t: translation } = useTranslation();

  const formSchema = yup.object().shape({
    name: yup
      .string()
      .nullable()
      .required(translation("roleSetting.roleNameIsRequired")),
    code: yup
      .string()
      .nullable()
      .required(translation("roleSetting.roleCodeIsRequired")),
    description: yup.string().nullable(),
  });

  const [form, setForm] = useState<EditRoleForm>(emptyForm);
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      if (role) {
        setForm({
          name: role.name,
          code: role.code,
          description: role.description,
        });
      }
      setSaving(false);
    };
  }, [show, role]);

  const formItems: NxpFormGridItemProps<EditRoleForm>[] = [
    {
      controlType: "input",
      label: translation("roleSetting.roleName"),
      itemFieldName: "name",
      required: true,
      span: 12,
    },
    {
      controlType: "input",
      label: translation("roleSetting.roleCode"),
      itemFieldName: "code",
      required: true,
      span: 12,
      controlProps: {
        disabled: true,
      },
    },
    {
      controlType: "input",
      label: translation("app.common.description"),
      itemFieldName: "description",
      required: false,
      span: 24,
    },
  ];

  const handleSaveValidated = async () => {
    setSaving(true);
    onEditRole(form).then((success) => {
      if (success) {
        onClose();
      } else {
        setSaving(false);
      }
    });
  };

  const [validationError, , , saveWithValidate] = useValidate<EditRoleForm>(
    form,
    formSchema,
    handleSaveValidated
  );

  const handleSaveButton = () => {
    saveWithValidate(undefined);
  };

  const handleCloseButton = () => {
    if (saving) return;
    onClose();
  };

  const handleFormStateChange = (
    fieldName: keyof typeof form,
    value: unknown
  ) => {
    setForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  return (
    <NxpModal
      width="large"
      title={translation("app.common.editRole")}
      visible={show}
      showMandatoryLabel
      onCancel={handleCloseButton}
      footer={
        <>
          <NxpButton onClick={handleCloseButton}>
            {translation("app.common.cancel")}
          </NxpButton>
          <NxpButton onClick={handleSaveButton} loading={saving}>
            {translation("app.common.save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={form}
        onFormStateChange={handleFormStateChange}
      />
    </NxpModal>
  );
};

export default EditRoleModal;
