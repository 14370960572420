import React from "react";
import { useTranslation } from "react-i18next";

import { NxpHeader } from "@nexploretechnology/nxp-ui";

import { Team } from "../../../services/app";
import MemberListPanel from "./MemberListPanel";

interface TeamMembersLayoutProps {
  teams: Team[];
  onSaveUserList: (team: Team, userIds: string[]) => void;
}

const TeamMembersLayout: React.FC<TeamMembersLayoutProps> = ({
  teams,
  onSaveUserList,
}) => {
  const { t: translation } = useTranslation();

  return (
    <>
      <NxpHeader titleContent={translation("app.common.teamMember")} />
      {teams.map((team) => (
        <MemberListPanel team={team} onSave={onSaveUserList} />
      ))}
    </>
  );
};

export default TeamMembersLayout;
