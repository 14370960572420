import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { notify, NxpButton, NxpHeader } from "@nexploretechnology/nxp-ui";
import useAppContext from "../../../../hooks/useAppContext";

import {
  SubcontractorSubscription,
  ReminderType,
  deleteReminders,
  createReminders,
} from "../../../../services/email";
import { reminderTypeList } from "../helper";
import { groupBy } from "lodash";
import { Subcontractor } from "../../../../services/app";

const useStyles = createUseStyles((theme) => ({
  title: {
    "& span": {
      fontSize: theme.pxToRem(20),
      fontWeight: theme.fontWeight.bolder,
    },
  },
}));

interface CreateList {
  id?: number;
  subconId: number;
  isDaily: boolean;
  isCcSubconUser: boolean;
  isAttachDefectReport: boolean;
  reminderType: ReminderType;
}

interface DeleteList {
  reminderType: ReminderType;
  id: number;
}

interface ReminderSettingsForm {
  dueDate: boolean;
  newDefect: boolean;
}

interface EmailNotificationSetupAllProps {
  allReminderSettings: SubcontractorSubscription[];
  allSubscribeList: ReminderType[];
  fetchAllReminders: any;
  setLoading: (value: boolean) => void;
}

const EmailNotificationSetupAll: React.FC<EmailNotificationSetupAllProps> = ({
  allReminderSettings,
  allSubscribeList,
  fetchAllReminders,
  setLoading,
}) => {
  const classes = useStyles();
  const { t: translation } = useTranslation();
  const { subcontractors, routeParams, serviceConfig } = useAppContext();
  const { entityId } = routeParams;

  const defaultReminderSettingsForm = {
    dueDate: false,
    newDefect: false,
  };

  const [reminderSettingsForm, setReminderSettingsForm] =
    useState<ReminderSettingsForm>(defaultReminderSettingsForm);

  const handleFormChange = (value: boolean, fieldName: ReminderType) => {
    const newReminderSettingsForm = {
      ...reminderSettingsForm,
      [fieldName]: value,
    };
    setReminderSettingsForm(newReminderSettingsForm);
  };

  useEffect(() => {
    const newReminderSettingsForm = {
      dueDate: Boolean(
        allSubscribeList.find((reminderType) => reminderType === "dueDate")
      ),
      newDefect: Boolean(
        allSubscribeList.find((reminderType) => reminderType === "newDefect")
      ),
    };
    setReminderSettingsForm(newReminderSettingsForm);
  }, [allSubscribeList]);

  const handleSave = async () => {
    const reminderSettingsByReminderType = groupBy(
      allReminderSettings,
      "reminderType"
    );
    // establish create and delete list
    let createList = [];
    let deleteList: DeleteList[] = [];
    for (const reminderTypeObj of reminderTypeList) {
      const reminderType = reminderTypeObj.value;
      const isCheckedBefore = Boolean(
        allSubscribeList.find(
          (subscribedReminderType) => subscribedReminderType === reminderType
        )
      );
      const isCheckedAfter = reminderSettingsForm[reminderType as ReminderType];
      if (isCheckedBefore !== isCheckedAfter) {
        // checkbox updated
        const checkedSubcontractors: Subcontractor[] =
          reminderSettingsByReminderType[reminderType]?.map(
            (reminder) => reminder.subcon
          ) || [];
        if (isCheckedAfter) {
          // filter out existing checked subcontractor
          const uncheckedSubcontractors = subcontractors.filter(
            (allSubcontractor) =>
              !checkedSubcontractors.some(
                (checkedSubcontractor) =>
                  allSubcontractor.id === checkedSubcontractor.id
              )
          );
          // create reminder
          for (const uncheckedSubcontractor of uncheckedSubcontractors) {
            const reminder: CreateList = {
              reminderType: reminderType as ReminderType,
              id: undefined,
              subconId: uncheckedSubcontractor.id,
              isDaily: false,
              isCcSubconUser: false,
              isAttachDefectReport: false,
            };
            createList.push(reminder);
          }
        } else {
          // delete reminder
          reminderSettingsByReminderType[reminderType].forEach(
            (reminderSetting) => {
              const reminder: DeleteList = {
                reminderType: reminderType as ReminderType,
                id: reminderSetting.id,
              };
              deleteList.push(reminder);
            }
          );
          setLoading(true);
        }
      }
    }
    // call api
    try {
      for (const reminder of createList) {
        await createReminders(
          entityId,
          reminder.subconId,
          reminder.reminderType,
          reminder.isDaily,
          reminder.isCcSubconUser,
          reminder.isAttachDefectReport,
          serviceConfig
        );
      }
      for (const reminder of deleteList) {
        await deleteReminders(entityId, reminder.id!, serviceConfig);
      }
      notify.actionCompleted();
    } catch (ex) {
      notify.error(`Save ${ex}`);
    } finally {
      setLoading(false);
      fetchAllReminders();
    }
  };

  return (
    <div>
      <NxpHeader
        titleContent=""
        actionContent={
          <NxpButton type="primary" onClick={handleSave}>
            {translation("app.common.save")}
          </NxpButton>
        }
      />
      <div className={classes.title}>
        <Checkbox
          checked={reminderSettingsForm.dueDate}
          onChange={(e) => handleFormChange(e.target.checked, "dueDate")}
        >
          {translation("settings.emailNotification.dueDateReminderEmail")}
        </Checkbox>
      </div>
      <br />
      <div className={classes.title}>
        <Checkbox
          checked={reminderSettingsForm.newDefect}
          onChange={(e) => handleFormChange(e.target.checked, "newDefect")}
        >
          {translation("settings.emailNotification.newDefectReminderEmail")}
        </Checkbox>
      </div>
    </div>
  );
};

export default EmailNotificationSetupAll;
