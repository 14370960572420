import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row, Spin } from "antd";
import React, { lazy, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

const importView = (subreddit: string) =>
  lazy(() => import(`./${subreddit}`).catch(() => import(`./${"NullView"}`)));

export const DynamicComponent: React.FC<{
  subredditsToShow: string[];
  title: string;
  header: Headers;
}> = (props) => {
  const { t: translation } = useTranslation();

  const [views, setViews] = useState<JSX.Element[]>([]);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    async function loadViews() {
      const componentPromises = props.subredditsToShow.map(
        async (subreddit, index) => {
          const View = await importView(subreddit);
          return <View key={index} {...props} />;
        }
      );

      Promise.all(componentPromises).then((res) => setViews(res));
    }

    loadViews();
  }, [props, props.subredditsToShow]);

  return (
    <React.Suspense
      fallback={
        <Row justify="center" align="middle">
          <Col>
            {translation("reportMenu.loadingPage")}....
            <Spin indicator={antIcon} />,
          </Col>
        </Row>
      }
    >
      <div className="container">{views}</div>
    </React.Suspense>
  );
};
export default DynamicComponent;
