import { Tree } from "antd";
import _ from "lodash";
import React from "react";
import { createUseStyles } from "react-jss";
import DynamicComponent from "./FetchDynamicComponent";
import { View } from "./ReportMenuContainer";
import { useTranslation } from "react-i18next";

interface Props {
  view: View | string;
  onViewChange: (view: View) => void;
}

const useStyles = createUseStyles((theme) => ({
  root: {
    margin: "-16px -40px",
    display: "flex",
  },
  header: {
    marginLeft: "25px",
    fontWeight: "bold",
  },
  tree: {
    background: "#FFFFFFFF",
    padding: "40px 20px",
    width: 450,
    height: "calc(100vh - 85px)",
  },
  content: {
    padding: "16px 40px",
    width: "100%",
    height: "calc(100vh - 85px)",
    overflowY: "scroll",
  },
}));

const ReportMenuLayout: React.FC<Props> = ({ view, onViewChange }) => {
  const classes = useStyles();

  const { t: translation } = useTranslation();

  const handleViewSelect = (value: View | any) => {
    if (Array.isArray(value)) {
      onViewChange(value[0] as View);
    } else {
      onViewChange(value as View);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.tree}>
          <p className={classes.header}>
            {translation("app.common.subcontractor")}
          </p>
          <Tree onSelect={handleViewSelect}>
            <Tree.TreeNode
              key="defect-summary-by-responsible-party"
              title={translation(
                "reportMenu.defect-summary-by-responsible-party"
              )}
            />
            <Tree.TreeNode
              key={"outstanding-defect-summary-by-responsible-party"}
              title={translation(
                "reportMenu.outstanding-defect-summary-by-responsible-party"
              )}
            />
            <Tree.TreeNode
              key={"back-charge-summary-by-responsible-party"}
              title={translation(
                "reportMenu.back-charge-summary-by-responsible-party"
              )}
            />
            <Tree.TreeNode
              key={"back-charge-cost-summary-by-responsible-party"}
              title={translation(
                "reportMenu.back-charge-cost-summary-by-responsible-party"
              )}
            />
            <Tree.TreeNode
              key={"monthly-defect-count-by-day"}
              title={translation("reportMenu.monthly-defect-count-by-day")}
            />
            <Tree.TreeNode
              key={"location-and-responsible-party-report"}
              title={translation(
                "reportMenu.location-and-responsible-party-report"
              )}
            />
            <Tree.TreeNode
              key={"location-and-defect-group-report"}
              title={translation("reportMenu.location-and-defect-group-report")}
            />
          </Tree>
        </div>
        <div className={classes.content}>
          {view && (
            <DynamicComponent
              header={translation(`reportMenu.${view}`)}
              title={_.words(view)
                .map((word) => _.capitalize(word))
                .join(" ")}
              subredditsToShow={[
                `${_.camelCase(view).slice(0, 1).toUpperCase()}${_.camelCase(
                  view
                ).slice(1)}`,
              ]}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ReportMenuLayout;
