import { useContext, useMemo } from "react";
import { useTheme } from "react-jss";
import AppContext from "../components/AppContext";
import useErrorHandler from "./useErrorHandler";
// import useCurrentUser from './useCurrentUser';
// import useErrorHandler from './useErrorHandler';
import useServiceConfig from "./useServiceConfig";

const useAppContext = () => {
  const appContext = useContext(AppContext);
  const serviceConfig = useServiceConfig();
  // const currentUser = useCurrentUser();
  const theme = useTheme();
  // const {enqueueSnackbar} = useSnackbar();
  const errorHandler = useErrorHandler();

  return useMemo(
    () => ({
      ...appContext,
      serviceConfig,
      // currentUser,
      theme,
      errorHandler,
    }),
    [
      appContext,
      serviceConfig,
      // currentUser,
      theme,
      errorHandler,
    ]
  );
};

export default useAppContext;
