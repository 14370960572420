import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useHistory } from "react-router";

import useAppContext from "../../hooks/useAppContext";
import SubcontractorViewLayout from "./SubcontractorViewLayout";
import {
  defaultSubcontractorStatistics,
  getSubcontractorStatistics,
} from "./report";
import subcontractorReducer, {
  initSubcontractorsState,
  SubcontractorStatistic,
} from "./SubcontractorReducer";
import { useParams } from "react-router-dom";
import { Spin } from "antd";

interface SubcontractorViewContainerProps {}

const SubcontractorViewContainer: React.FC<SubcontractorViewContainerProps> =
  () => {
    const appContext = useAppContext();
    const { serviceConfig, subcontractors, routeParams } = appContext;
    const { entityId, locationGroupId } = routeParams;
    const routeParam = useParams<{
      entityId: string;
      locationGroupId: string;
      subcontractorId: string;
    }>();
    const history = useHistory();
    const [subcontractorReducerState, dispatch] = useReducer(
      subcontractorReducer,
      initSubcontractorsState
    );

    const [extraQueries, setExtraQueries] = useState<string[]>([]);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [invalidateAt] = useState<number>(0);
    const [summaryVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [subcontractorStatisticsState, setSubcontractorStatisticsstate] =
      useState<SubcontractorStatistic[]>(defaultSubcontractorStatistics);

    // fetchData
    const handleFilterApply = (queryStrings: string[]) => {
      setExtraQueries(queryStrings);
      setFilterModalVisible(false);
    };

    const searchPhase = `&responsibleParty=${
      routeParam?.subcontractorId ?? subcontractors[0]?.id
    }`;
    let queryString = [searchPhase, ...extraQueries].join("&");
    if (!queryString.startsWith("?")) queryString = `?${queryString}`;

    const handleSummaryVisible = (type: "cost" | "normal") => {
      history.push({
        pathname: `/entities/${entityId}/location-groups/${locationGroupId}/subcontractor/${routeParam?.subcontractorId}/report/${type}`,
      });
    };
    const fetchData = useCallback(
      async (subcontractorId: string) => {
        const subcontractor = subcontractors.find(
          (subcontractor) => subcontractor.id === Number(subcontractorId)
        );
        if (!subcontractor) return;
        setLoading(true);
        if (subcontractors) {
          const subcontractorStatisticsRes = await getSubcontractorStatistics(
            entityId,
            subcontractor.id,
            locationGroupId,
            serviceConfig
          );
          setLoading(false);
          dispatch({
            type: "setSubcontractorStatistics",
            setSubcontractorStatistics: subcontractorStatisticsRes,
            setSubcontractor: subcontractor,
          });
          setSubcontractorStatisticsstate(subcontractorStatisticsRes);
        }
      },
      [entityId, locationGroupId, serviceConfig, subcontractors]
    );

    const handleLocationSelect = async (selectedKeys: React.Key[]) => {
      if (selectedKeys.length) {
        history.push({
          pathname: `/entities/${entityId}/location-groups/${locationGroupId}/subcontractors/${selectedKeys[0]}`,
        });
        fetchData(selectedKeys[0] as string);
      }
    };
    useEffect(() => {
      if (routeParam?.subcontractorId !== undefined)
        fetchData(routeParam?.subcontractorId ?? subcontractors[0]?.id);
      return () => {
        dispatch({
          type: "clear",
        });
      };
    }, [fetchData, routeParam?.subcontractorId, subcontractors]);

    return (
      <Spin spinning={loading}>
        <SubcontractorViewLayout
          extraQueries={extraQueries}
          filterModalVisible={filterModalVisible}
          history={history as any}
          invalidateAt={invalidateAt}
          queryString={queryString}
          subcontractor={subcontractorReducerState?.subcontractor}
          subcontractorStatistics={[...subcontractorStatisticsState]}
          summaryVisible={summaryVisible}
          handleFilterApply={handleFilterApply}
          onSubcontractorSelect={handleLocationSelect}
          setFilterModalVisible={setFilterModalVisible}
          setSummaryVisible={handleSummaryVisible}
        />
      </Spin>
    );
  };

export default SubcontractorViewContainer;
