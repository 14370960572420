import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { Col, Row } from "antd";
import _ from "lodash";
import moment from "moment";

import { AppTheme } from "../../components/App/appTheme";
import useAppContext from "../../hooks/useAppContext";
import noFloorPlan from "../../images/report-no-floor-plan.png";
import pinIcon from "../../images/vector.svg";
import { Defect } from "../../services/defect";

const useStyles = createUseStyles<
  "defectDetail",
  {
    floorPlan: {
      width: number;
      height: number;
    };
  },
  AppTheme
>((theme) => ({
  defectDetail: {
    "& .printable": {
      "@media screen": {
        display: "none",
        "& .image-area": {
          pageBreakInside: "avoid",
        },
      },

      "& table": {
        width: "100%",
        pageBreakInside: "avoid",
        borderCollapse: "collapse",
        border: "1px solid black",
        tableLayout: "fixed",
        "& td": {
          border: "1px solid black",
          textAlign: "center",
          overflowX: "hidden",
        },
      },
      "& hr": {
        width: 0,
        pageBreakAfter: "always",
      },
      "& .ant-row": {
        padding: 8,
      },
    },
    "& .defectGroupCircle": {
      color: theme.palette.primary,
      fontSize: theme.fontSize.h1,
      width: 80,
      height: 80,
      borderRadius: "50%",
      margin: "8px auto",
      border: `3px solid ${theme.palette.primary}`,
      lineHeight: 2.5,
      overflow: "hidden",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .innerText": {
        padding: theme.spacing(2),
      },
    },
    "& .floorPlanDiv": {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      width: (props) => {
        return props?.floorPlan?.width;
      },
      height: (props) => {
        return props?.floorPlan?.height;
      },
      margin: "24px auto",
    },
    "& .floorPlanImg": {
      width: (props) => {
        return props?.floorPlan?.width;
      },
      height: (props) => {
        return props?.floorPlan?.height;
      },
    },
    "& .pinImage": {
      position: "absolute",
      transform: "translate(-50%, -100%)",
    },
  },
}));

interface Props {
  defect: Defect;
  showPhoto: boolean;
}

const DefectDetailsReport: React.FC<Props> = ({ defect, showPhoto }) => {
  const imgElement = useRef<HTMLImageElement | null>(null);
  const classes = useStyles({
    floorPlan: {
      width: _.get(imgElement, "current.width") || 0,
      height: _.get(imgElement, "current.height") || 0,
    },
  });
  const { getLocationFullPath } = useAppContext();

  const { t: translation } = useTranslation();

  return (
    <div className={classes.defectDetail}>
      <div className="printable">
        <h1>{translation("defectDetails.defectDetailsReport")}</h1>
        <table>
          <tbody>
            <tr>
              <td>
                <b>{translation("app.common.defectNo")}</b>
              </td>
              <td>{defect.no}</td>
              <td>
                <b>{translation("app.common.raisedOn")}</b>
              </td>
              <td>
                {moment(defect.raiseDate, "YYYY-MM-DD").format("DD-MMM-YYYY")}
              </td>
              <td>
                <b>{translation("app.common.dueDate")}</b>
              </td>
              <td>
                {moment(defect.dueDate, "YYYY-MM-DD").format("DD-MMM-YYYY")}
              </td>
              <td>
                <b>{translation("app.common.status")}</b>
              </td>
              <td>{defect.status}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <h3>{translation("app.common.responsibleParty")}</h3>
        <table>
          <tbody>
            <tr>
              <td width="50%">{defect.responsibleParty?.name}</td>
              <td>
                <b>{translation("app.common.nextInspectionDate")}:</b>
                <br />
                {moment(defect.nextInspectionDate, "YYYY-MM-DD").format(
                  "DD-MMM-YYYY"
                )}
              </td>

              <td>
                <div className="defectGroupCircle">
                  <div className="innerText">{defect.defectGroup?.code}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <br />
        <br />
        <h3>{translation("defectDetails.defectDetails")}</h3>
        <table>
          <tbody>
            <tr>
              <td width="25%">
                <b>{translation("app.common.defectType")}</b>
              </td>
              <td colSpan={3}>{defect.defectType?.name}</td>
            </tr>
            <tr>
              <td width="25%">
                <b>{translation("app.common.inspectionType")}</b>
              </td>
              <td width="25%">{defect.inspectionType?.name}</td>
              <td width="25%">
                <b>{translation("app.common.defectGroup")}</b>
              </td>
              <td width="25%">{defect.defectGroup?.name}</td>
            </tr>

            <tr>
              <td width="25%">
                <b>{translation("app.common.subject")}</b>
              </td>
              <td colSpan={3}>{defect.defectSubject?.name}</td>
            </tr>

            <tr>
              <td width="25%">
                <b>{translation("app.common.description")}</b>
              </td>
              <td colSpan={3}>{defect.defectDescription?.name}</td>
            </tr>

            <tr>
              <td width="25%">
                <b>{translation("app.common.location")}</b>
              </td>
              <td colSpan={3}>{getLocationFullPath(defect.location)}</td>
            </tr>

            <tr>
              <td width="25%">
                <b>{translation("app.common.remarks")}</b>
              </td>
              <td colSpan={3}>
                {defect.remarks}
                <br />
                {defect.remarksLocal}
              </td>
            </tr>

            <tr>
              <td width="25%">
                <b>{translation("defectDetails.contactPerson")}</b>
              </td>
              <td colSpan={3}>{defect.responsiblePerson?.name}</td>
            </tr>
          </tbody>
        </table>

        <i>{translation("defectDetails.instruction")}</i>
        <hr />
        <div>
          <h3>{translation("app.common.floorPlanLabel")}</h3>
          <div className="floorPlanDiv">
            <img
              ref={imgElement}
              className="floorPlanImg"
              src={
                defect.location.floorPlans[0]?.file.thumbnailUrl || noFloorPlan
              }
              alt={"Floor plan"}
            />
            {defect.pinX && defect.pinY && (
              <img
                className="pinImage"
                style={{
                  left: `${defect.pinX * 100}%`,
                  top: `${defect.pinY * 100}%`,
                }}
                src={pinIcon}
                alt={"Pin"}
              />
            )}
          </div>
        </div>

        <hr />

        <h3>{translation("app.common.inspectionLog")}</h3>
        <table>
          <tbody>
            <tr>
              <td width="5%">
                <b>#</b>
              </td>
              <td>
                <b>{translation("app.common.date")}</b>
              </td>
              <td>
                <b>{translation("app.common.inspector")}</b>
              </td>
              <td>
                <b>{translation("app.common.status")}</b>
              </td>
              <td>
                <b>{translation("app.common.nextInspectionDate")}</b>
              </td>
            </tr>

            {defect.messages
              .filter((message) => message.type !== "response")
              .map((message, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {moment(message.date, "YYYY-MM-DD").format("DD-MMM-YYYY")}
                  </td>
                  <td>{message.createdBy?.name}</td>
                  <td>{message.defectStatus}</td>
                  <td>
                    {message.nextInspectionDate
                      ? moment(message.nextInspectionDate, "YYYY-MM-DD").format(
                          "DD-MMM-YYYY"
                        )
                      : " "}
                  </td>
                </tr>
              ))}

            <tr>
              <td colSpan={5}>
                <b>{translation("defectDetails.issuedOn")}:</b>{" "}
                {moment().format("DD-MMM-YYYY")}
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        {defect.messages
          .filter((message) => message.type !== "response")
          .map((message, index) => (
            <React.Fragment key={index}>
              <h3>{translation("app.common.inspectionSummary")}</h3>
              <table>
                <thead>
                  <tr>
                    <td>
                      <b>{translation("app.common.inspectionDate")}</b>
                    </td>
                    <td>
                      {message?.type !== "cancellation" &&
                        message?.type !== "closure" && (
                          <>
                            {moment(message.date, "YYYY-MM-DD").format(
                              "DD-MMM-YYYY"
                            )}
                          </>
                        )}
                    </td>
                    <td>
                      <b>{translation("app.common.inspector")}</b>
                    </td>
                    <td>{_.get(message, "createdBy.name")}</td>
                    <td>
                      <b>{translation("app.common.status")}</b>
                    </td>
                    <td>{message?.defectStatus}</td>
                    <td>
                      <b>{translation("app.common.nextInspectionDate")}</b>
                    </td>
                    <td>
                      {message?.defectStatus !== "cancelled" &&
                      message?.defectStatus !== "closed"
                        ? moment(
                            message?.nextInspectionDate,
                            "YYYY-MM-DD"
                          ).format("DD-MMM-YYYY")
                        : ""}
                    </td>
                  </tr>
                </thead>
                <tr>
                  <td colSpan={8} style={{ textAlign: "left" }}>
                    <h3 style={{ padding: 16 }}>
                      {translation("defectDetails.findingRemarks")}
                    </h3>
                  </td>
                </tr>
                <tbody>
                  <tr>
                    <td colSpan={8} style={{ textAlign: "left" }}>
                      <div style={{ padding: 16 }}>{message?.comment}</div>
                    </td>
                  </tr>
                </tbody>

                {showPhoto && (
                  <>
                    <tr>
                      <td colSpan={8} style={{ textAlign: "left" }}>
                        <h3 style={{ padding: 16 }}>
                          {translation("defectDetails.photos")}
                        </h3>
                      </td>
                    </tr>
                    <tbody>
                      {_.isArray(message?.attachments) &&
                      message?.attachments.length <= 0 ? (
                        <tr>
                          <td colSpan={8}>
                            {translation("defectDetails.noPhotoFound")}{" "}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan={8}>
                            <Row gutter={16} className="image-area">
                              {message?.attachments
                                .filter((item) =>
                                  _.includes(_.split(item?.type, "/"), "image")
                                )
                                .map((attached, idx) => {
                                  return (
                                    <Col flex="200px" key={idx}>
                                      <img
                                        src={attached?.thumbnailUrl}
                                        alt={attached.name}
                                        width="100%"
                                      />
                                    </Col>
                                  );
                                })}
                            </Row>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </>
                )}
              </table>
              <hr />
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default DefectDetailsReport;
