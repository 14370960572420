import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { NxpFormItem } from "@nexploretechnology/nxp-ui";
import { ColumnProps } from "antd/lib/table";
import * as yup from "yup";

import useAppContext from "../../../hooks/useAppContext";
import { useValidate, ValidationResult } from "../../../hooks/useValidate";
import { DefectGroup } from "../../../services/app";
import {
  deleteDefectGroup,
  updateDefectGroup,
} from "../../../services/defectGroup";
import notify from "../../../utils/notify";
import DefectGroupLayout from "./DefectGroupLayout";

interface DefectGroupContainerProps {}

const DefectGroupContainer: React.FC<DefectGroupContainerProps> = () => {
  const appContext = useAppContext();
  const {
    defectGroups,
    serviceConfig,
    routeParams,
    errorHandler,
    hasRight,
    refetchDefectGroups,
  } = appContext;
  const { entityId } = routeParams;

  const { t: translation } = useTranslation();

  const formSchema = yup.object().shape({
    name: yup
      .string()
      .nullable()
      .required(translation("app.common.groupNameIsRequired")),
    localName: yup
      .string()
      .nullable()
      .required(translation("app.common.inLocalLanguageIsRequired")),
    code: yup
      .string()
      .nullable()
      .required(translation("app.common.shortCodeIsRequired")),
  });

  const getColumns = (
    editRecord: DefectGroup | undefined,
    setEditRecord: (callback: SetStateAction<DefectGroup | undefined>) => void,
    validationError: ValidationResult<DefectGroup>
  ): ColumnProps<DefectGroup>[] => [
    {
      title: translation("app.common.groupName"),
      dataIndex: "name",
      fixed: "left",
      width: 300,
      render: (_: unknown, record: DefectGroup) => {
        const editing = editRecord?.id === record.id;
        return (
          <NxpFormItem
            editing={editing}
            controlType="input"
            error={validationError.name}
            controlProps={{
              value: editing ? editRecord?.name : record.name,
              onChange: (e) =>
                setEditRecord(
                  (prevState: any) =>
                    prevState && { ...prevState, name: e.target.value }
                ),
            }}
          />
        );
      },
    },
    {
      title: translation("app.common.inLocalLanguage"),
      dataIndex: "localName",
      fixed: "left",
      width: 300,
      render: (_: unknown, record: DefectGroup) => {
        const editing = editRecord?.id === record.id;
        return (
          <NxpFormItem
            editing={editing}
            controlType="input"
            error={validationError.localName}
            controlProps={{
              value: editing ? editRecord?.localName : record.localName,
              onChange: (e) =>
                setEditRecord(
                  (prevState) =>
                    prevState && {
                      ...prevState,
                      localName: e.target.value,
                    }
                ),
            }}
          />
        );
      },
    },
    {
      title: translation("app.common.shortCode"),
      dataIndex: "code",
      fixed: "left",
      width: 300,
      render: (_: unknown, record: DefectGroup) => {
        const editing = editRecord?.id === record.id;
        return (
          <NxpFormItem
            editing={editing}
            controlType="input"
            error={validationError.code}
            controlProps={{
              value: editing ? editRecord?.code : record.code,
              onChange: (e) =>
                setEditRecord(
                  (prevState) =>
                    prevState && { ...prevState, code: e.target.value }
                ),
            }}
          />
        );
      },
    },
  ];

  const [editItem, setEditItem] = useState<DefectGroup>();
  const [defectGroupListData, setDefectGroupListData] = useState<
    DefectGroup[] | undefined
  >(undefined);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleSaveValidated = async () => {
    setSaveInProgress(true);
    try {
      await updateDefectGroup(entityId!, editItem!, serviceConfig);
      const rec = defectGroupListData?.find((data) => data.id === editItem?.id);
      Object.assign(rec as {}, { ...editItem });
      setEditItem(undefined);
      notify.actionCompleted();
      refetchDefectGroups(entityId!, serviceConfig);
    } catch (ex: any) {
      errorHandler(
        ex,
        translation("settings.defectGroup.handleSaveValidated.error")
      );
    } finally {
      setSaveInProgress(false);
    }
  };

  const [validationError, , clearError, saveWithValidate] = useValidate<
    Pick<DefectGroup, "name" | "localName" | "code">
  >(editItem, formSchema, handleSaveValidated);

  const columns = useMemo(
    () => getColumns(editItem, setEditItem, validationError),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editItem, validationError]
  );

  const handleAddToList = useCallback(
    (item: DefectGroup) => {
      refetchDefectGroups(entityId, serviceConfig);
      setDefectGroupListData([
        ...defectGroupListData!,
        {
          id: item.id,
          defectSubjects: [],
          name: item.name,
          localName: item.localName,
          code: item.code,
        },
      ]);
    },
    [defectGroupListData, entityId, refetchDefectGroups, serviceConfig]
  );

  const handleRowCancel = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      setEditItem(undefined);
      clearError();
    },
    [clearError]
  );

  const handleRowDelete = useCallback(
    async (deleteItem: DefectGroup) => {
      setSaveInProgress(true);
      await deleteDefectGroup(entityId!, deleteItem.id, serviceConfig);
      refetchDefectGroups(entityId, serviceConfig);
      setDefectGroupListData(
        defectGroupListData?.filter((data) => data !== deleteItem)
      );
      notify.actionCompleted();
      setEditItem(undefined);
      setTimeout(() => setSaveInProgress(false)); // need setTimeout only because this demo use synchronous procedure for deleting
    },
    [entityId, serviceConfig, refetchDefectGroups, defectGroupListData]
  );

  const handleRowEdit = useCallback(
    (editItem: DefectGroup) => setEditItem({ ...editItem }),
    []
  );

  const handleRowSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  useEffect(() => {
    setDefectGroupListData(defectGroups);
    console.log("defectGroups: ", defectGroups);
  }, [defectGroups, serviceConfig, entityId]);

  return (
    <DefectGroupLayout
      columns={columns}
      defectGroupListData={defectGroupListData}
      editItem={editItem}
      entityId={entityId!}
      saveInProgress={saveInProgress}
      handleAddToList={handleAddToList}
      handleRowCancel={handleRowCancel}
      handleRowDelete={handleRowDelete}
      handleRowEdit={handleRowEdit}
      handleRowSave={handleRowSave}
      hasRight={hasRight}
    />
  );
};

export default React.memo(DefectGroupContainer);
