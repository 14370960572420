import { CaretDownFilled } from "@ant-design/icons";
import { Card, Col, Dropdown, Menu, Row, Spin } from "antd";
import { NxpHeader, NxpButton } from "@nexploretechnology/nxp-ui";
import React, { useEffect, useReducer, useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import AppDefectSummaryReportGenerationModal from "../../components/AppDefectSummaryReportGenerationModal";
import useAppContext from "../../hooks/useAppContext";
import { Subcontractor } from "../../services/app";
import {
  Defect,
  DefectStatisticResult,
  getDefectsFullSet,
} from "../../services/defect";
import notify from "../../utils/notify";
import { stringToBoolean } from "../../utils/throttle";
import subcontractorReducer, {
  initSubcontractorsState,
} from "./SubcontractorReducer";
import AppSubscontractorReportItem from "../../components/App/AppSubcontractorReportItem/AppSubscontractorReportItem";
import moment from "moment";
import { getSubcontractorStatistics } from "./report";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";

interface Props {}
export interface StatisticResult {
  loading: boolean;
  result?: DefectStatisticResult;
}
const useStyles = createUseStyles((theme) => ({
  title: {
    "& .left": { textAlign: "right" },
  },
  content: {
    padding: "16px 40px",
    maxWidth: "calc(70vw + 25px)",
  },

  defects: {
    padding: "0px 10px",
  },
  defectRow: {
    // display: "flex",
    // height: "280px",
  },
  statistics: {
    display: "flex",
    padding: "20px 0px",
    maxWidth: "calc(60vw + 25px)",
  },
  card: {
    textAlign: "center",
    marginRight: "20px",
    margin: theme.spacing(1, 3, 1, 0),
    minWidth: "200px",
    "& h1": {
      "&.green": {
        color: theme.palette.success,
      },
      "&.red": {
        color: theme.palette.warning,
      },
      fontSize: theme.pxToRem(25),
      color: "#005794",
      margin: 0,
    },
    "& p": {
      fontSize: theme.pxToRem(12),
      fontWeight: 700,
      color: "#8A9999",
      textTransform: "uppercase",
    },
  },
  dropdown: {
    border: `${theme.pxToRem(1)} solid ${theme.palette.borderLight}`,
    "& .ant-dropdown-menu-item": {
      color: theme.palette.primary,
      fontWeight: theme.fontWeight.bold,
      fontSize: theme.pxToRem(12),
    },
  },
}));
export type tablelItem = {
  label: string;
  getVal: string[];
  key?: keyof Defect;
  width: string;
};
const SubcontractorSummaryReport: React.FC<Props> = (props) => {
  const { t: translation } = useTranslation();

  const [subcontractorState, dispatch] = useReducer(
    subcontractorReducer,
    initSubcontractorsState
  );
  const classes = useStyles();
  const appContext = useAppContext();
  const [shouldGenerateListingReport, setShouldGenerateListingReport] =
    useState<boolean>(false);

  const [order] = useState<"asc" | "desc">("desc"); // for reference only, AppDefectList handle sorting on it's own
  const [showPhoto, setShowPhoto] = useState<boolean>(true);
  const [defects, setDefects] = useState<Defect[] | null>(null);
  const handleMenuClick = (e: any) => {
    setShowPhoto(stringToBoolean(e?.key));
    setShouldGenerateListingReport(true);
  };
  const menu = (
    <Menu onClick={handleMenuClick} className={classes.dropdown}>
      <Menu.Item key="true">
        {translation("app.common.exportIncPhotos")}
      </Menu.Item>
      <Menu.Item key="false">
        {translation("app.common.exportExcPhotos")}
      </Menu.Item>
    </Menu>
  );
  const { routeParams, subcontractors, serviceConfig } = appContext;
  const { subcontractorId, entityId, locationGroupId, reportType } =
    routeParams;

  const history = useHistory();
  const { hasRight } = useAppContext();
  const tableItem: tablelItem[] = [
    {
      label: "#",
      getVal: ["no"],
      key: "no",
      width: "5%",
    },
    {
      label: translation("app.common.raisedOn"),
      key: "raiseDate",
      getVal: ["raiseDate"],
      width: "10%",
    },
    {
      label: translation("app.common.dueDate"),
      key: "dueDate",
      getVal: ["dueDate"],
      width: "10%",
    },
    {
      label: translation("app.common.type"),
      key: "defectGroup",
      getVal: ["defectGroup.name"],
      width: "10%",
    },
    {
      label: translation("app.common.location"),
      key: "location",
      getVal: ["location"],
      width: "20%",
    },
    {
      label: translation("app.common.description"),
      key: "defectGroup",
      getVal: [
        "defectGroup.name",
        "defectSubject.name",
        "defectDescription.name",
      ],
      width: "15%",
    },
    {
      label: translation("app.common.round"),
      key: "round",
      getVal: ["round"],
      width: "10%",
    },
    {
      label: translation("app.common.remarks"),
      key: "remarks",
      getVal: ["remarks"],
      width: "30%",
    },
  ];
  useEffect(() => {
    if (subcontractorId) {
      const subcontractor = subcontractors?.find(
        (subcontractor) => subcontractor.id === Number(subcontractorId)
      ) as Subcontractor;

      getSubcontractorStatistics(
        entityId,
        subcontractorId,
        locationGroupId,
        serviceConfig
      ).then((subcontractorStatisticsRes) => {
        dispatch({
          type: "setSubcontractorStatistics",
          setSubcontractorStatistics: subcontractorStatisticsRes,
          setSubcontractor: subcontractor,
        });
      });

      dispatch({
        type: "setSubcontractor",
        setSubcontractor: subcontractor,
      });
    }
    if (subcontractorId && defects == null)
      getDefectsFullSet(
        entityId,
        locationGroupId,
        `?responsibleParty=${subcontractorId}`,
        "no",
        order,
        serviceConfig
      )
        .then((rows) => setDefects(rows))
        .catch((e) => notify.error(e));

    return () => {
      setDefects(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subcontractorId]);

  const defectRow: tablelItem[][] = [
    [...tableItem].splice(0, 4),
    [...tableItem].splice(4),
  ];
  return (
    <div className="subcontractor">
      <NxpHeader
        onBackClick={history.goBack}
        titleContent={`${translation("app.common.subcontractor")} ${
          routeParams?.reportType === "cost"
            ? translation("app.common.cost")
            : ""
        } ${translation("app.common.summaryReport")}`}
        actionContent={
          <>
            {hasRight("report-view") && (
              <Dropdown overlay={menu}>
                <NxpButton type="primary">
                  {translation("app.common.reports")} <CaretDownFilled />
                </NxpButton>
              </Dropdown>
            )}
          </>
        }
      />
      <Row justify="space-between" className={classes.title}>
        <Col flex="auto">{subcontractorState?.subcontractor?.name}</Col>
        <Col flex="auto" className="left">
          {translation("app.common.reportDate")}:{" "}
          {moment().format("DD-MMM-YYYY")}
        </Col>
      </Row>
      <div className={classes.statistics}>
        <Row>
          {subcontractorState?.subcontractorStatistic
            .filter((subcontractorStatistic) =>
              routeParams?.reportType === "normal"
                ? (subcontractorStatistic.title === "app.common.fixed" &&
                    subcontractorStatistic.get === "count") ||
                  (subcontractorStatistic.title === "app.common.outstanding" &&
                    subcontractorStatistic.get === "count") ||
                  (subcontractorStatistic.title === "app.common.overdue" &&
                    subcontractorStatistic.get === "count") ||
                  subcontractorStatistic.title === "app.common.averageDays" ||
                  subcontractorStatistic.title === "app.common.relatedDefects"
                : subcontractorStatistic.title === "app.common.fixed" ||
                  subcontractorStatistic.title === "app.common.outstanding" ||
                  subcontractorStatistic.title === "app.common.overdue" ||
                  subcontractorStatistic.title === "app.common.averageDays" ||
                  subcontractorStatistic.title ===
                    "app.common.relatedDefects" ||
                  subcontractorStatistic.title === "app.common.fixedOverdue" ||
                  subcontractorStatistic.title === "app.common.notYetFixed" ||
                  subcontractorStatistic.title === "app.common.totalDefects"
            )
            .map((subcontractorStatistic, index) => (
              <Col span={6} key={`subcontractor-index-${index}`}>
                <Card size="small" className={classes.card} key={index}>
                  <h1
                    className={
                      subcontractorStatistic?.title === "app.common.fixed" &&
                      subcontractorStatistic?.get === "count"
                        ? "green"
                        : subcontractorStatistic?.title === "app.common.overdue"
                        ? "red"
                        : "primary"
                    }
                  >
                    {subcontractorStatistic.key === "Total Defects" ||
                    subcontractorStatistic.key === "Fixed totalCost" ||
                    (subcontractorStatistic.key === "Outstanding" &&
                      subcontractorStatistic.get === "totalCost") ||
                    subcontractorStatistic.key === "Overdue totalCost"
                      ? `$` + subcontractorStatistic.value
                      : subcontractorStatistic.value}
                  </h1>
                  <p>{translation(subcontractorStatistic.title)}</p>
                </Card>
              </Col>
            ))}
        </Row>
      </div>
      <Spin spinning={!defects}>
        <div className={classes.defectRow}>
          <AppSubscontractorReportItem
            defects={defects ?? []}
            defectRow={defectRow}
          />
        </div>
      </Spin>

      <AppDefectSummaryReportGenerationModal
        reportTitle={`Subcontractor ${
          reportType !== "normal" ? capitalize(reportType) : ""
        } Summary Report`}
        subcontractorStatistic={subcontractorState?.subcontractorStatistic}
        subcontractor={
          subcontractors?.find(
            (subcontractor) => subcontractor.id === Number(subcontractorId)
          ) as Subcontractor
        }
        tableItem={tableItem}
        execute={shouldGenerateListingReport}
        queryString={`?responsibleParty=${subcontractorId}`}
        sortBy={"no"}
        order={order}
        onClose={() => setShouldGenerateListingReport(false)}
        showAttachmentPhoto={showPhoto}
      />
    </div>
  );
};

export default SubcontractorSummaryReport;
