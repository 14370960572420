import React from "react";
import { createUseStyles } from "react-jss";

import splashScreenImg from "./Punchlist_Background.png";

interface SplashScreenProps {}

const useStyles = createUseStyles(() => ({
  root: {
    margin: "-16px -40px",
    background: `url('${splashScreenImg}') center center`,
    height: "calc(100vh - 85px)",
    width: "calc(100vw - 106px)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
  },
}));

const SplashScreen: React.FC<SplashScreenProps> = () => {
  const classes = useStyles();
  return <div title="splash-screen" className={classes.root}></div>;
};

export default React.memo(SplashScreen);
