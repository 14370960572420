import { ThemeProvider } from "react-jss";

import "./App.less";
// import {useTranslation} from 'react-i18next';
import "./i18n";

import { NxpBackTop } from "@nexploretechnology/nxp-ui";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { ConfigProvider } from "antd";

import AppContextProvider from "../AppContext/AppContextProvider";
import AppRouter from "./AppRouter";
import { appTheme } from "./appTheme";
import getKeycloakInstance from "./keycloak";
import useAppTimer from "./useAppTimer";

import "react-image-lightbox/style.css";

export interface UserInfo {
  username: string;
  email: string;
  token: string;
}

function App() {
  // const {t} = useTranslation();
  // for tracking idle time and token expiry

  const tokenLogger = (tokens: unknown) => {
    console.log("onKeycloakTokens", tokens);
  };

  useAppTimer(getKeycloakInstance());

  return (
    <ReactKeycloakProvider
      authClient={getKeycloakInstance()}
      // onEvent={eventLogger}
      onTokens={tokenLogger}
      initOptions={{
        onLoad: "login-required",
        flow: "implicit",
      }}
    >
      <ConfigProvider csp={{ nonce: "EDNnf03nceIOfn39fn3e9h3sdfa" }}>
        <ThemeProvider theme={appTheme}>
          <AppContextProvider>
            <AppRouter />
          </AppContextProvider>
          <NxpBackTop />
        </ThemeProvider>
      </ConfigProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
