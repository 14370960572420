import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { UploadOutlined } from "@ant-design/icons";
import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import { Upload } from "antd";
import moment from "moment";
import * as yup from "yup";

import useAppContext from "../../../../hooks/useAppContext";
import { useValidate } from "../../../../hooks/useValidate";
import { File } from "../../../../services/app";
import notify from "../../../../utils/notify";
import { DefectDetailsContext } from "../../DefectDetailsContainer";

interface AddInspectionModalProps {
  visible: boolean;
  onDismiss: () => void;
}
export interface AddInspectionGridForm {
  date: Date;
  comment: string;
  status: string;
  nextinspectiondate: Date;
  attachments: number[];
}

const AddInspectionContainer: React.FC<AddInspectionModalProps> = (props) => {
  const { activeEntityParameter, serviceConfig, routeParams } = useAppContext();
  const { entityId } = routeParams;
  const { defect, onAddInspectionSubmit } = useContext(DefectDetailsContext);

  const { t: translation } = useTranslation();

  const formSchema = yup.object().shape({
    date: yup.date().required(translation("app.common.dateIsRequired")),
    comment: yup
      .string()
      .nullable()
      .required(translation("app.common.commentIsRequired")),
    status: yup
      .string()
      .nullable()
      .required(translation("app.common.statusIsRequired")),
    nextinspectiondate: yup
      .date()
      .required(translation("app.common.nextInspectionDateIsRequired")),
  });

  const [submitting, setSubmitting] = useState<boolean>(false);

  const emptyForm = {
    date: new Date(),
    comment: "",
    status: "",
    nextinspectiondate: moment()
      .add(
        (defect.isTopPriority
          ? activeEntityParameter?.nextInspectionDatePriority
          : activeEntityParameter?.nextInspectionDate) || 14,
        "day"
      )
      .toDate(),
    attachments: [],
  };

  const [addInspectionForm, setAddInspectionForm] =
    useState<AddInspectionGridForm>(emptyForm);

  const handleSaveValidated = () => {
    if (!submitting) {
      setSubmitting(true);
      onAddInspectionSubmit(addInspectionForm)
        .then((success) => {
          if (success) {
            props.onDismiss();
            setAddInspectionForm(emptyForm);
          }
          setSubmitting(false);
        })
        .catch((e) => {
          setSubmitting(false);
        });
    }
  };

  const [validationError, , , saveWithValidate] =
    useValidate<AddInspectionGridForm>(
      addInspectionForm,
      formSchema,
      handleSaveValidated
    );

  const handleSaveClick = () => {
    saveWithValidate(undefined);
  };

  const handleFormGridStateChange = (
    fieldName: keyof AddInspectionGridForm,
    value: unknown
  ) => {
    setAddInspectionForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const formItems: NxpFormGridItemProps<AddInspectionGridForm>[] = [
    {
      controlType: "datePicker",
      label: translation("app.common.date"),
      itemFieldName: "date",
      span: 8,
      controlProps: {
        disabledDate: (current) => {
          return current < moment(Number(defect.createdAt));
        },
      },
    },
    {
      controlType: "input",
      label: translation("app.common.comment"),
      itemFieldName: "comment",
      span: 24,
    },
    {
      controlType: "select",
      controlProps: {
        allowClear: true,
        options:
          defect.messages.filter((message) => message.type === "inspection")
            .length > 0
            ? [
                {
                  label: translation("app.common.open"),
                  value: "open",
                },
                {
                  label: translation("app.common.markClosed"),
                  value: "mark-closed",
                },
              ]
            : [
                {
                  label: translation("app.common.open"),
                  value: "open",
                },
              ],
      },
      label: translation("app.common.status"),
      itemFieldName: "status",
      span: 12,
      startOnNewRow: true,
    },
    {
      controlType: "datePicker",
      label: translation("app.common.nextInspectionDate"),
      itemFieldName: "nextinspectiondate",
      controlProps: {
        disabledDate: (current) => {
          return current < moment(Number(defect.createdAt));
        },
      },
      span: 8,
    },
    {
      controlType: "custom",
      label: translation("app.common.attachments"),
      itemFieldName: "attachments",
      customContent: (
        <Upload
          name="file"
          multiple={true}
          action={`${serviceConfig.apiBaseUrl}/entities/${entityId}/files`}
          headers={{
            Authorization: `Bearer ${serviceConfig.token}`,
          }}
          onChange={(info) => {
            const { status } = info.file;
            if (status !== "uploading") {
              console.log(info.fileList);
            }

            if (status === "done") {
              const file: File = info.file.response as File;
              handleFormGridStateChange("attachments", [
                ...addInspectionForm.attachments,
                file.id,
              ]);
            } else if (status === "error") {
              notify.error(`${info.file.name} upload failed.`);
            } else if (status === "removed") {
              const file: File = info.file.response as File;
              handleFormGridStateChange("attachments", [
                addInspectionForm.attachments.filter(
                  (fileId) => fileId !== file.id
                ),
              ]);
            }
          }}
        >
          <NxpButton icon={<UploadOutlined />} type="default">
            {translation("app.common.clickToUpload")}
          </NxpButton>
        </Upload>
      ),
      span: 12,
    },
  ];
  return (
    <NxpModal
      title={translation("app.common.inspection.addInspection.addInspection")}
      destroyOnClose={true}
      visible={props.visible}
      onCancel={() => {
        if (!submitting) props.onDismiss();
      }}
      footer={
        <NxpButton onClick={handleSaveClick}>
          {translation("app.common.save")}
        </NxpButton>
      }
      width="small"
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={addInspectionForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddInspectionContainer;
