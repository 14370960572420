import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory, useParams } from "react-router-dom";

import {
  CaretDownFilled,
  DownloadOutlined,
  FilterFilled,
} from "@ant-design/icons";
import {
  NxpButton,
  NxpHeader,
  NxpTabs,
  TabPane,
} from "@nexploretechnology/nxp-ui";
import { Dropdown, Menu, Tree } from "antd";

import { AppDefectListItem } from "../../components/AppDefectList/AppDefectListLayout";
import useAppContext from "../../hooks/useAppContext";
import { User } from "../../services/app";
import { pxToRem } from "../../theme.config";
import AddDefectButton from "./AddDefect/AddDefectButton";
import Calendar from "./Calendar";
import Defects from "./Defects";
import Summary from "./Summary";

interface InspectorsLayoutLayoutProps {
  activeTab: string;
  blockedFilterFields: (keyof AppDefectListItem)[];
  extraQueries: string[];
  filterModalVisible: boolean;
  inspector: User | undefined;
  inspectors: User[] | undefined;
  invalidateAt: number;
  queryString: string;
  handleDefectCreated: () => void;
  handleExportExcel: () => Promise<void>;
  handleFilterApply: (queryStrings: string[]) => void;
  handleItemSelect: (selectedKeys: React.Key[], info: any) => void;
  handleMenuClick: (e: any) => void;
  handleSortingChange: (sortBy: string, order: "asc" | "desc") => void;
  handleTabChange: ((activeKey: string) => void) | undefined;
  setFilterModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = createUseStyles((theme) => ({
  root: {
    margin: "-16px -40px",
    display: "flex",
  },
  header: {
    marginLeft: "25px",
    fontWeight: "bold",
  },
  tree: {
    background: "#FFFFFFFF",
    padding: "40px 20px",
    width: 450,
    height: "calc(100vh - 85px)",
  },
  content: {
    width: "100%",
    height: "calc(100vh - 85px)",
    padding: "16px 40px",
    overflowY: "scroll",
  },
  filterCounter: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.backgroundPrimaryLight,
    color: theme.palette.primary,
    borderRadius: theme.pxToRem(5),
    width: theme.pxToRem(20),
    height: pxToRem(20),
    margin: theme.spacing(0, 1),
  },
  dropdown: {
    border: `${theme.pxToRem(1)} solid ${theme.palette.borderLight}`,
    "& .ant-dropdown-menu-item": {
      color: theme.palette.primary,
      fontWeight: theme.fontWeight.bold,
      fontSize: theme.pxToRem(12),
    },
  },
}));

const InspectorsLayoutLayout: React.FC<InspectorsLayoutLayoutProps> = ({
  activeTab,
  blockedFilterFields,
  extraQueries,
  filterModalVisible,
  inspector,
  inspectors,
  invalidateAt,
  queryString,
  handleDefectCreated,
  handleExportExcel,
  handleFilterApply,
  handleItemSelect,
  handleMenuClick,
  handleSortingChange,
  handleTabChange,
  setFilterModalVisible,
}) => {
  const classes = useStyles();

  const appContext = useAppContext();
  const history = useHistory();
  const { hasRight } = appContext;

  const { t: translation } = useTranslation();

  const routeParam = useParams<{
    entityId: string;
    locationGroupId: string;
    subcontractorId: string;
  }>();

  const menu = (
    <Menu onClick={handleMenuClick} className={classes.dropdown}>
      <Menu.Item key="true">
        {translation("app.common.exportIncPhotos")}
      </Menu.Item>
      <Menu.Item key="false">
        {translation("app.common.exportExcPhotos")}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className={classes.root}>
        <div className={classes.tree}>
          <p className={classes.header}>
            {translation("app.common.inspectors")}
          </p>
          <Tree onSelect={handleItemSelect}>
            {inspectors?.map((inspector) => (
              <Tree.TreeNode key={inspector.id} title={inspector.name} />
            ))}
          </Tree>
        </div>
        <div className={classes.content}>
          {inspector && (
            <>
              <NxpHeader
                titleContent={inspector?.name}
                actionContent={
                  <>
                    {activeTab === "defects" && (
                      <>
                        {hasRight("defect-view") && (
                          <NxpButton
                            type="default"
                            icon={<FilterFilled />}
                            onClick={() => setFilterModalVisible(true)}
                          >
                            {extraQueries?.length > 0 && (
                              <div className={classes.filterCounter}>
                                <div>{extraQueries?.length}</div>
                              </div>
                            )}
                          </NxpButton>
                        )}

                        {hasRight("defect-view") && (
                          <NxpButton
                            type="primary"
                            icon={<DownloadOutlined />}
                            onClick={handleExportExcel}
                          >
                            {translation("app.common.exportToExcel")}
                          </NxpButton>
                        )}

                        {hasRight("report-view") && (
                          <Dropdown overlay={menu}>
                            <NxpButton type="primary">
                              {translation("app.common.report")}{" "}
                              <CaretDownFilled />
                            </NxpButton>
                          </Dropdown>
                        )}

                        {hasRight("defect-add") && (
                          <AddDefectButton
                            onDefectCreated={handleDefectCreated}
                            inspector={inspector}
                          />
                        )}
                      </>
                    )}
                  </>
                }
              />
              <NxpTabs defaultActiveKey="summary" onChange={handleTabChange}>
                <TabPane tab={translation("app.common.summary")} key="summary">
                  <Summary inspector={inspector!} />
                </TabPane>
                <TabPane
                  tab={translation("inspectors.calendar.label")}
                  key="calendar"
                >
                  <Calendar inspector={inspector}></Calendar>
                </TabPane>
                <TabPane tab={translation("app.common.defects")} key="defects">
                  <Defects
                    blockedFilterFields={blockedFilterFields}
                    filterModalVisible={filterModalVisible}
                    invalidateAt={invalidateAt}
                    queryString={queryString}
                    handleFilterApply={handleFilterApply}
                    handleSortingChange={handleSortingChange}
                    setFilterModalVisible={setFilterModalVisible}
                    handleDefectClick={(defectId) =>
                      history.push(
                        `/entities/${routeParam?.entityId}/location-groups/${routeParam?.locationGroupId}/defects/${defectId}`
                      )
                    }
                  />
                </TabPane>
              </NxpTabs>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default InspectorsLayoutLayout;
