import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { NxpButton } from "@nexploretechnology/nxp-ui";
import { useValidate } from "../../../hooks/useValidate";
import CustomApiError from "../../../utils/backend/customApiError";
import { createDefect, DefectCreateForm } from "../../../services/defect";
import { NxpFormGrid, NxpFormGridItemProps } from "@nexploretechnology/nxp-ui";
import { NxpModal } from "@nexploretechnology/nxp-ui";
import notify from "../../../utils/notify";
import useAppContext from "../../../hooks/useAppContext";
import moment from "moment";
import _ from "lodash";
import { DateFormatPattern } from "@nexploretechnology/nxp-ui";
import { EntityParameter } from "../../../services/app";

import { useTranslation } from "react-i18next";

interface AddDefectModalProps {
  showModal: boolean;
  handleDialogClose: () => void;
  onDefectCreated?: () => void;
}

const AddDefectContainer: React.FC<AddDefectModalProps> = ({
  showModal,
  handleDialogClose,
  onDefectCreated = () => {},
}) => {
  const appContext = useAppContext();
  const {
    activeEntity,
    activeEntityParameter,
    activeLocationGroup,
    defectGroups,
    defectTypes,
    inspectionTypes,
    routeParams,
    serviceConfig,
    subcontractors,
    users,
  } = appContext;
  const { locationId } = routeParams;

  const { t: translation } = useTranslation();

  const formSchema = yup.object().shape({
    location: yup
      .string()
      .nullable()
      .required(translation("app.common.locationIsRequired")),
    defectType: yup
      .string()
      .nullable()
      .required(translation("app.common.defectTypeIsRequired")),
    inspectionType: yup
      .string()
      .nullable()
      .required(translation("app.common.inspectionTypeIsRequired")),
    defectGroup: yup
      .string()
      .nullable()
      .required(translation("app.common.defectGroupIsRequired")),
    responsibleParty: yup
      .string()
      .nullable()
      .required(translation("app.common.responsiblePartyIsRequired")),
    responsiblePerson: yup
      .string()
      .nullable()
      .required(translation("app.common.responsiblePersonIsRequired")),
    defectSubject: yup
      .string()
      .nullable()
      .required(translation("app.common.defectSubjectIsRequired")),
    defectDescription: yup
      .string()
      .nullable()
      .required(translation("app.common.defectDescriptionIsRequired")),
    remarks: yup
      .string()
      .nullable()
      .required(translation("app.common.defectRemarkIsRequired")),
    estimatedCost: yup
      .number()
      .nullable()
      .required(translation("app.common.estimatedCostIsRequired")),
    nextInspectionDate: yup
      .string()
      .nullable()
      .required(translation("app.common.nextInspectionDateIsRequired")),
    dueDate: yup
      .string()
      .nullable()
      .required(translation("app.common.dueDateIsRequired")),
  });

  const [defectForm, setDefectForm] = useState<Partial<DefectCreateForm>>({
    isTopPriority: "false",
  });
  function setDate(
    key: keyof EntityParameter,
    fieldName: keyof DefectCreateForm
  ) {
    handleFormGridStateChange(
      fieldName,
      moment()
        .add(_.get(activeEntityParameter, key, 14), "day")
        .format(DateFormatPattern.date)
    );
  }
  useEffect(() => {
    if (showModal) {
      if (_.isEmpty(defectForm?.defectDescription)) {
        handleFormGridStateChange("isTopPriority", "false");
        setDefectForm((form) => {
          const result = { ...form };
          if (locationId) {
            result.location = "" + locationId;
          }
          setDate("dueDate", "dueDate");
          setDate("nextInspectionDate", "nextInspectionDate");

          return result;
        });
      }
    }
    return () => {
      setDefectForm({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeEntityParameter?.dueDate,
    activeEntityParameter?.nextInspectionDate,
    locationId,
    showModal,
    onDefectCreated,
  ]);
  useEffect(() => {
    if (!_.isEmpty(defectForm?.isTopPriority)) {
      if (defectForm?.isTopPriority === "false") {
        setDate("dueDate", "dueDate");
        setDate("nextInspectionDate", "nextInspectionDate");
      } else if (defectForm?.isTopPriority === "true") {
        setDate("dueDatePriority", "dueDate");
        setDate("nextInspectionDatePriority", "nextInspectionDate");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defectForm?.isTopPriority]);

  const formItems: NxpFormGridItemProps<Partial<DefectCreateForm>>[] = [
    {
      controlType: "treeSelect",
      controlProps: {
        allowClear: true,
        options: {
          ...activeLocationGroup,
          ...{
            title: activeLocationGroup?.name,
            value: activeLocationGroup?.id.toString(),
          },
        },
      },
      label: translation("app.common.location"),
      itemFieldName: "location",
      span: 12,
      required: true,
    },
    {
      controlType: "select",
      label: translation("app.common.defectType"),
      itemFieldName: "defectType",
      span: 12,
      controlProps: {
        allowClear: true,
        options: defectTypes.map((type) => ({
          label: type?.name,
          value: type?.id,
        })),
      },

      required: true,
    },
    {
      controlType: "select",
      label: translation("app.common.inspectionType"),
      itemFieldName: "inspectionType",
      span: 12,
      controlProps: {
        allowClear: true,
        options: inspectionTypes.map((type) => ({
          label: type?.name,
          value: type?.id,
        })),
      },
      startOnNewRow: true,
      required: true,
    },
    {
      controlType: "select",
      controlProps: {
        allowClear: true,
        onClear: () => {
          handleFormGridStateChange("defectSubject", undefined);
          handleFormGridStateChange("defectDescription", undefined);
        },
        onChange: (e) => {
          handleFormGridStateChange("defectSubject", undefined);
          handleFormGridStateChange("defectDescription", undefined);
          handleFormGridStateChange("defectGroup", e);
        },
        options: defectGroups.map((type) => ({
          label: type?.name,
          value: type?.id,
          key: type?.code,
        })),
      },
      label: translation("app.common.defectGroup"),
      itemFieldName: "defectGroup",
      span: 12,
      required: true,
    },
    {
      controlType: "select",
      label: translation("app.common.subject"),
      itemFieldName: "defectSubject",
      controlProps: {
        allowClear: true,
        disabled: defectForm.defectGroup === undefined,
        onChange: (e) => {
          handleFormGridStateChange("defectDescription", undefined);
          handleFormGridStateChange("defectSubject", e);
        },
        options:
          defectForm.defectGroup !== undefined
            ? defectGroups
                ?.filter((grp) => grp.id === Number(defectForm?.defectGroup))[0]
                ["defectSubjects"].map((opt) => ({
                  label: opt?.name,
                  value: opt?.id,
                }))
            : [],
      },
      span: 12,
      required: true,
    },
    {
      controlType: "select",
      label: translation("app.common.description"),
      itemFieldName: "defectDescription",
      span: 12,
      required: true,
      controlProps: {
        allowClear: true,
        disabled:
          defectForm.defectGroup === undefined ||
          defectForm.defectSubject === undefined,
        options:
          defectForm.defectGroup !== undefined &&
          defectForm.defectSubject !== undefined
            ? defectGroups
                ?.filter((grp) => grp.id === Number(defectForm?.defectGroup))[0]
                ["defectSubjects"].filter(
                  (group) => group?.id === Number(defectForm?.defectSubject)
                )[0]
                ["defectDescriptions"].map((opt) => ({
                  label: opt?.name,
                  value: opt?.id,
                }))
            : [],
      },
    },
    {
      controlType: "select",
      controlProps: {
        allowClear: true,
        options:
          defectForm.defectGroup !== undefined &&
          defectForm.defectSubject !== undefined &&
          defectForm.defectDescription !== undefined &&
          _.get(
            defectGroups
              ?.filter((grp) => grp.id === Number(defectForm?.defectGroup))[0]
              ["defectSubjects"].filter(
                (group) => group?.id === Number(defectForm?.defectSubject)
              )[0]
              ["defectDescriptions"].filter(
                (description) =>
                  description?.id === Number(defectForm?.defectDescription)
              )[0],
            "subcontractors",
            []
          ).length
            ? defectGroups
                ?.filter((grp) => grp.id === Number(defectForm?.defectGroup))[0]
                ["defectSubjects"].filter(
                  (group) => group?.id === Number(defectForm?.defectSubject)
                )[0]
                ["defectDescriptions"].filter(
                  (description) =>
                    description?.id === Number(defectForm?.defectDescription)
                )[0]
                ["subcontractors"]?.map((opt) => {
                  return {
                    label: opt?.name,
                    value: opt?.id,
                  };
                })
            : subcontractors?.map((subcontractor) => ({
                label: subcontractor?.name,
                value: subcontractor?.id,
              })),
      },
      label: translation("app.common.responsibleParty"),
      itemFieldName: "responsibleParty",
      span: 12,
      required: true,
    },
    {
      controlType: "select",
      label: translation("app.common.responsiblePerson"),
      itemFieldName: "responsiblePerson",
      controlProps: {
        allowClear: true,
        options: users.map((user) => ({
          label: user?.name,
          value: user?.id,
        })),
      },
      span: 12,
      required: true,
    },
    {
      controlType: "textarea",
      label: translation("app.common.remarks"),
      itemFieldName: "remarks",
      required: true,
      span: 24,
      controlProps: {
        allowClear: true,
      },
    },
    {
      controlType: "inputNumber",
      label: translation("app.common.estimatedCost"),
      itemFieldName: "estimatedCost",
      controlProps: {
        decimalPlace: 0,
        id: "estimatedCost",
        prefix: "$",
        keyboard: true,
      },
      required: true,
      span: 12,
    },
    {
      controlType: "radioGroup",
      label: translation("app.common.topPriority"),
      itemFieldName: "isTopPriority",
      span: 12,
      controlProps: {
        options: [
          { label: translation("app.common.yes"), value: "true" },
          { label: translation("app.common.no"), value: "false" },
        ],
      },
    },
    {
      controlType: "datePicker",
      label: translation("app.common.nextInspectionDate"),
      controlProps: {
        disabledDate: (current) => {
          return current < moment().startOf("day");
        },
      },
      itemFieldName: "nextInspectionDate",
      required: true,
      span: 12,
    },
    {
      controlType: "datePicker",
      label: translation("app.common.dueDate"),
      controlProps: {
        disabledDate: (current) => {
          return current < moment().startOf("day");
        },
      },
      itemFieldName: "dueDate",
      required: true,
      span: 12,
    },
  ];

  const handleSaveValidated = async () => {
    if (activeLocationGroup?.id && activeEntity?.id) {
      try {
        const defect = await createDefect(
          activeEntity?.id,
          activeLocationGroup?.id,
          {
            ...defectForm,
            ...{
              location: Number(defectForm?.location),
              defectDescription: Number(defectForm?.defectDescription),
              responsiblePerson: defectForm?.responsiblePerson!,
              isTopPriority: defectForm?.isTopPriority === "true",
            },
          },
          serviceConfig
        );
        if (defect) {
          notify.success(
            translation("defectListing.addDefect.handleSaveValidated.success")
          );
          onDefectCreated();
        }
      } catch (e) {
        if (e instanceof CustomApiError) {
          const apiError: CustomApiError = e;
          if (apiError.status === 403) {
            notify.error(translation("app.common.errorOccurred"));
          } else {
            notify.error(apiError);
          }
        } else {
          notify.error(e);
        }
      }
    }
  };

  const [validationError, , , saveWithValidate] = useValidate<
    Partial<DefectCreateForm>
  >(defectForm, formSchema, handleSaveValidated);

  const handleSave = () => {
    return (
      Object.values(saveWithValidate(undefined)).filter(
        (val) => val !== undefined
      ).length === 0
    );
  };

  const handleFormGridStateChange = (
    fieldName: keyof typeof defectForm,
    value: unknown
  ) => {
    setDefectForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleModalSave = () => {
    if (handleSave()) {
      handleDialogClose();
    }
  };
  return (
    <NxpModal
      width="large"
      title={translation("app.common.addDefects")}
      visible={showModal}
      showMandatoryLabel
      onCancel={handleDialogClose}
      footer={
        <NxpButton onClick={handleModalSave}>
          {translation("app.common.save")}
        </NxpButton>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={defectForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddDefectContainer;
