import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Card, Col, Modal, Row } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import moment from "moment";
import { File } from "../../../services/app";
import { NxpButton } from "@nexploretechnology/nxp-ui";
import FileSaver from "file-saver";
import useAppContext from "../../../hooks/useAppContext";

import { useTranslation } from "react-i18next";

interface Props {
  file: File;
  onDelete: (file: File) => Promise<boolean>;
}

const useStyles = createUseStyles((theme) => ({
  card: {
    marginBottom: 10,
    "& .ant-card-body": {
      padding: 8,
      verticalAlign: "bottom",
    },
    "& .ant-col": {
      lineHeight: 2,
      verticalAlign: "bottom",
      whiteSpace: "nowrap",
      overflow: "hidden",
      paddingRight: 16,
    },
  },
  nameButton: {
    verticalAlign: "bottom",
    maxWidth: "100%",
    "& span": {
      verticalAlign: "bottom",
      maxWidth: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

const FileListItem: React.FC<Props> = ({ file, onDelete }) => {
  const classes = useStyles();
  const { hasRight } = useAppContext();

  const { t: translation } = useTranslation();

  const handleDownload = async () => {
    let response: Response;
    try {
      response = await fetch(file.originalUrl, {
        credentials: "same-origin",
        headers: {
          Pragma: "no-cache",
          "Cache-Control": "no-cache",
        },
      });
    } catch (e) {
      throw new Error(
        `Error occured :${e instanceof Error ? e.message : `${e}`}`
      );
    }
    if (response?.status === 200) {
      const blob = await response.blob();

      FileSaver.saveAs(blob, file.name);
    } else {
      throw new Error(`Error ${response?.status} occurred`);
    }
  };

  const handleDelete = () => {
    Modal.confirm({
      title: "Delete File",
      icon: <ExclamationCircleOutlined />,
      okText: "Confirm",
      cancelText: "Cancel",
      content: `Do you want to delete the file?`,
      onOk: async () => {
        await onDelete(file);
      },
      onCancel: () => {},
    });
  };

  return (
    <Card className={classes.card}>
      <Row>
        <Col span={10}>
          <NxpButton
            className={classes.nameButton}
            type="link"
            onClick={handleDownload}
          >
            {file.name}
          </NxpButton>
        </Col>
        <Col span={6}>
          {moment(new Date(parseInt(file.createdAt))).format("DD-MMM-YYYY")}
        </Col>
        <Col span={6}>{file.uploadedBy.name}</Col>
        <Col span={2}>
          {hasRight("location-file-delete") && (
            <NxpButton type="link" danger onClick={handleDelete}>
              {translation("app.common.delete")}
            </NxpButton>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default FileListItem;
