import moment from "moment";
import { DefectStatistics, getDefectStatistics } from "../../services/app";
import { ServiceConfig } from "../../utils/backend";
import { formatNumber } from "../../utils/formatNumber";
import { SubcontractorStatistic } from "./SubcontractorReducer";

export const defaultSubcontractorStatistics: SubcontractorStatistic[] = [
  {
    key: "Related Defects",
    title: "app.common.relatedDefects",
    value: "0",
    get: "count",
    query: `?responsiblePartyId={subconId}&locationGroupId={locationGroupId}&statusNot=cancelled`,
  },
  {
    key: "Fixed count",
    title: "app.common.fixed",
    value: "0",
    get: "count",
    query: `?responsiblePartyId={subconId}&locationGroupId={locationGroupId}&status=closed`,
  },
  {
    key: "Outstanding",
    title: "app.common.outstanding",
    value: "0",
    get: "count",
    query: `?responsiblePartyId={subconId}&locationGroupId={locationGroupId}&statusNot=closed,cancelled&dueDateGte={tomorrow}`,
  },
  {
    key: "Overdue count",
    title: "app.common.overdue",
    value: "0",
    get: "count",
    query: `?responsiblePartyId={subconId}&locationGroupId={locationGroupId}&statusNot=closed,cancelled&dueDateLte={today}`,
  },
  {
    key: "Average days",
    title: "app.common.averageDays",
    value: "0",
    get: "averageDuration",
    query: `?responsiblePartyId={subconId}&locationGroupId={locationGroupId}&status=closed`,
  },
  {
    key: "Total Defects",
    title: "app.common.totalDefects",
    value: "0",
    get: "totalCost",
    query: `?responsiblePartyId={subconId}&locationGroupId={locationGroupId}&statusNot=cancelled`,
  },
  {
    key: "Fixed totalCost",
    title: "app.common.fixed",
    value: "0",
    get: "totalCost",
    query: `?responsiblePartyId={subconId}&locationGroupId={locationGroupId}&status=closed`,
  },
  {
    key: "Outstanding",
    title: "app.common.outstanding",
    value: "0",
    get: "totalCost",
    query: `?responsiblePartyId={subconId}&locationGroupId={locationGroupId}&statusNot=closed,cancelled&dueDateGte={tomorrow}`,
  },
  {
    key: "Overdue totalCost",
    title: "app.common.overdue",
    value: "0",
    get: "totalCost",
    query: `?responsiblePartyId={subconId}&locationGroupId={locationGroupId}&statusNot=closed,cancelled&dueDateLte={today}`,
  },
  {
    key: "Total $$ Backcharge",
    title: "app.common.totalBackcharge",
    value: "0",
    get: "totalBackchargeCost",
    query: `?responsiblePartyId={subconId}&locationGroupId={locationGroupId}&status=closed`,
  },
  {
    key: "Days impact",
    title: "app.common.daysImpact",
    value: "0",
    get: "totalScheduleImpact",
    query: `?responsiblePartyId={subconId}&locationGroupId={locationGroupId}&status=closed`,
  },
];

export const getSubcontractorStatistics = async (
  entityId: string | undefined,
  subcontractorId: number | undefined,
  locationGroupId: number | undefined,
  serviceConfig: ServiceConfig
): Promise<SubcontractorStatistic[]> => {
  const statistics = defaultSubcontractorStatistics.map(
    (defaultSubcontractorStatistic) => ({
      ...defaultSubcontractorStatistic,
      query: defaultSubcontractorStatistic.query
        .replace("{subconId}", "" + subcontractorId?.toString())
        .replace("{locationGroupId}", locationGroupId!.toString())
        .replace("{today}", moment().format("YYYY-MM-DD"))
        .replace("{tomorrow}", moment().add(1, "d").format("YYYY-MM-DD")),
    })
  );

  const apiResults = await Promise.all(
    statistics.map(({ query }) => {
      return getDefectStatistics(entityId ?? "", serviceConfig, query);
    })
  );

  return statistics.map((statistic, index) => {
    const apiResult = apiResults[index];
    const value = apiResult[statistic.get as keyof DefectStatistics];
    return {
      ...statistic,
      value: formatNumber(Number(value)).toString(),
    } as SubcontractorStatistic;
  });
};
