import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Route, RouteProps } from "react-router-dom";

import { NxpAuthSpinner, NxpLayoutGlobal } from "@nexploretechnology/nxp-ui";
import { useKeycloak } from "@react-keycloak/web";

import notify from "../../../utils/notify";
import AppLayout from "../AppLayout/AppLayout";
import useRouteAppContextUpdate from "./useRouteAppContextUpdate";

interface AppProtectedRouteProps extends RouteProps {
  children: ReactNode;
}

const AppProtectedRoute: React.FC<AppProtectedRouteProps> = ({
  children,
  ...restProps
}) => {
  const { t: translation } = useTranslation();
  const { keycloak } = useKeycloak();

  useRouteAppContextUpdate();
  if (keycloak?.authenticated) {
    return (
      <Route {...restProps}>
        <AppLayout>{children}</AppLayout>
      </Route>
    );
  }

  setTimeout(() => {
    if (!keycloak?.authenticated) {
      notify.error(translation("app.appRouter.authenticated.error"));
    }
  }, 15000);
  return (
    <NxpLayoutGlobal>
      <NxpAuthSpinner />
    </NxpLayoutGlobal>
  );
};

export default AppProtectedRoute;
