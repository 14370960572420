import { Col, Row } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";

interface Props {
  items: { name: string; span?: number; flex?: string }[];
  padding?: number;
}

const useStyles = createUseStyles((theme) => ({
  row: {
    marginTop: 10,
    marginBottom: 10,
  },
  span: {
    textTransform: "uppercase",
    fontSize: theme.fontSize.smaller,
    fontWeight: theme.fontWeight.bolder,
    color: "rgba(138, 153, 153, 1)",
    transform: "scale(0.9)",
    transformOrigin: "0% 50% 0",
    margin: 0,
  },
}));

const AppFakeTableHeader: React.FC<Props> = ({ items, padding = 0 }) => {
  const classes = useStyles();

  return (
    <Row
      className={classes.row}
      style={{ paddingLeft: padding, paddingRight: padding }}
    >
      {items.map((item, index) => (
        <Col span={item?.span} flex={item?.flex} key={index}>
          <div className={classes.span}>{item.name}</div>
        </Col>
      ))}
    </Row>
  );
};

export default AppFakeTableHeader;
