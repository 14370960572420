import React, { useContext, useState } from "react";
import { LocationDetailsContext } from "../LocationDetailsContainer";

// import useAppContext from "../../../hooks/useAppContext";
import TuiImageEditor from "tui-image-editor";
import AppImageEditor from "../../../components/AppImageEditor";
import { File } from "../../../services/app";
import { NxpHeader } from "@nexploretechnology/nxp-ui";
import TabBar from "../TabBar";
import { NxpButton } from "@nexploretechnology/nxp-ui";
import useAppContext from "../../../hooks/useAppContext";
import notify from "../../../utils/notify";

import { useTranslation } from "react-i18next";

interface Props {
  onActiveKeyChange: (activeKey: string) => void;
}

// const useStyles = createUseStyles((theme) => ({}));

const LocationFloorPlanEditing: React.FC<Props> = ({ onActiveKeyChange }) => {
  // const classes = useStyles();
  const { routeParams, serviceConfig } = useAppContext();
  const { entityId } = routeParams;

  const { t: translation } = useTranslation();

  const [uploading, setUploading] = useState<boolean>(false);
  const {
    location,
    onEditFloorPlanImageSave,
    onEditFloorPlanImageCancel,
    onUpdateLocation,
  } = useContext(LocationDetailsContext);

  const [editor, setEditor] = useState<TuiImageEditor | null>(null);

  // const appContext = useAppContext();

  if (!location) return null;
  if (!location.floorPlans) return null;

  const url = location.floorPlans[0]?.file?.url;

  const handleSave = async () => {
    if (!editor) return;
    const blob = await (await fetch(editor?.toDataURL())).blob();

    const data = new FormData();
    data.append("file", blob);

    const response = await fetch(
      `${serviceConfig.apiBaseUrl}/entities/${entityId}/files`,
      {
        headers: {
          Authorization: `Bearer ${serviceConfig.token}` || "",
        },
        method: "POST",
        body: data,
      }
    );

    if (Math.floor(response.status / 100) === 2) {
      const file = (await response.json()) as unknown as File;
      handleSaveSuccess(file);
    }
  };

  const handleSaveSuccess = async (file: File) => {
    setUploading(true);
    try {
      const result = await onEditFloorPlanImageSave(file);
      setUploading(false);
      if (result) onUpdateLocation();
    } catch (error) {
      notify.error(error);
      setUploading(false);
    }
  };

  return (
    <>
      <NxpHeader
        titleContent={location.name}
        actionContent={
          <>
            <NxpButton type="primary" loading={uploading} onClick={handleSave}>
              {translation("app.common.save")}
            </NxpButton>
            <NxpButton type="primary" onClick={onEditFloorPlanImageCancel}>
              {translation(
                "locationDetails.locationFloorPlanImageEditing.close"
              )}
            </NxpButton>
          </>
        }
      />
      <TabBar activeKey={"floor-plan"} onChange={onActiveKeyChange} />
      <AppImageEditor
        src={url}
        height={window.innerHeight - 240}
        onInstanceMount={(instance) => setEditor(instance)}
      />
    </>
  );
};

export default LocationFloorPlanEditing;
