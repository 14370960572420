import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import AddDescriptionModal from "./AddDescriptionModal";

interface AddDescriptionButtonProps {
  currentNode: { groupId: string; subjectId: string };
  handleAddToList: (item: any, itemType: string) => void;
}

const AddDescriptionButton: React.FC<AddDescriptionButtonProps> = ({
  currentNode,
  handleAddToList,
}) => {
  const { t: translation } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const handleModalVisible = () => {
    setModalVisible(false);
  };

  return (
    <>
      <NxpButton onClick={() => setModalVisible(true)}>
        {translation("app.common.subjectForm.addDescription.addDescription")}
      </NxpButton>
      <AddDescriptionModal
        currentNode={currentNode}
        modalVisible={modalVisible}
        handleAddToList={handleAddToList}
        handleModalVisible={handleModalVisible}
      />
    </>
  );
};

export default AddDescriptionButton;
