import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { SearchOutlined } from "@ant-design/icons";
import { NxpButton, NxpFormItem, NxpHeader } from "@nexploretechnology/nxp-ui";
import { Col, Row, Spin } from "antd";

import AppFakeTableHeader from "../../components/AppFakeTableHeader";
import useAppContext from "../../hooks/useAppContext";
import { Role } from "../../services/role";
import AddRoleModal, { AddRoleForm } from "./AddRoleModal";
import BatchAssignModal, { BatchAssignForm } from "./BatchAssignModal";
import CopyRoleModal, { CopyRoleForm } from "./CopyRoleModal";
import { EditRoleForm } from "./EditRoleModal";
import RoleAssigneeList from "./RoleAssigneeList";

interface RoleSettingLayoutProps {
  roles?: Role[];
  selected: boolean[];
  deleted: boolean[];
  userIds: string[][];
  originalUserIds: string[][];
  onRoleSelect: (role: Role, value: boolean) => void;
  onAssigneeChange: (role: Role, userIds: string[]) => void;
  onDelete: (role: Role) => Promise<boolean>;
  onSave: () => void;
  onDiscard: () => void;
  onAddRole: (form: AddRoleForm) => Promise<boolean>;
  onEditRole: (role: Role, form: EditRoleForm) => Promise<boolean>;
  onBatchAssign: (form: BatchAssignForm) => Promise<boolean>;
  onCopyRole: (form: CopyRoleForm) => Promise<boolean>;
}

const useStyles = createUseStyles((theme) => ({
  root: {
    margin: "-16px -40px",
    width: "calc(100% + 80px);",
    height: "calc(100vh - 85px)",
    overflowY: "scroll",
    padding: "16px 40px",
  },
  content: { minHeight: 300 },
}));

const RoleSettingLayout: React.FC<RoleSettingLayoutProps> = ({
  roles,
  selected,
  deleted,
  userIds,
  originalUserIds,
  onRoleSelect,
  onAssigneeChange,
  onDelete,
  onSave,
  onDiscard,
  onAddRole,
  onEditRole,
  onBatchAssign,
  onCopyRole,
}) => {
  const classes = useStyles();
  const { routeParams, hasRight } = useAppContext();
  const { locationGroupId } = routeParams;

  const { t: translation } = useTranslation();

  const [filter, setFilter] = useState<string>("");
  const [showAddRoleModal, setShowAddRoleModal] = useState<boolean>(false);
  const [showBatchAssignModal, setShowBatchAssignModal] =
    useState<boolean>(false);
  const [showCopyRoleModal, setShowCopyRoleModal] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      <Spin spinning={roles === undefined}>
        <NxpHeader
          titleContent={`${translation("roleSetting.manageRoles")} (${
            locationGroupId
              ? translation("app.common.locationGroup")
              : translation("app.common.module")
          })`}
          actionContent={
            <>
              <Row gutter={10}>
                <Col>
                  <NxpFormItem
                    controlType={"input"}
                    controlProps={{
                      value: filter,
                      onChange: (e) => setFilter(e.target.value),
                      prefix: <SearchOutlined />,
                      placeholder: translation("app.common.search"),
                    }}
                  />
                </Col>
                {hasRight("role-add") && (
                  <Col>
                    <NxpButton
                      color="primary"
                      onClick={() => setShowAddRoleModal(true)}
                    >
                      {translation("roleSetting.addRole")}
                    </NxpButton>
                    <AddRoleModal
                      show={showAddRoleModal}
                      onAddRole={onAddRole}
                      onClose={() => setShowAddRoleModal(false)}
                    />
                  </Col>
                )}
                {locationGroupId && (
                  <>
                    <Col>
                      <NxpButton
                        color="primary"
                        onClick={() => setShowCopyRoleModal(true)}
                      >
                        {translation("roleSetting.copyAccess")}
                      </NxpButton>
                    </Col>
                    <CopyRoleModal
                      show={showCopyRoleModal}
                      onClose={() => setShowCopyRoleModal(false)}
                      onCopyRole={onCopyRole}
                    />
                  </>
                )}
                {hasRight("role-assign") && selected.some((x) => x) && (
                  <Col>
                    <NxpButton
                      color="primary"
                      onClick={() => setShowBatchAssignModal(true)}
                    >
                      {translation("roleSetting.batchAssign")}
                    </NxpButton>
                    <BatchAssignModal
                      show={showBatchAssignModal}
                      onBatchAssign={onBatchAssign}
                      onClose={() => setShowBatchAssignModal(false)}
                    />
                  </Col>
                )}
              </Row>
            </>
          }
        />
        <div className={classes.content}>
          <AppFakeTableHeader
            items={[
              { name: " ", span: 1 },
              { name: translation("roleSetting.role"), span: 5 },
              { name: translation("app.common.user"), span: 17 },
            ]}
            padding={24}
          />
          {roles &&
            roles
              .filter((role) => role.name.indexOf(filter) !== -1)
              .map((role, index) =>
                !deleted[index] ? (
                  <RoleAssigneeList
                    key={`RoleAssigneeList_${role.id}`}
                    role={role}
                    selected={selected[index]}
                    userIds={userIds[index]}
                    originalUserIds={originalUserIds[index]}
                    onRoleSelect={(value) => onRoleSelect(role, value)}
                    onAssigneeChange={onAssigneeChange}
                    onEditRole={async (
                      form: EditRoleForm
                    ): Promise<boolean> => {
                      return await onEditRole(role, form);
                    }}
                    onDelete={onDelete}
                  />
                ) : null
              )}
        </div>
        {hasRight("role-assign") && (
          <Row gutter={10} justify="end">
            <Col>
              <NxpButton color="primaryLight" onClick={onDiscard}>
                {translation("roleSetting.discardChanges")}
              </NxpButton>
            </Col>

            <Col>
              <NxpButton color="primary" onClick={onSave}>
                {translation("app.common.saveChanges")}
              </NxpButton>
            </Col>
          </Row>
        )}
      </Spin>
    </div>
  );
};

export default RoleSettingLayout;
