import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import { DefectDetailsContext } from "../../DefectDetailsContainer";
import AddInspectionModal from "./AddInspectionModal";

interface AddInspectionButtonProps {}

const AddInspectionButton: React.FC<AddInspectionButtonProps> = (props) => {
  const { defect } = useContext(DefectDetailsContext);
  const [modalVisible, setModalVisible] = useState(false);
  const handleModalVisible = () => {
    setModalVisible(false);
  };
  const { t: translation } = useTranslation();

  return (
    <>
      <NxpButton
        onClick={() => setModalVisible(true)}
        disabled={["closure", "cancelled"].includes(defect.status)}
      >
        {translation("app.common.inspection.addInspection.addInspection")}
      </NxpButton>
      <AddInspectionModal
        visible={modalVisible}
        onDismiss={handleModalVisible}
      />
    </>
  );
};

export default AddInspectionButton;
