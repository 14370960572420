import React, { useEffect, useState } from "react";

import { Defect } from "../../../services/defect";

import CostLayout from "./CostLayout";

import { useTranslation } from "react-i18next";

interface CostContainerProps {
  defects: Defect[];
}

const CostContainer: React.FC<CostContainerProps> = ({ defects }) => {
  const { t: translation } = useTranslation();

  let initialCost: { key: string; title: string; value: string }[] = [
    {
      key: "Total Defects",
      title: translation("app.common.totalDefects"),
      value: "0",
    },
    {
      key: "Fixed",
      title: translation("app.common.fixed"),
      value: "0",
    },
    {
      key: "Outstanding",
      title: translation("app.common.outstanding"),
      value: "0",
    },
    {
      key: "Overdue",
      title: translation("app.common.overdue"),
      value: "0",
    },
  ];

  const [cost, setCost] = useState(initialCost);

  useEffect(() => {
    initialCost[0].value = (
      defects
        .filter(
          (defect) =>
            defect?.status !== "closed" && defect?.status !== "cancelled"
        )
        .map((defect) => defect.estimatedCost)
        .reduce(
          (previousValue, currentValue) => previousValue + Number(currentValue),
          0
        ) +
      defects
        .filter((defect) => defect?.status === "closed")
        .map((defect) => defect.analysis?.actualCost!)
        .reduce(
          (previousValue, currentValue) => previousValue + Number(currentValue),
          0
        )
    ).toString();

    initialCost[1].value = defects
      .filter((defect) => defect?.status === "closed")
      .map((defect) => defect.analysis!.actualCost)
      .reduce(
        (previousValue, currentValue) => previousValue + Number(currentValue),
        0
      )
      .toString();

    initialCost[2].value = defects
      .filter(
        (defect) =>
          defect?.status !== "closed" &&
          defect?.status !== "cancelled" &&
          new Date(defect.dueDate) >= new Date()
      )
      .map((defect) => defect.estimatedCost)
      .reduce(
        (previousValue, currentValue) => previousValue + Number(currentValue),
        0
      )
      .toString();

    initialCost[3].value = defects
      .filter(
        (defect) =>
          defect?.status !== "closed" &&
          defect?.status !== "cancelled" &&
          new Date(defect.dueDate) <= new Date()
      )
      .map((defect) => defect.estimatedCost)
      .reduce(
        (previousValue, currentValue) => previousValue + Number(currentValue),
        0
      )
      .toString();

    setCost([...initialCost]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defects]);

  return <CostLayout cost={[...cost]} />;
};

export default CostContainer;
