import React from "react";
import { createUseStyles } from "react-jss";
import { Card } from "antd";
import { useTranslation } from "react-i18next";

interface DefectGroupLayoutProps {
  data: {
    group: string;
    total: string;
  }[];
}

const useStyles = createUseStyles(() => ({
  cloudCard: {
    textAlign: "center",
    width: "500px",
    margin: "10px 10px 0px 0px",
    height: "fit-content",
    "& p": {
      display: "inline-block",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "16px",
      color: "#005794",
      margin: "10px ",
    },
  },
}));

const DefectGroupLayout: React.FC<DefectGroupLayoutProps> = ({ data }) => {
  const classes = useStyles();

  const { t: translation } = useTranslation();

  return (
    <Card className={classes.cloudCard}>
      <h1>{translation("app.common.defectGroup")}</h1>
      {data.map((item, index) => (
        <p key={index}>
          {item.group} ({item.total})
        </p>
      ))}
    </Card>
  );
};

export default DefectGroupLayout;
