export interface CustomApiErrorServerResponseData {
  message: string;
  [key: string]: string | string[];
}

export default class CustomApiError extends Error {
  public date: Date;
  public constructor(
    /* eslint-disable */
    // Definition for rule '@typescript-eslint/no-parameter-proper' was not found
    public path: string,
    public requestInit: RequestInit,
    public status?: number,
    public statusText?: string,
    public serverResponseData?: CustomApiErrorServerResponseData,
    public innerError?: Error | unknown,
    public retryCount?: number,
    public retryError?: Error | unknown,
    public stopRetry: boolean = false /* eslint-enable */
  ) {
    super(`Api request error ${status}`);

    if (serverResponseData) {
      this.message = serverResponseData.message;

      // error format not same as voc, should work out how to get the field errors later
      // Object.assign(this.serverResponseData, {
      //   ...JSON.parse(serverResponseData.message?.replace(/'/g, '"') || '{}'),
      // });
    } else if (innerError && innerError instanceof Error) {
      this.message += ": " + innerError.message;
    } else if (innerError) {
      this.message += `: ${innerError}`;
    } else if (statusText) {
      this.message += ": " + statusText;
    }

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomApiError);
    }
    this.name = "CustomApiError";
    this.date = new Date();
  }
}
