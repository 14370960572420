import React from "react";
import { AppDefectListItem } from "../../../components/AppDefectList/AppDefectListLayout";

import DefectsLayout from "./DefectsLayout";

interface DefectsContainerProps {
  blockedFilterFields: (keyof AppDefectListItem)[];
  filterModalVisible: boolean;
  invalidateAt: number;
  queryString: string;
  handleFilterApply: (queryStrings: string[]) => void;
  handleSortingChange: (sortBy: string, order: "asc" | "desc") => void;
  setFilterModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleDefectClick?: (defectId: number) => void;
}

const DefectsContainer: React.FC<DefectsContainerProps> = ({
  filterModalVisible,
  invalidateAt,
  queryString,
  handleFilterApply,
  handleSortingChange,
  setFilterModalVisible,
  handleDefectClick,
}) => {
  return (
    <DefectsLayout
      filterModalVisible={filterModalVisible}
      invalidateAt={invalidateAt}
      queryString={queryString}
      handleFilterApply={handleFilterApply}
      handleSortingChange={handleSortingChange}
      setFilterModalVisible={setFilterModalVisible}
      handleDefectClick={handleDefectClick}
    />
  );
};

export default DefectsContainer;
