import moment from "moment";
import React, { useState } from "react";
import { AppDefectListItem } from "../AppDefectList/AppDefectListLayout";
import FilterModalLayout from "./AppDefectFilterModalLayout";
import { OperatorWidgetFields } from "./OperatorWidget";

interface Props {
  show: boolean;
  blockedFields: (keyof AppDefectListItem)[];
  onApply: (queryStrings: string[]) => void;
  onDismiss: () => void;
}

export interface Filter {
  field: keyof AppDefectListItem | "";
  operator: "" | "Not" | "Gte" | "Lte" | "Inc";
  value: any;
}

const FilterModal: React.FC<Props> = (props) => {
  const [filters, setFilters] = useState<Filter[]>([{}] as Filter[]);
  const handleFilterFieldChange = (
    index: number,
    field: keyof AppDefectListItem | ""
  ) => {
    const newFilters = [...filters];
    let operator = newFilters[index].operator;

    if (field !== "") {
      const availableOperators = OperatorWidgetFields[field].options.map(
        (option: { value: string }) => option.value
      );
      if (!availableOperators.includes(operator)) {
        operator = availableOperators[0];
      }
    }

    newFilters[index] = { field, operator, value: "" };
    setFilters(newFilters);
  };

  const handleFilterOperatorChange = (
    index: number,
    operator: Filter["operator"]
  ) => {
    const newFilters = [...filters];
    newFilters[index] = { ...newFilters[index], operator };
    setFilters(newFilters);
  };

  const handleFilterValueChange = (index: number, value: any) => {
    const newFilters = [...filters];
    newFilters[index] = { ...newFilters[index], value };
    setFilters(newFilters);
  };

  const handleAddFilter = () => {
    setFilters([...filters, { field: "", operator: "", value: "" }]);
  };

  const handleDeleteFilter = (index: number) => {
    const newFilters = [...filters];
    newFilters.splice(index, 1);
    setFilters(newFilters);
  };

  const handleClearAllFilters = () => {
    setFilters([]);
  };

  const handleApply = () => {
    const queryDict: Record<string, any[]> = {};
    for (const filter of filters) {
      const { field, operator, value } = filter;
      if (field === "") continue;
      if (!value && value !== false) continue;
      let key = `${field}${operator}`;
      if (!queryDict[key]) {
        queryDict[key] = [];
      }
      queryDict[key].push(value);
    }

    const queryStrings: string[] = [];
    for (const key of Object.keys(queryDict)) {
      if (["createdAt", "createdAtGte", "createdAtLte"].includes(key)) {
        queryStrings.push(
          `${key}=${queryDict[key]
            .map((dateString) => {
              const localTime =
                key !== "createdAtLte" ? "00:00:00.000" : "23:59:59.999";
              return moment(`${dateString} ${localTime}`).valueOf();
            })
            .join(",")}`
        );
      } else {
        queryStrings.push(`${key}=${queryDict[key].join(",")}`);
      }
    }
    props.onApply(queryStrings);
  };

  return (
    <FilterModalLayout
      show={props.show}
      blockedFields={props.blockedFields}
      filters={filters}
      onFilterFieldChange={handleFilterFieldChange}
      onFilterOperatorChange={handleFilterOperatorChange}
      onFilterValueChange={handleFilterValueChange}
      onAddFilter={handleAddFilter}
      onDeleteFilter={handleDeleteFilter}
      onClearAllFilters={handleClearAllFilters}
      onApply={handleApply}
      onDismiss={props.onDismiss}
    />
  );
};

export default FilterModal;
