import { cloneDeep } from "lodash";
import moment from "moment";

// CPCS-1572 adjustment made for punchlist backend's datetime handling, suggest review how datetime value should be handled for punchlist so as to align with other qc projects
export const getObjectWithOffsetDate = (source: Record<string, any>) => {
  const o = cloneDeep(source);
  Object.keys(o).forEach((key) => {
    if (o[key] instanceof Date) {
      o[key] = moment(o[key]).add(moment().utcOffset(), "minutes");
    }
  });
  return o;
};
