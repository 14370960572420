import { Subcontractor } from "../../services/app";
import { defaultSubcontractorStatistics } from "./report";

export interface SubcontractorStatistic {
  key: string;
  title: string;
  value: string | undefined;
  get: string;
  query: string;
}
interface subcontractorState {
  subcontractorStatistic: SubcontractorStatistic[];
  subcontractor: Subcontractor;
}

type subcontractorAction =
  | {
      type: "setSubcontractorStatistics";
      setSubcontractorStatistics: subcontractorState["subcontractorStatistic"];
      setSubcontractor: subcontractorState["subcontractor"];
    }
  | {
      type: "setSubcontractor";
      setSubcontractor: subcontractorState["subcontractor"];
    }
  | {
      type: "clear";
    };
export const initSubcontractorsState: subcontractorState = {
  subcontractorStatistic: defaultSubcontractorStatistics,
  subcontractor: {} as Subcontractor,
};
export default function subcontractorReducer(
  state: subcontractorState,
  action: subcontractorAction
): subcontractorState {
  switch (action.type) {
    case "setSubcontractorStatistics": {
      return {
        ...state,
        subcontractorStatistic: action.setSubcontractorStatistics,
        subcontractor: action.setSubcontractor,
      };
    }
    case "setSubcontractor": {
      return {
        ...state,
        subcontractor: action.setSubcontractor,
      };
    }
    case "clear": {
      return initSubcontractorsState;
    }
    default:
      return state;
  }
}
