import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { NxpPanelEditable, NxpSelectUser } from "@nexploretechnology/nxp-ui";
import { Col, Row } from "antd";

import useAppContext from "../../../../hooks/useAppContext";
import { Subcontractor } from "../../../../services/app";

interface Props {
  subcontractor: Subcontractor;
  onSave?: (subcontractor: Subcontractor, userIds: string[]) => void;
}

const useStyles = createUseStyles((theme) => ({
  root: {
    marginBottom: 10,
    "& .ant-select": {
      width: "100%",
    },
  },
  label: {
    color: theme.palette.textLabel,
    fontSize: theme.fontSize.smaller,
    textTransform: "uppercase",
    fontWeight: theme.fontWeight.bold,
  },
}));

const UserListPanel: React.FC<Props> = ({
  subcontractor,
  onSave = () => {},
}) => {
  const classes = useStyles();
  const { users, hasRight } = useAppContext();

  const { t: translation } = useTranslation();

  const [userIds, setUserIds] = useState<string[]>([] as string[]);

  const handleSave = (setEditing: (editing: boolean) => void) => {
    setEditing(false);
    onSave(subcontractor, userIds);
  };

  const handleValueChange = (value: string | string[]) => {
    if (typeof value == "string") {
      value = [value];
    }
    setUserIds(value);
  };

  useEffect(() => {
    setUserIds(subcontractor.users?.map((user) => "" + user.id) || []);
  }, [subcontractor]);

  return (
    <NxpPanelEditable
      className={classes.root}
      titleContent={`${subcontractor.name} (${
        subcontractor.users?.length || 0
      })`}
      editable={hasRight("subcontractor-user-edit")}
      defaultCollapsed={true}
      onPanelSave={handleSave}
    >
      {(editing) => {
        if (!editing) {
          return (
            <div data-testid="subConOtp">
              {subcontractor.users?.map((user) => user.name).join(", ")}
            </div>
          );
        }

        return (
          <Row>
            <Col span={24}>
              <label className={classes.label}>
                {translation("app.common.user")}
              </label>
              <NxpSelectUser
                mode="multiple"
                allowClear={true}
                options={users.map((user) => ({
                  name: user?.name,
                  id: "" + user?.id,
                }))}
                value={userIds}
                onChange={handleValueChange}
              ></NxpSelectUser>
            </Col>
          </Row>
        );
      }}
    </NxpPanelEditable>
  );
};

export default UserListPanel;
