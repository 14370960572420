import React, { useEffect, useState } from "react";

import { Defect } from "../../../../services/defect";

import ByDefectTypeLayout from "./ByDefectTypeLayout";

interface ByDefectTypeContainerProps {
  defects: Defect[];
}

let initialByDefectTypeData: {
  type: string;
  value: number;
}[] = [];

const ByDefectTypeContainer: React.FC<ByDefectTypeContainerProps> = ({
  defects,
}) => {
  const [byDefectTypeData, setByDefectTypeData] = useState(
    initialByDefectTypeData
  );

  useEffect(() => {
    initialByDefectTypeData = [];

    setByDefectTypeData(initialByDefectTypeData);
    const defectGroups = new Set(
      defects.map((defect) => defect.defectGroup?.id)
    );

    const defectTypes = new Set(defects.map((defect) => defect.defectType?.id));
    defectGroups.forEach((defectGroup) => {
      defectTypes.forEach((defectType) => {
        initialByDefectTypeData.push({
          type: `${
            defects.find((defect) => defect.defectType?.id === defectType)
              ?.defectType?.name
          } - ${
            defects.find((defect) => defect.defectGroup?.id === defectGroup)
              ?.defectGroup?.name
          }
          `,
          value: defects.filter(
            (defect) =>
              defect.defectGroup?.id === defectGroup &&
              defect.defectType?.id === defectType
          ).length,
        });
      });
    });

    setByDefectTypeData([
      ...initialByDefectTypeData.filter((item) => item.value !== 0),
    ]);
  }, [defects]);

  return <ByDefectTypeLayout byDefectTypeData={byDefectTypeData} />;
};

export default ByDefectTypeContainer;
