import React, { ReactNode, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { IdcardFilled, MailFilled } from "@ant-design/icons";
import { NxpLayout, NxpNavbarConfigMenu } from "@nexploretechnology/nxp-ui";
import { useKeycloak } from "@react-keycloak/web";

import useAppContext from "../../../hooks/useAppContext";
import dashboardIcon from "../../../images/sidebar/sidebar-dashboard.svg";
import defectIcon from "../../../images/sidebar/sidebar-defect.svg";
import searchIcon from "../../../images/sidebar/sidebar-inspection.svg";
import locationGroupIcon from "../../../images/sidebar/sidebar-location-group.svg";
import locationsIcon from "../../../images/sidebar/sidebar-locations.svg";
import reportsIcon from "../../../images/sidebar/sidebar-reports.svg";
import securityIcon from "../../../images/sidebar/sidebar-security.svg";
import settingsIcon from "../../../images/sidebar/sidebar-setup.svg";
import subcontractorsIcon from "../../../images/sidebar/sidebar-subcontractors.svg";
import { patchMyUserPreference } from "../../../services/app";
import i18n from "../i18n";

const useStyles = createUseStyles((theme) => ({
  defect: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${defectIcon})`,
      transform: "scale(.9)",
    },
  },
  location: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${locationsIcon})`,
      transform: "scale(.95)",
    },
  },
  search: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${searchIcon})`,
      transform: "scale(.8)",
    },
  },
  subcontractors: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${subcontractorsIcon})`,
      transform: "scale(.95)",
    },
  },
  dashboard: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${dashboardIcon})`,
      transform: "scale(.8)",
    },
  },
  reports: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${reportsIcon})`,
      transform: "scale(.8)",
    },
  },
  security: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${securityIcon})`,
      transform: "scale(.8)",
    },
  },
  locationGroup: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${locationGroupIcon})`,
      transform: "scale(.95)",
    },
  },
  settings: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${settingsIcon})`,
      transform: "scale(.9)",
    },
  },
  userInfo: {
    "& > span.anticon": {
      marginRight: theme.spacing(1),
      transform: "scale(.9)",
    },
  },
}));

interface AppLayoutProps {
  children: ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const { t: translation } = useTranslation();

  const classes = useStyles();
  const appContext = useAppContext();
  const {
    routeParams,
    activeUser,
    hasRight,
    activeLocationGroup,
    subcontractors,
    activeEntity,
    serviceConfig,
    language,
    onAppContextCacheItemUpdate,
  } = appContext;
  const { entityId, locationGroupId } = routeParams;
  const { keycloak } = useKeycloak();

  const changeLanguage = useCallback(
    async (key: string) => {
      const newUserPreference = await patchMyUserPreference(serviceConfig, {
        application: "entity",
        parameter: "language",
        value: key,
      });
      onAppContextCacheItemUpdate("language", key);
      return newUserPreference;
    },
    [serviceConfig, onAppContextCacheItemUpdate]
  );
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <NxpLayout
      navBarProps={{
        title: `Punchlist`,
        subtitleContent: `${activeEntity?.name} (v0.7.0)`,
        subheading: activeLocationGroup?.name,
        username: activeUser?.name || "",
        userPopoverContent: (
          <>
            <p className={classes.userInfo}>
              <IdcardFilled /> {activeUser?.name}
            </p>
            <p className={classes.userInfo}>
              <MailFilled /> {activeUser?.email}
            </p>
          </>
        ),
        settingsPopoverContent: (
          <NxpNavbarConfigMenu
            activeLanguageKey={i18n.language}
            onLanguageChange={changeLanguage}
            onLogoutClick={() => keycloak.logout()}
            languageSettings={[
              {
                name: "English",
                key: "en",
              },
              {
                name: "中文",
                key: "zh-HK",
              },
              {
                name: "Deutsch",
                key: "de",
              },
              {
                name: "Español",
                key: "es",
              },
            ]}
          />
        ),
      }}
      sidebarProps={{
        items: [
          locationGroupId && hasRight("defect-view")
            ? {
                type: "submenu",
                title: translation("app.common.defects"),
                className: classes.defect,
                menuLinks: [
                  {
                    href: `/entities/${entityId}/location-groups/${locationGroupId}/defects?raisedBy=${activeUser?.id}`,
                    title: translation("app.common.defectsRaisedByMe"),
                  },
                  {
                    href: `/entities/${entityId}/location-groups/${locationGroupId}/defects?responsiblePerson=${activeUser?.id}`,
                    title: translation("app.common.defectsForMe"),
                  },
                  {
                    href: `/entities/${entityId}/location-groups/${locationGroupId}/defects?isJointInspection=true`,
                    title: translation("app.common.jointInspectionDefects"),
                  },
                  {
                    href: `/entities/${entityId}/location-groups/${locationGroupId}/defects?isOutstanding=true`,
                    title: translation("app.common.outstandingDefects"),
                  },
                  {
                    href: `/entities/${entityId}/location-groups/${locationGroupId}/defects?isClosed=true`,
                    title: translation("app.common.closedDefects"),
                  },
                  {
                    href: `/entities/${entityId}/location-groups/${locationGroupId}/defects`,
                    title: translation("app.common.allDefects"),
                  },
                ],
              }
            : null,
          locationGroupId && hasRight("location-view")
            ? {
                type: "link",
                href: `/entities/${entityId}/location-groups/${locationGroupId}/locations`,
                title: translation("app.appLayout.locations"),
                className: classes.location,
              }
            : null,
          locationGroupId && hasRight("plan-view", "locationGroup")
            ? {
                type: "submenu",
                title: translation("app.common.inspection.label"),
                className: classes.search,
                menuLinks: [
                  {
                    href: `/entities/${entityId}/location-groups/${locationGroupId}/inspectors`,
                    title: translation("app.common.inspectors"),
                  },
                ],
              }
            : null,
          !locationGroupId && hasRight("subcontractor-view", "module")
            ? {
                type: "link",
                href: `/entities/${entityId}/subcontractors`,
                title: translation("app.appLayout.subcon"),
                className: classes.subcontractors,
              }
            : null,
          locationGroupId &&
          subcontractors &&
          hasRight("subcontractor-dashboard-view")
            ? {
                type: "link",
                href: `/entities/${entityId}/location-groups/${locationGroupId}/subcontractors`,
                title: translation("app.appLayout.subconView"),
                className: classes.subcontractors,
              }
            : null,
          locationGroupId && hasRight("dashboard-view", "locationGroup")
            ? {
                type: "link",
                href: `/entities/${entityId}/location-groups/${locationGroupId}/dashboard`,
                title: translation("app.common.dashboard"),
                className: classes.dashboard,
              }
            : null,
          locationGroupId && hasRight("report-view", "locationGroup")
            ? {
                type: "link",
                href: `/entities/${entityId}/location-groups/${locationGroupId}/reports`,
                title: translation("app.common.reports"),
                className: classes.reports,
              }
            : null,
          !locationGroupId && (hasRight("role-view") || hasRight("right-view"))
            ? {
                type: "submenu",
                title: translation("app.appLayout.security"),
                className: classes.security,
                menuLinks: [
                  ...(hasRight("role-view")
                    ? [
                        {
                          href: `/entities/${entityId}/roles`,
                          title: translation("app.appLayout.manageRolesModule"),
                        },
                      ]
                    : []),
                  ...(hasRight("right-view")
                    ? [
                        {
                          href: `/entities/${entityId}/rights`,
                          title: translation(
                            "app.appLayout.accessRightsModule"
                          ),
                        },
                      ]
                    : []),
                ],
              }
            : null,
          locationGroupId && (hasRight("role-view") || hasRight("right-view"))
            ? {
                type: "submenu",
                title: translation("app.appLayout.security"),
                className: classes.security,
                menuLinks: [
                  ...(hasRight("role-view")
                    ? [
                        {
                          href: `/entities/${entityId}/location-groups/${locationGroupId}/roles`,
                          title: translation(
                            "app.appLayout.manageRolesLocationGroup"
                          ),
                        },
                      ]
                    : []),
                  ...(hasRight("right-view")
                    ? [
                        {
                          href: `/entities/${entityId}/location-groups/${locationGroupId}/rights`,
                          title: translation(
                            "app.appLayout.accessRightsLocationGroup"
                          ),
                        },
                      ]
                    : []),
                ],
              }
            : null,
          {
            type: "link",
            href: `/entities/${entityId}/location-groups/select`,
            title: translation("app.appLayout.locationGroups"),
            className: classes.locationGroup,
          },
          !locationGroupId && hasRight("setup-view")
            ? {
                type: "submenu",
                title: translation("app.common.settings"),
                menuLinks: [
                  {
                    href: `/entities/${entityId}/settings/cause`,
                    title: translation("app.common.cause"),
                  },
                  {
                    href: `/entities/${entityId}/settings/inspection-type`,
                    title: translation("app.common.inspectionType"),
                  },
                  {
                    href: `/entities/${entityId}/settings/defect-type`,
                    title: translation("app.common.defectType"),
                  },
                  {
                    href: `/entities/${entityId}/settings/defect-group`,
                    title: translation("app.common.defectGroup"),
                  },
                  {
                    href: `/entities/${entityId}/settings/subject`,
                    title: translation("app.common.subject"),
                  },
                  {
                    href: `/entities/${entityId}/settings/checklist`,
                    title: translation("app.common.checklist"),
                  },
                  ...(hasRight("subcontractor-user-view")
                    ? [
                        {
                          href: `/entities/${entityId}/settings/subcontractor-user`,
                          title: translation("app.common.subcontractorUser"),
                        },
                      ]
                    : []),
                  {
                    href: `/entities/${entityId}/settings/email-notification`,
                    title: translation("app.appLayout.emailNotification"),
                  },
                  {
                    href: `/entities/${entityId}/settings/parameters`,
                    title: translation("app.common.parameters"),
                  },
                  {
                    href: `/entities/${entityId}/settings/team`,
                    title: translation("app.common.team"),
                  },
                  {
                    href: `/entities/${entityId}/settings/team-members`,
                    title: translation("app.common.teamMember"),
                  },
                ],
                className: classes.settings,
              }
            : null,
        ],
      }}
    >
      {children}
    </NxpLayout>
  );
};

export default AppLayout;
