import React from "react";
import { createUseStyles } from "react-jss";

import { Subcontractor } from "../../../services/app";
import SubjectForm from "./SubjectForm";
import SubjectTree from "./SubjectTree/SubjectTree";
import { ActionType, TreeNode } from "./SubjectContainer";

interface SubjectLayoutProps {
  currentNode: TreeNode;
  defectGroupList: TreeNode[];
  descriptionSubcontractors: Subcontractor[] | undefined;
  handleModifiedSubcontractors: (subcontractors: any[]) => void;
  handleModifyTree: (
    item: any,
    itemType: string,
    actionType: ActionType
  ) => void;
  onSelect: (selectedKeys: React.Key[], info: any) => void;
}

const useStyles = createUseStyles(() => ({
  root: {
    display: "flex",
    margin: "-16px -40px",
  },
  tree: {
    background: "#FFFFFFFF",
    padding: "16px 40px",
    width: 450,
    height: "calc(100vh - 85px)",
  },
  content: {
    padding: "16px 40px",
    flexGrow: 1,
  },
}));

const SubjectLayout: React.FC<SubjectLayoutProps> = ({
  currentNode,
  defectGroupList,
  descriptionSubcontractors,
  handleModifiedSubcontractors,
  handleModifyTree,
  onSelect,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.tree}>
        <SubjectTree defectGroupList={defectGroupList} onSelect={onSelect} />
      </div>
      <div className={classes.content}>
        <SubjectForm
          currentNode={currentNode}
          descriptionSubcontractors={descriptionSubcontractors}
          handleModifiedSubcontractors={handleModifiedSubcontractors}
          handleModifyTree={handleModifyTree}
        />
      </div>
    </div>
  );
};

export default SubjectLayout;
