import React from "react";
import { Row, Col, Checkbox, Radio } from "antd";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";

import { ReminderType } from "../../../../services/email";
import { FieldName } from "../helper";

const useStyles = createUseStyles((theme) => ({
  dueDate: {},
  title: {
    "& span": {
      fontSize: theme.pxToRem(20),
      fontWeight: theme.fontWeight.bolder,
    },
  },
  details: {
    marginBottom: theme.spacing(5),
    "& .detail": {
      paddingTop: theme.spacing(2.5),
    },
  },
}));

interface EmailNotificationSetupProps {
  reminderSettingsForm: any;
  handleFormChange: (
    value: boolean,
    reminderType: ReminderType,
    fieldName: FieldName
  ) => void;
}

const EmailNotificationSetup: React.FC<EmailNotificationSetupProps> = ({
  reminderSettingsForm,
  handleFormChange,
}) => {
  const classes = useStyles();
  const { t: translation } = useTranslation();

  return (
    <div className={classes.dueDate}>
      <div className={classes.title}>
        <Checkbox
          checked={reminderSettingsForm.dueDate.isChecked}
          onChange={(e) =>
            handleFormChange(e.target.checked, "dueDate", "isChecked")
          }
        >
          {translation("settings.emailNotification.dueDateReminderEmail")}
        </Checkbox>
      </div>
      {reminderSettingsForm.dueDate.isChecked ? (
        <div className={classes.details}>
          <Row className="detail">
            <Col flex="100px">
              {translation("settings.emailNotification.frequency")}:
            </Col>
            <Col flex="auto">
              <Radio.Group
                onChange={(e) => {
                  handleFormChange(
                    e.target.value === "daily",
                    "dueDate",
                    "isDaily"
                  );
                }}
                value={
                  reminderSettingsForm.dueDate.isDaily ? "daily" : "weekly"
                }
              >
                <Radio value={"daily"}>
                  {translation("settings.emailNotification.daily")}
                </Radio>
                <Radio value={"weekly"}>
                  {translation("settings.emailNotification.weeklyMonday")}
                </Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row className="detail">
            <Col flex="400px">
              <div>
                {translation(
                  "settings.emailNotification.includeSubcontractorUserInReminderEmail"
                )}
                :
              </div>
              <div>{`(${translation(
                "settings.emailNotification.assignInSettings"
              )} > ${translation(
                "settings.emailNotification.subcontractorUsers"
              )})`}</div>
            </Col>
            <Col flex="auto">
              <Radio.Group
                onChange={(e) => {
                  handleFormChange(
                    e.target.value === "yes",
                    "dueDate",
                    "isCcSubconUser"
                  );
                }}
                value={
                  reminderSettingsForm.dueDate.isCcSubconUser ? "yes" : "no"
                }
              >
                <Radio value={"yes"}>{translation("app.common.yes")}</Radio>
                <Radio value={"no"}>{translation("app.common.no")}</Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row className="detail">
            <Col flex="400px">
              <div>
                {translation(
                  "settings.emailNotification.attachSubcontractorDefectReportInEmail"
                )}
                :
              </div>
            </Col>
            <Col flex="auto">
              <Radio.Group
                onChange={(e) => {
                  handleFormChange(
                    e.target.value === "yes",
                    "dueDate",
                    "isAttachDefectReport"
                  );
                }}
                value={
                  reminderSettingsForm.dueDate.isAttachDefectReport
                    ? "yes"
                    : "no"
                }
              >
                <Radio value={"yes"}>{translation("app.common.yes")}</Radio>
                <Radio value={"no"}>{translation("app.common.no")}</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </div>
      ) : null}
    </div>
  );
};

export default EmailNotificationSetup;
