import React, { useEffect, useState } from "react";
import moment from "moment";

import { Defect } from "../../../../services/defect";

import DefectProgressLayout from "./DefectProgressLayout";

interface DefectProgressContainerProps {
  defects: Defect[];
}

let initialDefectRaisedOnThatDayData: {
  date: string;
  value: number;
  type: string;
}[] = [];

let initialCumulativeDefectData: {
  date: string;
  count: number;
  type: string;
}[] = [];

const DefectProgressContainer: React.FC<DefectProgressContainerProps> = ({
  defects,
}) => {
  const [defectRaisedOnThatDayData, setDefectRaisedOnThatDayData] = useState(
    initialDefectRaisedOnThatDayData
  );

  const [cumulativeDefectData, setCumulativeDefectData] = useState(
    initialCumulativeDefectData
  );

  useEffect(() => {
    initialDefectRaisedOnThatDayData = [];
    initialCumulativeDefectData = [];

    const dateList = [];
    const previousMonth = moment().subtract(1, "month").add(1, "day");
    for (let d = previousMonth; d <= moment(); d = d.add(1, "day")) {
      dateList.push(d.format("YYYY-MM-DD"));
    }

    dateList.forEach((date) => {
      initialDefectRaisedOnThatDayData.push({
        date: new Date(date)
          .toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
          })
          .split(" ")
          .join("-"),
        value: defects.filter((defect) => defect.raiseDate === date).length,
        type: "Raise",
      });
    });

    dateList.forEach((date) => {
      initialDefectRaisedOnThatDayData.push({
        date: new Date(date)
          .toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
          })
          .split(" ")
          .join("-"),
        value: defects.filter((defect) => defect.closeDate === date).length,
        type: "Close",
      });
    });

    dateList.forEach((date) => {
      initialCumulativeDefectData.push({
        date: new Date(date)
          .toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
          })
          .split(" ")
          .join("-"),
        count: defects.filter((defect) => defect.raiseDate <= date).length,
        type: "Cumulative Raised",
      });
    });

    dateList.forEach((date) => {
      initialCumulativeDefectData.push({
        date: new Date(date)
          .toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
          })
          .split(" ")
          .join("-"),
        count: defects.filter((defect) => defect.closeDate <= date).length,
        type: "Cumulative Closed",
      });
    });

    setDefectRaisedOnThatDayData([...initialDefectRaisedOnThatDayData]);
    setCumulativeDefectData([...initialCumulativeDefectData]);
  }, [defects]);

  return (
    <DefectProgressLayout
      defectRaisedOnThatDayData={defectRaisedOnThatDayData}
      cumulativeDefectData={cumulativeDefectData}
    />
  );
};

export default DefectProgressContainer;
