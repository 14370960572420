import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";

import { FilterFilled } from "@ant-design/icons";
import { NxpButton, NxpHeader } from "@nexploretechnology/nxp-ui";
import { Card, Col, Row, Spin, Tree } from "antd";

import FilterModal from "../../components/AppDefectFilterModal";
import AppDefectList from "../../components/AppDefectList";
import useAppContext from "../../hooks/useAppContext";
import { Subcontractor } from "../../services/app";
import { pxToRem } from "../../theme.config";
import { SubcontractorStatistic } from "./SubcontractorReducer";

interface SubcontractorViewLayoutProps {
  extraQueries: string[];
  filterModalVisible: boolean;
  history: any;
  invalidateAt: number;
  queryString: string;
  subcontractor: Subcontractor | undefined;
  subcontractorStatistics: SubcontractorStatistic[];
  summaryVisible: boolean;
  handleFilterApply: (queryStrings: string[]) => void;
  onSubcontractorSelect: (selectedKeys: React.Key[], info: any) => void;
  setFilterModalVisible: (value: React.SetStateAction<boolean>) => void;
  setSummaryVisible: (type: "cost" | "normal") => void;
}

const useStyles = createUseStyles((theme) => ({
  root: {
    margin: "-16px -40px",
    display: "flex",
  },
  header: {
    marginLeft: "25px",
    fontWeight: "bold",
  },
  tree: {
    background: "#FFFFFFFF",
    padding: "40px 20px",
    width: 450,
    height: "calc(100vh - 85px)",
  },
  content: {
    padding: "16px 40px",
    maxWidth: "calc(70vw + 25px)",
  },
  statistics: {
    display: "flex",
    padding: "20px 0px",
    maxWidth: "calc(60vw + 25px)",
  },
  defects: {
    padding: "0px 10px",
  },
  defectRow: {
    display: "flex",
    height: "280px",
  },
  defectRowLeft: {
    display: "block",
    width: "65%",
  },
  defectRowLeftInner: {
    display: "flex",
    height: "50%",
  },
  defectRowRight: {
    display: "block",
    width: "35%",
  },
  defectRowFirstItem: {
    display: "flex",
    width: "20%",
  },
  defectRowSecondItem: {
    display: "flex",
    width: "25%",
  },
  floorPlan: {
    width: "100%",
    height: "100%",
  },
  card: {
    textAlign: "center",
    marginRight: "20px",
    margin: theme.spacing(1, 3, 1, 0),
    minWidth: "200px",
    "& h1": {
      "&.green": {
        color: theme.palette.success,
      },
      "&.red": {
        color: theme.palette.warning,
      },
      fontSize: theme.pxToRem(25),
      color: "#005794",
      margin: 0,
    },
    "& p": {
      fontSize: theme.pxToRem(12),
      fontWeight: 700,
      color: "#8A9999",
      textTransform: "uppercase",
    },
  },
  filterCounter: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.backgroundPrimaryLight,
    color: theme.palette.primary,
    borderRadius: theme.pxToRem(5),
    width: theme.pxToRem(20),
    height: pxToRem(20),
    margin: theme.spacing(0, 1),
  },
}));

const SubcontractorViewLayout: React.FC<SubcontractorViewLayoutProps> = ({
  extraQueries,
  filterModalVisible,
  history,
  invalidateAt,
  queryString,
  subcontractor,
  subcontractorStatistics,
  handleFilterApply,
  onSubcontractorSelect,
  setFilterModalVisible,
  setSummaryVisible,
}) => {
  const { t: translation } = useTranslation();

  const classes = useStyles();

  const appContext = useAppContext();
  const { hasRight, subcontractors } = appContext;

  const routeParam = useParams<{
    entityId: string;
    locationGroupId: string;
    subcontractorId: string;
  }>();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.tree}>
          <p className={classes.header}>
            {translation("app.common.subcontractor")}
          </p>
          <Tree
            onSelect={onSubcontractorSelect}
            defaultSelectedKeys={[routeParam?.subcontractorId]}
          >
            {subcontractors
              .sort((a, b) => a.name.localeCompare(b.name))
              ?.map((subcontractor, index) => (
                <React.Fragment key={`subcontractors_${index}`}>
                  <Tree.TreeNode
                    key={subcontractor.id}
                    title={subcontractor.name}
                  />
                </React.Fragment>
              ))}
          </Tree>
        </div>
        <div className={classes.content}>
          {subcontractor && routeParam?.subcontractorId !== undefined && (
            <>
              <NxpHeader
                titleContent={subcontractor.name}
                actionContent={
                  <>
                    <NxpButton
                      type="default"
                      icon={<FilterFilled />}
                      onClick={() => setFilterModalVisible(true)}
                    >
                      {extraQueries?.length > 0 && (
                        <div className={classes.filterCounter}>
                          <div>{extraQueries?.length}</div>
                        </div>
                      )}
                    </NxpButton>
                    {routeParam?.locationGroupId && hasRight("report-view") && (
                      <NxpButton onClick={() => setSummaryVisible("normal")}>
                        {translation("app.common.summary")}
                      </NxpButton>
                    )}
                    {routeParam?.locationGroupId && hasRight("report-view") && (
                      <NxpButton onClick={() => setSummaryVisible("cost")}>
                        {translation("subcontractorView.costSummary")}
                      </NxpButton>
                    )}
                  </>
                }
              />

              <Row gutter={[8, 8]}>
                {subcontractorStatistics.map(
                  (subcontractorStatistic, index) => {
                    return (
                      <Col span={6} key={`subcontractorStatistic-${index}`}>
                        <Card size="small" className={classes.card}>
                          <h1
                            className={
                              subcontractorStatistic?.title ===
                                "app.common.fixed" &&
                              subcontractorStatistic?.get === "count"
                                ? "green"
                                : subcontractorStatistic?.title ===
                                  "app.common.overdue"
                                ? "red"
                                : "primary"
                            }
                          >
                            {subcontractorStatistic?.key === "Total Defects" ||
                            subcontractorStatistic?.key === "Fixed totalCost" ||
                            (subcontractorStatistic?.key === "Outstanding" &&
                              subcontractorStatistic?.get === "totalCost") ||
                            subcontractorStatistic?.key ===
                              "Overdue totalCost" ||
                            subcontractorStatistic?.key === "Total $ Backcharge"
                              ? `$` + subcontractorStatistic?.value
                              : subcontractorStatistic?.value}
                          </h1>
                          <p>{translation(subcontractorStatistic?.title)}</p>
                        </Card>
                      </Col>
                    );
                  }
                )}
                <Col span={24}>
                  <Spin spinning={!routeParam?.subcontractorId}>
                    <AppDefectList
                      queryString={queryString}
                      invalidateAt={invalidateAt}
                      onDefectClick={(defectId) =>
                        history.push(
                          `/entities/${routeParam?.entityId}/location-groups/${routeParam?.locationGroupId}/defects/${defectId}`
                        )
                      }
                    />
                  </Spin>
                  <FilterModal
                    show={filterModalVisible}
                    blockedFields={["responsibleParty"]}
                    onApply={handleFilterApply}
                    onDismiss={() => {
                      setFilterModalVisible(false);
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SubcontractorViewLayout;
